<template>
<div class="animated fadeIn">
   
        <div class="row">
            <!--begin::Sidebar-->
            <div class="col-xl-4">
                <!--begin::Card-->
                <div class="card rounded-1 border-secondary border border-dashed">
                    <!--begin::Card body-->
                    <div class="card-body card-scroll-none p-3 pt-3"> 
                        <div class="d-flex justify-content-end">
                            <button type="button" class="btn btn-light-primary btn-icon btn-sm me-2" v-if="user.employee.status_id !== STATUS_CODE.UNAPPROVE && !trigSetting" @click="TOGGLE_SETTINGS()">
                                <i class="mdi mdi-account-cog fs-2 fw-bolder"></i>
                            </button>
                            <button type="button" class="btn btn-light-primary btn-icon btn-sm me-2" v-if="trigSetting || approve" @click="trigSetting = false, approve = false">
                                <i class="mdi mdi-arrow-left fs-2 fw-bolder"></i>
                            </button>
                            <button type="button" @click="TOGGLE_APPROVE()" class="btn btn-primary btn-sm fs-9 rounded-0 me-2" v-if="user.employee.status_id === STATUS_CODE.UNAPPROVE && !approve">
                                approve 
                            </button> 
                        </div>
                        <!--begin::User Info-->
                        <div class="d-flex flex-center flex-column py-5">
                            <!--begin::Avatar-->
                            <div class="symbol symbol-200px symbol-circle mb-7">
                                <img :src="user.photo" alt="image">
                            </div>
                            <!--end::Avatar-->
                            <!--begin::Name-->
                            <h6 class="fs-3 text-gray-800 text-hover-primary fw-bolder mb-3">{{user.surname +' '+ user.first_name }}</h6>
                            <!--end::Name-->
                            <!--begin::Position-->
                            <div class="mb-2"> 
                                <!--begin::Badge-->
                                <div class="badge badge-lg d-inline fs-9" :class="'badge-light-'+GET_COLOR(user.employee.status_id)">{{CAPITALIZE_STR(user.employee.status.name)}}</div>
                                <!--begin::Badge-->
                            </div>
                            <!--end::Position-->
                            <!--begin::Info-->
                            <!--begin::Info heading-->
                            <div class="fw-bolder mb-2 text-gray-700">Records
                            <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-html="true" data-bs-content="Number of support tickets assigned, closed and pending this week." data-bs-original-title="" title=""></i></div>
                            <!--begin::Stats-->
                            <div class="row g-5 g-xl-2">   
                                <!--begin::Stat-->
                                <div class="col-4">
                                    <div class="py-2 px-4 border border-gray-300 border-dashed">
                                    <!--begin::Number-->
                                    <div class="d-flex align-items-center"> 
                                        <div class="fs-6 fw-bolder">$15,000</div>
                                    </div>
                                    <!--end::Number-->
                                    <!--begin::Label-->
                                    <div class="fw-bold fs-8 text-gray-400">Ratings</div>
                                    <!--end::Label-->
                                    </div>
                                </div>
                                <!--end::Stat-->

                                <!--begin::Stat-->
                                <div class="col-4">
                                    <div class="py-2 px-4 border border-gray-300 border-dashed">
                                    <!--begin::Number-->
                                    <div class="d-flex align-items-center"> 
                                        <div class="fs-6 fw-bolder">$15,000</div>
                                    </div>
                                    <!--end::Number-->
                                    <!--begin::Label-->
                                    <div class="fw-bold fs-8 text-gray-400">Locations</div>
                                    <!--end::Label-->
                                    </div>
                                </div>
                                <!--end::Stat-->

                                <!--begin::Stat-->
                                <div class="col-4">
                                    <div class="py-2 px-4 border border-gray-300 border-dashed">
                                    <!--begin::Number-->
                                    <div class="d-flex align-items-center"> 
                                        <div class="fs-6 fw-bolder">$15,000</div>
                                    </div>
                                    <!--end::Number-->
                                    <!--begin::Label-->
                                    <div class="fw-bold fs-8 text-gray-400">Reports</div>
                                    <!--end::Label-->
                                    </div>
                                </div>
                                <!--end::Stat--> 

                            </div>
                            <!--end::Stats-->
                        </div>
                        <!--end::User Info-->
                        <!--end::Summary-->

 
                    </div>
                    <!--end::Card body-->
                </div>
                <!--end::Card--> 
            </div>
            <!--end::Sidebar--> 
            
            <div class="col-xl-8 mt-10 mt-md-0" id="infoPane"> 
                <div v-if="!trigSetting && !approve"> 
                    <!--begin::PERSONAL INFO-->
                    <div class="d-flex flex-stack fs-4 pb-3">
                        <div class="fw-bolder fs-5 collapsible"  @click="active_drop = 1" aria-expanded="false">Personal Info</div> 
                    </div>
                    <!--end::Details toggle-->
                    <div class="separator"></div>
                    <div id="personal_info" class="collapse animated fadeIn bg-gray-100 mt-n1" :class="active_drop == 1 ? 'show' : null">
                        <div class="pb-5 pt-3 fs-6 px-3">
                            <!--begin::Details item-->
                            <div class="fw-bolder mt-5 fs-7">Full name</div>
                            <div class="text-gray-600 fs-8">{{user.surname + ' '+user.first_name + ' '+user.other_name}}</div>
                            <!--begin::Details item-->
                            <!--begin::Details item-->
                            <div class="fw-bolder mt-5 fs-7">Gender</div>
                            <div class="text-gray-600 fs-8">{{!IS_EMPTY(user.gender) ? user.gender : '-----------------------------------------------------------'}}</div>
                            <!--begin::Details item-->
                            <!--begin::Details item-->
                            <div class="fw-bolder mt-5 fs-7">Date of birth</div>
                            <div class="text-gray-600 fs-8">{{!IS_EMPTY(user.dob) ? user.dob : '-----------------------------------------------------------'}}</div>
                            <!--begin::Details item-->
                            <!--begin::Details item-->
                            <div class="fw-bolder mt-5 fs-7">Resiential address</div> 
                            <div class="text-gray-600 fs-8">{{user.address}} <span v-if="!IS_EMPTY(user.state_id)">{{user.state.name}}, </span> <span v-if="!IS_EMPTY(user.country_id)">{{user.country.name}}.</span> </div>
                            <!--begin::Details item-->
                            <!--begin::Details item-->
                            <div class="fw-bolder mt-5 fs-7">Hometown</div>
                            <div class="text-gray-600 fs-8">{{!IS_EMPTY(user.hometown) ? user.hometown : '-----------------------------------------------------------'}}</div>
                            <!--begin::Details item--> 
                        </div>
                        <div class="separator mt-8 mb-n0"></div>
                    </div>
                    <!--end::PERSONAL INFO--> 
                    
                    <!--begin::JOB INFO-->
                    <div class="d-flex flex-stack fs-4 py-3" v-if="user.employee.status_id !== STATUS_CODE.UNAPPROVE">
                        <div class="fw-bolder fs-5 collapsible" @click="active_drop = 2" role="button" aria-expanded="false">Job details</div> 
                    </div>
                    <!--end::Details toggle-->
                    <div class="separator"></div>
                    <div id="job_info" class="collapse animated fadeIn bg-gray-100 mt-n1" :class="active_drop == 2 ? 'show' : null" v-if="user.employee.status_id !== STATUS_CODE.UNAPPROVE">
                        <div class="pb-5 pt-3 fs-6 px-3"> 
                            <!--begin::Details item-->
                            <div class="fw-bolder mt-5">Work @</div> 
                            <div class="text-gray-600 fs-8">{{user.employee.subsidiary.name}} </div>
                            <!--begin::Details item-->
                            <!--begin::Details item-->
                            <div class="fw-bolder mt-5">Office address</div> 
                            <div class="text-gray-600 fs-8">{{!IS_EMPTY(user.employee.office_id) ? user.employee.office.address+ ' '+user.employee.office.state.name +', '+user.employee.office.country.name : '-----------------------------------------------------------'}} </div>
                            <!--begin::Details item-->
                            <!--begin::Details item-->
                            <div class="fw-bolder mt-5">No. of work days</div> 
                            <div class="text-gray-600 fs-8">{{!IS_EMPTY(user.employee.work_days) ? user.employee.work_days : '-----------------------------------------------------------'}} </div>
                            <!--begin::Details item-->
                            <!--begin::Details item-->
                            <div class="fw-bolder mt-5">Working days</div> 
                            <div class="text-gray-600 fs-8">{{!IS_EMPTY(user.employee.number_of_work_days) ? user.employee.number_of_work_days : '-----------------------------------------------------------'}} </div>
                            <!--begin::Details item-->

                            
                            <!--begin::Details item-->
                            <div class="fw-bolder mt-5">Hiring source</div> 
                            <div class="text-gray-600 fs-8">{{!IS_EMPTY(user.employee.hiring_source) ? user.employee.hiring_source : '-----------------------------------------------------------'}} </div>
                            <!--begin::Details item-->
                            <!--begin::Details item-->
                            <div class="fw-bolder mt-5">Employment Type</div> 
                            <div class="text-gray-600 fs-8">{{!IS_EMPTY(user.employee.employment_type_id) ? user.employee.employment_type.name : '-----------------------------------------------------------'}} </div>
                            <!--begin::Details item-->
                            <!--begin::Details item-->
                            <div class="fw-bolder mt-5">Resumed on</div> 
                            <div class="text-gray-600 fs-8">{{!IS_EMPTY(user.employee.resumption_date) ? READABLE_DATE(user.employee.resumption_date) : '-----------------------------------------------------------'}} </div>
                            <!--begin::Details item--> 
                            
                            <span v-if="!IS_EMPTY(user.employee.end_date)">
                            <!--begin::Details item-->
                            <div class="fw-bolder mt-5">End date</div> 
                            <div class="text-gray-600 fs-8">{{!IS_EMPTY(user.employee.end_date) ? READABLE_DATE(user.employee.end_date) : '-----------------------------------------------------------'}} </div>
                            <!--begin::Details item--> 
                            </span>

                            
                            <!--begin::Details item-->
                            <div class="fw-bolder mt-5">Employee level</div>
                            <div class="text-gray-600 fs-8">{{!IS_EMPTY(user.employee.employee_level_id) ? user.employee.employee_level.name : '-----------------------------------------------------------'}}</div>
                            <!--begin::Details item-->
                            <!--begin::Details item-->
                            <div class="fw-bolder mt-5">Employee position</div>
                            <div class="text-gray-600 fs-8">{{ !IS_EMPTY(user.employee.employee_position_id) ? user.employee.employee_position.name : '-----------------------------------------------------------' }}</div>
                            <!--begin::Details item--> 
                            <!--begin::Details item-->
                            <div class="fw-bolder mt-5">Employee department</div> 
                            <div class="text-gray-600 fs-8">{{ !IS_EMPTY(user.employee.employee_department_id) ? user.employee.employee_department.name : '-----------------------------------------------------------'}}</div>
                            <!--begin::Details item-->
                            <!--begin::Details item-->
                            <div class="fw-bolder mt-5">Employee Team</div> 
                            <div class="text-gray-600 fs-8">{{ !IS_EMPTY(user.employee.employee_team_id) ? user.employee.employee_team.name : '-----------------------------------------------------------'}}</div>
                            <!--begin::Details item-->
                            <!--begin::Details item-->
                            <div class="fw-bolder mt-5">Employee Unit</div> 
                            <div class="text-gray-600 fs-8">{{ !IS_EMPTY(user.employee.employee_unit_id) ? user.employee.employee_unit.name : '-----------------------------------------------------------'}}</div>
                            <!--begin::Details item-->
                        </div>
                    </div>
                    <!--end::JOB INFO-->

                    <!--begin::CONTACT INFO-->
                    <div class="d-flex flex-stack fs-4 py-3">
                        <div class="fw-bolder fs-5 collapsible" @click="active_drop = 3" role="button" aria-expanded="false">Contact</div> 
                    </div>
                    <!--end::Details toggle-->
                    <div class="separator"></div>
                    <div id="job_info" class="collapse animated fadeIn bg-gray-100 mt-n1" :class="active_drop == 3 ? 'show' : null">
                                <div class="pb-5 pt-3 fs-6 px-3"> 
                                    <!--begin::Details item-->
                                        <div class="fw-bolder mt-5 fs-7">Email address</div>
                                        <div class="text-gray-600">{{user.email}}</div>
                                        <!--begin::Details item-->
                                        <!--begin::Details item-->
                                        <div class="fw-bolder mt-5 fs-7">Phone number</div>
                                        <div class="text-gray-600">
                                            <a href="#" class="text-gray-600 text-hover-primary">{{user.phone}}</a>
                                        </div>
                                        <!--begin::Details item--> 
                                        <div class="fw-bolder mt-5">Socials</div> 
                                        <!--begin::Details item-->
                                        <div class="fw-bolder mt-5 fs-7">Facebook.com</div> 
                                        <div class="text-gray-600 fs-8">{{!IS_EMPTY(user.employee.facebook) ? user.employee.facebook : '-----------------------------------------------------'}} </div> 
                                        <!--begin::Details item-->
                                        <!--begin::Details item-->
                                        <div class="fw-bolder mt-5 fs-7">Twitter.com</div> 
                                        <div class="text-gray-600 fs-8">{{!IS_EMPTY(user.employee.twitter) ? user.employee.twitter : '-----------------------------------------------------'}} </div>
                                        <!--begin::Details item-->  
                                </div>
                            </div>
                            <!--end::CONTACT INFO-->  
            
            
                </div>
                <SETTINGSCOMPONENT :user="user" v-if="trigSetting" /> 
                <APPROVALCOMPONENT class="animated fadeIn" :user="user" v-if="approve" />
            </div>
 
        </div> 

</div>
</template> 
<script> 
// import USER_SERVICE  from "@/services/user.js"; 
//EMPLOYEE COMPONENT RESPONSE_STATUS
import APPROVALCOMPONENT from "@/views/user/components/employee/employee_profile_approval.vue";  
import SETTINGSCOMPONENT from "@/views/user/components/setting.vue";   
import { TRUNCATE_STRING, STATUS_CODE, GET_COLOR, CAPITALIZE_STR, RESPONSE_STATUS, IS_EMPTY, READABLE_DATE, RANDOM_STRING} from "@/utils/";  
import { ENCRYPT, DECRYPT } from "@/utils/security/";  
export default {
    name: "about-user-component", 
    props: ["user"],  
    data() {
        return{ 
        approve:false,
        trigSetting: false,
        active_drop: 1,  
        TRUNCATE_STRING, GET_COLOR, CAPITALIZE_STR, ENCRYPT, DECRYPT, RESPONSE_STATUS, IS_EMPTY, READABLE_DATE, RANDOM_STRING, STATUS_CODE
		}
    }, 
    components: {SETTINGSCOMPONENT, APPROVALCOMPONENT},
	methods: {  
        TOGGLE_SETTINGS: function(){ 
            this.trigSetting = true; 
            document.getElementById("infoPane").scrollIntoView({behavior: 'smooth'}); 
        },
        TOGGLE_APPROVE: function(){ 
            this.approve = true; 
            document.getElementById("infoPane").scrollIntoView({behavior: 'smooth'}); 
        }, 
	}, 
}
</script> 