<template>
    <div class="row g-5 g-xl-2">  
        <div :class="cols" v-for="(n, i) in cn" :key="i">
            <div class="skeleton"> 
                <div class="card-block" :class="ht"></div>   
            </div> 
        </div> 
    </div>
</template>

<script>    
export default {
name: "grid-loader",
props: ["count", "grid", "ht"],
data() {
    return{ 
        cn: this.count,
        cols: this.grid 
    }
}, 
};
</script>
