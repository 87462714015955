<template>
<div> 

    <button class="btn btn-sm btn-icon btn-bg-primary rounded-circle btn-active-color-primary me-2" @click="launchModal = true">
        <i class="bi bi-plus fs-3 text-white"></i>
    </button> 

    <CModal backdrop="static" :size="mSize" :visible="launchModal" @close="() => { launchModal = false }">
        <div class="card shadow-none rounded-0">  
            <div class="card-header border-1" style="">
                <!--begin::Title-->
                <h3 class="card-title align-items-start flex-column">
                    <span class="card-label fw-bolder fs-3 mb-1">New employee</span> 
                </h3>
                <!--end::Title-->
                <!--begin::Toolbar-->
                <div class="card-toolbar">
                   <button class="btn btn-light-danger btn-icon rounded-circle btn-sm me-1 animated fadeIn" @click="reg_type = null, mSize = 'sm'" v-if="!IS_EMPTY(reg_type)">
                       <span class="mdi mdi-arrow-left fw-bolder fs-5"></span>
                    </button>
                   <button class="btn btn-light btn-icon rounded-circle btn-sm" @click="() => { launchModal = false }">
                       <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                            </svg>
                        </span>
                    </button>
                </div>
                <!--end::Toolbar-->
            </div> 
            <div class="card-body">  
                <div v-if="IS_EMPTY(reg_type)" class="row">
                    <button class="col-12 btn btn-primary rounded-0" type="button" @click="reg_type = 'full', mSize = 'xl'">Full Registration</button>
                    <button class="col-12 btn btn-primary rounded-0 my-4" type="button" @click="reg_type = 'self', mSize = 'md'">Self service Registration</button>
                    <button class="col-12 btn btn-primary rounded-0" type="button" @click="reg_type = 'self', mSize = 'md'">File upload</button>
                </div>
                <div v-else>
                    <FULLREG v-if="reg_type == 'full'" :subsidiary="subsidiary" v-on:action="CHILD_ACTION"/>
                    <SELFREG v-if="reg_type == 'self'" :subsidiary="subsidiary" v-on:action="CHILD_ACTION"/> 
                </div>
            </div>    
        </div> 
    </CModal>

</div> 
</template>
<script> 
import FULLREG from "./components/form/registration/full.vue"; 
import SELFREG from "./components/form/registration/self.vue"; 
import { CModal } from '@coreui/vue'
import { TRUNCATE_STRING , GET_COLOR, CAPITALIZE_STR, RESPONSE_STATUS, IS_EMPTY, READABLE_DATE, RANDOM_STRING} from "@/utils/";  
import { ENCRYPT, DECRYPT } from "@/utils/security/"; 
import EMPLOYEE_SERVICE  from "@/services/employee.js";  

  export default {
    props: ["subsidiary"],
    data() {
      return { 
        reg_type: "",
        launchModal: false,
        user: "", 
        mSize: "sm",
        active_tab: 1,
        triggerSettings: false,
        TRUNCATE_STRING, GET_COLOR, CAPITALIZE_STR, ENCRYPT, DECRYPT, RESPONSE_STATUS, IS_EMPTY, READABLE_DATE, RANDOM_STRING
      }
    },
components: { CModal, FULLREG, SELFREG }, 
methods: {    
 
    CHILD_ACTION: function(action){     
        this.$emit('action', action) 
        this.launchModal = false;
    },
    
    LOAD_COMPONENT: function(n){ 
        this.$store.commit('PAGE_NOTIFICATION', null) 
        this.active_tab = n;
    },
 
    LOAD_EMPLOYEE: function(){ // FETCH ALL MESSAGES FROM DATABASE  
        let payload = ENCRYPT({'id': this.emp.users_id}); 
        EMPLOYEE_SERVICE.LOAD_EMPLOYEE({payload}) 
        .then(result =>{ 

            console.log(DECRYPT(result.data.data))
            !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) : null;

            if(result.data.success){ 
                this.user = DECRYPT(result.data.data); 
                console.log(this.employee)
                // this.remItem =  DECRYPT(result.data.data).rem;
                // this.page = DECRYPT(result.data.data).curPage;  
                // this.totalSubs = DECRYPT(result.data.data).total; 
                // let pLoad = DECRYPT(result.data.data).employees; 
                
                // setTimeout(() => this.employees = [...this.employees, ...pLoad],500)  

            }

            if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1000); 
        })
        .catch(err => {
            console.log('Error:' + err.message) 
            this.$store.commit('PAGE_NOTIFICATION', {
                "success": false, 
                "msg": 'error occured loading contents' 
            });
        })
    },  

}, 

mounted() {   
} 
    
}
</script>