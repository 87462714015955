<template>
<div class="bg-body"> 
<NOTIFICATION />  
    <div class="d-flex flex-column flex-root" style="min-height: 100vh !important;">
			<!--begin::Authentication - Multi-steps-->
			<div class="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column" id="kt_create_account_stepper" data-kt-stepper="true">
				<!--begin::Aside-->
				<div class="d-flex flex-column flex-lg-row-auto w-xl-300px bg-lighten shadow-lite d-none d-lg-inline">
					<!--begin::Wrapper-->
					<div class="d-flex flex-column position-xl-fixed top-0 bottom-0">
						<!--begin::Header-->
						<div class="d-flex flex-row-fluid flex-column flex-center p-10 pt-lg-20">
							<!--begin::Logo-->
                                <span class="mb-10">
                                    <img alt="Logo" :src="APP_INFO.logo" class="h-30px" />
                                </span>
							<!--end::Logo-->
							<!--begin::Nav-->
							<div class="stepper-nav">
								<!--begin::Step 1-->
								<div class="stepper-item" :class="stage == ONBOARDING_STATUS.bio ? 'current' : null"  data-kt-stepper-element="nav">
									<!--begin::Line-->
									<div class="stepper-line w-40px"></div>
									<!--end::Line-->
									<!--begin::Icon-->
									<div class="stepper-icon w-40px h-40px">
										<i class="stepper-check fas fa-check"></i>
										<span class="stepper-number text-white">1</span>
									</div>
									<!--end::Icon-->
									<!--begin::Label-->
									<div class="stepper-label">
										<h3 class="stepper-title">Bio info</h3>
										<div class="stepper-desc fw-bold">Set your personal info</div>
									</div>
									<!--end::Label-->
								</div>
								<!--end::Step 1-->
								<!--begin::Step 2-->
								<div class="stepper-item" :class="stage == ONBOARDING_STATUS.education ? 'current' : null"  data-kt-stepper-element="nav">
									<!--begin::Line-->
									<div class="stepper-line w-40px"></div>
									<!--end::Line-->
									<!--begin::Icon-->
									<div class="stepper-icon w-40px h-40px">
										<i class="stepper-check fas fa-check"></i>
										<span class="stepper-number">2</span>
									</div>
									<!--end::Icon-->
									<!--begin::Label-->
									<div class="stepper-label">
										<h3 class="stepper-title">Education history</h3>
										<div class="stepper-desc fw-bold">Educational records</div>
									</div>
									<!--end::Label-->
								</div>
								<!--end::Step 2-->
								<!--begin::Step 3-->
								<div class="stepper-item" :class="stage == ONBOARDING_STATUS.work_experience ? 'current' : null"  data-kt-stepper-element="nav">
									<!--begin::Line-->
									<div class="stepper-line w-40px"></div>
									<!--end::Line-->
									<!--begin::Icon-->
									<div class="stepper-icon w-40px h-40px">
										<i class="stepper-check fas fa-check"></i>
										<span class="stepper-number">3</span>
									</div>
									<!--end::Icon-->
									<!--begin::Label-->
									<div class="stepper-label">
										<h3 class="stepper-title">Expertise Details</h3>
										<div class="stepper-desc fw-bold">Work experience info</div>
									</div>
									<!--end::Label-->
								</div>
								<!--end::Step 3-->
								<!--begin::Step 4-->
								<div class="stepper-item" :class="stage == ONBOARDING_STATUS.surity ? 'current' : null"  data-kt-stepper-element="nav">
									<!--begin::Line-->
									<div class="stepper-line w-40px"></div>
									<!--end::Line-->
									<!--begin::Icon-->
									<div class="stepper-icon w-40px h-40px">
										<i class="stepper-check fas fa-check"></i>
										<span class="stepper-number">4</span>
									</div>
									<!--end::Icon-->
									<!--begin::Label-->
									<div class="stepper-label">
										<h3 class="stepper-title">Surity Details</h3>
										<div class="stepper-desc fw-bold">Refrence & gurantors</div>
									</div>
									<!--end::Label-->
								</div>
								<!--end::Step 4-->
								<!--begin::Step 5-->
								<div class="stepper-item" :class="stage > ONBOARDING_STATUS.surity ? 'current' : null"  data-kt-stepper-element="nav">
									<!--begin::Line-->
									<div class="stepper-line w-40px"></div>
									<!--end::Line-->
									<!--begin::Icon-->
									<div class="stepper-icon w-40px h-40px">
										<i class="stepper-check fas fa-check"></i>
										<span class="stepper-number">5</span>
									</div>
									<!--end::Icon-->
									<!--begin::Label-->
									<div class="stepper-label">
										<h3 class="stepper-title">Completed</h3>
										<div class="stepper-desc fw-bold"></div>
									</div>
									<!--end::Label-->
								</div>
								<!--end::Step 5-->
							</div>
							<!--end::Nav-->
						</div>
						<!--end::Header--> 
					</div>
					<!--end::Wrapper-->
				</div>
				<!--begin::Aside-->


				<!--begin::Body-->
				<div class="d-flex flex-column flex-lg-row-fluid py-10">
					<!--begin::Content-->
					<div class="d-flex flex-center flex-column flex-column-fluid">
						<!--begin::Wrapper-->
						<div class="w-lg-900px mx-auto">
							<!--begin::Form-->
							<form class="my-auto pb-5 fv-plugins-bootstrap5 fv-plugins-framework" >  
                                <!--begin::Step 1-->
								<div class="current" v-if="IS_EMPTY(user)">
									<!--begin::Wrapper-->
									<div class="w-100">
										<!--begin::Heading-->
										<div class="pb-10 pb-lg-15" v-if="!err">
											<!--begin::Title-->
											<h2 class="fw-bolder d-flex align-items-center text-gray-500 animated flash infinite">
                                                <span class="spinner-border spinner-border-sm align-middle me-3"></span> 
                                                Please wait...
                                            </h2>
											<!--end::Title-->
											<!--begin::Notice-->
											<div class="text-muted fw-bold fs-7">Relax while we try and verify your request</div>
											<!--end::Notice-->
										</div>
										<!--end::Heading-->  

										<!--begin::Heading-->
										<div class="pb-10 pb-lg-15" v-else>
											<!--begin::Title-->
											<span class="fw-bolder d-flex align-items-center text-danger animated fadeIn fs-1">
                                                <span class="mdi mdi-account-off align-middle me-3"></span> 
                                                Not found
                                            </span>
											<!--end::Title-->
											<!--begin::Notice-->
											<div class="text-muted fs-7">The user requested was not found / does not exist. </div>
											<!--end::Notice-->
										</div>
										<!--end::Heading--> 

									</div>
									<!--end::Wrapper-->
								</div>
								<!--end::Step 1--> 
								<div v-else class="card">
									<OVERLAYLOADER v-if="processing"/>
									<!--begin::Step 2-->
									<BIO :user="user" class="animated fadeInRight" v-on:action="CHILD_ACTION" v-if="!IS_EMPTY(user) && stage == ONBOARDING_STATUS.bio || IS_EMPTY(stage)" />
									<!--end::Step 2-->
									
									<!--begin::Step 2-->
									<EDUCATION :user="user" class="animated fadeInRight" v-on:action="CHILD_ACTION" v-if="!IS_EMPTY(user) && stage == ONBOARDING_STATUS.education" />
									<!--end::Step 2-->
									
									<!--begin::Step 2-->
									<WORKEXP :user="user" class="animated fadeInRight" v-on:action="CHILD_ACTION" v-if="!IS_EMPTY(user) && stage == ONBOARDING_STATUS.work_experience" />
									<!--end::Step 2-->
									
									<!--begin::Step 2-->
									<SURITY :user="user" class="animated fadeInRight" v-on:action="CHILD_ACTION" v-if="!IS_EMPTY(user) && stage == ONBOARDING_STATUS.surity" />
									<!--end::Step 2--> 
									
									<!--begin::Step 2-->
									<COMPLETED :user="user" class="animated fadeInRight" v-on:action="CHILD_ACTION" v-if="!IS_EMPTY(user) && stage == ONBOARDING_STATUS.completed" />
									<!--end::Step 2-->  
								</div>

                            </form>
							<!--end::Form-->
						</div>
						<!--end::Wrapper-->
					</div>
					<!--end::Content--> 
				</div>
				<!--end::Body-->
			</div>
			<!--end::Authentication - Multi-steps-->
		</div>
</div> 
</template>

<script>
// @ is an alias to /src 
import NOTIFICATION from "@/components/global/notification.vue";
import OVERLAYLOADER from "@/components/loaders/overlay-loader.vue" 
import BIO from "./components/bio"; 
import EDUCATION from "./components/education"; 
import WORKEXP from "./components/work_experience"; 
import SURITY from "./components/surity";  
import COMPLETED from "./components/completed";  
import AUTH_SERVICES from "@/services/auth"; 
import { RESPONSE_STATUS, APP_INFO, IS_EMPTY, STATUS_CODE, ONBOARDING_STATUS } from "@/utils/"; 
import { ENCRYPT, DECRYPT } from "@/utils/security/";  

export default {
name: "Email_Verification",
data() {
    return{ 
        // Theme: this.$store.state.currentTheme, 
        err: false,
        err_msg: '',
		stage: null,
        id: this.$route.query.ref, // truncate ref url data to get user id 
        token: this.$route.query.token, // truncate ref url data to get user id 
        processing: false, 
        user: '',
        DECRYPT, ENCRYPT, STATUS_CODE, APP_INFO, RESPONSE_STATUS, IS_EMPTY, ONBOARDING_STATUS
    }
},
components: { BIO, EDUCATION, WORKEXP, SURITY, NOTIFICATION, OVERLAYLOADER, COMPLETED },  
methods: { 

	CHILD_ACTION: function(x){
		
		this.processing = x.processing; 
		
		if(!IS_EMPTY(x.stage)) this.stage = x.stage;
		
		if(!IS_EMPTY(x.reload)){
			x.reload ? this.CONFIRM_USER() : null;
		}
	},
      
    CONFIRM_USER: function(){ 
    let payload = ENCRYPT({"id": DECRYPT(this.id), "token": DECRYPT(this.token) })  

    AUTH_SERVICES.CONFIRM_SELF_REGISTRATION({payload})
    .then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION    
        if(result.data.success){ 
			this.user = DECRYPT(result.data.data);
			this.stage = this.user.onboarding.stage;
        }
        if(!result.data.success){
            this.err = true;
            this.err_msg = result.data.msg;  
        }  
    })
    .catch(() => {  
        this.err = true;
        this.err_msg = " encountered a fatal server error";   
    })
}
}, 
mounted() {
 this.CONFIRM_USER()
} 

};
</script>