<template> 
    <div class="card">

 
        <div class="card-body p-0" v-if="st == 'err'" > 
            <!--begin::Illustration-->
            <div class="text-center px-4">
                <img class="mw-100 mh-300px" alt="" src="/img/error.png" />
            </div>
            <!--end::Illustration-->
            <!--begin::Wrapper-->
            <div class="text-center">
                <!--begin::Title-->
                <h6 class="fs-2x fw-bolder text-gray-800">Error!</h6>
                <!--end::Title-->
                <!--begin::Description-->
                <p class="text-gray-400 fs-8 fw-bold mb-10">{{mssg || 'Sorry an internal server error occured.' }}</p>
                <!--end::Description--> 
            </div>
            <!--end::Wrapper-->
        </div>
        <!--end::Card body-->
         

        <div class="card-body p-0" v-if="st == 'empty'" > 
            <!--begin::Illustration-->
            <div class="text-center px-4">
                <img class="mw-100 mh-300px" alt="" src="/img/empty.png" />
            </div>
            <!--end::Illustration-->
            <!--begin::Wrapper-->
            <div class="text-center">
                <!--begin::Title-->
                <h6 class="fs-2x fw-bolder">Empty!</h6>
                <!--end::Title-->
                <!--begin::Description-->
                <p class="text-gray-400 fs-8 fw-bold mb-10">{{mssg || 'Sorry there are no items available to display.' }}</p>
                <!--end::Description--> 
            </div>
            <!--end::Wrapper-->
        </div>
        <!--end::Card body-->
 
    </div> 
</template>

<script>    
export default {
name: "invalid-resp",
props: ["status", "msg"],
data() {
    return{ st: this.status, mssg: this.msg }
}, 
};
</script>
