<template>
<div class="d-flex flex-center flex-column flex-column-fluid">
    <!--begin::Wrapper-->
    <div class="w-lg-900px p-10 p-lg-5 mx-auto">
        <!--begin::Form-->
        <form class="my-auto pb-5 fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate" id="kt_create_account_form"> 
            <!--begin::Step 5-->
            <div>
                <!--begin::Wrapper-->
                <div class="w-100">
                    <!--begin::Heading-->
                    <div class="pb-8 pb-lg-10">
                        <!--begin::Title-->
                        <h2 class="fw-bolder text-dark">Work experience</h2>
                        <!--end::Title-->
                        <!--begin::Notice-->
                        <div class="text-muted fw-bold fs-6">If you need more info, please
                        <a href="../../demo3/dist/authentication/sign-in/basic.html" class="link-primary fw-bolder">Sign In</a>.</div>
                        <!--end::Notice-->
                    </div>
                    <!--end::Heading-->
                    <!--begin::Body-->
                    <div class="mb-0"> 
                    
                        <div class="row mt-10 animated fadeIn border shadow-lite px-md-10 pt-md-10 p-5" v-for="(wrk, i) in work_list" :key="i">
                            <div class="col-md-6 mb-3">
                                <label class="form-label fs-5 fw-bolder text-muted">Employer <span class="text-danger">*</span></label> 
                                <input v-model="wrk.employer" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" required="">
                            </div>
                            <div class="col-md-6 col-sm-7 mb-3">
                                <label class="form-label fs-5 fw-bolder text-muted">Job role <span class="text-danger">*</span></label> 
                                <input v-model="wrk.role" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" required="">
                            </div> 
                            <div class="col-md-4 col-sm-5 mb-3">
                                <label class="form-label fs-5 fw-bolder text-muted">Salary <span class="text-danger">*<span class="fs-8">(numbers only)</span></span></label> 
                                <input v-model="wrk.salary" type="number" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" required="">
                            </div> 
                            <div class="col-md-4 col-sm-6 mb-3">
                                <label class="form-label fs-5 fw-bolder text-muted">Start year <span class="text-danger">*</span></label> 
                                <input v-model="wrk.start_year" type="date" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" required="">
                            </div> 
                            <div class="col-md-4 col-sm-6 mb-3">
                                <label class="form-label fs-5 fw-bolder text-muted">End year <span class="text-danger">*</span></label> 
                                <input v-model="wrk.end_year" type="date" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" required="">
                            </div> 
                            <div class="col-md-12 col-sm-12 mb-3">
                                <label class="form-label fs-5 fw-bolder text-muted">Reason for termination</label> 
                                <input v-model="wrk.reason_for_termination" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" required="">
                            </div> 
                            <div class="col-md-12 mb-3">
                                <label class="form-label fs-5 fw-bolder text-muted">Job description</label> 
                                <textarea v-model="wrk.job_description" rows="2" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" required=""></textarea>
                            </div>  
                            
                            <div class="col-md-4 col-5 mb-5" v-if="i !== 0"> 
                                <button @click="DELETE_SERVICE_WORK(wrk.id, i)" type="button" class="btn btn-light-danger rounded-0 shadow-lite">remove</button>
                            </div>

                        </div> 
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Wrapper-->
            </div>
            <!--end::Step 5--> 
            <div class="mt-8 mb-1 d-flex justify-content-between">
                <button @click="$emit('action', {stage: ONBOARDING_STATUS.education, processing: false})" type="button" class="btn btn-sm btn-light-primary shadow-lite rounded-0 animated fadeIn"><i class="mdi mdi-arrow-left"></i> Back</button>
                
                <div class="d-flex justify-content-between">
                    <button @click="MORE_WORK()" type="button" class="btn btn-sm btn-primary shadow-lite rounded-0 animated fadeIn me-2 me-sm-3">More <i class="mdi mdi-plus fw-bolder"></i></button>
                    <button @click="DO_SUBMIT()" type="button" class="btn btn-sm btn-light-primary shadow-lite rounded-0 animated fadeIn me-1 me-sm-3" v-if="user.onboarding.stage == ONBOARDING_STATUS.work_experience">Continue <i class="mdi mdi-arrow-right"></i></button>
                    <button @click="DO_SUBMIT()" type="button" class="btn btn-sm btn-light-primary shadow-lite rounded-0 animated fadeIn me-1 me-sm-3" v-if="user.onboarding.stage > ONBOARDING_STATUS.work_experience">Update <i class="mdi mdi-arrow-right"></i></button>
                    <button @click="this.$emit('action', {stage: ONBOARDING_STATUS.surity, processing: false})" type="button" class="btn btn-sm btn-light shadow-lite rounded-0 animated fadeIn me-1 me-sm-3" v-if="user.onboarding.stage > ONBOARDING_STATUS.work_experience">Skip <i class="mdi mdi-arrow-right"></i></button>
                </div> 
            </div> 

        </form>
        <!--end::Form-->
    </div>
    <!--end::Wrapper-->
</div>
</template>

<script>
// @ is an alias to /src     
import AUTH_SERVICE  from "@/services/auth.js"; 
import {MILLISECONDS_TO_DAYS, CAPITALIZE_STR, IS_EMPTY, REMOVE_CHARC, ONBOARDING_STATUS, RESPONSE_STATUS, STATUS_CODE} from "@/utils/"
import { ENCRYPT, DECRYPT } from "@/utils/security/"; 
export default {
name: "add-self-work-exp",
props: ['user'], 
data() {
    return{ 
        // Theme: this.$store.state.currentTheme,  
        ref: this.$route.query.ref, // truncate ref url data to get user id 
        token: this.$route.query.token, // truncate ref url data to get user id 
        MILLISECONDS_TO_DAYS, CAPITALIZE_STR, REMOVE_CHARC, ENCRYPT, DECRYPT, RESPONSE_STATUS, STATUS_CODE, ONBOARDING_STATUS, IS_EMPTY, 
        work_list: [{
            "id": null, 
            "employee_id": this.user.employee.id,  
            "employer": "", 
            "role": "",
            "salary": "",
            "start_year": "",
            "end_year": "",   
            "reason_for_termination": "",
            "job_description": "",
        }], 
    }
},
components: { }, 
methods: {
 
    VALIDATE_NEW_FORM: function() { // FETCH ALL MESSAGES FROM DATABASE    
        document.getElementById('workExp_sub_btn').innerHTML = 'Submitting <i class="mdi mdi-loading mdi-spin"></i>';
        document.getElementById('workExp_sub_btn').setAttribute(`disabled`, true); 
        // if( IS_EMPTY(this.workExpType)  || IS_EMPTY(this.name)  || IS_EMPTY(this.email)  || IS_EMPTY(this.phone)  || IS_EMPTY(this.country)  || IS_EMPTY(this.state)  || IS_EMPTY(this.city) ){
        //     this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " ensure no required filed is empty."}); 
        //     document.getElementById('workExp_sub_btn').innerHTML = 'Submit <i class="mdi mdi-upload"></i>';
        //     document.getElementById('workExp_sub_btn').removeAttribute(`disabled`);
        // }
        // else{
            this.SUBMIT_FORM() 
        // }
    },

    
    LOAD_WORKEXP_HISTORY: function() { // FETCH ALL MESSAGES FROM DATABASE    
        let payload = ENCRYPT({'id': this.user.employee.id, 'token': DECRYPT(this.token)}); 
        AUTH_SERVICE.LOAD_WORKEXP_HISTORY({payload})   
        .then(result =>{   
            if(result.data.success){  
                let resp = DECRYPT(result.data.data);
                resp.length > 0 ? this.work_list = resp : null
                // this.work_list = DECRYPT(result.data.data);
            }
            else{
                this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) 
            }
        })
        .catch(() => {
            this.loading = false; 
            this.$store.commit('PAGE_NOTIFICATION', {  "success": false, "msg": 'error occured loading contents' });
        })
    }, 
    
    MORE_WORK: function() { 
        this.work_list.push({
            "id": null, 
            "employee_id": this.user.employee.id,  
            "employer": "", 
            "start_year": "",
            "end_year": "",   
            "reason_for_termination": "",
            "role": "",
            "job_description": "",
            "salary": "",
        });   
    },

    
    DO_SUBMIT: function() { // FETCH ALL MESSAGES FROM DATABASE 

        this.$emit('action', {stage: this.user.onboarding.stage, processing: true})
        const payload = ENCRYPT(this.work_list) 
        console.log(this.work_list)
        const id = ENCRYPT(this.user.id) 
        AUTH_SERVICE.SELF_SERVICE_WORK_SUBMISSION({payload, id})  
        .then(result => {  
            this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg });  
            if(result.data.success) this.$emit('action', {stage: ONBOARDING_STATUS.surity, processing: false});  
        })
        .catch(() => { 
            this.$emit('action', {stage: ONBOARDING_STATUS.work_experience, processing: false, reload: true}) 
            this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": "encountereda fatal server error" })
        })

    },
    

    DELETE_SERVICE_WORK: function(id, i) { // FETCH ALL MESSAGES FROM DATABASE 
         this.$emit('action', {stage: ONBOARDING_STATUS.work_experience, processing: true})
        if(!IS_EMPTY(id)){
            const payload = ENCRYPT(id)  
            AUTH_SERVICE.DELETE_SERVICE_WORK({payload})  
            .then(result => {  
                this.$emit('action', {stage: ONBOARDING_STATUS.work_experience, processing: false})
                this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg });  
                if(result.data.success) this.work_list.splice(i, 1); // remove item
            })
            .catch(err => { 
                this.$emit('action', {stage: ONBOARDING_STATUS.work_experience, processing: false})
                console.log('Error:' + err.message) 
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": "encountereda fatal server error" })
            })
        }
        else{
            this.$emit('action', {stage: ONBOARDING_STATUS.work_experience, processing: false})
            this.work_list.splice(i, 1); // remove item 
        } 

    },
 

}, 
mounted() {
    this.LOAD_WORKEXP_HISTORY()
 },

};
</script>`