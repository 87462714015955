<template>
<div class="animated fadeIn">
    <div class="mb-5 mb-xxl-10 py-2"> 
                <div class="col-12 alert alert-danger d-flex align-items-center p-1 " v-if="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">
                    <!--begin::Svg Icon | path: icons/duotune/general/gen048.svg-->
                    <span class="svg-icon svg-icon-2hx svg-icon-danger me-4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                            <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                        </svg>
                    </span>
                    <!--end::Svg Icon--> 
                        <b class="mb-1 text-danger me-2">Note: </b> <span class="fs-8">You don`t have the access right to update these informations. </span>
                </div> 
                <!--begin::Form-->
                <form id="kt_account_profile_details_form" class="form" v-else>
                    <!--begin::Card body-->
                    <div class="p-1"> 
                        <!--begin::Input group-->
                        <div class="row">   

                            <!--begin::Col-->
                            <div class="col-lg-6 mt-md-3">
                                <label class="col-form-label required fw-bold fs-6">Department</label>
                                <select class="form-select form-select-solid form-select-lg fw-bold" v-model="editable_user.employee.employee_department_id" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">
                                    <option value="">--none--</option>
                                    <option v-bind:value="dept.id" v-for="(dept, i) in departments" :key="i"> {{dept.name}}</option>
                                </select>
                            </div>
                            <!--end::Col--> 

                            <!--begin::Col-->
                            <div class="col-lg-6 mt-md-3">
                                <label class="col-form-label required fw-bold fs-6">Position</label>
                                <select class="form-select form-select-solid form-select-lg fw-bold" v-model="editable_user.employee.employee_position_id" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">
                                    <option value="">--none--</option>
                                    <option v-bind:value="position.id" v-for="(position, i) in positions" :key="i"> {{position.name}}</option>
                                </select>
                            </div>
                            <!--end::Col-->  

                            <!--begin::Col-->
                            <div class="col-lg-6 mt-md-3">
                            
                                <label class="col-form-label required fw-bold fs-6">Level</label>
                                <select class="form-select form-select-solid form-select-lg fw-bold" v-model="editable_user.employee.employee_level_id" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">
                                    <option value="">--none--</option>
                                    <option v-bind:value="level.id" v-for="(level, i) in levels" :key="i"> {{level.name}}</option>
                                </select>
                            </div>
                            <!--end::Col--> 

                            <!--begin::Col-->
                            <div class="col-lg-6 mt-md-3">
                                <label class="col-form-label required fw-bold fs-6">Employment type</label>
                                <select v-model="empTypeData" @change="CHECK_END_DATE()" class="form-select form-select-solid form-select-lg fw-bold" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">
                                    <option v-bind:value="(emp.id + '/' + emp.expires)" v-for="(emp, i) in empTypes" :key="i"> {{emp.name}}</option>
                                </select>
                            </div>
                            <!--end::Col--> 

                            <!--begin::Col-->
                            <div class="col-lg-3 mt-md-3">
                                <label class="col-form-label required fw-bold fs-6">Salary <span class="badge bg-danger fs-9 p-1">numbers only</span></label>
                                <input type="number" class="form-control form-control-lg form-control-solid" v-model="editable_user.employee.salary" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">
                                
                            </div>
                            <!--end::Col-->

                            <!--begin::Col-->
                            <div class="col-lg-3 mt-md-3">
                                <label class="col-form-label required fw-bold fs-6">Working days</label>
                                <input type="number" class="form-control form-control-lg form-control-solid" v-model="editable_user.employee.number_of_work_days" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">
                                <small class="text-danger fs-9 mb-3 mb-lg-0">numbers only</small>
                            </div> 
                            <!--end::Col-->

                            <!--begin::Col-->
                            <div class="col-lg-6 mt-md-3">
                                <label class="col-form-label fw-bold fs-6">Hiring source</label>
                                <input type="text" class="form-control form-control-lg form-control-solid" v-model="editable_user.employee.hiring_source" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">
                            </div>
                            <!--end::Col-->  

                            <!--begin::Col-->
                            <div class="col-lg-3 mt-md-3">
                                <label class="col-form-label fw-bold fs-6">Resumption date</label>
                                <input type="date" class="form-control form-control-lg form-control-solid" v-model="editable_user.employee.resumption_date" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">
                            </div>
                            <!--end::Col-->    

                            <!--begin::Col-->
                            <div class="col-lg-3 mt-md-3 animated fadeIn" v-if="eDate !== '' || eDateStatus">
                                <label class="col-form-label fw-bold fs-6">End date</label>
                                <input type="date" class="form-control form-control-lg form-control-solid" v-model="editable_user.employee.end_date" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">
                            </div>
                            <!--end::Col-->   

                            <!--begin::Col-->
                            <div class="col-lg-6 mt-md-3">
                                <label class="col-form-label fw-bold fs-6">Team</label>
                                <select v-model="editable_user.employee.employment_type_id" class="form-select form-select-solid form-select-lg fw-bold" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">
                                    <option value="">-- none --</option>
                                    <option v-bind:value="team.id" v-for="(team, i) in teams" :key="i"> {{team.name}}</option>
                                </select>
                            </div>
                            <!--end::Col-->   

                            <!--begin::Col-->
                            <div class="col-lg-3 mt-md-3">
                                <label class="col-form-label required fw-bold fs-6">Job role</label>
                                <input type="text" class="form-control form-control-lg form-control-solid" v-model="editable_user.employee.job_role" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">
                            </div>
                            <!--end::Col-->  

                            <!--begin::Col-->
                            <div class="col-lg-9 mt-md-3">
                                <label class="col-form-label required fw-bold fs-6">Office address</label>
                                <select v-model="editable_user.employee.office_id" class="form-select form-select-solid form-select-lg fw-bold" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">
                                    <option v-bind:value="office.id" v-for="(office, i) in offices" :key="i"> {{office.address+', '+office.state.name+' '+office.country.name}}</option>
                                </select>
                            </div>
                            <!--end::Col-->    

                            <!--begin::Col-->
                            <div class="col-lg-12 mt-md-3">
                                <label class="col-form-label required fw-bold fs-6">Work days</label>
                                <select class="form-select form-select-solid form-select-lg fw-bold" multiple="multiple" v-model="editable_user.employee.work_days" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">
                                    <option :value="day" v-for="(day, i) in WEEK_DAYS" :key="i" :selected="!IS_EMPTY(editable_user.employee.work_days) ? editable_user.employee.work_days.includes(day) : null">{{CAPITALIZE_STR(day)}}</option> 
                                </select>
                            </div>
                            <!--end::Col-->    

                            <!--begin::Col-->
                            <div class="col-lg-12 mt-md-3">
                                <label class="col-form-label required fw-bold fs-6">Job description</label>
                                <textarea class="form-control" id="kt_docs_maxlength_textarea" placeholder="" rows="6" v-model="editable_user.employee.job_desc" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)" />
                                <span class="fs-6 text-muted">Max. 500 words</span>
                            </div>
                            <!--end::Col-->   

                        </div>
                    </div>
                    <!--end::Card body-->
                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end pb-2"> 
                        <button type="button" class="btn btn-light-primary"  @click="DO_VALIDATE()" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">Save Changes</button>
                    </div>
                    <!--end::Actions-->
                </form>
                <!--end::Form-->  
    </div>

 
</div>
</template>

<script> 
import PORTFOLIO_SERVICE from "@/services/portfolio.js"
import UTILITY_SERVICE from "@/services/utility_services.js"
import EMPLOYEE_SERVICE from "@/services/employee.js"

//EMPLOYEE COMPONENT 
import { IS_EMPTY, CHECK_BOOLEAN, READABLE_DATE, ADMINISTRATIVE_CLASS, CAPITALIZE_STR, RESPONSE_STATUS, ACCESS_PRIVELEDGES, GRANT_ACCESS } from "@/utils/"; 
import { WEEK_DAYS } from "@/utils/date_utils.js";  
import { ENCRYPT, DECRYPT, PARSE_AUTH } from "@/utils/security/";  
export default {
    name: "User overview", 
    props: ["user"],  
    data() {
        return{  
            IS_EMPTY, CHECK_BOOLEAN, READABLE_DATE, CAPITALIZE_STR, WEEK_DAYS, ACCESS_PRIVELEDGES, ADMINISTRATIVE_CLASS, GRANT_ACCESS,
            editable_user: this.user,
            chkPassword: '',
            offices: '',
            subsidiary: '',
            departments: '',
            teams: '',
            positions: '',
            levels: '',
            empTypes: '',
            empTypeData: '',
            eDateStatus: '',
            eDate: '',
            auth: PARSE_AUTH(this.$store.state.auth, "user"),
        }
    },  
    methods: {
        
            LOAD_SUBSIDIARY: function(){ // FETCH ALL MESSAGES FROM DATABASE
            this.isLoading = 'yes';
            this.retry = ''  
            let payload = ENCRYPT({'id': this.editable_user.employee.subsidiary_id})
            PORTFOLIO_SERVICE.LOAD_PORTFOLIO_DASHBOARD({payload})
            .then(result =>{      
                if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) {
                    this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": result.data.msg })
                    setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path}), 2500) 
                }// logout and redirect with refferer page         
                
                if(!result.data.success){ 
                    this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg })
                }
                else{     
                    let subsidiary =  DECRYPT(result.data.data) 
                    this.offices =  subsidiary.offices  
                    this.departments = subsidiary.employee_departments
                    this.positions = subsidiary.employee_positions
                    this.levels = subsidiary.employee_levels 
                    this.teams = subsidiary.employee_teams  
                } 
            })
            .catch(err => {
                console.log(err.message)  
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": 'error occured loading page contents' })
            })
        }, 
        
		GET_EMPLOYEEMENT_TYPES: function() {
			UTILITY_SERVICE.GET_EMPLOYEEMENT_TYPES({})
            .then(result => { // TRY ALL RESOURCE NEEDED FOR REGISTRATION 
            console.log(result.data)
                !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) : this.empTypes = result.data.data 
            })
            .catch(err => {
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": 'error occured loading page contents' })
                console.log(err.message)
            }) 
		}, 

		CHECK_END_DATE: function() { // reload content
		let str =   this.empTypeData.split('/')
		this.empType = str.shift(); 
        this.editable_user.employee.employment_type_id = this.empType;

			if(str.pop() == 'true'){
				this.eDateStatus = true;
			}else{
				this.eDateStatus = false;
			}
		}, 


        // VALIDATE FORM DATA
        DO_VALIDATE: function(){
            !GRANT_ACCESS(this.auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT) ? this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " you don`t have the access right to complete this process!" }) : this.DO_UPDATE();
        },

        //PROFILE UPDATE
        
        DO_UPDATE: function(){
            let payload = ENCRYPT(this.editable_user.employee)
 
            EMPLOYEE_SERVICE.UPDATE_EMPLOYEE({payload})
            .then(result => {        
                if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1500); 
                this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) 
                result.data.success ? setTimeout(() =>  location.reload(), 2500) : null
            })
            .catch(err => { 
                console.log(err.message)
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured processing request, try again!" });
            })
        }
    },
    mounted() {  
        this.GET_EMPLOYEEMENT_TYPES()
        this.LOAD_SUBSIDIARY()
    }    
}
</script> 