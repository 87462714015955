import API from './api.js';
// import { ENCRYPT_JSON_PAYLOAD } from "@/utils/";

export default {
 
    //UTILITY SERVER SERVICES TO GET NEW COMPANY RESOURCES
    GET_NEW_COMPANY_RESOURCES() { /**** GET COMPANY TYPES RECOURCES*****/  
        return API('utility').get('get_new_company_res')
    },
    GET_ACCOUNT_TYPES() { /**** GET CITIES RESOURCES*****/ 
        return API('utility').get('account_types')
    }, 
    GET_STATUS() { /**** GET COUNTRIES AND ASSOCIATED RECOURCES *****/   
        return API('utility').get('all_status')
    },
    GET_COUNTRIES() { /**** GET COUNTRIES AND ASSOCIATED RECOURCES *****/   
        return API('utility').get('countries')
    }, 
    GET_COUNTRY() { /**** GET COUNTRIES RECOURCES *****/ 
        return API('utility').get('country')
    }, 
    GET_STATES(data) { /**** GET STATES RECOURCES *****/ 
        return API('utility').post('states', data)
    }, 
    GET_CITIES(data) { /**** GET CITIES RESOURCES*****/ 
        return API('utility').post('cities', data)
    }, 
    CONFIRM_ORG_NAME(data) { /**** GET REGISTRATION RECOURCES *****/  
        return API('utility').post('check_org_name', data)
    }, 
    CONFIRM_SUB_NAME(data) { /**** GET REGISTRATION RECOURCES *****/  
        return API('utility').post('csne', data)
    },
    CONFIRM_ORG_EMAIL(data) { /**** GET REGISTRATION RECOURCES *****/  
        return API('utility').post('check_org_email', data)
    }, 
    LOAD_DEGREES() { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/  
        return API('utility').get('load_degrees')
    },

    LOAD_SUTIRY_TYPES() { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('utility').get('load_surity_types')
    },

    //UTILITY FOR USERS 
    CONFIRM_USER_EMAIL(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/  
        return API('utility').post('cuede', data) //Confirm User Email Don`t Exist
    },

    LOAD_ACCESS_PRIVILEDGES(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/   
        return API('utility').post('load_access_priviledges', data)
    },

    LOAD_ADMIN_CLASSES(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/   
        return API('utility').post('load_admin_classes', data)
    },
    
    GET_EMPLOYEEMENT_TYPES() { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('utility').get('load_employment_types') 
    },

    LOAD_FILE_CATEGORIES() { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('utility').get('load_file_categories') 
    },

    

    

}
