<template>
<div>
<BLANKLOADER v-if="loading" ht="h-300px" />   
    <div v-else class="animated fadeIn"> 
        <div class="card mb-5 mb-xxl-10"> 
            <OVERLAYLOADER v-if="processing"/>
            <!--begin::Body-->
            <div class="py-3">
                <!--begin::Form-->
                <form id="kt_account_profile_details_form" class="form" enctype="multipart/form-data">
                    <!--begin::Card body-->
                    <div class="p-1">
                        <!--begin::Input group-->
                        <div class="row mb-6">
                            <!--begin::Label--> 
                            <!--end::Label-->
                            <!--begin::Col-->
                            <div class="col-lg-12">
                                <!--begin::Image input-->
                                    <div class="image-input image-input-outline" data-kt-image-input="true" :style="`background-image: url(${ dp })`">
                                        <!--begin::Preview existing avatar-->
                                    <div class="image-input-wrapper w-125px h-125px" :style="`background-image: url(${ dp })`">
                                    </div>
                                    <!--end::Preview existing avatar-->
                                    <!--begin::Label-->
                                    <label class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change avatar">
                                        <i class="bi bi-camera-fill fs-7"></i>
                                        <!--begin::Inputs-->
                                        <input type="file" name="avatar" accept=".png, .jpg, .jpeg" @change="PREVIEW_DP($event)" />
                                        <input type="hidden" name="avatar_remove" />
                                        <!--end::Inputs-->
                                    </label>
                                    <!--end::Label--> 
                                </div>
                                <!--end::Image input-->
                                <!--begin::Hint-->
                                <div class="form-text">Allowed file types: png, jpg, jpeg.</div>
                                <!--end::Hint-->
                            </div>
                            <!--end::Col-->
                        </div>
                        <!--end::Input group-->
                    
                        
                    </div>
                    <!--end::Card body-->
                    
                </form>
                <!--end::Form--> 
            </div>
            <!--end::Body-->
                   
                        <!--begin::PERSONAL INFO-->
                            <div class="d-flex flex-stack fs-4 pb-3">
                                <div class="fw-bolder fs-5 collapsible"  @click="active_drop = 1" aria-expanded="false">Update Personal Info</div> 
                            </div>
                            <!--end::Details toggle-->
                            <div class="separator"></div>
                            <div id="personal_info" class="collapse animated fadeIn mt-n1" :class="active_drop == 1 ? 'show' : null">
                                <div class="pb-5 pt-3 fs-6 px-3">
                                    <div class="row mb-4">   
                                    
                                        <div class="col-12 alert alert-danger d-flex align-items-center p-1 " v-if="GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen048.svg-->
                                            <span class="svg-icon svg-icon-2hx svg-icon-danger me-4">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                                                <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/>
                                                <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/>
                                                </svg>
                                            </span>
                                            <!--end::Svg Icon--> 
                                                <b class="mb-1 text-danger me-2">Note: </b> <span class="fs-9 fs-md-8">You don`t have the access right to update some informations.</span> 
                                        </div>
                
                                        <!--begin::Col-->
                                        <div class="col-lg-6 mt-md-3">
                                            <label class="col-form-label required fw-bold fs-6">Surname</label>
                                            <input type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Surname" v-model="editable_user.surname" v-if="GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)" />
                                            <input type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Surname" :value="editable_user.surname" v-else disabled/>
                                        </div>
                                        <!--end::Col-->

                                        <!--begin::Col-->
                                        <div class="col-lg-6 mt-md-3">
                                            <label class="col-form-label required fw-bold fs-6">First name</label>
                                            <input type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Last name" v-model="editable_user.first_name" v-if="GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)" />
                                            <input type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Last name" :value="editable_user.first_name" v-else disabled/>
                                        </div>
                                        <!--end::Col-->

                                        <!--begin::Col-->
                                        <div class="col-lg-6 mt-md-3">
                                            <label class="col-form-label required fw-bold fs-6">Other name</label>
                                            <input type="text" class="form-control form-control-lg form-control-solid" placeholder="Other name" v-model="editable_user.other_name" v-if="GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)" />
                                            <input type="text" class="form-control form-control-lg form-control-solid" placeholder="Other name" :value="editable_user.other_name" v-else disabled />
                                        </div>
                                        <!--end::Col-->  

                                        <!--begin::Col-->
                                        <div class="col-6 col-lg-3 mt-md-3">
                                            <label class="col-form-label required fw-bold fs-6">Date of birth</label>
                                            <input type="date" class="form-control form-control-lg form-control-solid" v-model="editable_user.dob" v-if="GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)"/>
                                            <input type="date" class="form-control form-control-lg form-control-solid" :value="editable_user.dob" v-else disabled>
                                        </div>
                                        <!--end::Col-->  

                                        <!--begin::Col-->
                                        <div class="col-6 col-lg-3 mt-md-3">
                                            <label class="col-form-label required fw-bold fs-6">Gender</label>
                                            <select class="form-select form-select-solid form-select-lg fw-bold" v-model="editable_user.gender" >
                                                <option value="male" :selected="editable_user.gender == 'male' ">Male</option>
                                                <option value="female" :selected="editable_user.gender == 'female' ">Female</option>
                                            </select>
                                        </div>
                                        <!--end::Col--> 

                                        <!--begin::Col-->
                                        <div class="col-lg-5 mt-md-3">
                                            <label class="col-form-label required fw-bold fs-6">Hometown</label>
                                            <input type="text" class="form-control form-control-lg form-control-solid" v-model="editable_user.hometown" v-if="GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)"/>
                                            <input type="text" class="form-control form-control-lg form-control-solid" v-model="editable_user.hometown" v-else disabled/>
                                        </div>
                                        <!--end::Col-->   

                                        <!--begin::Col-->
                                        <div class="col-lg-3 mt-md-3">
                                            <label class="col-form-label required fw-bold fs-6">Phone number</label>
                                            <input type="text" class="form-control form-control-lg form-control-solid" placeholder="Phone number" v-model="editable_user.phone" v-if="GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)"/>
                                            <input type="text" class="form-control form-control-lg form-control-solid" placeholder="Phone number" value="editable_user.phone" v-else disabled/>
                                        </div>
                                        <!--end::Col-->        

                                        <!--begin::Col-->
                                        <div class="col-lg-4 mt-md-3">
                                            <label class="col-form-label required fw-bold fs-6">Local Govt. Area</label>
                                            <input type="text" class="form-control form-control-lg form-control-solid" placeholder="Local government area" v-model="editable_user.lga"/>
                                        </div>
                                        <!--end::Col-->     


                                        <!--begin::Col-->
                                        <div class="col-lg-4 mt-md-3">
                                            <label class="col-form-label required fw-bold fs-6">Country of origin</label>
                                            <select class="form-select form-select-solid form-select-lg fw-bold" v-model="editable_user.country_id" @change="GET_STATES()">
                                                <option v-bind:value="country.id" v-for="(country, i) in countries" :key="i"> {{country.name}}</option>
                                            </select>
                                        </div>
                                        <!--end::Col-->  
                                        
                                        <!--begin::Col-->
                                        <div class="col-lg-4 mt-md-3">
                                            <label class="col-form-label required fw-bold fs-6">State of origin</label>
                                            <select class="form-select form-select-solid form-select-lg fw-bold" v-model="editable_user.state_id"  @change="GET_CITIES()">
                                                <option v-bind:value="st.id" v-for="(st, i) in states" :key="i"> {{st.name}}</option>
                                            </select>
                                        </div>
                                        <!--end::Col-->  

                                        <!--begin::Col-->
                                        <div class="col-lg-4 mt-md-3">
                                            <label class="col-form-label required fw-bold fs-6">Resident city</label>
                                            <select class="form-select form-select-solid form-select-lg fw-bold" v-model="editable_user.city_id"> 
                                                <option v-bind:value="city.id" v-for="(city, i) in cities" :key="i"> {{city.name}}</option> 
                                                <option v-bind:value="0">others</option> 
                                            </select>
                                        </div>
                                        <!--end::Col-->  

                                        <!--begin::Col-->
                                        <div class="col-lg-12 mt-md-3">
                                            <label class="col-form-label required fw-bold fs-6">Residential address</label>
                                            <input type="text" class="form-control form-control-lg form-control-solid" placeholder="Phone number" v-model="editable_user.address" v-if="GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)"/>
                                            <input type="text" class="form-control form-control-lg form-control-solid" placeholder="Phone number" :value="editable_user.address" v-else disabled/>
                                        </div>
                                        <!--end::Col-->   
                                        
                                        <!--begin::Col-->
                                        <div class="col-lg-12">
                                            <label class="col-form-label required fw-bold fs-6">About me</label>
                                            <textarea class="form-control" id="kt_docs_maxlength_textarea" placeholder="" rows="6" v-model="editable_user.about" />
                                            <span class="fs-6 text-muted">Max. 500 words</span>
                                        </div>
                                        <!--end::Col-->   
                                    </div>
                                    <!--begin::Actions-->
                                    <div class="d-flex justify-content-end"> 
                                        <button type="button" class="btn btn-light-primary" @click="DO_VALIDATE()" >Save Changes</button>
                                    </div>
                                    <!--end::Actions-->
                                </div>
                                <div class="separator mt-8 mb-n0"></div>
                            </div>
                            <!--end::PERSONAL INFO--> 

                            <!--begin::CONTACT INFO-->
                            <div class="d-flex flex-stack fs-4 py-3" v-if="user.employee.status_id !== STATUS_CODE.UNAPPROVE">
                                <div class="fw-bolder fs-5 collapsible" @click="active_drop = 2" role="button" aria-expanded="false">Update Job Info</div> 
                            </div>
                            <!--end::Details toggle-->
                            <div class="separator"></div>
                            <div id="job_info" class="collapse animated fadeIn mt-n1" :class="active_drop == 2 ? 'show' : null"  v-if="user.employee.status_id !== STATUS_CODE.UNAPPROVE">
                                <div class="pb-5 fs-6 px-3">  
                                    <EmployeeSetting :user="user" />
                                </div>
                            </div>
                            <!--end::CONTACT INFO-->  

                     <!--begin::JOB INFO-->
                    <div class="d-flex flex-stack fs-4 py-3">
                        <div class="fw-bolder fs-5 collapsible" @click="active_drop = 3" role="button" aria-expanded="false">Update Security Info</div> 
                    </div>
                    <!--end::Details toggle-->
                    <div class="separator"></div>
                    <div id="security" class="collapse animated fadeIn mt-n1" :class="active_drop == 3 ? 'show' : null">
                        <div class="pb-5 pt-10 fs-6"> 
                            <SecuritySettings :user="user" /> 
                        </div>
                    </div>
                    <!--end::JOB INFO--> 
                    
        </div>  
                
    </div>
</div>
</template>

<script> 
import apiServices from "@/services/apiServices"
import utility_services from "@/services/utility_services"; 
//EMPLOYEE COMPONENT
import BLANKLOADER from "@/components/loaders/blank-loader.vue";
import OVERLAYLOADER from "@/components/loaders/overlay-loader.vue"
import EmployeeSetting from "./employee/job_settings.vue"; 
import SecuritySettings from "./security.vue"; 
import { PARSE_AUTH, ENCRYPT, DECRYPT } from "@/utils/security/";  
import { IS_EMPTY, ADMINISTRATIVE_CLASS, STATUS_CODE, CHECK_BOOLEAN, READABLE_DATE, APP_INFO, REMOVE_CHARC, RESPONSE_STATUS, ACCESS_PRIVELEDGES, GRANT_ACCESS } from "@/utils/"; 
export default {
    name: "User overview", 
    props: ["user"],  
    data() {
        return{  
            active_drop: 1, 
            processing: false, 
            loading: true,
            notifyer: null,
            IS_EMPTY, CHECK_BOOLEAN, ADMINISTRATIVE_CLASS, STATUS_CODE, READABLE_DATE, REMOVE_CHARC, RESPONSE_STATUS, ACCESS_PRIVELEDGES, GRANT_ACCESS, APP_INFO,
            editable_user: this.user,
            chkPassword: '',
            states: '',
            countries: '',
            cities: '',
            photo: '',
            dp: IS_EMPTY(this.user.photo) ? APP_INFO.default_img : this.user.photo,
            auth: PARSE_AUTH(this.$store.state.auth, "user"),
        }
    }, 
	methods: {
         
        PREVIEW_DP: function(event) { 
            this.photo = event.target.files[0];
            (this.photo) ? this.dp = URL.createObjectURL(this.photo) : null
            this.UPLOAD_USER_PHOTO() // PROCESS IMAGE UPLOAD
        },
        
		GET_COUNTRIES: function() {
			utility_services.GET_COUNTRIES()
            .then(result => { // TRY ALL RESOURCE NEEDED FOR REGISTRATION 
                this.loading = false; 
                if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1500); 
                !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" }) : this.countries = DECRYPT(result.data.data);
            })
            .catch(() => {
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" })
            }) 
		},

		GET_STATES: function() {
			this.states = '';
            this.cities  = ''; 
			utility_services.GET_STATES({
                'id': this.editable_user.country_id
            })
            .then(result => { // TRY ALL RESOURCE NEEDED FOR REGISTRATION  
                if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1500); 
                !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" }) : this.states = result.data.data; 
            })
            .catch(() => {
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" })
            })  
		},

        GET_CITIES: function(){
            this.cities  = null; 
            utility_services.GET_CITIES({'id':this.editable_user.state_id})
            .then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION 
                if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1500); 
                !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" }) : this.cities  = result.data.data;   
            })
            .catch(() => {
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" })
            })
        },

        // VALIDATE FORM DATA
        // DO_VALIDATE: function(){
        //     !GRANT_ACCESS(this.editable_user.access_priviledge, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT) ? this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " you don`t have the access right to complete this process!" }) : this.DO_PROFILE_UPDATE();
        // },

        // VALIDATE FORM DATA
        DO_VALIDATE: function(){
          this.DO_PROFILE_UPDATE();
        },

        //PROCESS IMAGE UPLOAD
        
		UPLOAD_USER_PHOTO: function() { 
			this.processing = true;
			let formData = new FormData();
			formData.append("photo", this.photo)  
 
            let payload = ENCRYPT({'id': this.user.id}) 

            apiServices.UPLOAD_USER_PHOTO(payload, formData)
            .then(result => { 
                this.processing = false;
                this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) 
                result.data.success ? setTimeout(() => this.INIT_RELOAD(true), 3000) : null; 
                if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1500);
            })
            .catch(() => {
                this.processing = false;
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured processing request, try again!" });
            }) 
        },

        //PROFILE UPDATE
        
        DO_PROFILE_UPDATE: function(){
            this.processing = true;
            let payload = ENCRYPT(this.editable_user)
             
            apiServices.UPDATE_USER({payload})
            .then(result => { 
                this.processing = false;       
                this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) 
                result.data.success ? setTimeout(() => this.INIT_RELOAD(true), 3000)  : null
                if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1500); 
            })
            .catch(() => { 
                this.processing = false; 
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured processing request, try again!" }); 
            })
        },

        INIT_RELOAD: function(x){
            this.$emit('reload', x)
        }
        
    },
    components: { SecuritySettings, EmployeeSetting, BLANKLOADER, OVERLAYLOADER }, 
    mounted() {  
        this.GET_COUNTRIES()
        this.GET_STATES()
        this.GET_CITIES()
        let extScript = document.createElement('script')
        extScript.setAttribute('src', '/js/custom/documentation/forms/image-input.js') 
        document.head.appendChild(extScript)  
    }    
}
</script> 