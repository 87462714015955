import API from './api.js';
export default {
    //UTILITY SERVER SERVICES
     
    LOAD_EMPLOYEES_FILTER_OFFICE(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/   
        return API('employee').post('load_office_employees', data)
    },

    LOAD_EMPLOYEES_FILTER_DEPARTMENT(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/   
        return API('employee').post('load_department_employees', data)
    },

    LOAD_EMPLOYEES_FILTER_SUBSIDIARY(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/   
        return API('employee').post('load_subsidiary_employees', data)
    },

    LOAD_EMPLOYEES_FILTER_LEVEL(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/   
        return API('employee').post('load_level_employees', data)
    },

    LOAD_EMPLOYEES_FILTER_POSITION(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/   
        return API('employee').post('load_position_employees', data)
    },

    LOAD_EMPLOYEES_FILTER_STATUS(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/   
        return API('employee').post('load_status_employees', data)
    },
 
    LOAD_EMPLOYEES(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/   
        return API('employee').post('load_employees', data)
    },

    LOAD_EMPLOYEE(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/   
        return API('employee').post('load_employee', data)
    },
    
    UPDATE_EMPLOYEE(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('employee').post('update_employee', data)
    },

    SAVE_EMPLOYEE(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/   
        return API('employee').post('save_employee', data)
    },

    ADD_SELF_EMPLOYEE(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/   
        return API('employee').post('init_self_service_registration', data)
    },
    
    APPROVE_SELF_EMPLOYEE(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/   
        return API('employee').post('approve_self_service_profile', data)
    },
    
    LOAD_EMPLOYEE_EDUCATION_HISTORY(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('employee').post('load_employee_education', data)
    },
 
    SUBMIT_EDUCATION_FORM(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('employee').post('save_employee_education', data)
    }, 

    UPLOAD_EDUCATION_FILE(ref, data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/    
        return API('employee').post(`upload_education_file/` + ref, data)
    }, 
    
    DELETE_EDUCATIONAL_RECORD(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('employee').post('delete_employee_education_record', data)
    },

    UPDATE_EDUCATION_FORM(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('employee').post('update_employee_education_record', data)
    },

    UPDATE_EDUCATION_ATTACHMENT(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('employee').post('update_employee_education_record_attachment', data)
    },

    // EMPLOYEE SURITY SERVICE ROUTES
    CONFIRM_SURITY_RECORD(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('employee').post('confirm_employee_surity_record', data)
    },

    LOAD_SUTIRY_TYPES() { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('employee').get('load_surity_types')
    },

    DELETE_SURITY_RECORD(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('employee').post('delete_employee_surity_record', data)
    },

    LOAD_EMPLOYEE_SURITIES(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('employee').post('load_employee_surities', data)
    },

    SUBMIT_NEW_SURITY_FORM(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('employee').post('save_employee_surity', data)
    },

    UPDATE_SURITY_FORM(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('employee').post('update_employee_surity_record', data)
    },
    
    // WORK EXPERIENCE SERVICE ROUTES
    CONFIRM_WORK_EXPERIENCE_RECORD(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('employee').post('confirm_employee_work_experience_record', data)
    },

    DELETE_WORK_EXPERIENCE_RECORD(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('employee').post('delete_employee_work_experience_record', data)
    },

    LOAD_EMPLOYEE_WORK_EXPERIENCE_HISTORY(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('employee').post('load_employee_work_experience', data)
    },

    UPDATE_WORK_EXP_RECORD(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('employee').post('update_employee_work_experience', data)
    },

    SUBMIT_WORK_EXPERIENCE(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('employee').post('save_employee_work_experience', data)
    },

    SUBMIT_WORK_EXPERIENCE_EDIT_FORM(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('employee').post('update_employee_work_experience_record', data)
    },

    LOAD_EMPLOYEE_FILES_FILTER(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
    return API('employee').post('load_employee_files_filter', data)
},


}