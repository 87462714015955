<template>

<!--begin::Root-->
<div class="d-flex flex-column flex-root">
    <!--begin::Authentication - Signup Welcome Message -->
    <div class="d-flex flex-column flex-column-fluid">
        <!--begin::Content-->
        <div class="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
            <!--begin::Logo-->
            <a href="../../demo1/dist/index.html" class="mb-10 pt-lg-10">
                <img alt="Logo" src="/media/logos/logo-1.svg" class="h-40px mb-5" />
            </a>
            <!--end::Logo-->
            <!--begin::Wrapper-->
            <div class="pt-lg-10 mb-10">
                <!--begin::Logo-->
                <h1 class="fw-bolder fs-2qx text-gray-800 mb-7 animated flash infinite" v-if="vStatus === 0">Verifying</h1>
                <h1 class="fw-bolder fs-2qx text-primary mb-7 animated fadeIn" v-if="vStatus === 1"> <span class="mdi mdi-check ms-2 text-info"></span> Congratulations!</h1>
                <h1 class="fw-bolder fs-2qx text-danger mb-7 animated fadeIn" v-if="vStatus === 2"> <span class="mdi mdi-check ms-2 text-danger"></span> Verification failed</h1>
                <!--end::Logo-->
                <!--begin::Message-->
                <div class="fw-bold fs-3 text-muted mb-15" v-if="vStatus === 0">Wait while we try to verify your account</div> 
                <!--end::Message-->
                <!--begin::Message-->
                <div class="fw-bold fs-3 text-muted mb-15 animated fadeIn" v-if="vStatus > 0"> {{resMsg}} </div> 
                <!--end::Message--> 
                <!--begin::Action-->
                <div class="text-center animated fadeIn" v-if="vStatus === 1">
                    <router-link to="/auth/login" class="btn btn-lg btn-primary fw-bolder">Go to login page</router-link>
                </div>
                <!--end::Action-->
            </div>
            <!--end::Wrapper-->
            <!--begin::Illustration-->
            <div class="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px" style="background-image: url(/media/illustrations/sketchy-1/17.png"></div>
            <!--end::Illustration-->
        </div>
        <!--end::Content--> 
    </div>
    <!--end::Authentication - Signup Welcome Message-->
</div>
<!--end::Root-->

<div>
 
</div>
</template>

<script>
// @ is an alias to /src 
import apiServices from "@/services/apiServices"; 
import { DECRYPT_STR, RESPONSE_STATUS } from "@/utils/";  

export default {
name: "Email Verification",
data() {
    return{ 
        // Theme: this.$store.state.currentTheme, 
        id: this.$route.query.ref, // truncate ref url data to get user id
        email: this.$route.query.meta, // truncate ref url data to get user id 
        vStatus: 0,
        resMsg: '',
        DECRYPT_STR: DECRYPT_STR,
        RESPONSE_STATUS: RESPONSE_STATUS,
    }
},
components: {
    // Header,
},  
methods: {  
      
    DO_VERIFICATION: function(){  
    let data = {'email': this.email, 'id': this.id} 

    apiServices.VERIFY_USER_EMAIL(data)
    .then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION  
    console.log(result.data) 
        this.resMsg = result.data.msg;  
        if(!result.data.success){  
            this.vStatus = 2;  
        }else{
            this.vStatus = 1; 
            setTimeout(() => this.$router.push('/auth/login'), 2000)  // redirct to login page  
        }  
    })
    .catch(err => {
        console.log(err.message) 
        this.vStatus = 2;
        this.resMsg = 'Sorry email verification failed try again.';   
    })
}
}, 
mounted() {
 this.DO_VERIFICATION()
} 

};
</script>