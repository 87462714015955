import API from './api.js'; 
export default {

     //USER AUTHENTICATION SERVER SERVICES   
     LOAD_DASHBOARD(data) { /**** PROCESS LOGIN*****/  
        return API('user').post('load_dashboard', data) 
    },

    UPDATE_USER_EMAIL(data) { /**** PROCESS LOGIN*****/  
       return API('user').post('update_user_email', data) 
   },

   UPDATE_USER_PASSWORD(data) { /**** PROCESS LOGIN*****/  
    return API('user').post('update_user_password', data) 
   },

   
   UPLOAD_USER_PHOTO(ref, data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/    
      return API('user').post(`upload_user_photo/` + ref, data)
   },

   USER_2FA(data) { /**** PROCESS LOGIN*****/  
    return API('user').post('update_user_2fa', data) 
   },

   LOAD_EVENTS(data) { /**** PROCESS LOGIN*****/  
      return API('user').post('load_user_events', data) 
   },

   CONFIRM_USER(data) {
      return API('user').post('confirm_user', data)
   }, 
        
}
