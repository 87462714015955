<template> 
        <!--begin::Stats-->
        <div class="d-flex flex-wrap flex-stack">
            <!--begin::Wrapper-->
            <div class="d-flex flex-column flex-grow-1 pe-8 mt-3">
                <!--begin::Stats-->
                <div class="row">
                    <!--begin::Stat-->
                    <div class="col-md-2 border border-gray-300 border-dashed rounded min-w-125px py-3 px-2 me-6 mb-3">
                        <!--begin::Number-->
                        <div class="d-flex align-items-center"> 
                            <div class="fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-value="4500" data-kt-countup-prefix="$">{{THOUSAND_SEPARATOR(organizations.length)}}</div>
                        </div>
                        <!--end::Number-->
                        <!--begin::Label-->
                        <div class="fw-bold fs-6 text-gray-400">Organizations</div>
                        <!--end::Label-->
                    </div>
                    <!--end::Stat-->
                    <!--begin::Stat-->
                    <div class="col-md-2 border border-gray-300 border-dashed rounded min-w-125px py-3 px-2 me-6 mb-3">
                        <!--begin::Number-->
                        <div class="d-flex align-items-center"> 
                            <div class="fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-value="75">{{THOUSAND_SEPARATOR(this.subsidiaries.length)}}</div>
                        </div>
                        <!--end::Number-->
                        <!--begin::Label-->
                        <div class="fw-bold fs-6 text-gray-400">Subsidiaries</div>
                        <!--end::Label-->
                    </div>
                    <!--end::Stat-->
                    <!--begin::Stat-->
                    <div class="col-md-2 border border-gray-300 border-dashed rounded min-w-125px py-3 px-2 me-6 mb-3">
                        <!--begin::Number-->
                        <div class="d-flex align-items-center"> 
                            <div class="fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-value="60" data-kt-countup-prefix="%">{{THOUSAND_SEPARATOR(this.employees.length)}}</div>
                        </div>
                        <!--end::Number-->
                        <!--begin::Label-->
                        <div class="fw-bold fs-6 text-gray-400">Employees</div>
                        <!--end::Label-->
                    </div>
                    <!--end::Stat-->
                    <!--begin::Progress-->
                    <div class="col-md-4 border rounded border-gray-300 border-dashed py-4 px-2 me-0 mb-3">
                        <div class="h-5px w-100 bg-light my-3">
                            <div class="bg-success rounded h-5px" role="progressbar" style="width: 50%;" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div class="d-flex justify-content-between w-100 mt-auto mt-4">
                            <span class="fw-bold fs-6 text-gray-400">Profile Compleation</span>
                            <span class="fw-bolder fs-6">50%</span>
                        </div>
                    </div>
                    <!--end::Progress-->
                </div>
                <!--end::Stats-->
            </div>
            <!--end::Wrapper--> 
        </div>
        <!--end::Stats-->
</template>

<script>  
import chiefServices from "@/services/chief.js"
//EMPLOYEE COMPONENT 
import { IS_EMPTY, CHECK_BOOLEAN, CAPITALIZE_STR, ENCRYPT_JSON_PAYLOAD, RESPONSE_STATUS, DECRYPT_JSON_PAYLOAD,  PARSE_AUTH, THOUSAND_SEPARATOR } from "@/utils/"; 
import { WEEK_DAYS } from "@/utils/date_utils.js"; 
export default {
    name: "User overview",  
    data() {
        return{  
            IS_EMPTY: IS_EMPTY, 
            CHECK_BOOLEAN: CHECK_BOOLEAN, 
            CAPITALIZE_STR: CAPITALIZE_STR,
            THOUSAND_SEPARATOR: THOUSAND_SEPARATOR,
            WEEK_DAYS: WEEK_DAYS, 
            user: PARSE_AUTH(this.$store.state.auth, "user"),
            organizations: '',
            subsidiaries: [],
            employees: [],
        }
    },  
    methods: {
        
            LOAD_CHIEF_STATS: function(){ // FETCH ALL MESSAGES FROM DATABASE
            this.isLoading = 'yes';
            this.retry = ''  
            let payload = ENCRYPT_JSON_PAYLOAD({'id': this.user.id})
            chiefServices.LOAD_CHIEF_STATS({'payload': payload})
            .then(result =>{      
                if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) {
                    this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": result.data.msg })
                    setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path}), 2500) 
                }// logout and redirect with refferer page         
                
                if(!result.data.success){ 
                    this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg })
                }
                else{     
                    let stats =  DECRYPT_JSON_PAYLOAD(result.data.data).payload 
                    this.organizations = stats.organizations 
                    for (const org of this.organizations) {
                        this.subsidiaries.push(org.subsidiaries)
                    } 
                    for (const sub of this.subsidiaries) {
                        this.employees.push(sub.employees)
                    } 
                    console.log(this.employees)
                    // this.offices =  subsidiary.offices  
                    // this.departments = subsidiary.employee_departments
                    // this.positions = subsidiary.employee_positions
                    // this.levels = subsidiary.employee_levels 
                    // this.teams = subsidiary.employee_teams  
                } 
            })
            .catch(err => {
                console.log(err.message)  
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": 'error occured loading page contents' })
            })
        }, 
         
 
    },
    mounted() {   
        this.LOAD_CHIEF_STATS()
    }    
}
</script> 