<template>
	<!--begin::Aside-->
	<div id="kt_aside" class="aside py-9" data-kt-drawer="true" data-kt-drawer-name="aside" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_aside_toggle">
		<!--begin::Brand-->
		<div class="aside-logo flex-column-auto px-9 mb-9" id="kt_aside_logo">
			<!--begin::Logo-->
			<a href="/">
				<img alt="tasquik.com" :src="APP_INFO.logo" class="h-25px logo" />
			</a>
			<!--end::Logo-->
		</div>
		<!--end::Brand-->
		<!--begin::Aside menu-->
		<div class="aside-menu flex-column-fluid ps-5 pe-3 mb-9" id="kt_aside_menu">
			<!--begin::Aside Menu-->
			<div class="w-100 hover-scroll-overlay-y d-flex pe-2" id="kt_aside_menu_wrapper" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer" data-kt-scroll-wrappers="#kt_aside, #kt_aside_menu, #kt_aside_menu_wrapper" data-kt-scroll-offset="100">
				<!--begin::Menu-->
				<div class="menu menu-column menu-rounded fw-bold my-auto" id="#kt_aside_menu" data-kt-menu="true">
					<div data-kt-menu-trigger="click" class="menu-item here show menu-accordion">
						<span class="menu-link">
							<span class="menu-icon">
								<!--begin::Svg Icon | path: icons/duotune/arrows/arr001.svg-->
								<span class="svg-icon svg-icon-5">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
										<path d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z" fill="black" />
										<path opacity="0.3" d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z" fill="black" />
									</svg>
								</span>
								<!--end::Svg Icon-->
							</span>
							<span class="menu-title">Dashboards</span>
							<span class="menu-arrow"></span>
						</span>
						<div class="menu-sub menu-sub-accordion">
							<div class="menu-item">
								<a class="menu-link" href="../../demo3/dist/index.html">
									<span class="menu-bullet">
										<span class="bullet bullet-dot"></span>
									</span>
									<span class="menu-title">Multipurpose</span>
								</a>
							</div>
							<div class="menu-item">
								<a class="menu-link active" href="../../demo3/dist/dashboards/ecommerce.html">
									<span class="menu-bullet">
										<span class="bullet bullet-dot"></span>
									</span>
									<span class="menu-title">eCommerce</span>
								</a>
							</div>
							<div class="menu-item">
								<a class="menu-link" href="../../demo3/dist/dashboards/store-analytics.html">
									<span class="menu-bullet">
										<span class="bullet bullet-dot"></span>
									</span>
									<span class="menu-title">Store Analytics</span>
								</a>
							</div>
							<div class="menu-item">
								<a class="menu-link" href="../../demo3/dist/dashboards/logistics.html">
									<span class="menu-bullet">
										<span class="bullet bullet-dot"></span>
									</span>
									<span class="menu-title">Logistics</span>
								</a>
							</div>
							<div class="menu-item">
								<a class="menu-link" href="../../demo3/dist/dashboards/delivery.html">
									<span class="menu-bullet">
										<span class="bullet bullet-dot"></span>
									</span>
									<span class="menu-title">Delivery</span>
								</a>
							</div>
							<div class="menu-item">
								<a class="menu-link" href="../../demo3/dist/dashboards/marketing.html">
									<span class="menu-bullet">
										<span class="bullet bullet-dot"></span>
									</span>
									<span class="menu-title">Marketing</span>
								</a>
							</div>
							<div class="menu-item">
								<a class="menu-link" href="../../demo3/dist/dashboards/social.html">
									<span class="menu-bullet">
										<span class="bullet bullet-dot"></span>
									</span>
									<span class="menu-title">Social</span>
								</a>
							</div>
							<div class="menu-item">
								<a class="menu-link" href="../../demo3/dist/landing.html">
									<span class="menu-bullet">
										<span class="bullet bullet-dot"></span>
									</span>
									<span class="menu-title">Landing</span>
								</a>
							</div>
						</div>
					</div>
					<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
						<span class="menu-link">
							<span class="menu-icon">
								<!--begin::Svg Icon | path: icons/duotune/arrows/arr001.svg-->
								<span class="svg-icon svg-icon-5">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
										<path d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z" fill="black" />
										<path opacity="0.3" d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z" fill="black" />
									</svg>
								</span>
								<!--end::Svg Icon-->
							</span>
							<span class="menu-title">Crafted</span>
							<span class="menu-arrow"></span>
						</span>
						<div class="menu-sub menu-sub-accordion">
							<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
								<span class="menu-link">
									<span class="menu-bullet">
										<span class="bullet bullet-dot"></span>
									</span>
									<span class="menu-title">Pages</span>
									<span class="menu-arrow"></span>
								</span>
								<div class="menu-sub menu-sub-accordion menu-active-bg">
									<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
										<span class="menu-link">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">User Profile</span>
											<span class="menu-arrow"></span>
										</span>
										<div class="menu-sub menu-sub-accordion menu-active-bg">
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/pages/user-profile/overview.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Overview</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/pages/user-profile/projects.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Projects</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/pages/user-profile/campaigns.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Campaigns</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/pages/user-profile/documents.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Documents</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/pages/user-profile/followers.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Followers</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/pages/user-profile/activity.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Activity</span>
												</a>
											</div>
										</div>
									</div>
									<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
										<span class="menu-link">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Blog</span>
											<span class="menu-arrow"></span>
										</span>
										<div class="menu-sub menu-sub-accordion menu-active-bg">
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/pages/blog/home.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Blog Home</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/pages/blog/post.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Blog Post</span>
												</a>
											</div>
										</div>
									</div>
									<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
										<span class="menu-link">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Pricing</span>
											<span class="menu-arrow"></span>
										</span>
										<div class="menu-sub menu-sub-accordion menu-active-bg">
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/pages/pricing/pricing-1.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Pricing 1</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/pages/pricing/pricing-2.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Pricing 2</span>
												</a>
											</div>
										</div>
									</div>
									<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
										<span class="menu-link">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Careers</span>
											<span class="menu-arrow"></span>
										</span>
										<div class="menu-sub menu-sub-accordion menu-active-bg">
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/pages/careers/list.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Careers List</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/pages/careers/apply.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Careers Apply</span>
												</a>
											</div>
										</div>
									</div>
									<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
										<span class="menu-link">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">FAQ</span>
											<span class="menu-arrow"></span>
										</span>
										<div class="menu-sub menu-sub-accordion menu-active-bg">
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/pages/faq/classic.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Classic</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/pages/faq/extended.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Extended</span>
												</a>
											</div>
										</div>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/pages/about.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">About Us</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/pages/contact.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Contact Us</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/pages/team.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Our Team</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/pages/licenses.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Licenses</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/pages/sitemap.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Sitemap</span>
										</a>
									</div>
								</div>
							</div>
							<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
								<span class="menu-link">
									<span class="menu-bullet">
										<span class="bullet bullet-dot"></span>
									</span>
									<span class="menu-title">Account</span>
									<span class="menu-arrow"></span>
								</span>
								<div class="menu-sub menu-sub-accordion menu-active-bg">
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/account/overview.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Overview</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/account/settings.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Settings</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/account/security.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Security</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/account/billing.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Billing</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/account/statements.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Statements</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/account/referrals.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Referrals</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/account/api-keys.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">API Keys</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/account/logs.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Logs</span>
										</a>
									</div>
								</div>
							</div>
							<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
								<span class="menu-link">
									<span class="menu-bullet">
										<span class="bullet bullet-dot"></span>
									</span>
									<span class="menu-title">Authentication</span>
									<span class="menu-arrow"></span>
								</span>
								<div class="menu-sub menu-sub-accordion menu-active-bg">
									<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
										<span class="menu-link">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Basic Layout</span>
											<span class="menu-arrow"></span>
										</span>
										<div class="menu-sub menu-sub-accordion menu-active-bg">
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/authentication/layouts/basic/sign-in.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Sign-in</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/authentication/layouts/basic/sign-up.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Sign-up</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/authentication/layouts/basic/two-steps.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Two-steps</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/authentication/layouts/basic/password-reset.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Password Reset</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/authentication/layouts/basic/new-password.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">New Password</span>
												</a>
											</div>
										</div>
									</div>
									<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
										<span class="menu-link">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Aside Layout</span>
											<span class="menu-arrow"></span>
										</span>
										<div class="menu-sub menu-sub-accordion menu-active-bg">
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/authentication/layouts/aside/sign-in.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Sign-in</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/authentication/layouts/aside/sign-up.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Sign-up</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/authentication/layouts/aside/two-steps.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Two-steps</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/authentication/layouts/aside/password-reset.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Password Reset</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/authentication/layouts/aside/new-password.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">New Password</span>
												</a>
											</div>
										</div>
									</div>
									<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
										<span class="menu-link">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Dark Layout</span>
											<span class="menu-arrow"></span>
										</span>
										<div class="menu-sub menu-sub-accordion menu-active-bg">
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/authentication/layouts/dark/sign-in.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Sign-in</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/authentication/layouts/dark/sign-up.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Sign-up</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/authentication/layouts/dark/two-steps.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Two-steps</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/authentication/layouts/dark/password-reset.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Password Reset</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/authentication/layouts/dark/new-password.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">New Password</span>
												</a>
											</div>
										</div>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/authentication/extended/multi-steps-sign-up.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Multi-steps Sign-up</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/authentication/extended/two-factor-authentication.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Two Factor Auth</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/authentication/extended/free-trial-sign-up.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Free Trial Sign-up</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/authentication/extended/coming-soon.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Coming Soon</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/authentication/general/welcome.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Welcome Message</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/authentication/general/verify-email.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Verify Email</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/authentication/general/password-confirmation.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Password Confirmation</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/authentication/general/deactivation.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Account Deactivation</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/authentication/general/error-404.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Error 404</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/authentication/general/error-500.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Error 500</span>
										</a>
									</div>
									<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
										<span class="menu-link">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Email Templates</span>
											<span class="menu-arrow"></span>
										</span>
										<div class="menu-sub menu-sub-accordion menu-active-bg">
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/authentication/email/verify-email.html" target="blank">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Verify Email</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/authentication/email/invitation.html" target="blank">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Account Invitation</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/authentication/email/password-reset.html" target="blank">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Password Reset</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/authentication/email/password-change.html" target="blank">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Password Changed</span>
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
								<span class="menu-link">
									<span class="menu-bullet">
										<span class="bullet bullet-dot"></span>
									</span>
									<span class="menu-title">Utilities</span>
									<span class="menu-arrow"></span>
								</span>
								<div class="menu-sub menu-sub-accordion menu-active-bg">
									<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
										<span class="menu-link">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Modals</span>
											<span class="menu-arrow"></span>
										</span>
										<div class="menu-sub menu-sub-accordion menu-active-bg">
											<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
												<span class="menu-link">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">General</span>
													<span class="menu-arrow"></span>
												</span>
												<div class="menu-sub menu-sub-accordion menu-active-bg">
													<div class="menu-item">
														<a class="menu-link" href="../../demo3/dist/utilities/modals/general/invite-friends.html">
															<span class="menu-bullet">
																<span class="bullet bullet-dot"></span>
															</span>
															<span class="menu-title">Invite Friends</span>
														</a>
													</div>
													<div class="menu-item">
														<a class="menu-link" href="../../demo3/dist/utilities/modals/general/view-users.html">
															<span class="menu-bullet">
																<span class="bullet bullet-dot"></span>
															</span>
															<span class="menu-title">View Users</span>
														</a>
													</div>
													<div class="menu-item">
														<a class="menu-link" href="../../demo3/dist/utilities/modals/general/select-users.html">
															<span class="menu-bullet">
																<span class="bullet bullet-dot"></span>
															</span>
															<span class="menu-title">Select Users</span>
														</a>
													</div>
													<div class="menu-item">
														<a class="menu-link" href="../../demo3/dist/utilities/modals/general/upgrade-plan.html">
															<span class="menu-bullet">
																<span class="bullet bullet-dot"></span>
															</span>
															<span class="menu-title">Upgrade Plan</span>
														</a>
													</div>
													<div class="menu-item">
														<a class="menu-link" href="../../demo3/dist/utilities/modals/general/share-earn.html">
															<span class="menu-bullet">
																<span class="bullet bullet-dot"></span>
															</span>
															<span class="menu-title">Share &amp; Earn</span>
														</a>
													</div>
												</div>
											</div>
											<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
												<span class="menu-link">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Forms</span>
													<span class="menu-arrow"></span>
												</span>
												<div class="menu-sub menu-sub-accordion menu-active-bg">
													<div class="menu-item">
														<a class="menu-link" href="../../demo3/dist/utilities/modals/forms/new-target.html">
															<span class="menu-bullet">
																<span class="bullet bullet-dot"></span>
															</span>
															<span class="menu-title">New Target</span>
														</a>
													</div>
													<div class="menu-item">
														<a class="menu-link" href="../../demo3/dist/utilities/modals/forms/new-card.html">
															<span class="menu-bullet">
																<span class="bullet bullet-dot"></span>
															</span>
															<span class="menu-title">New Card</span>
														</a>
													</div>
													<div class="menu-item">
														<a class="menu-link" href="../../demo3/dist/utilities/modals/forms/new-address.html">
															<span class="menu-bullet">
																<span class="bullet bullet-dot"></span>
															</span>
															<span class="menu-title">New Address</span>
														</a>
													</div>
													<div class="menu-item">
														<a class="menu-link" href="../../demo3/dist/utilities/modals/forms/create-api-key.html">
															<span class="menu-bullet">
																<span class="bullet bullet-dot"></span>
															</span>
															<span class="menu-title">Create API Key</span>
														</a>
													</div>
												</div>
											</div>
											<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
												<span class="menu-link">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Wizards</span>
													<span class="menu-arrow"></span>
												</span>
												<div class="menu-sub menu-sub-accordion menu-active-bg">
													<div class="menu-item">
														<a class="menu-link" href="../../demo3/dist/utilities/modals/wizards/create-app.html">
															<span class="menu-bullet">
																<span class="bullet bullet-dot"></span>
															</span>
															<span class="menu-title">Create App</span>
														</a>
													</div>
													<div class="menu-item">
														<a class="menu-link" href="../../demo3/dist/utilities/modals/wizards/create-campaign.html">
															<span class="menu-bullet">
																<span class="bullet bullet-dot"></span>
															</span>
															<span class="menu-title">Create Campaign</span>
														</a>
													</div>
													<div class="menu-item">
														<a class="menu-link" href="../../demo3/dist/utilities/modals/wizards/create-account.html">
															<span class="menu-bullet">
																<span class="bullet bullet-dot"></span>
															</span>
															<span class="menu-title">Create Business Acc</span>
														</a>
													</div>
													<div class="menu-item">
														<a class="menu-link" href="../../demo3/dist/utilities/modals/wizards/create-project.html">
															<span class="menu-bullet">
																<span class="bullet bullet-dot"></span>
															</span>
															<span class="menu-title">Create Project</span>
														</a>
													</div>
													<div class="menu-item">
														<a class="menu-link" href="../../demo3/dist/utilities/modals/wizards/offer-a-deal.html">
															<span class="menu-bullet">
																<span class="bullet bullet-dot"></span>
															</span>
															<span class="menu-title">Offer a Deal</span>
														</a>
													</div>
													<div class="menu-item">
														<a class="menu-link" href="../../demo3/dist/utilities/modals/wizards/two-factor-authentication.html">
															<span class="menu-bullet">
																<span class="bullet bullet-dot"></span>
															</span>
															<span class="menu-title">Two Factor Auth</span>
														</a>
													</div>
												</div>
											</div>
											<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
												<span class="menu-link">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Search</span>
													<span class="menu-arrow"></span>
												</span>
												<div class="menu-sub menu-sub-accordion menu-active-bg">
													<div class="menu-item">
														<a class="menu-link" href="../../demo3/dist/utilities/modals/search/users.html">
															<span class="menu-bullet">
																<span class="bullet bullet-dot"></span>
															</span>
															<span class="menu-title">Users</span>
														</a>
													</div>
													<div class="menu-item">
														<a class="menu-link" href="../../demo3/dist/utilities/modals/search/select-location.html">
															<span class="menu-bullet">
																<span class="bullet bullet-dot"></span>
															</span>
															<span class="menu-title">Select Location</span>
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
										<span class="menu-link">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Search</span>
											<span class="menu-arrow"></span>
										</span>
										<div class="menu-sub menu-sub-accordion menu-active-bg">
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/utilities/search/horizontal.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Horizontal</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/utilities/search/vertical.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Vertical</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/utilities/search/users.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Users</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/utilities/search/select-location.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Location</span>
												</a>
											</div>
										</div>
									</div>
									<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
										<span class="menu-link">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Wizards</span>
											<span class="menu-arrow"></span>
										</span>
										<div class="menu-sub menu-sub-accordion menu-active-bg">
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/utilities/wizards/horizontal.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Horizontal</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/utilities/wizards/vertical.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Vertical</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/utilities/wizards/two-factor-authentication.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Two Factor Auth</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/utilities/wizards/create-app.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Create App</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/utilities/wizards/create-campaign.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Create Campaign</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/utilities/wizards/create-account.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Create Account</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/utilities/wizards/create-project.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Create Project</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/utilities/wizards/offer-a-deal.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Offer a Deal</span>
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
								<span class="menu-link">
									<span class="menu-bullet">
										<span class="bullet bullet-dot"></span>
									</span>
									<span class="menu-title">Widgets</span>
									<span class="menu-arrow"></span>
								</span>
								<div class="menu-sub menu-sub-accordion menu-active-bg">
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/widgets/lists.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Lists</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/widgets/statistics.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Statistics</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/widgets/charts.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Charts</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/widgets/mixed.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Mixed</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/widgets/tables.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Tables</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/widgets/feeds.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Feeds</span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
						<span class="menu-link">
							<span class="menu-icon">
								<!--begin::Svg Icon | path: icons/duotune/arrows/arr001.svg-->
								<span class="svg-icon svg-icon-5">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
										<path d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z" fill="black" />
										<path opacity="0.3" d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z" fill="black" />
									</svg>
								</span>
								<!--end::Svg Icon-->
							</span>
							<span class="menu-title">Apps</span>
							<span class="menu-arrow"></span>
						</span>
						<div class="menu-sub menu-sub-accordion">
							<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
								<span class="menu-link">
									<span class="menu-bullet">
										<span class="bullet bullet-dot"></span>
									</span>
									<span class="menu-title">Projects</span>
									<span class="menu-arrow"></span>
								</span>
								<div class="menu-sub menu-sub-accordion">
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/projects/list.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">My Projects</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/projects/project.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">View Project</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/projects/targets.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Targets</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/projects/budget.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Budget</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/projects/users.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Users</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/projects/files.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Files</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/projects/activity.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Activity</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/projects/settings.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Settings</span>
										</a>
									</div>
								</div>
							</div>
							<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
								<span class="menu-link">
									<span class="menu-bullet">
										<span class="bullet bullet-dot"></span>
									</span>
									<span class="menu-title">eCommerce</span>
									<span class="menu-arrow"></span>
								</span>
								<div class="menu-sub menu-sub-accordion">
									<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
										<span class="menu-link">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Catalog</span>
											<span class="menu-arrow"></span>
										</span>
										<div class="menu-sub menu-sub-accordion">
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/apps/ecommerce/catalog/products.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Products</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/apps/ecommerce/catalog/categories.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Categories</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/apps/ecommerce/catalog/add-product.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Add Product</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/apps/ecommerce/catalog/edit-product.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Edit Product</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/apps/ecommerce/catalog/add-category.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Add Category</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/apps/ecommerce/catalog/edit-category.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Edit Category</span>
												</a>
											</div>
										</div>
									</div>
									<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
										<span class="menu-link">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Sales</span>
											<span class="menu-arrow"></span>
										</span>
										<div class="menu-sub menu-sub-accordion">
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/apps/ecommerce/sales/listing.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Orders Listing</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/apps/ecommerce/sales/details.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Order Details</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/apps/ecommerce/sales/add-order.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Add Order</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/apps/ecommerce/sales/edit-order.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Edit Order</span>
												</a>
											</div>
										</div>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="#" title="Coming soon" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss="click" data-bs-placement="right">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Customers</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="#" title="Coming soon" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss="click" data-bs-placement="right">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Reports</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="#" title="Coming soon" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss="click" data-bs-placement="right">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Settings</span>
										</a>
									</div>
								</div>
							</div>
							<div data-kt-menu-trigger="click" class="menu-item menu-accordion mb-1">
								<span class="menu-link">
									<span class="menu-bullet">
										<span class="bullet bullet-dot"></span>
									</span>
									<span class="menu-title">Support Center</span>
									<span class="menu-arrow"></span>
								</span>
								<div class="menu-sub menu-sub-accordion">
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/support-center/overview.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Overview</span>
										</a>
									</div>
									<div data-kt-menu-trigger="click" class="menu-item menu-accordion mb-1">
										<span class="menu-link">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Tickets</span>
											<span class="menu-arrow"></span>
										</span>
										<div class="menu-sub menu-sub-accordion">
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/apps/support-center/tickets/list.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Tickets List</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/apps/support-center/tickets/view.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">View Ticket</span>
												</a>
											</div>
										</div>
									</div>
									<div data-kt-menu-trigger="click" class="menu-item menu-accordion mb-1">
										<span class="menu-link">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Tutorials</span>
											<span class="menu-arrow"></span>
										</span>
										<div class="menu-sub menu-sub-accordion">
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/apps/support-center/tutorials/list.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Tutorials List</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/apps/support-center/tutorials/post.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Tutorial Post</span>
												</a>
											</div>
										</div>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/support-center/faq.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">FAQ</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/support-center/licenses.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Licenses</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/support-center/contact.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Contact Us</span>
										</a>
									</div>
								</div>
							</div>
							<div data-kt-menu-trigger="click" class="menu-item menu-accordion mb-1">
								<span class="menu-link">
									<span class="menu-bullet">
										<span class="bullet bullet-dot"></span>
									</span>
									<span class="menu-title">User Management</span>
									<span class="menu-arrow"></span>
								</span>
								<div class="menu-sub menu-sub-accordion">
									<div data-kt-menu-trigger="click" class="menu-item menu-accordion mb-1">
										<span class="menu-link">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Users</span>
											<span class="menu-arrow"></span>
										</span>
										<div class="menu-sub menu-sub-accordion">
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/apps/user-management/users/list.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Users List</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/apps/user-management/users/view.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">View User</span>
												</a>
											</div>
										</div>
									</div>
									<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
										<span class="menu-link">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Roles</span>
											<span class="menu-arrow"></span>
										</span>
										<div class="menu-sub menu-sub-accordion">
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/apps/user-management/roles/list.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Roles List</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/apps/user-management/roles/view.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">View Role</span>
												</a>
											</div>
										</div>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/user-management/permissions.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Permissions</span>
										</a>
									</div>
								</div>
							</div>
							<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
								<span class="menu-link">
									<span class="menu-bullet">
										<span class="bullet bullet-dot"></span>
									</span>
									<span class="menu-title">Subscriptions</span>
									<span class="menu-arrow"></span>
								</span>
								<div class="menu-sub menu-sub-accordion">
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/subscriptions/getting-started.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Getting Started</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/subscriptions/list.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Subscription List</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/subscriptions/add.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Add Subscription</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/subscriptions/view.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">View Subscription</span>
										</a>
									</div>
								</div>
							</div>
							<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
								<span class="menu-link">
									<span class="menu-bullet">
										<span class="bullet bullet-dot"></span>
									</span>
									<span class="menu-title">Customers</span>
									<span class="menu-arrow"></span>
								</span>
								<div class="menu-sub menu-sub-accordion">
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/customers/getting-started.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Getting Started</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/customers/list.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Customer Listing</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/customers/view.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Customer Details</span>
										</a>
									</div>
								</div>
							</div>
							<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
								<span class="menu-link">
									<span class="menu-bullet">
										<span class="bullet bullet-dot"></span>
									</span>
									<span class="menu-title">File Manager</span>
									<span class="menu-arrow"></span>
								</span>
								<div class="menu-sub menu-sub-accordion">
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/file-manager/folders.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Folders</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/file-manager/files.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Files</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/file-manager/blank.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Blank Directory</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/file-manager/settings.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Settings</span>
										</a>
									</div>
								</div>
							</div>
							<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
								<span class="menu-link">
									<span class="menu-bullet">
										<span class="bullet bullet-dot"></span>
									</span>
									<span class="menu-title">Invoice Manager</span>
									<span class="menu-arrow"></span>
								</span>
								<div class="menu-sub menu-sub-accordion">
									<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
										<span class="menu-link">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">View Invoices</span>
											<span class="menu-arrow"></span>
										</span>
										<div class="menu-sub menu-sub-accordion menu-active-bg">
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/apps/invoices/view/invoice-1.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Invoice 1</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/apps/invoices/view/invoice-2.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Invoice 2</span>
												</a>
											</div>
											<div class="menu-item">
												<a class="menu-link" href="../../demo3/dist/apps/invoices/view/invoice-3.html">
													<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
													<span class="menu-title">Invoice 3</span>
												</a>
											</div>
										</div>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/invoices/create.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Create Invoice</span>
										</a>
									</div>
								</div>
							</div>
							<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
								<span class="menu-link">
									<span class="menu-bullet">
										<span class="bullet bullet-dot"></span>
									</span>
									<span class="menu-title">Inbox</span>
									<span class="menu-arrow"></span>
								</span>
								<div class="menu-sub menu-sub-accordion">
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/inbox/listing.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Messages</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/inbox/compose.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Compose</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/inbox/reply.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">View &amp; Reply</span>
										</a>
									</div>
								</div>
							</div>
							<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
								<span class="menu-link">
									<span class="menu-bullet">
										<span class="bullet bullet-dot"></span>
									</span>
									<span class="menu-title">Chat</span>
									<span class="menu-arrow"></span>
								</span>
								<div class="menu-sub menu-sub-accordion">
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/chat/private.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Private Chat</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/chat/group.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Group Chat</span>
										</a>
									</div>
									<div class="menu-item">
										<a class="menu-link" href="../../demo3/dist/apps/chat/drawer.html">
											<span class="menu-bullet">
												<span class="bullet bullet-dot"></span>
											</span>
											<span class="menu-title">Drawer Chat</span>
										</a>
									</div>
								</div>
							</div>
							<div class="menu-item">
								<a class="menu-link" href="../../demo3/dist/apps/calendar.html">
									<span class="menu-bullet">
										<span class="bullet bullet-dot"></span>
									</span>
									<span class="menu-title">Calendar</span>
								</a>
							</div>
						</div>
					</div>
					<div class="menu-item">
						<a class="menu-link" href="https://preview.keenthemes.com/metronic8/demo3/layout-builder.html">
							<span class="menu-icon">
								<!--begin::Svg Icon | path: icons/duotune/arrows/arr001.svg-->
								<span class="svg-icon svg-icon-5">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
										<path d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z" fill="black" />
										<path opacity="0.3" d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z" fill="black" />
									</svg>
								</span>
								<!--end::Svg Icon-->
							</span>
							<span class="menu-title">Layout Builder</span>
						</a>
					</div>
					<div class="menu-item">
						<a class="menu-link" href="../../demo3/dist/documentation/base/utilities.html">
							<span class="menu-icon">
								<!--begin::Svg Icon | path: icons/duotune/arrows/arr001.svg-->
								<span class="svg-icon svg-icon-5">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
										<path d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z" fill="black" />
										<path opacity="0.3" d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z" fill="black" />
									</svg>
								</span>
								<!--end::Svg Icon-->
							</span>
							<span class="menu-title">Components</span>
						</a>
					</div>
					<div class="menu-item">
						<a class="menu-link" href="../../demo3/dist/documentation/getting-started.html">
							<span class="menu-icon">
								<!--begin::Svg Icon | path: icons/duotune/arrows/arr001.svg-->
								<span class="svg-icon svg-icon-5">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
										<path d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z" fill="black" />
										<path opacity="0.3" d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z" fill="black" />
									</svg>
								</span>
								<!--end::Svg Icon-->
							</span>
							<span class="menu-title">Documentation</span>
						</a>
					</div>
					<div class="menu-item">
						<a class="menu-link" href="../../demo3/dist/documentation/getting-started/changelog.html">
							<span class="menu-icon">
								<!--begin::Svg Icon | path: icons/duotune/arrows/arr001.svg-->
								<span class="svg-icon svg-icon-5">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
										<path d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z" fill="black" />
										<path opacity="0.3" d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z" fill="black" />
									</svg>
								</span>
								<!--end::Svg Icon-->
							</span>
							<span class="menu-title">Changelog v8.0.35</span>
						</a>
					</div>
				</div>
				<!--end::Menu-->
			</div>
			<!--end::Aside Menu-->
		</div>
		<!--end::Aside menu-->
		<!--begin::Footer-->
					<div class="aside-footer flex-column-auto px-9" id="kt_aside_footer">
						<!--begin::User panel-->
						<div class="d-flex flex-stack">
							<!--begin::Wrapper-->
							<div class="d-flex align-items-center">
								<!--begin::Avatar-->
								<div class="symbol symbol-circle symbol-40px"> 
									<div class="symbol-label fs-2 fw-bold bg-dark text-white tx-bolder " >{{GET_INITIAL(user.surname+ ' '+user.first_name).toUpperCase()}}</div>
								</div>
								
								<!--end::Avatar-->
								<!--begin::User info-->
								<div class="ms-2">
									<!--begin::Name-->
									<router-link to="/dashboard" class="text-gray-800 text-hover-primary fs-6 fw-bolder lh-1">{{TRUNCATE_STRING(CAPITALIZE_STR(user.surname +' '+user.first_name), 12)}}</router-link>
									<!--end::Name-->
									<!--begin::Major-->
									<span class="text-muted fw-bold d-block fs-7 lh-1">Python Dev</span>
									<!--end::Major-->
								</div>
								<!--end::User info-->
							</div>
							<!--end::Wrapper-->
							<!--begin::User menu-->
							<div class="ms-1">
								<div class="btn btn-sm btn-icon btn-active-color-primary position-relative me-n2" data-kt-menu-trigger="click" data-kt-menu-overflow="true" data-kt-menu-placement="top-end">
									<!--begin::Svg Icon | path: icons/duotune/coding/cod001.svg-->
									<span class="svg-icon svg-icon-1">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="black" />
											<path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="black" />
										</svg>
									</span>
									<!--end::Svg Icon-->
								</div>
								<!--begin::User account menu-->
								<div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px" data-kt-menu="true">
									<!--begin::Menu item-->
									<div class="menu-item px-3">
										<div class="menu-content d-flex align-items-center px-3">
											<!--begin::Avatar-->
											<div class="symbol symbol-50px me-5">
												<img alt="Logo" src="assets/media/avatars/300-1.jpg" />
											</div>
											<!--end::Avatar-->
											<!--begin::Username-->
											<div class="d-flex flex-column">
												<div class="fw-bolder d-flex align-items-center fs-5">Max Smith
												<span class="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">Pro</span></div>
												<a href="#" class="fw-bold text-muted text-hover-primary fs-7">max@kt.com</a>
											</div>
											<!--end::Username-->
										</div>
									</div>
									<!--end::Menu item-->
									<!--begin::Menu separator-->
									<div class="separator my-2"></div>
									<!--end::Menu separator-->
									<!--begin::Menu item-->
									<div class="menu-item px-5">
										<a href="../../demo3/dist/account/overview.html" class="menu-link px-5">My Profile</a>
									</div>
									<!--end::Menu item-->
									<!--begin::Menu item-->
									<div class="menu-item px-5">
										<a href="../../demo3/dist/apps/projects/list.html" class="menu-link px-5">
											<span class="menu-text">My Projects</span>
											<span class="menu-badge">
												<span class="badge badge-light-danger badge-circle fw-bolder fs-7">3</span>
											</span>
										</a>
									</div>
									<!--end::Menu item-->
									<!--begin::Menu item-->
									<div class="menu-item px-5" data-kt-menu-trigger="hover" data-kt-menu-placement="right-end">
										<a href="#" class="menu-link px-5">
											<span class="menu-title">My Subscription</span>
											<span class="menu-arrow"></span>
										</a>
										<!--begin::Menu sub-->
										<div class="menu-sub menu-sub-dropdown w-175px py-4">
											<!--begin::Menu item-->
											<div class="menu-item px-3">
												<a href="../../demo3/dist/account/referrals.html" class="menu-link px-5">Referrals</a>
											</div>
											<!--end::Menu item-->
											<!--begin::Menu item-->
											<div class="menu-item px-3">
												<a href="../../demo3/dist/account/billing.html" class="menu-link px-5">Billing</a>
											</div>
											<!--end::Menu item-->
											<!--begin::Menu item-->
											<div class="menu-item px-3">
												<a href="../../demo3/dist/account/statements.html" class="menu-link px-5">Payments</a>
											</div>
											<!--end::Menu item-->
											<!--begin::Menu item-->
											<div class="menu-item px-3">
												<a href="../../demo3/dist/account/statements.html" class="menu-link d-flex flex-stack px-5">Statements
												<i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="View your statements"></i></a>
											</div>
											<!--end::Menu item-->
											<!--begin::Menu separator-->
											<div class="separator my-2"></div>
											<!--end::Menu separator-->
											<!--begin::Menu item-->
											<div class="menu-item px-3">
												<div class="menu-content px-3">
													<label class="form-check form-switch form-check-custom form-check-solid">
														<input class="form-check-input w-30px h-20px" type="checkbox" value="1" checked="checked" name="notifications" />
														<span class="form-check-label text-muted fs-7">Notifications</span>
													</label>
												</div>
											</div>
											<!--end::Menu item-->
										</div>
										<!--end::Menu sub-->
									</div>
									<!--end::Menu item-->
									<!--begin::Menu item-->
									<div class="menu-item px-5">
										<a href="../../demo3/dist/account/statements.html" class="menu-link px-5">My Statements</a>
									</div>
									<!--end::Menu item-->
									<!--begin::Menu separator-->
									<div class="separator my-2"></div>
									<!--end::Menu separator-->
									<!--begin::Menu item-->
									<div class="menu-item px-5" data-kt-menu-trigger="hover" data-kt-menu-placement="right-end">
										<a href="#" class="menu-link px-5">
											<span class="menu-title position-relative">Language
											<span class="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">English
											<img class="w-15px h-15px rounded-1 ms-2" src="assets/media/flags/united-states.svg" alt="" /></span></span>
										</a>
										<!--begin::Menu sub-->
										<div class="menu-sub menu-sub-dropdown w-175px py-4">
											<!--begin::Menu item-->
											<div class="menu-item px-3">
												<a href="../../demo3/dist/account/settings.html" class="menu-link d-flex px-5 active">
												<span class="symbol symbol-20px me-4">
													<img class="rounded-1" src="assets/media/flags/united-states.svg" alt="" />
												</span>English</a>
											</div>
											<!--end::Menu item-->
											<!--begin::Menu item-->
											<div class="menu-item px-3">
												<a href="../../demo3/dist/account/settings.html" class="menu-link d-flex px-5">
												<span class="symbol symbol-20px me-4">
													<img class="rounded-1" src="assets/media/flags/spain.svg" alt="" />
												</span>Spanish</a>
											</div>
											<!--end::Menu item-->
											<!--begin::Menu item-->
											<div class="menu-item px-3">
												<a href="../../demo3/dist/account/settings.html" class="menu-link d-flex px-5">
												<span class="symbol symbol-20px me-4">
													<img class="rounded-1" src="assets/media/flags/germany.svg" alt="" />
												</span>German</a>
											</div>
											<!--end::Menu item-->
											<!--begin::Menu item-->
											<div class="menu-item px-3">
												<a href="../../demo3/dist/account/settings.html" class="menu-link d-flex px-5">
												<span class="symbol symbol-20px me-4">
													<img class="rounded-1" src="assets/media/flags/japan.svg" alt="" />
												</span>Japanese</a>
											</div>
											<!--end::Menu item-->
											<!--begin::Menu item-->
											<div class="menu-item px-3">
												<a href="../../demo3/dist/account/settings.html" class="menu-link d-flex px-5">
												<span class="symbol symbol-20px me-4">
													<img class="rounded-1" src="assets/media/flags/france.svg" alt="" />
												</span>French</a>
											</div>
											<!--end::Menu item-->
										</div>
										<!--end::Menu sub-->
									</div>
									<!--end::Menu item-->
									<!--begin::Menu item-->
									<div class="menu-item px-5 my-1">
										<a href="../../demo3/dist/account/settings.html" class="menu-link px-5">Account Settings</a>
									</div>
									<!--end::Menu item-->
									<!--begin::Menu item-->
									<div class="menu-item px-5">
										<a href="../../demo3/dist/authentication/flows/basic/sign-in.html" class="menu-link px-5">Sign Out</a>
									</div>
									<!--end::Menu item-->
									<!--begin::Menu separator-->
									<div class="separator my-2"></div>
									<!--end::Menu separator-->
									<!--begin::Menu item-->
									<div class="menu-item px-5">
										<div class="menu-content px-5">
											<label class="form-check form-switch form-check-custom form-check-solid pulse pulse-success" for="kt_user_menu_dark_mode_toggle">
												<input class="form-check-input w-30px h-20px" type="checkbox" value="1" name="mode" id="kt_user_menu_dark_mode_toggle" data-kt-url="../../demo3/dist/index.html" />
												<span class="pulse-ring ms-n1"></span>
												<span class="form-check-label text-gray-600 fs-7">Dark Mode</span>
											</label>
										</div>
									</div>
									<!--end::Menu item-->
								</div>
								<!--end::User account menu-->
							</div>
							<!--end::User menu-->
						</div>
						<!--end::User panel-->
					</div>
					<!--end::Footer-->
	</div>
</template>

<script> 
import { APP_INFO, GET_INITIAL, CAPITALIZE_STR, TRUNCATE_STRING } from "@/utils/"; 
import { PARSE_AUTH } from "@/utils/security/";  
export default {
    name: "Side_Navigation", 
    data() {
		return{  
		APP_INFO, TRUNCATE_STRING, PARSE_AUTH, GET_INITIAL, CAPITALIZE_STR,
		user: PARSE_AUTH(this.$store.state.auth, "user"),
		}
    },
}
 
</script>