<template>
<div class="d-flex flex-center flex-column flex-column-fluid">
    <!--begin::Wrapper-->
    <div class="w-lg-900px p-10 p-lg-5 mx-auto">
        <!--begin::Form-->
        <div class="my-auto pb-5 fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate" id="kt_create_account_form">
            
            <!--begin::Step 1-->
            <div class="current" data-kt-stepper-element="content">
                <!--begin::Wrapper-->
                <div class="w-100">
                    <!--begin::Heading-->
                    <div class="pb-10 pb-lg-15">
                        <!--begin::Title-->
                        <h2 class="fw-bolder d-flex align-items-center text-dark">Profiling Completed <i class="fas fa-check-circle ms-2 fs-1 text-primary"></i></h2>
                        <!--end::Title-->
                        <!--begin::Notice-->
                        <div class="text-muted fw-bold fs-7 fs-md-6 mt-4">Your account is yet to be approved by your organization.<br/>
                        if you wish to update your info <a href="javascript:void(0)" @click="this.$emit('action', {stage: ONBOARDING_STATUS.bio, processing: false})" class="link-primary fw-bolder">click here</a>.</div>
                        <!--end::Notice-->
                       <!-- <div class="d-flex justify-content-start mt-4">
                           <button @click="SEND_REMINDER()" type="button" class="btn btn-light-primary btn-sm shadow-lite rounded-0 animated fadeIn">Send reminder <i class="mdi mdi-bell-alert"></i></button> 
                        </div> -->
                    </div> 
                </div>
                <!--end::Wrapper-->
            </div>
            <!--end::Step 1--> 
        </div>
        <!--end::Form-->
    </div>
    <!--end::Wrapper-->
</div>
</template>

<script>
// @ is an alias to /src     
import AUTH_SERVICE  from "@/services/auth.js"; 
import { ONBOARDING_STATUS } from "@/utils/" 
import { ENCRYPT, DECRYPT } from "@/utils/security/"; 
export default {
name: "self-service-completed",
props: ['user'], 
data() {
    return{  
        ONBOARDING_STATUS,  
    } 
}, 
methods: { 
 
    SEND_REMINDER: function(){ 

        this.$emit('action', {stage: ONBOARDING_STATUS.completed, processing: true})
        let payload = ENCRYPT({"id": this.user.id, "token": DECRYPT(this.token) })  

        AUTH_SERVICE.SELF_REGISTRATION_REMINDER({payload})
        .then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION   
            this.$emit('action', {stage: ONBOARDING_STATUS.completed, processing: false})
            this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg })  
            
        })
        .catch(() => {  
            this.$emit('action', {stage: ONBOARDING_STATUS.completed, processing: false})
            this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" })  
        })
    }
},
mounted() { 
},

};
</script>