<template>
<div> 
        <div class="row gy-5 g-xl-4 mb-5 d-none">
            <!--begin::Col  d-sm-flex-->
            <div class="col-6 col-md-3">
                <!--begin::Mixed Widget 13-->
                <div class="card card-stretch mb-xl-10" style="background-color: #F7D9E3">
                    <!--begin::Body-->
                    <div class="card-body d-flex flex-column">
                        <!--begin::Wrapper-->
                        <div class="d-flex flex-column flex-grow-1">
                            <!--begin::Title-->
                            <span class="text-dark text-hover-primary fw-bolder fs-3">Budget</span>
                            <!--end::Title-->
                            <!--begin::Chart
                            <div class="mixed-widget-13-chart" style="height: 100px"></div>
                            end::Chart-->
                        </div>
                        <!--end::Wrapper-->
                        <!--begin::Stats-->
                        <div class="pt-5"> 
                            <!--begin::Symbol-->
                            <span class="text-dark fw-bolder fs-3x lh-0">$0</span><br/>
                            <!--end::Number-->
                            <!--begin::Text-->
                            <span class="text-dark fw-boldest fs-4">Total expenditure</span>
                            <!--end::Text-->
                        </div>
                        <!--end::Stats-->
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Mixed Widget 13-->
            </div>
            <!--end::Col-->
            <!--begin::Col-->
            <div class="col-6 col-md-3">
                <!--begin::Mixed Widget 14--> 
                    <!--begin::Card body-->  
                <!--begin::Card widget 7-->
                <div class="card card-flush card-stretch mb-xl-10 shadow-sm" v-if="CHECK_BOOLEAN(subsidiary.main_subsidiary)">
                    <!--begin::Header-->
                    <div class="card-header pt-5">
                        <!--begin::Title-->
                        <div class="card-title d-flex flex-column">
                            <!--begin::Amount-->
                            <span class="fs-1hx fw-bolder text-dark me-2 lh-1">Subsidiaries</span>
                            <!--end::Amount-->
                            <!--begin::Subtitle-->
                            <span class="text-gray-900 pt-1 fw-bold fs-8">related organizations.</span>
                            <!--end::Subtitle-->
                        </div>
                        <!--end::Title-->
                    </div>
                    <!--end::Header-->
                    <!--begin::Card body-->
                    <div class="card-body d-flex flex-column justify-content-end pe-0">
                        <!--begin::Title-->
                        <span class="fs-6 fw-boldest text-gray-800 d-block mb-2">{{subsidiaries.length}} available</span>
                        <!--end::Title-->
                        <!--begin::Users group-->
                        <div class="symbol-group symbol-hover flex-nowrap">
                            <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Alan Warden">
                                <span class="symbol-label bg-warning text-inverse-warning fw-bolder">A</span>
                            </div> 
                            <a :href="'/employees/o?ref='+ref" class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="View all">
                                <span class="symbol-label bg-white"><i class="text-gray-700 fs-3 mdi mdi-arrow-right"></i></span>
                            </a>
                        </div>
                        <!--end::Users group-->
                    </div>
                    <!--end::Card body-->
                </div>
                <!--end::Card widget 7-->
                <!--end::Mixed Widget 14-->
            </div>
            <!--end::Col-->
            <!--begin::Col-->
            <div class="col-6 col-md-3">
                <!--begin::Mixed Widget 14--> 
                    <!--begin::Card body-->  
                <!--begin::Card widget 7-->
                <div class="card card-flush card-stretch mb-xl-10 shadow-sm">
                    <!--begin::Header-->
                    <div class="card-header pt-5">
                        <!--begin::Title-->
                        <div class="card-title d-flex flex-column">
                            <!--begin::Amount-->
                            <span class="fs-1hx fw-bolder text-dark me-2 lh-1">Workforce</span>
                            <!--end::Amount-->
                            <!--begin::Subtitle-->
                            <span class="text-gray-900 pt-1 fw-bold fs-8">all employees.</span>
                            <!--end::Subtitle-->
                        </div>
                        <!--end::Title-->
                    </div>
                    <!--end::Header-->
                    <!--begin::Card body-->
                    <div class="card-body d-flex flex-column justify-content-end pe-0">
                        <!--begin::Title-->
                        <span class="fs-6 fw-boldest text-gray-800 d-block mb-2">55 available</span>
                        <!--end::Title-->
                        <!--begin::Users group-->
                        <div class="symbol-group symbol-hover flex-nowrap">
                            <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Alan Warden">
                                <span class="symbol-label bg-warning text-inverse-warning fw-bolder">A</span>
                            </div> 
                            <a :href="'/employees/o?ref='+ref" class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="View all">
                                <span class="symbol-label bg-white"><i class="text-gray-700 fs-3 mdi mdi-account-multiple"></i></span>
                            </a>
                        </div>
                        <!--end::Users group-->
                    </div>
                    <!--end::Card body-->
                </div>
                <!--end::Card widget 7-->
                <!--end::Mixed Widget 14-->
            </div>
            <!--end::Col-->
            <!--begin::Col-->
            <div class="col-6 col-md-3">
                <!--begin::Card widget 10-->
                                    <div class="card card-flush card-stretch mb-xl-10 shadow-sm">
                                        <!--begin::Header-->
                                        <div class="card-header pt-5">
                                            <!--begin::Title-->
                                            <div class="card-title d-flex flex-column">
                                                <!--begin::Amount-->
                                                <span class="fs-1hx fw-bolder text-dark me-2 lh-1">Utility</span>
                                                <!--end::Amount-->
                                                <!--begin::Subtitle-->
                                                <span class="text-gray-900 pt-1 fw-bold fs-9">Expected Earnings This Month</span>
                                                <!--end::Subtitle-->
                                            </div>
                                            <!--end::Title-->
                                        </div>
                                        <!--end::Header-->
                                        <!--begin::Card body-->
                                        <div class="card-body d-flex align-items-end pt-0">
                                            <!--begin::Wrapper-->
                                            <div class="d-flex align-items-center flex-wrap"> 
                                                <!--begin::Labels--> 
                                                <div class="d-flex flex-column content-justify-center flex-grow-1">
                                                    <!--begin::Label--> 
                                                    <a class="d-flex fs-6 fw-bold align-items-center">
                                                        <!--begin::Bullet-->
                                                        <div class="bullet w-8px h-6px rounded-2 bg-success me-3"></div>
                                                        <!--end::Bullet-->
                                                        <!--begin::Label-->
                                                        <div class="fs-8 text-gray-400 flex-shrink-0">Departments</div>
                                                        <!--end::Label-->
                                                        <!--begin::Separator-->
                                                        <div class="separator separator-dashed min-w-150px min-w-md-30px flex-grow-1 mx-2"></div>
                                                        <!--end::Separator-->
                                                        <!--begin::Stats-->
                                                        <div class="ms-auto fw-boldest text-gray-700 text-end">1,000</div>
                                                        <!--end::Stats-->
                                                    </a>
                                                    <!--end::Label-->
                                                    <!--begin::Label-->
                                                    <a :href="'/loactions/o?ref='+ref" class="d-flex fs-6 fw-bold align-items-center my-1">
                                                        <!--begin::Bullet-->
                                                        <div class="bullet w-8px h-6px rounded-2 bg-primary me-3"></div>
                                                        <!--end::Bullet-->
                                                        <!--begin::Label-->
                                                        <div class="fs-8 text-gray-400 flex-shrink-0">Locations</div>
                                                        <!--end::Label-->
                                                        <!--begin::Separator-->
                                                        <div class="separator separator-dashed min-w-150px min-w-md-30px flex-grow-1 mx-2"></div>
                                                        <!--end::Separator-->
                                                        <!--begin::Stats-->
                                                        <div class="ms-auto fw-boldest text-gray-700 text-end">21%</div>
                                                        <!--end::Stats-->
                                                    </a>
                                                    <!--end::Label-->
                                                    <!--begin::Label-->
                                                    <a :href="'/events/o?ref='+ref" class="d-flex fs-6 fw-bold align-items-center my-1">
                                                        <!--begin::Bullet-->
                                                        <div class="bullet w-8px h-6px rounded-2 bg-warning me-3"></div>
                                                        <!--end::Bullet-->
                                                        <!--begin::Label-->
                                                        <div class="fs-8 text-gray-400 flex-shrink-0">Events</div>
                                                        <!--end::Label-->
                                                        <!--begin::Separator-->
                                                        <div class="separator separator-dashed min-w-150px min-w-md-30px flex-grow-1 mx-2"></div>
                                                        <!--end::Separator-->
                                                        <!--begin::Stats-->
                                                        <div class="ms-auto fw-boldest text-gray-700 text-end">21%</div>
                                                        <!--end::Stats-->
                                                    </a>
                                                    <!--end::Label--> 
                                                </div>
                                                <!--end::Labels-->
                                            </div>
                                            <!--end::Wrapper-->
                                        </div>
                                        <!--end::Card body-->
                                    </div>
                                    <!--end::Card widget 10-->
            </div>
            <!--end::Col-->
        </div>
        <!--end::Row-->   

        <div class="card mb-5 mb-xl-8" v-if="GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.MAIN_ADMINISTRATOR.clearance_level, ACCESS_PRIVELEDGES.PASS)">
            <!--begin::Body-->
            <div class="card-body pb-0">
                <!--begin::Header-->
                <div class="d-flex align-items-center">
                    <!--begin::User-->
                    <div class="d-flex align-items-center flex-grow-1">
                        <!--begin::Avatar-->
                        <div class="symbol symbol-45px me-5 ">
                            <img :src="auth.photo" alt="photo" class="rounded-circle">
                        </div>
                        <!--end::Avatar-->
                        <!--begin::Info-->
                        <div class="d-flex flex-column">
                            <span class="text-gray-900 text-hover-primary fs-6 fw-bolder">{{auth.surname +' '+ auth.first_name}}</span>
                            <span class="text-gray-400 fs-7 fw-bold">Make a Broadcast</span>
                        </div>
                        <!--end::Info-->
                    </div>
                    <!--end::User--> 
                </div>
                <!--end::Header--> 
 
                <form class="position-relative mt-5">
					<textarea class="form-control border-0 p-0 resize-none h-50px fs-6 fw-normal" rows="10" placeholder="Make a post.." v-model="new_broadcast.content" style="overflow: hidden; overflow-wrap: break-word;"></textarea>
				</form>
                <button class="btn btn-sm btn-primary mb-3" @click="MAKE_POST()">send</button>
            </div>
            <!--end::Body-->
        </div> 
            <!--end::Container-->  


        <div class="card mb-5 mb-xxl-5" v-for="(cast, i) in broadcasts" :key="i">
            <!--begin::Body-->
            <div class="card-body pb-0">
                <!--begin::Header-->
                <div class="d-flex align-items-center mb-5">
                    <!--begin::User-->
                    <div class="d-flex align-items-center flex-grow-1">
                        <!--begin::Avatar-->
                        <div class="symbol symbol-45px me-5">
                            <img :src="cast.user.photo" class="rounded-circle" alt="photo">
                        </div>
                        <!--end::Avatar-->
                        <!--begin::Info-->
                        <div class="d-flex flex-column">
                            <a href="#" class="text-gray-900 text-hover-primary fs-6 fw-bolder">{{CAPITALIZE_STR(cast.user.surname) +' '+CAPITALIZE_STR(cast.user.first_name)}}</a>
                            <span class="text-gray-400 fw-bold fs-8">{{READABLE_DATE(cast.created_at)}}</span>
                        </div>
                        <!--end::Info-->
                    </div>
                    <!--end::User--> 
                </div>
                <!--end::Header-->
                <!--begin::Post-->
                <div class="mb-5">
                    <!--begin::Text-->
                    <p class="text-gray-800 fw-normal mb-5">
                        {{cast.content}}
                    </p>
                    <!--end::Text--> 
                </div>
                <!--end::Post-->
 
            </div>
            <!--end::Body-->
        </div>







</div> 
</template>

<script>
// @ is an alias to /src 
import PORTFOLIO_SERVICE  from "@/services/portfolio.js";  




import { PORTFOLIO_URLS } from "@/router/urls";
  
//TABS COMPONENT    
import { IS_EMPTY, APP_INFO, REMOVE_CHARC, GRANT_ACCESS, ADMINISTRATIVE_CLASS, ACCESS_PRIVELEDGES, RESPONSE_STATUS, STATUS_TAG_COLOR, CAPITALIZE_STR, CHECK_BOOLEAN, CHECK_PLURAL, READABLE_DATE, TRUNCATE_STRING} from "@/utils/";  
import { PARSE_AUTH, ENCRYPT, DECRYPT } from "@/utils/security/";  
// import { QuillEditor } from '@vueup/vue-quill'
// import '@vueup/vue-quill/dist/vue-quill.snow.css';
export default {
name: "Organization-Dashboard",
props: ["subsidiary"],
data() {
    return{ 
		auth: PARSE_AUTH(this.$store.state.auth, "user"),
        // Theme: this.$store.state.currentTheme, 
            // notifications: this.$store.getters.notifications, 
        PORTFOLIO_URLS,
        editModal: false,  
        admins: [], 
        administrator: '', 
        subsidiaries: [],
        // subsidiary: '',
        employees: [],
        offices: [],  
        events: [], 
        priviledges: [],  
        admin_classes: [],
        adClassDesc: '',
        HQ: '', 
        new_broadcast: { 
            "users_id": PARSE_AUTH(this.$store.state.auth, "user").id,
            "subsidiary_id": this.subsidiary.id,
            "content": '',
            "subject": ''
        },
        broadcasts: [],
        page: 1, 
        total: null,   
        nextPage: 0,
        remItem: 0, 
        
        // comment_page: 1, 
        // comment_total: null,   
        // comment_nextPage: 0,
        // comment_remItem: 0, 
        CHECK_PLURAL, ENCRYPT, DECRYPT, IS_EMPTY, PARSE_AUTH, GRANT_ACCESS, ADMINISTRATIVE_CLASS, ACCESS_PRIVELEDGES, APP_INFO, REMOVE_CHARC, STATUS_TAG_COLOR, CAPITALIZE_STR, CHECK_BOOLEAN, READABLE_DATE, TRUNCATE_STRING, 
        ref: this.$route.query.ref, // truncate ref url data to get user id 
    }
},
components: { 
    // Notification, SideNav, TopNav /*EVENTSCOMPONENT, ACTIVITYCOMPONENT /* CPlaceholder */
},  
methods: {  

        // LOAD_COMMENT: function(id){    
        //     let payload = ENCRYPT({'id': id, 'page':this.comment_page})
        //     PORTFOLIO_SERVICE.LOAD_BROADCASTS({payload}) 
        //     .then(res =>{ // TRY USER LOGIN PROCESS    
        //         !res.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": res.data.success, "msg": res.data.msg }) : null;
        //         if(res.data.success){ 
        //             let resp = DECRYPT(res.data.data);
        //             this.comment_nextPage = resp.nextPage;
        //             this.comment_remItem =  resp.rem;
        //             this.comment_page = resp.curPage;  
        //             this.comment_totalSubs = resp.total;  
        //             this.comment_broadcasts = resp.items;  
        //         } 
        //         if(res.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 2500);
        //     }) 
        //     .catch(() => {  
        //         this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading page contents, reload page" }); 
        //     })   
        // },
          
        LOAD_BROADCASTS: function(){    
            let payload = ENCRYPT({'id': DECRYPT(this.ref), 'page':this.page})
            PORTFOLIO_SERVICE.LOAD_BROADCASTS({payload}) 
            .then(res =>{ // TRY USER LOGIN PROCESS    
                !res.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": res.data.success, "msg": res.data.msg }) : null;
                if(res.data.success){ 
                    let resp = DECRYPT(res.data.data);
                    this.nextPage = resp.nextPage;
                    this.remItem =  resp.rem;
                    this.page = resp.curPage;  
                    this.totalSubs = resp.total;  
                    this.broadcasts = resp.items;  
                } 
                if(res.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 2500);
            }) 
            .catch(() => {  
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading page contents, reload page" }); 
            })   
        },

        MAKE_POST: function(){   
            let payload = ENCRYPT(this.new_broadcast)
            PORTFOLIO_SERVICE.MAKE_BROADCAST({payload}) 
            .then(res =>{ // TRY USER LOGIN PROCESS    
                this.$store.commit('PAGE_NOTIFICATION', {"success": res.data.success, "msg": res.data.msg });
                if(res.data.success) this.new_broadcast = ''; 
                if(res.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 2500);
            }) 
            .catch(() => {  
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading page contents, reload page" }); 
            })   
        },

        LOAD_COMPONENT: function(tab){ 
            this.$store.commit('PAGE_NOTIFICATION', null) 
            this.active_tab = tab;
        },

        GET_HEAD_OFFICE: function(){  
            this.HQ = this.subsidiary.offices.find(element => CHECK_BOOLEAN(element.head_office) == true) 
        }

}, 
mounted() {  this.LOAD_BROADCASTS() }, 
};
</script>
