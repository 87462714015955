<template>
<div class="animated fadeIn">
 
  
<div class="card-body">
        <!--begin::Stats-->
        <div class="row">
            <!--begin::Stat-->
            <div class="col-sm-3 col-md-2 shadow-sm bg-white rounded min-w-125px py-3 px-2 me-0 me-sm-6 mb-3">
                <!--begin::Number-->
                <router-link to="/organizations" class="d-flex align-items-center"> 
                    <div class="fs-2 fw-bolder counted text-black" data-kt-countup="true" data-kt-countup-value="4500" data-kt-countup-prefix="$">20</div>
                </router-link>
                <!--end::Number-->
                <!--begin::Label-->
                <div class="fw-bold fs-6 text-gray-400">New</div>
                <!--end::Label-->
            </div>
            <!--end::Stat-->
            <!--begin::Stat-->
            <div class="col-sm-3 col-md-2 shadow-sm bg-white rounded min-w-125px py-3 px-2 me-0 me-sm-6 mb-3">
                <!--begin::Number-->
                <div class="d-flex align-items-center"> 
                    <div class="fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-value="75">3</div>
                </div>
                <!--end::Number-->
                <!--begin::Label-->
                <div class="fw-bold fs-6 text-gray-400">Unattended</div>
                <!--end::Label-->
            </div>
            <!--end::Stat-->
            <!--begin::Stat-->
            <div class="col-sm-3 col-md-2 shadow-sm bg-white rounded min-w-125px py-3 px-2 me-0 me-sm-6 mb-3">
                <!--begin::Number-->
                <div class="d-flex align-items-center"> 
                    <div class="fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-value="75">3</div>
                </div>
                <!--end::Number-->
                <!--begin::Label-->
                <div class="fw-bold fs-6 text-gray-400">Proccessed</div>
                <!--end::Label-->
            </div>
            <!--end::Stat-->
            <!--begin::Stat-->
            <div class="col-sm-3 col-md-2 shadow-sm bg-white rounded min-w-125px py-3 px-2 me-0 me-sm-6 mb-3">
                <!--begin::Number-->
                <div class="d-flex align-items-center"> 
                    <div class="fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-value="60" data-kt-countup-prefix="%">0</div>
                </div>
                <!--end::Number-->
                <!--begin::Label-->
                <div class="fw-bold fs-6 text-gray-400">Cancelled</div>
                <!--end::Label-->
            </div>
            <!--end::Stat--> 
            <!--begin::Stat-->
            <div class="col-sm-3 col-md-2 shadow-sm bg-white rounded min-w-125px py-3 px-2 me-0 me-sm-6 mb-3">
                <!--begin::Number-->
                <div class="d-flex align-items-center"> 
                    <div class="fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-value="60" data-kt-countup-prefix="%">0</div>
                </div>
                <!--end::Number-->
                <!--begin::Label-->
                <div class="fw-bold fs-6 text-gray-400">Total</div>
                <!--end::Label-->
            </div>
            <!--end::Stat--> 
        </div>
        <!--end::Stats--> 
</div>


<div class="card mb-5 mb-xxl-10"> 
    <!--begin::Card header-->
    <div class="card-header">
        <!--begin::Card title-->
        <div class="card-title flex-column">
            <h5 class="mb-1">My tasks</h5>
            <div class="fs-6 text-muted">Total 25 tasks in backlog</div>
        </div>
        <!--end::Card title-->
        <!--begin::Card toolbar-->
        <div class="card-toolbar">
            <button type="button" class="btn btn-light-primary btn-sm" data-bs-toggle="modal" data-bs-target="#kt_modal_add_task">
            <!--begin::Svg Icon | path: icons/duotune/files/fil005.svg-->
            <span class="svg-icon svg-icon-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM16 13.5L12.5 13V10C12.5 9.4 12.6 9.5 12 9.5C11.4 9.5 11.5 9.4 11.5 10L11 13L8 13.5C7.4 13.5 7 13.4 7 14C7 14.6 7.4 14.5 8 14.5H11V18C11 18.6 11.4 19 12 19C12.6 19 12.5 18.6 12.5 18V14.5L16 14C16.6 14 17 14.6 17 14C17 13.4 16.6 13.5 16 13.5Z" fill="black"></path>
                    <rect x="11" y="19" width="10" height="2" rx="1" transform="rotate(-90 11 19)" fill="black"></rect>
                    <rect x="7" y="13" width="10" height="2" rx="1" fill="black"></rect>
                    <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black"></path>
                </svg>
            </span>
            <!--end::Svg Icon-->Add Task</button>
        </div>
        <!--end::Card toolbar-->
    </div>
    <!--end::Card header-->
    <!--begin::Card body-->
    <div class="card-body d-flex flex-column">
        <!--begin::Item-->
        <div class="d-flex align-items-center position-relative mb-7">
            <!--begin::Label-->
            <div class="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px"></div>
            <!--end::Label-->
            <!--begin::Details-->
            <div class="fw-bold ms-5">
                <a href="#" class="fs-5 fw-bolder text-dark text-hover-primary">Create FureStibe branding logo</a>
                <!--begin::Info-->
                <div class="fs-7 text-muted">Due in 1 day
                <a href="#">Karina Clark</a></div>
                <!--end::Info-->
            </div>
            <!--end::Details-->
            <!--begin::Menu-->
            <button type="button" class="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                <!--begin::Svg Icon | path: icons/duotune/general/gen019.svg-->
                <span class="svg-icon svg-icon-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z" fill="black"></path>
                        <path opacity="0.3" d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z" fill="black"></path>
                    </svg>
                </span>
                <!--end::Svg Icon-->
            </button>
            <!--begin::Task menu-->
            <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true" data-kt-menu-id="kt-users-tasks">
                <!--begin::Header-->
                <div class="px-7 py-5">
                    <div class="fs-5 text-dark fw-bolder">Update Status</div>
                </div>
                <!--end::Header-->
                <!--begin::Menu separator-->
                <div class="separator border-gray-200"></div>
                <!--end::Menu separator-->
                <!--begin::Form-->
                <form class="form px-7 py-5 fv-plugins-bootstrap5 fv-plugins-framework" data-kt-menu-id="kt-users-tasks-form">
                    <!--begin::Input group-->
                    <div class="fv-row mb-10 fv-plugins-icon-container">
                        <!--begin::Label-->
                        <label class="form-label fs-6 fw-bold">Status:</label>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <select class="form-select form-select-solid select2-hidden-accessible" name="task_status" data-kt-select2="true" data-placeholder="Select option" data-allow-clear="true" data-hide-search="true" data-select2-id="select2-data-7-osbu" tabindex="-1" aria-hidden="true">
                            <option data-select2-id="select2-data-9-tysx"></option>
                            <option value="1">Approved</option>
                            <option value="2">Pending</option>
                            <option value="3">In Process</option>
                            <option value="4">Rejected</option>
                        </select><span class="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-8-txuj" style="width: 100%;"><span class="selection"><span class="select2-selection select2-selection--single form-select form-select-solid" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-task_status-8z-container" aria-controls="select2-task_status-8z-container"><span class="select2-selection__rendered" id="select2-task_status-8z-container" role="textbox" aria-readonly="true" title="Select option"><span class="select2-selection__placeholder">Select option</span></span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
                        <!--end::Input-->
                    <div class="fv-plugins-message-container invalid-feedback"></div></div>
                    <!--end::Input group-->
                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                        <button type="button" class="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-users-update-task-status="reset">Reset</button>
                        <button type="submit" class="btn btn-sm btn-primary" data-kt-users-update-task-status="submit">
                            <span class="indicator-label">Apply</span>
                            <span class="indicator-progress">Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                        </button>
                    </div>
                    <!--end::Actions-->
                <div></div></form>
                <!--end::Form-->
            </div>
            <!--end::Task menu-->
            <!--end::Menu-->
        </div>
        <!--end::Item-->
        <!--begin::Item-->
        <div class="d-flex align-items-center position-relative mb-7">
            <!--begin::Label-->
            <div class="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px"></div>
            <!--end::Label-->
            <!--begin::Details-->
            <div class="fw-bold ms-5">
                <a href="#" class="fs-5 fw-bolder text-dark text-hover-primary">Schedule a meeting with FireBear CTO John</a>
                <!--begin::Info-->
                <div class="fs-7 text-muted">Due in 3 days
                <a href="#">Rober Doe</a></div>
                <!--end::Info-->
            </div>
            <!--end::Details-->
            <!--begin::Menu-->
            <button type="button" class="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                <!--begin::Svg Icon | path: icons/duotune/general/gen019.svg-->
                <span class="svg-icon svg-icon-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z" fill="black"></path>
                        <path opacity="0.3" d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z" fill="black"></path>
                    </svg>
                </span>
                <!--end::Svg Icon-->
            </button>
            <!--begin::Task menu-->
            <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true" data-kt-menu-id="kt-users-tasks">
                <!--begin::Header-->
                <div class="px-7 py-5">
                    <div class="fs-5 text-dark fw-bolder">Update Status</div>
                </div>
                <!--end::Header-->
                <!--begin::Menu separator-->
                <div class="separator border-gray-200"></div>
                <!--end::Menu separator-->
                <!--begin::Form-->
                <form class="form px-7 py-5 fv-plugins-bootstrap5 fv-plugins-framework" data-kt-menu-id="kt-users-tasks-form">
                    <!--begin::Input group-->
                    <div class="fv-row mb-10 fv-plugins-icon-container">
                        <!--begin::Label-->
                        <label class="form-label fs-6 fw-bold">Status:</label>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <select class="form-select form-select-solid select2-hidden-accessible" name="task_status" data-kt-select2="true" data-placeholder="Select option" data-allow-clear="true" data-hide-search="true" data-select2-id="select2-data-10-3som" tabindex="-1" aria-hidden="true">
                            <option data-select2-id="select2-data-12-trr2"></option>
                            <option value="1">Approved</option>
                            <option value="2">Pending</option>
                            <option value="3">In Process</option>
                            <option value="4">Rejected</option>
                        </select><span class="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-11-uwia" style="width: 100%;"><span class="selection"><span class="select2-selection select2-selection--single form-select form-select-solid" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-task_status-0e-container" aria-controls="select2-task_status-0e-container"><span class="select2-selection__rendered" id="select2-task_status-0e-container" role="textbox" aria-readonly="true" title="Select option"><span class="select2-selection__placeholder">Select option</span></span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
                        <!--end::Input-->
                    <div class="fv-plugins-message-container invalid-feedback"></div></div>
                    <!--end::Input group-->
                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                        <button type="button" class="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-users-update-task-status="reset">Reset</button>
                        <button type="submit" class="btn btn-sm btn-primary" data-kt-users-update-task-status="submit">
                            <span class="indicator-label">Apply</span>
                            <span class="indicator-progress">Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                        </button>
                    </div>
                    <!--end::Actions-->
                <div></div></form>
                <!--end::Form-->
            </div>
            <!--end::Task menu-->
            <!--end::Menu-->
        </div>
        <!--end::Item-->
        <!--begin::Item-->
        <div class="d-flex align-items-center position-relative mb-7">
            <!--begin::Label-->
            <div class="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px"></div>
            <!--end::Label-->
            <!--begin::Details-->
            <div class="fw-bold ms-5">
                <a href="#" class="fs-5 fw-bolder text-dark text-hover-primary">9 Degree Project Estimation</a>
                <!--begin::Info-->
                <div class="fs-7 text-muted">Due in 1 week
                <a href="#">Neil Owen</a></div>
                <!--end::Info-->
            </div>
            <!--end::Details-->
            <!--begin::Menu-->
            <button type="button" class="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                <!--begin::Svg Icon | path: icons/duotune/general/gen019.svg-->
                <span class="svg-icon svg-icon-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z" fill="black"></path>
                        <path opacity="0.3" d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z" fill="black"></path>
                    </svg>
                </span>
                <!--end::Svg Icon-->
            </button>
            <!--begin::Task menu-->
            <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true" data-kt-menu-id="kt-users-tasks">
                <!--begin::Header-->
                <div class="px-7 py-5">
                    <div class="fs-5 text-dark fw-bolder">Update Status</div>
                </div>
                <!--end::Header-->
                <!--begin::Menu separator-->
                <div class="separator border-gray-200"></div>
                <!--end::Menu separator-->
                <!--begin::Form-->
                <form class="form px-7 py-5 fv-plugins-bootstrap5 fv-plugins-framework" data-kt-menu-id="kt-users-tasks-form">
                    <!--begin::Input group-->
                    <div class="fv-row mb-10 fv-plugins-icon-container">
                        <!--begin::Label-->
                        <label class="form-label fs-6 fw-bold">Status:</label>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <select class="form-select form-select-solid select2-hidden-accessible" name="task_status" data-kt-select2="true" data-placeholder="Select option" data-allow-clear="true" data-hide-search="true" data-select2-id="select2-data-13-p3f8" tabindex="-1" aria-hidden="true">
                            <option data-select2-id="select2-data-15-v17g"></option>
                            <option value="1">Approved</option>
                            <option value="2">Pending</option>
                            <option value="3">In Process</option>
                            <option value="4">Rejected</option>
                        </select><span class="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-14-taj0" style="width: 100%;"><span class="selection"><span class="select2-selection select2-selection--single form-select form-select-solid" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-task_status-md-container" aria-controls="select2-task_status-md-container"><span class="select2-selection__rendered" id="select2-task_status-md-container" role="textbox" aria-readonly="true" title="Select option"><span class="select2-selection__placeholder">Select option</span></span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
                        <!--end::Input-->
                    <div class="fv-plugins-message-container invalid-feedback"></div></div>
                    <!--end::Input group-->
                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                        <button type="button" class="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-users-update-task-status="reset">Reset</button>
                        <button type="submit" class="btn btn-sm btn-primary" data-kt-users-update-task-status="submit">
                            <span class="indicator-label">Apply</span>
                            <span class="indicator-progress">Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                        </button>
                    </div>
                    <!--end::Actions-->
                <div></div></form>
                <!--end::Form-->
            </div>
            <!--end::Task menu-->
            <!--end::Menu-->
        </div>
        <!--end::Item-->
        <!--begin::Item-->
        <div class="d-flex align-items-center position-relative mb-7">
            <!--begin::Label-->
            <div class="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px"></div>
            <!--end::Label-->
            <!--begin::Details-->
            <div class="fw-bold ms-5">
                <a href="#" class="fs-5 fw-bolder text-dark text-hover-primary">Dashboard UI &amp; UX for Leafr CRM</a>
                <!--begin::Info-->
                <div class="fs-7 text-muted">Due in 1 week
                <a href="#">Olivia Wild</a></div>
                <!--end::Info-->
            </div>
            <!--end::Details-->
            <!--begin::Menu-->
            <button type="button" class="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                <!--begin::Svg Icon | path: icons/duotune/general/gen019.svg-->
                <span class="svg-icon svg-icon-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z" fill="black"></path>
                        <path opacity="0.3" d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z" fill="black"></path>
                    </svg>
                </span>
                <!--end::Svg Icon-->
            </button>
            <!--begin::Task menu-->
            <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true" data-kt-menu-id="kt-users-tasks">
                <!--begin::Header-->
                <div class="px-7 py-5">
                    <div class="fs-5 text-dark fw-bolder">Update Status</div>
                </div>
                <!--end::Header-->
                <!--begin::Menu separator-->
                <div class="separator border-gray-200"></div>
                <!--end::Menu separator-->
                <!--begin::Form-->
                <form class="form px-7 py-5 fv-plugins-bootstrap5 fv-plugins-framework" data-kt-menu-id="kt-users-tasks-form">
                    <!--begin::Input group-->
                    <div class="fv-row mb-10 fv-plugins-icon-container">
                        <!--begin::Label-->
                        <label class="form-label fs-6 fw-bold">Status:</label>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <select class="form-select form-select-solid select2-hidden-accessible" name="task_status" data-kt-select2="true" data-placeholder="Select option" data-allow-clear="true" data-hide-search="true" data-select2-id="select2-data-16-8cll" tabindex="-1" aria-hidden="true">
                            <option data-select2-id="select2-data-18-qomv"></option>
                            <option value="1">Approved</option>
                            <option value="2">Pending</option>
                            <option value="3">In Process</option>
                            <option value="4">Rejected</option>
                        </select><span class="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-17-dze5" style="width: 100%;"><span class="selection"><span class="select2-selection select2-selection--single form-select form-select-solid" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-task_status-xe-container" aria-controls="select2-task_status-xe-container"><span class="select2-selection__rendered" id="select2-task_status-xe-container" role="textbox" aria-readonly="true" title="Select option"><span class="select2-selection__placeholder">Select option</span></span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
                        <!--end::Input-->
                    <div class="fv-plugins-message-container invalid-feedback"></div></div>
                    <!--end::Input group-->
                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                        <button type="button" class="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-users-update-task-status="reset">Reset</button>
                        <button type="submit" class="btn btn-sm btn-primary" data-kt-users-update-task-status="submit">
                            <span class="indicator-label">Apply</span>
                            <span class="indicator-progress">Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                        </button>
                    </div>
                    <!--end::Actions-->
                <div></div></form>
                <!--end::Form-->
            </div>
            <!--end::Task menu-->
            <!--end::Menu-->
        </div>
        <!--end::Item-->
        <!--begin::Item-->
        <div class="d-flex align-items-center position-relative">
            <!--begin::Label-->
            <div class="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px"></div>
            <!--end::Label-->
            <!--begin::Details-->
            <div class="fw-bold ms-5">
                <a href="#" class="fs-5 fw-bolder text-dark text-hover-primary">Mivy App R&amp;D, Meeting with clients</a>
                <!--begin::Info-->
                <div class="fs-7 text-muted">Due in 2 weeks
                <a href="#">Sean Bean</a></div>
                <!--end::Info-->
            </div>
            <!--end::Details-->
            <!--begin::Menu-->
            <button type="button" class="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                <!--begin::Svg Icon | path: icons/duotune/general/gen019.svg-->
                <span class="svg-icon svg-icon-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z" fill="black"></path>
                        <path opacity="0.3" d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z" fill="black"></path>
                    </svg>
                </span>
                <!--end::Svg Icon-->
            </button>
            <!--begin::Task menu-->
            <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true" data-kt-menu-id="kt-users-tasks">
                <!--begin::Header-->
                <div class="px-7 py-5">
                    <div class="fs-5 text-dark fw-bolder">Update Status</div>
                </div>
                <!--end::Header-->
                <!--begin::Menu separator-->
                <div class="separator border-gray-200"></div>
                <!--end::Menu separator-->
                <!--begin::Form-->
                <form class="form px-7 py-5 fv-plugins-bootstrap5 fv-plugins-framework" data-kt-menu-id="kt-users-tasks-form">
                    <!--begin::Input group-->
                    <div class="fv-row mb-10 fv-plugins-icon-container">
                        <!--begin::Label-->
                        <label class="form-label fs-6 fw-bold">Status:</label>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <select class="form-select form-select-solid select2-hidden-accessible" name="task_status" data-kt-select2="true" data-placeholder="Select option" data-allow-clear="true" data-hide-search="true" data-select2-id="select2-data-19-z5a4" tabindex="-1" aria-hidden="true">
                            <option data-select2-id="select2-data-21-q6vd"></option>
                            <option value="1">Approved</option>
                            <option value="2">Pending</option>
                            <option value="3">In Process</option>
                            <option value="4">Rejected</option>
                        </select><span class="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-20-ify4" style="width: 100%;"><span class="selection"><span class="select2-selection select2-selection--single form-select form-select-solid" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-task_status-ik-container" aria-controls="select2-task_status-ik-container"><span class="select2-selection__rendered" id="select2-task_status-ik-container" role="textbox" aria-readonly="true" title="Select option"><span class="select2-selection__placeholder">Select option</span></span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
                        <!--end::Input-->
                    <div class="fv-plugins-message-container invalid-feedback"></div></div>
                    <!--end::Input group-->
                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                        <button type="button" class="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-users-update-task-status="reset">Reset</button>
                        <button type="submit" class="btn btn-sm btn-primary" data-kt-users-update-task-status="submit">
                            <span class="indicator-label">Apply</span>
                            <span class="indicator-progress">Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                        </button>
                    </div>
                    <!--end::Actions-->
                <div></div></form>
                <!--end::Form-->
            </div>
            <!--end::Task menu-->
            <!--end::Menu-->
        </div>
        <!--end::Item-->
    </div>
    <!--end::Card body-->
</div>
 
    
</div>
</template>

<script> 
//EMPLOYEE COMPONENT 
import { IS_EMPTY, CHECK_BOOLEAN, READABLE_DATE, TRUNCATE_STRING } from "@/utils/"; 
export default {
    name: "User overview", 
    props: ["user"],  
    data() {
        return{  
            IS_EMPTY: IS_EMPTY, 
            CHECK_BOOLEAN: CHECK_BOOLEAN,
            READABLE_DATE: READABLE_DATE,
            TRUNCATE_STRING: TRUNCATE_STRING, 
        }
    }, 
    components: {  },
    mounted() {   
 
        let extScript3 = document.createElement('script')
        extScript3.setAttribute('src', '/js/widgets.bundle.js') 
        document.head.appendChild(extScript3)  
        
        let extScript1 = document.createElement('script')
        extScript1.setAttribute('src', '/js/custom/widgets.js') 
        document.head.appendChild(extScript1)  
        


        
    } 
}
</script> 