<template>
<div class="card card-body h-400px mb-10 mb-md-15 justify-content-center align-items-center">
    <span class="shadow-lite w-100px text-center p-3 fw-bold text-muted">
        <span class="spinner-border spinner-border-sm align-middle"></span> Loading
    </span>
</div>
</template>

<script> 
import { APP_INFO } from "@/utils/"; 
import { PARSE_AUTH } from "@/utils/security/";  
export default {
    name: "blank-loader", 
    data() {
        return{ 
            APP_INFO, PARSE_AUTH, 
            user: PARSE_AUTH(this.$store.state.auth, "user") 
        }
    },
}
 
</script>