import API from './api.js';
  
export default {

     //CHIEF HR SERVER SERVICES  
     
     LOAD_CHIEF_STATS(data) { /**** LOAD STATS*****/ 
        return API('chiefHR').post('load_stats', data)
    },
      
    LOAD_CHIEF_DASHBOARD(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/   
        return API('chiefHR').post('load_dashboard', data)
    },
    LOAD_ORGANIZATIONS(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/   
        return API('chiefHR').post('load_orgs', data)
    },
    LOAD_ORGANIZATION(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/  
        return API('org').post('load_org', data)
    },
    ADD_NEW_ORGANIZATION(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/  
        return API('chiefHR').post('save_org', data)
    },
    LOAD_ORGANIZATIONS_SUBSIDIARIES(data) { /**** LOAD SUBSIDIARIES ATTACHED TO AN ORGANIZATION*****/  
        return API('org').post('load_subsidiaries', data)
    },
    LOAD_SUBSIDIARY(data) { /**** LOAD SUBSIDIARIES ATTACHED TO AN ORGANIZATION*****/   
        return API('org').post('load_subsidiary', data)
    },
    CONFIRM_SUBSIDIARY(data) { /**** CONFIRM A SUBSIDIARY USING AN ID*****/  
        return API('org').post('confirm_subsidiary', data)
    },
    SAVE_NEW_SUBSIDIARY(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/  
        return API('org').post('save_subsidiary', data)
    },
    UPLOAD_SUBSIDIARY_LOGO(ref, data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/   
        return API('org').post(`upload_subsidiary_logo/` + ref, data)
    },
    LOAD_EMPLOYEES(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('org').post('load_employees', data)
    },
    LOAD_EMPLOYEES_FILTER_STATUS(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('org').post('load_employees_filter_status', data)
    },
    LOAD_EMPLOYEE(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('org').post('load_employee', data)
    }, 
    LOAD_USER(data){
        return API('user').post('load_user', data)  
    },
    LOAD_CHIEF_HR_PORTFOLIO(data){
        return API('chiefHR').post('load_portfolio', data)  
    },
    LOAD_CHIEF_ACTIVITIES(data){
        return API('chiefHR').post('load_activities', data)  
    },
    
    UPDATE_ADMINISTRATOR(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/   
        return API('chiefHR').post('update_administrator', data)
    },

     
}
