<template>
<div>


        

        <ADDWORKEXPCOMPONENT class="animated fadeIn" :employee="user.employee" v-if="add_pane" v-on:action="CHILD_ACTION" />

        <EDITWORKEXPCOMPONENT class="animated fadeIn" :workExp="workExp" :employee="user.employee" v-if="edit_pane" v-on:action="CHILD_ACTION" />


        <CModal alignment="center" size="sm" :visible="delModal" @close="() => { delModal = false }"> 
            <div class="card card-body py-15">
                <span class="fs-6 text-center fw-bold">Are you sure you want to delete this record?</span>
                <div class="d-flex justify-content-between mt-5">
                    <button type="button" @click="() => { delModal = false }" class="btn btn-sm btn-light-primary">No abort</button>
                    <button type="button" @click="DELETE_WORKEXP_RECORD(workExp.id), delModal = false" class="btn btn-sm btn-light-danger">Yes continue</button>
                </div>
            </div>
        </CModal>
         
            <!--begin::Card title-->
            <div class="card-title d-flex justify-content-between my-5" v-if="!add_pane && !edit_pane">
                <span>
                    <div class="mb-1 fs-4 fw-bolder">{{user.surname}}'s work experience</div>
                    <div class="fs-9 text-muted">Total of {{work_exps.length}} record{{CHECK_PLURAL(work_exps.length)}}</div> 
                </span> 
                    <button class="btn btn-light-primary btn-sm rounded-1" @click="add_pane = true">Add record</button>
            </div>
            <!--end::Card title-->  

            <!--begin::Card body-->
            <div id="kt_customer_view_payment_method" class="pt-0" v-if="!add_pane && !edit_pane"> 
                <BLOCKLOADER ht="h-300px" v-if="loading"/> 
                <div v-else>
                    <!--begin::Option-->
                    <div class="py-0" data-kt-customer-payment-method="row" v-for="(work_exp, i) in work_exps" :key="i">
                        <!--begin::Header-->
                        <div class="py-3 d-flex flex-stack flex-wrap animated fadeIn">
                            <!--begin::Toggle-->
                            <div class="d-flex align-items-center collapsible rotate collapsed" data-bs-toggle="collapse" :href="`#kt_customer_view_payment_method_`+i" role="button" aria-expanded="false" :aria-controls="`kt_customer_view_payment_method_`+i">
                                <!--begin::Arrow-->
                                <div class="me-3 rotate-90">
                                    <!--begin::Svg Icon | path: icons/duotune/arrows/arr071.svg-->
                                    <span class="svg-icon svg-icon-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z" fill="black"></path>
                                        </svg>
                                    </span>
                                    <!--end::Svg Icon-->
                                </div>
                                <!--end::Arrow-->
                                <!--begin::Logo-->
                                <img src="/media/svg/card-logos/mastercard.svg" class="w-40px me-3" alt="">
                                <!--end::Logo-->
                                <!--begin::Summary-->
                                <div class="me-3">
                                    <div class="d-flex align-items-center">
                                        <div class="text-gray-800 fw-bolder fs-6">{{work_exp.employer}}</div>
                                        <div class="badge badge-light-primary ms-5 fs-9">{{EXTRACT_DATE(work_exp.start_year, "month_short_name")+" "+EXTRACT_DATE(work_exp.start_year, "year") }} - {{EXTRACT_DATE(work_exp.end_year, "month_short_name")+" "+EXTRACT_DATE(work_exp.end_year, "year")}}</div>
                                    </div>                                                                      
                                    <div class="text-muted fs-4">{{work_exp.role}}</div>
                                </div>
                                <!--end::Summary-->
                            </div>
                            <!--end::Toggle-->
                            <!--begin::Toolbar-->
                            <div class="d-flex my-3 ms-9">
                                <!--begin::Edit-->
                                <button type="button" @click="UPDATE_RECORD(work_exp), edit_pane = true" class="btn btn-icon btn-light-primary btn-sm me-3" > 
                                    <!--begin::Svg Icon | path: icons/duotune/art/art005.svg-->
                                    <span class="mdi mdi-pencil"></span>
                                    <!--end::Svg Icon--> 
                                </button>
                                <!--end::Edit-->
                                <!--begin::Delete-->
                                <button type="button" class="btn btn-icon btn-light-danger btn-sm me-3" @click="CONFIRM_DELETE(work_exp), delModal = true">
                                    <!--begin::Svg Icon | path: icons/duotune/general/gen027.svg-->
                                    <span class="mdi mdi-trash-can-outline"></span>
                                    <!--end::Svg Icon-->
                                </button>
                                <!--end::Delete--> 
                            </div>
                            <!--end::Toolbar-->
                        </div>
                        <!--end::Header-->
                        <!--begin::Body-->
                        <div :id="`kt_customer_view_payment_method_`+i" class="fs-6 ps-10 collapse" data-bs-parent="#kt_customer_view_payment_method" style="">
                            <!--begin::Details--> 
                            <div class="my-3">
                                <span class="d-block">Salary</span>
                                <span class="text-muted fs-7 fw-bold">{{work_exp.salary}}</span>
                            </div>  
                            <div class="my-3">
                                <span class="d-block">Reason for termination</span>
                                <span class="text-muted fs-7 fw-bold">{{work_exp.reason_for_termination}}</span>
                            </div>  
                            <div class="my-3">
                                <span class="d-block">Description</span>
                                <span class="text-muted fs-7 fw-bold">{{work_exp.job_description}}</span>
                            </div>  
                        </div>
                        <!--end::Body-->
                        
                    <div class="separator separator-dashed"></div>
                    <!--begin::Option--> 
                    </div>
                    <!--end::Option--> 
                    <div class="bg-white py-3 border-top-1" v-if="remItem !== 0"> 
                        <div class="d-flex justify-content-center">
                            <button class="btn btn-secondary btn-sm rounded-0" type="button" @click="LOAD_EMPLOYEE_WORK_EXP()">Load more...</button>
                        </div>
                    </div>
                </div>
                <EMPERROR v-if="resp" :status="respStat" :msg="respMsg" /> 

            </div>
            <!--end::Card body-->  


</div>
</template>
<script>   
import ADDWORKEXPCOMPONENT from "./form/work_exp/add.vue"; 
import EDITWORKEXPCOMPONENT from "./form/work_exp/edit.vue"; 
import { CModal } from '@coreui/vue'
import { TRUNCATE_STRING, GET_INITIAL, CHECK_PLURAL, GET_COLOR, CAPITALIZE_STR, REMOVE_CHARC, RESPONSE_STATUS, IS_EMPTY, READABLE_DATE, RANDOM_STRING} from "@/utils/";  
import {EXTRACT_DATE} from "@/utils/date_utils.js";  
import { ENCRYPT, DECRYPT } from "@/utils/security/"; 
import EMPLOYEE_SERVICE  from "@/services/employee.js"; 
import BLOCKLOADER from "@/components/loaders/blank-loader.vue";
import EMPERROR from "@/components/loaders/response.vue";
// import FILEMAN_SERVICE  from "@/services/fileman.js"; 


export default {
    props: ["user"],
    data() {
      return {  
        respMsg: '',
        resp: false,
        respStat: '',
        loading: true, 
        delModal: false,
        add_pane: false, 
        edit_pane: false,
        page: 1,
        workExp: '', 
        total: null,   
        nextPage: 0,
        remItem: 0,
        work_exps: [],       
        TRUNCATE_STRING, EXTRACT_DATE, GET_COLOR, CHECK_PLURAL, GET_INITIAL, REMOVE_CHARC, CAPITALIZE_STR, ENCRYPT, DECRYPT, RESPONSE_STATUS, IS_EMPTY, READABLE_DATE, RANDOM_STRING
      }
    },

    components: { ADDWORKEXPCOMPONENT, EDITWORKEXPCOMPONENT, CModal, EMPERROR, BLOCKLOADER }, 

    methods: {    

        RELOAD_CONTENT: function(){ 
            this.page = 1, 
            this.total = null,   
            this.nextPage = 0,
            this.remItem = 0,
            this.work_exps = [],
            this.LOAD_EMPLOYEE_WORK_EXPERIENCE_HISTORY()
        },  
        
        CONFIRM_DELETE: function(workExp){    
            this.workExp = workExp;
        },

        UPDATE_RECORD: function(workExp){    
            this.workExp = workExp;
        },

        CHILD_ACTION: function(action){    
            if(action.reload){
                this.RELOAD_CONTENT()
            }  
            if(action.close == 'add'){
                this.add_pane = false;
            } 
            if(action.close == 'edit'){
                this.edit_pane = false;
            } 
        },

        DELETE_WORKEXP_RECORD: function(id) { // FETCH ALL MESSAGES FROM DATABASE   
            let payload = ENCRYPT({id}) 

            EMPLOYEE_SERVICE.DELETE_WORK_EXPERIENCE_RECORD({payload})   
            .then(result => {  
                console.log(id)
                this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg });
                if(result.data.success) {
                    this.work_exps = this.work_exps.filter(item => item.id !== id);
                    if(this.work_exps.length == 0) this.resp = true; this.respStat="empty";
                }
                if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1000); 
    
            })

            .catch(err => {
                console.log(err.message)
                this.isLoading = 'error';
                this.$emit('notifyer', {
                    'success': 'error',
                    'msg': 'error occured loading form contents'
                })
            })

        },
    
    LOAD_EMPLOYEE_WORK_EXPERIENCE_HISTORY: function() { // FETCH ALL MESSAGES FROM DATABASE    
            let payload = ENCRYPT({'id': this.user.employee.id, 'page':this.page}); 
            EMPLOYEE_SERVICE.LOAD_EMPLOYEE_WORK_EXPERIENCE_HISTORY({payload})   
            .then(result =>{   
                this.loading = false;
                if(result.data.success){ 
                    this.nextPage = DECRYPT(result.data.data).nextPage;
                    this.remItem =  DECRYPT(result.data.data).rem;
                    this.page = DECRYPT(result.data.data).curPage;  
                    this.total = DECRYPT(result.data.data).total; 
                    let pLoad = DECRYPT(result.data.data).work_exps;  
                    this.work_exps = [...this.work_exps, ...pLoad];
                    if(this.work_exps.length == 0) this.resp = true; this.respStat="empty";
                }
                else{
                    this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg })
                    this.resp = true;
                    this.respStat="err"
                    this.respMsg=result.data.msg
                }

                if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1000); 
            })
            .catch(err => {
                console.log('Error:' + err.message) 
                this.$store.commit('PAGE_NOTIFICATION', {
                    "success": false, 
                    "msg": 'error occured loading contents' 
                });
            })
        },

    }, 

    mounted() { 
        this.LOAD_EMPLOYEE_WORK_EXPERIENCE_HISTORY()  
    } 
    
  }
</script>