<template>
<div class="animated fadeIn">
    <div class="mb-5 mb-xxl-10 py-2">  
        <!--begin::Form-->
        <form id="kt_account_profile_details_form" class="form card">
            <OVERLAYLOADER v-if="processing"/>
        
            <div class="d-flex flex-stack fs-4 pb-3">
                <div class="fw-bolder fs-5 collapsible" >Complete employee job profile to approve</div> 
            </div>
            <!--begin::Card body-->  
                <div class="row">   

                    <!--begin::Col-->
                    <div class="col-lg-6 mb-md-3">
                        <label class="col-form-label required fw-bold fs-6">Department </label>
                        <select class="form-select form-select-solid form-select-lg fw-bold" v-model="editable_user.employee.employee_department_id" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">
                            <option value="">--none--</option>
                            <option v-bind:value="dept.id" v-for="(dept, i) in departments" :key="i"> {{dept.name}}</option>
                        </select>
                    </div>
                    <!--end::Col--> 

                    <!--begin::Col-->
                    <div class="col-lg-6 mb-md-3">
                        <label class="col-form-label fw-bold fs-6">Position</label>
                        <select class="form-select form-select-solid form-select-lg fw-bold" v-model="editable_user.employee.employee_position_id" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">
                            <option value="">--none--</option>
                            <option v-bind:value="position.id" v-for="(position, i) in positions" :key="i"> {{position.name}}</option>
                        </select>
                    </div>
                    <!--end::Col-->  

                    <!--begin::Col-->
                    <div class="col-lg-6 mb-md-3">
                    
                        <label class="col-form-label fw-bold fs-6">Level</label>
                        <select class="form-select form-select-solid form-select-lg fw-bold" v-model="editable_user.employee.employee_level_id" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">
                            <option value="">--none--</option>
                            <option v-bind:value="level.id" v-for="(level, i) in levels" :key="i"> {{level.name}}</option>
                        </select>
                    </div>
                    <!--end::Col--> 

                    <!--begin::Col-->
                    <div class="col-lg-6 mb-md-3">
                        <label class="col-form-label required fw-bold fs-6">Employment type</label>
                        <select v-model="empTypeData" @change="CHECK_END_DATE()" class="form-select form-select-solid form-select-lg fw-bold" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">
                            <option v-bind:value="(emp.id + '/' + emp.expires)" v-for="(emp, i) in empTypes" :key="i"> {{emp.name}}</option>
                        </select>
                    </div>
                    <!--end::Col--> 

                    <!--begin::Col-->
                    <div class="col-lg-3 mb-md-3">
                        <label class="col-form-label required fw-bold fs-6">Salary  <span class="badge bg-danger fs-9 p-1">numbers only</span></label>
                        <input type="number" class="form-control form-control-lg form-control-solid" v-model="editable_user.employee.salary" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">
                        
                    </div>
                    <!--end::Col-->

                    <!--begin::Col-->
                    <div class="col-lg-3 mb-md-3">
                        <label class="col-form-label fw-bold fs-6">Working days</label>
                        <input type="number" class="form-control form-control-lg form-control-solid" v-model="editable_user.employee.number_of_work_days" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">
                        <small class="text-danger fs-9 mb-3 mb-lg-0">numbers only</small>
                    </div> 
                    <!--end::Col-->

                    <!--begin::Col-->
                    <div class="col-lg-6 mb-md-3">
                        <label class="col-form-label fw-bold fs-6">Hiring source</label>
                        <input type="text" class="form-control form-control-lg form-control-solid" v-model="editable_user.employee.hiring_source" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">
                    </div>
                    <!--end::Col-->  

                    <!--begin::Col-->
                    <div class="col-lg-3 mb-md-3">
                        <label class="col-form-label fw-bold fs-6">Resumption date</label>
                        <input type="date" class="form-control form-control-lg form-control-solid" v-model="editable_user.employee.resumption_date" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">
                    </div>
                    <!--end::Col-->    

                    <!--begin::Col-->
                    <div class="col-lg-3 mb-md-3 animated fadeIn" v-if="eDate !== '' || eDateStatus">
                        <label class="col-form-label fw-bold fs-6">End date</label>
                        <input type="date" class="form-control form-control-lg form-control-solid" v-model="editable_user.employee.end_date" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">
                    </div>
                    <!--end::Col-->   

                    <!--begin::Col-->
                    <div class="col-lg-6 mb-md-3">
                        <label class="col-form-label fw-bold fs-6">Team</label>
                        <select v-model="editable_user.employee.employment_type_id" class="form-select form-select-solid form-select-lg fw-bold" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">
                            <option :value="null">-- none --</option>
                            <option v-bind:value="team.id" v-for="(team, i) in teams" :key="i"> {{team.name}}</option>
                        </select>
                    </div>
                    <!--end::Col-->   

                    <!--begin::Col-->
                    <div class="col-lg-3 mb-md-3">
                        <label class="col-form-label required fw-bold fs-6">Job role</label>
                        <input type="text" class="form-control form-control-lg form-control-solid" v-model="editable_user.employee.job_role" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">
                    </div>
                    <!--end::Col-->  

                    <!--begin::Col-->
                    <div class="col-lg-9 mb-md-3">
                        <label class="col-form-label required fw-bold fs-6">Office address</label>
                        <select v-model="editable_user.employee.office_id" class="form-select form-select-solid form-select-lg fw-bold" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">
                            <option v-bind:value="office.id" v-for="(office, i) in offices" :key="i"> {{office.address+', '+office.state.name+' '+office.country.name}}</option>
                        </select>
                    </div>
                    <!--end::Col-->    
 
                    <!--begin::Col-->
                    <div class="col-lg-12 mb-md-3">
                        <label class="col-form-label required fw-bold fs-6">Job description</label>
                        <textarea class="form-control" id="kt_docs_maxlength_textarea" placeholder="" rows="6" v-model="editable_user.employee.job_desc" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)" />
                        <span class="fs-6 text-muted">Max. 500 words</span>
                    </div>
                    <!--end::Col-->   

                </div> 
            <!--end::Card body-->
            <!--begin::Actions-->
            <div class="d-flex justify-content-end pb-2"> 
                <button type="button" class="btn btn-light-primary"  @click="DO_VALIDATE()" :disabled="!GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">Save Changes</button>
            </div>
            <!--end::Actions-->
        </form>
        <!--end::Form-->  
    </div>
</div>
</template>

<script> 
import PORTFOLIO_SERVICE from "@/services/portfolio.js"
import UTILITY_SERVICE from "@/services/utility_services.js"
import EMPLOYEE_SERVICE from "@/services/employee.js"
import OVERLAYLOADER from "@/components/loaders/overlay-loader.vue" 

//EMPLOYEE COMPONENT 
import { IS_EMPTY, CHECK_BOOLEAN, READABLE_DATE, ADMINISTRATIVE_CLASS, STATUS_CODE, CAPITALIZE_STR, RESPONSE_STATUS, ACCESS_PRIVELEDGES, GRANT_ACCESS } from "@/utils/"; 
import { WEEK_DAYS } from "@/utils/date_utils.js";  
import { ENCRYPT, DECRYPT, PARSE_AUTH } from "@/utils/security/";  
export default {
    name: "User overview", 
    props: ["user"],  
    data() {
        return{  
            IS_EMPTY, CHECK_BOOLEAN, READABLE_DATE, CAPITALIZE_STR, WEEK_DAYS, ACCESS_PRIVELEDGES, ADMINISTRATIVE_CLASS, STATUS_CODE, GRANT_ACCESS,
            editable_user: this.user,
            processing: false,
            chkPassword: '',
            offices: '',
            subsidiary: '',
            departments: '',
            teams: '',
            positions: '',
            levels: '',
            empTypes: '',
            empTypeData: '',
            eDateStatus: '',
            eDate: '',
            auth: PARSE_AUTH(this.$store.state.auth, "user"),
        }
    },  
    components: { OVERLAYLOADER }, 
    methods: {
        
            LOAD_SUBSIDIARY: function(){ // FETCH ALL MESSAGES FROM DATABASE
            this.isLoading = 'yes';
            this.retry = ''  
            let payload = ENCRYPT({'id': this.editable_user.employee.subsidiary_id})
            PORTFOLIO_SERVICE.LOAD_PORTFOLIO_DASHBOARD({payload})
            .then(result =>{      
                if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) {
                    this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": result.data.msg })
                    setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path}), 2500) 
                }// logout and redirect with refferer page         
                
                if(!result.data.success){ 
                    this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg })
                }
                else{     
                    let subsidiary =  DECRYPT(result.data.data) 
                    this.offices =  subsidiary.offices  
                    this.departments = subsidiary.employee_departments
                    this.positions = subsidiary.employee_positions
                    this.levels = subsidiary.employee_levels 
                    this.teams = subsidiary.employee_teams  
                } 
            })
            .catch(err => {
                console.log(err.message)  
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": 'error occured loading page contents' })
            })
        }, 
        
		GET_EMPLOYEEMENT_TYPES: function() {
			UTILITY_SERVICE.GET_EMPLOYEEMENT_TYPES({})
            .then(result => { // TRY ALL RESOURCE NEEDED FOR REGISTRATION 
            console.log(result.data)
                !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) : this.empTypes = result.data.data 
            })
            .catch(err => {
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": 'error occured loading page contents' })
                console.log(err.message)
            }) 
		}, 

		CHECK_END_DATE: function() { // reload content
		let str =   this.empTypeData.split('/')
		this.empType = str.shift(); 
        this.editable_user.employee.employment_type_id = this.empType;

			if(str.pop() == 'true'){
				this.eDateStatus = true;
			}else{
				this.eDateStatus = false;
			}
		}, 


        // VALIDATE FORM DATA
        DO_VALIDATE: function(){
          (IS_EMPTY(this.editable_user.employee.employee_department_id) ||  IS_EMPTY(this.editable_user.employee.employment_type_id ) || IS_EMPTY(this.editable_user.employee.salary) || IS_EMPTY(this.editable_user.employee.number_of_work_days) || IS_EMPTY(this.editable_user.employee.job_role) || IS_EMPTY(this.editable_user.employee.job_desc) || IS_EMPTY(this.editable_user.employee.office_id)) ? this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " some required fields are empty!" }) : this.ACTIVATE_PROFILE();
        },

        //PROFILE UPDATE
        
        ACTIVATE_PROFILE: function(){
            this.processing = true;
            this.editable_user.employee.status_id = STATUS_CODE.ACTIVATE;
            let payload = ENCRYPT(this.editable_user.employee)
 
            EMPLOYEE_SERVICE.APPROVE_SELF_EMPLOYEE({payload})
            .then(result => {      
                this.processing = false;  
                if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1500); 
                this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) 
                result.data.success ? setTimeout(() => this.INIT_RELOAD(true), 3000)  : null
            })
            .catch(() => { 
                this.processing = false; 
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured processing request, try again!" });
            })
        },
        
        INIT_RELOAD: function(x){
            this.$emit('reload', x)
        }
    },
    mounted() {   
        this.GET_EMPLOYEEMENT_TYPES()
        this.LOAD_SUBSIDIARY()
    }    
}
</script> 