<template>
<div> 
<span class="text-hover-primary" @click="launch_modal = true, LOAD_EMPLOYEE()">
    <span class="d-none d-sm-block">{{CAPITALIZE_STR(TRUNCATE_STRING(emp.user.surname +' '+ emp.user.first_name, 12)) }}</span>
    <span class="d-sm-none fs-6">{{TRUNCATE_STRING(emp.user.surname +' '+ emp.user.first_name, 8) }}</span>
</span> 
<CModal backdrop="static" size="xl" :visible="launch_modal" @close="() => { launch_modal = false }">
    
        <div class="card shadow-none rounded-0">  

            <div class="card-header border-1 py-0 mt-n1">
                    <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder ms-n5">
                        <!--begin::Nav item-->
                        <li class="nav-item">
                            <span class="nav-link text-active-primary me-4 fs-8 fs-md-7" :class="active_tab == 1 ? 'active' : null" @click="LOAD_COMPONENT(1)">About {{TRUNCATE_STRING(emp.user.first_name, 7)}}</span>
                        </li>
                        <!--end::Nav item-->
                        <!--begin::Nav item-->
                        <li class="nav-item">
                            <span class="nav-link text-active-primary me-4 fs-8 fs-md-7" :class="active_tab == 2 ? 'active' : null" @click="LOAD_COMPONENT(2)">Calendar</span>
                        </li>
                        <!--end::Nav item-->
                        <!--begin::Nav item-->
                        <li class="nav-item d-none d-lg-flex">
                            <span class="nav-link text-active-primary me-4 fs-8 fs-md-7" :class="active_tab == 3 ? 'active' : null" @click="LOAD_COMPONENT(3)">Tasks-man</span>
                        </li>
                        <!--end::Nav item-->
                        <!--begin::Nav item-->
                        <li class="nav-item">
                            <span class="nav-link text-active-primary me-4 fs-8 fs-md-7" :class="active_tab == 4 ? 'active' : null" @click="LOAD_COMPONENT(4)">Reports</span>
                        </li>
                        <!--end::Nav item-->
                        <!--begin::Nav item-->
                        <li class="nav-item d-none d-lg-flex">
                            <span class="nav-link text-active-primary me-4 fs-8 fs-md-7" :class="active_tab == 5 ? 'active' : null" @click="LOAD_COMPONENT(5)">Education history</span>
                        </li>
                        <!--end::Nav item-->
                        <!--begin::Nav item-->
                        <li class="nav-item d-none d-lg-flex">
                            <span class="nav-link text-active-primary me-4 fs-8 fs-md-7" :class="active_tab == 6 ? 'active' : null" @click="LOAD_COMPONENT(6)">Surites & Gurantors</span>
                        </li>
                        <!--end::Nav item-->
                        <!--begin::Nav item-->
                        <li class="nav-item d-none d-lg-flex">
                            <span class="nav-link text-active-primary me-4 fs-8 fs-md-7" :class="active_tab == 7 ? 'active' : null" @click="LOAD_COMPONENT(7)">Work experience</span>
                        </li>
                        <!--end::Nav item-->
                        <!--begin::Nav item-->
                        <li class="nav-item d-none d-lg-flex">
                            <span class="nav-link text-active-primary me-4 fs-8 fs-md-7" :class="active_tab == 8 ? 'active' : null" @click="LOAD_COMPONENT(8)">File management</span>
                        </li>
                        <!--end::Nav item-->  
                    </ul>
                    <!--begin::Toolbar-->
                <div class="card-toolbar"> 

                <div class="dropdown">
                    <button class="btn btn-sm btn-icon btn-bg-light rounded-circle btn-active-color-primary me-2 dropbtn">
                        <i class="bi bi-three-dots fs-3"></i>
                    </button>
                    <div class="fs-8 py-4 menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px dropdown-content">
                        <!--begin::Heading-->
                        <div class="menu-item px-3">
                            <div class="menu-content text-muted pb-2 px-3 fs-8 text-uppercase">Navigations</div>
                        </div>
                        <!--end::Heading-->
                        <!--begin::Menu item-->
                        <div class="menu-item px-3">
                            <span @click="LOAD_COMPONENT(3)" :class="active_tab == 3 ? 'active' : null" class="menu-link flex-stack px-3">Tasks-man</span>
                        </div>
                        <!--begin::Menu item-->
                        <div class="menu-item px-3 d-sm-none">
                            <span @click="LOAD_COMPONENT(4)" :class="active_tab == 4 ? 'active' : null" class="menu-link px-3">Reports</span>
                        </div>
                        <!--end::Menu item-->  
                        <!--begin::Menu item-->
                        <div class="menu-item px-3">
                            <span @click="LOAD_COMPONENT(5)" :class="active_tab == 5 ? 'active' : null" class="menu-link px-3">Education history</span>
                        </div>
                        <!--end::Menu item-->  
                        <!--begin::Menu item-->
                        <div class="menu-item px-3 d-md-none">
                            <span @click="LOAD_COMPONENT(6)" :class="active_tab == 6 ? 'active' : null" class="menu-link px-3">Surites & Gurantors</span>
                        </div>
                        <!--end::Menu item-->
                        <div class="menu-item px-3">
                            <span @click="LOAD_COMPONENT(7)" :class="active_tab == 7 ? 'active' : null" class="menu-link flex-stack px-3">Work experience</span>
                        </div>
                        <!--end::Menu item--> 
                        <!--end::Menu item-->
                        <div class="menu-item px-3">
                            <span @click="LOAD_COMPONENT(8)" :class="active_tab == 8 ? 'active' : null" class="menu-link flex-stack px-3">File management</span>
                        </div>
                        <!--end::Menu item--> 
                    </div>
                </div> 
   

                    <button type="button" class="btn btn-sm btn-icon bg-light rounded-circle" @click="() => { launch_modal = false }">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                            </svg>
                        </span>
                    </button>
                </div>
                <!--end::Toolbar-->

            </div>
 
            <div class="card-body" v-if="!IS_EMPTY(user)">    
                <div class="mt-n0">    
                    <ABOUTUSERCOMPONENT :user="user" v-if="active_tab == 1" /> 
                    <CALENDARCOMPONENT :user="user" v-if="active_tab == 2" /> 
                    <TASKMANCOMPONENT :user="user" v-if="active_tab == 3" />
                    <REPORTCOMPONENT :user="user" v-if="active_tab == 4" />
                    <EDUCATIONCOMPONENT :user="user" v-if="active_tab == 5" />
                    <SURITIESCOMPONENT :user="user" v-if="active_tab == 6" />
                    <WORKEXPCOMPONENT :user="user" v-if="active_tab == 7" />
                    <FILEMANCOMPONENT :user="user" v-if="active_tab == 8" /> 
                </div>
                <!--end::Content-->  
            </div> 
                 
        </div> 
  </CModal>



</div> 
</template>
<script> 
import { CModal } from '@coreui/vue'
import { TRUNCATE_STRING, GET_COLOR, CAPITALIZE_STR, RESPONSE_STATUS, IS_EMPTY, READABLE_DATE, RANDOM_STRING} from "@/utils/";  
import { ENCRYPT, DECRYPT } from "@/utils/security/"; 
import EMPLOYEE_SERVICE  from "@/services/employee.js"; 
import ABOUTUSERCOMPONENT from "@/views/user/components/about.vue"; 
import CALENDARCOMPONENT from "@/views/user/components/calendar.vue"; 


import TASKMANCOMPONENT from "./components/employee_taskman.vue"; 
import REPORTCOMPONENT from "./components/employee_report.vue"; 

import EDUCATIONCOMPONENT from "./components/employee_education.vue";  
import SURITIESCOMPONENT from "./components/employee_surties.vue";  
import WORKEXPCOMPONENT from "./components/employee_work_exp.vue";  
import FILEMANCOMPONENT from "./components/employee_fileman.vue";  
 

  export default {
    props: ["emp"],
    data() {
      return { 
        launch_modal: false,
        user: "", 
        active_tab: 1,
        triggerSettings: false,
        TRUNCATE_STRING, GET_COLOR, CAPITALIZE_STR, ENCRYPT, DECRYPT, RESPONSE_STATUS, IS_EMPTY, READABLE_DATE, RANDOM_STRING
      }
    },
components: { ABOUTUSERCOMPONENT, CALENDARCOMPONENT, CModal, EDUCATIONCOMPONENT, SURITIESCOMPONENT, REPORTCOMPONENT, TASKMANCOMPONENT, WORKEXPCOMPONENT, FILEMANCOMPONENT }, 
methods: {    

    RELOAD_CONTENT: function(){ 
        this.LOAD_EMPLOYEE()
    }, 
    
    LOAD_COMPONENT: function(n){ 
        this.$store.commit('PAGE_NOTIFICATION', null) 
        this.active_tab = n;
    },
 
    LOAD_EMPLOYEE: function(){ // FETCH ALL MESSAGES FROM DATABASE  
        let payload = ENCRYPT({'id': this.emp.users_id}); 
        EMPLOYEE_SERVICE.LOAD_EMPLOYEE({payload}) 
        .then(result =>{  
            !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) : null;

            if(result.data.success){ 
                this.user = DECRYPT(result.data.data); 
                console.log(this.employee)
                // this.remItem =  DECRYPT(result.data.data).rem;
                // this.page = DECRYPT(result.data.data).curPage;  
                // this.totalSubs = DECRYPT(result.data.data).total; 
                // let pLoad = DECRYPT(result.data.data).employees; 
                
                // setTimeout(() => this.employees = [...this.employees, ...pLoad],500)  

            }

            if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1000); 
        })
        .catch(err => {
            console.log('Error:' + err.message) 
            this.$store.commit('PAGE_NOTIFICATION', {
                "success": false, 
                "msg": 'error occured loading contents' 
            });
        })
    },  

}, 

beforeMount() { 
    
	let extScript = document.createElement('script')
	extScript.setAttribute('src', '/js/scripts.bundle.js')
	document.head.appendChild(extScript)   
} 
    
}
</script>