const CryptoJS = require("crypto-js");

const CHECK_ENV = () => {   
    const url = window.location.href;
    return url.includes('localhost:') || url.includes('127.0.0.1:') ? 'test' : 'live';
}

const APP_INFO = {
    ip: CHECK_ENV() === "test" ? "http://localhost" : "http://104.248.48.188", 
    baseUrl: CHECK_ENV() === "test" ? "http://localhost:8080" : "http://tasquik.com", 
    physical_address: '30 airport road Ikeja Lagos state.',
    company: 'Tasquik softwares.',
    logo: 'https://res.cloudinary.com/tasquik/image/upload/v1640079991/assets/images/logo_cp4tsy.png',
    default_img: '/img/default.png',
    name: 'Tasquik.com',
    support: 'tasquiksoftware@gmail.com',
    email: 'tasquiksoftware@gmail.com',
    email_password: 'tpxrrjrwotpdbocj',
    api_key: 'CURPdibl5MTRv89gHA3CrzwrGAdyAS1H29EdGAzPbUW4J9KP17',
}

const APP_CONSTANTS = {
    default_photo: '/img/default.png', 
}

const BOTTOM_VISIBLE = () => {
    var scrollY = window.scrollY
    var visible = document.documentElement.clientHeight
    var pageHeight = document.documentElement.scrollHeight
    var bottomOfPage = visible + scrollY >= pageHeight
    return bottomOfPage || pageHeight < visible
} 

const INTERSECTION_OBSERVER = (xs) => {
 
    let config = {
        root: null, // avoiding 'root' or setting it to 'null' sets it to default value: viewport
        rootMargin: '0px',
        threshold: 0.5
    };
    let observer = new IntersectionObserver(function(entries) {
    entries.forEach(entry => {
        if (entry.isIntersecting) { 
            return true; 
        } else { 
            return false; 
        }
    });
    }, config); 

    xs.forEach(x => {
        observer.observe(x); 
    });
}

const CALCU_YEARS = (y1, y2) => { 
    var dif = Number(y2) - Number(y1);

    return dif + ' year'+CHECK_PLURAL(dif);
}

const CAPITALIZE_STR = (str) => {   
    var res = '';
    (str == null || str == undefined || str == '') ? res = '' : res = str[0].toUpperCase() + str.substring(1); 
    return res; 
}

const CHECK_ACCESS_LEVEL = (accessLvls, id) => { 
     var res = false;
     accessLvls.forEach( function(level) { 
        if(id == level){
            res = true; 
        } 
    })   
    return res;
}

const CHECK_VOWEL = (str) => {  
    let res = ['a','e','i','o','u'].indexOf(str[0].toLowerCase()) !== -1;
        return res ? `an ${str}` : `a ${str}` ;
}  

const GENERATE_RAND_STRING = (length) => {
    
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let characterLength = characters.length;

    for(var i = 0; i < length; i++){
        result += characters.charAt(Math.floor(Math.random() * characterLength))+Date.now().toString(36);
    }

    return result
}

const GET_ACCESS_LEVEL = (accessLevels, id) => {  
    var accessLevel = accessLevels.find(lvl => lvl.id == id); // search and find access level from array using id
    return accessLevel.title;
}

const ENCRYPTION_HASH = () => {  
    const date =  new Date(); 
    const milliSec =  date.getTime(); 
    const randStr =  Math.random().toString(36).slice(2);
    const hash = milliSec + randStr;
    return hash;
}  

const CHECK_PLURAL = (num) => {  
    return num > 1 ? 's' : '';
} 

const GENERATE_RANDOM_NUM = (n) => { 
    var add = 1, max = 12 - add;   // 12 is the min safe number Math.random() can generate without it starting to pad the end with zeros.   
    max        = Math.pow(10, n+add);
    var min    = max/10; // Math.pow(10, n) basically
    var number = Math.floor( Math.random() * (max - min + 1) ) + min;
    return ("" + number).substring(add); 
} 
 
const ENCRYPTION_BYTE = 50;

// const CONVERT_TO_HEX = (str) => { 
//     let hex = new Buffer.from(str).toString('hex'); // encrypt to hexadecimal 
//     return hex;
// }

const CONVERT_TO_BASE64 = (str) => { 
    let b64 = new Buffer.from(str).toString('base64'); // encrypt to base64 
    return b64;
}

const CONVERT_BASE64_TO_HEX = (str) => { 
    let b64ToHex = new Buffer.from(str).toString('hex'); // encrypt to base16 
    return b64ToHex;
}

const CONVERT_FROM_BASE64 = (str) => { 
    let fromBase64 = new Buffer.from(str, 'base64').toString(); // decrypt to base64 
    return fromBase64;
}

const ENCRYPT = (str) => CryptoJS.AES.encrypt(JSON.stringify(str), 'secret key 123').toString(); 

const DECRYPT = (str) => CryptoJS.AES.decrypt(str, 'secret key 123').toString(CryptoJS.enc.Utf8);  
 
const ENCRYPT_JSON_PAYLOAD = (payload) => {   
    return payload;
} 

const DECRYPT_JSON_PAYLOAD = (payload) => {   
    return  JSON.parse(DECODE_HEX(CONVERT_FROM_BASE64(DECODE_HEX(CONVERT_BASE64_TO_HEX(payload)))));
} 

const DECODE_HEX = (str) => {
    let hex = str.toString();
    var res = ''; 
    for(var i = 0; i < hex.length; i +=2 ){
        res += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    }

    return res
}


const ENCRYPT_ID = (id) => {   
    const randNum = DAYS_IN_MILLISECONDS(id); // convert id to get equivalent in milli seconds 
    const mutiply = randNum * HASH_NUMBER; // convert id to get equivalent in milli seconds 
    const ref = mutiply.toString(32);   
    return ref;
} 

const DECRYPT_ID = (str) => {  
    const convert_str_from_base32 = parseInt(str, 32); 
    const divide = convert_str_from_base32 / HASH_NUMBER; 
    const id = MILLISECONDS_TO_DAYS(divide)  
    return id;
} 

const PARSE_AUTH = (auth, typ) => {     
    let res = '';
    let payload = DECRYPT_JSON_PAYLOAD(auth); 
    switch (typ) {
        case 'user':
            res = payload.user
            break; 
        
        case 'header':
            res = payload.headers
            break;
    
        default:
            break;
    }  
    return res
}  

const THOUSAND_SEPARATOR = (num) => {
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
}

const CALCU_PERCENTAGE = (percent, price) => {
    var res = Number(percent) / 100 * Number(price);
    return `₦` + THOUSAND_SEPARATOR(res);
}

const GET_INITIAL = (str) => {
    if(str !== null){ 
        if (str.length > 1) {
            return str.slice(0, 2) + "";
        } else {
            return str;
        }
    }
} 

const TRUNCATE_STRING = (str, num) => {
    if(str !== null){ 
        if (str.length > num) {
            return str.slice(0, num) + "...";
        } else {
            return str;
        }
    }
}

const TRUNCATE_BEFORE_CHARC = (str, char) => { 
    return str.substr(str.lastIndexOf(char) + 1);
}

// const TRUNCATE_BEFORE_CHARC = (str, char) => { 
//     return str.substr(str.lastIndexOf(char) + 1);
// }

const CHECK_BOOLEAN = (x) => { 
    return x === 't' ? true : false ;
}

const CHECK_SELECTED = (id, lists) => { 
    for (const item of lists) {
        if(item == id){
            return true;
        } 
    } 
}

const GET_HEAD_OFFICE = (array) => { 
    let lists = array.filter(x => x.head_office == 't')
    for (const item of lists) { 
        return item
    } 
} 

const BOOLEN_VALUE = {
    TRUE: 1,
    FALSE: 0
} 

const ACCESS_PRIVELEDGES = {
    PASS: 0,
    WILD_CARD: 1,
    EMPLOYEE_MANAGEMENT: 2,
    FINANCE_MANAGEMENT: 3, 
}

const ADMINISTRATIVE_CLASS = { 
    PASS: {id: 0, clearance_level: 1},
    SUPER_ADMINISTRATOR: {id: 1, clearance_level: 10}, // App-wide access
    MAIN_ADMINISTRATOR: {id: 2, clearance_level: 9}, // Organization-wide access
    ADMINISTRATOR: {id: 3, clearance_level: 8}, // Subsidiary-wide access
    MODERATOR: {id: 4, clearance_level: 7}, // Office-wide access
}

const GRANT_ACCESS = (user, admin_class_specified, priviledge) => { 
    let res = false   
    // if(priviledge === ACCESS_PRIVELEDGES.PASS) res = true; // return true if priviledge is the for all users
    if(CHECK_BOOLEAN(user.is_chief_hr)) res = true; // return true if user is the super admin
    if(user.administrator.administrative_class_id === ADMINISTRATIVE_CLASS.SUPER_ADMINISTRATOR.id) res = true; // return true if user is the super admin
    else if(user.administrator.administrative_class.clearance_level >= admin_class_specified){ // if 
        let arr = user.administrator.privileges;
        for (let i = 0; i < arr.length; i++) {  
            if(priviledge === arr[i].id || priviledge === ACCESS_PRIVELEDGES.PASS){ // check if user access priviledges match resource access id or if it match WILD_CARD key or if access is null(for everybody)
              res = true;
              break;
            } 
        }  
    }
    return res
}

const PUNCTUATE = (total, num) => {
    if (total == num) {
        return ".";
    } else {
        return ", ";
    }
}

const RATING = (num) => {
    if(num == 0){
    var info = `<span class="badge badge-danger">not rated</span>`; 
    return info;
    }
    else{
        var rating = [];
        for (var j = 0; j < num; j++) { 
            rating += `<i class="mdi mdi-star text-warning"></i>`;
        }  
        return rating;
   }
} 

const HASH_NUMBER = 2580
 

const RAND_STRING = (len) => { // CONVERT DAYS TO MILLI SECONDS 
    return Math.random().toString(36).substr(len);
}  

const DAYS_IN_MILLISECONDS = (days) => { // CONVERT DAYS TO MILLI SECONDS
    const oneDayMS = 86400000; // milliseconds for a day  
    return Number(oneDayMS) * Number(days);
}

const MILLISECONDS_TO_DAYS = (ms) => { // CONVERT DAYS TO MILLI SECONDS
    const oneDayMS = 86400000; // milliseconds for a day  
    return Number(ms) / Number(oneDayMS);
}
 
const STRIP_STRING = (str) => {  
    return str.replace(/(<([^>]+)>)/gi, "");
}

const REMOVE_CHARC = (str, charc) => {  
    return str.replace(charc, "");
}


const MONTH_NAMES = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
    
const MAX_DP_SIZE = 1024 * 512

const THIRTY_DAYS_IN_MILLIS = 2592000000;

const HOST = (req) => req.protocol + '://' + req.get('host')
 
const FUTURE_DATE_MILI_SECS = (date, days) => date.setDate(date.getDate() + Number(days)); 
 
const TEN_MINUTES_IN_MILIS = ((60 * 10) * 1000)    

const IS_EMPTY = (payload) => { 
    return (payload === [] || payload === null || payload === '' || payload === undefined)  ? true : false; 
}

const NOTIFYER = (x) => {  
    let info = {name: APP_INFO.name, image: APP_INFO.logo}
    return (x === 'TQ')  ? info : false; 
}
 
const READABLE_DATE = (d) => {  
    const dd = new Date(`${d}`) 
    return dd.toDateString();
}
 
const EXTRACT_DATE = (d, typ) => {  
    const dd = new Date(`${d}`) 
    if(typ == "year"){
        return dd.getFullYear();
    }
    
    if(typ == "month"){
        return dd.getMonth();
    }
    
} 

const DAYS_REMAINING = (date, otherDate) => Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));
 
 
const ONBOARDING_STATUS = {
    completed: 99,
    bio: 1,
    education: 2,
    work_experience: 3,
    surity: 4,   
 }

// const RANDOM_NUMBER = (len) => {  
//     let random = '';
//     let possible = '012345';

//     for( let i=0; i < len; i++ )
//       random += possible.charAt(Math.floor(Math.random() * possible.length));
//     return random;
// }
 
const STATUS_TAG_COLOR = (x) => {     
    let res = ''; 
    switch (x) {
        case STATUS_CODE.ACTIVATE:
            res = "primary"
        break;  

        case STATUS_CODE.INACTIVATE:
         res = "dark"
        break;

        case STATUS_CODE.APPROVED:
         res = "success"
        break;

        case STATUS_CODE.SUSPEND:
            res = "warning"
        break;

        case STATUS_CODE.REJECTED:
            res = "danger"
        break; 

        case STATUS_CODE.UNPROCESSED:
            res = "dark"
        break; 

        case STATUS_CODE.ONLEAVE:
            res = "secondary"
        break; 

        // case STATUS_CODE.REVOKED:
        //     res = "secondary"
        // break; 

        default:
            res = "lighten"
        break;
    }   
    return res
} 


const GET_COLOR = (x) => {      
    let color_arr = ["primary", "dark", "success", "warning", "danger", "secondary", "info"];
    return  x < 7 ? color_arr[x] : "dark";
} 
 
const STATUS_CODE = {
    ACTIVATE: 1,
    INACTIVATE: 2,
    NEW: 3,
    SEEN: 4,
    ONLINE: 5,
    OFFLINE: 6,
    RESIGN: 7,
    RETIRE: 8,
    TERMINATE: 9,
    ONLEAVE: 10,
    UNAPPROVE: 11,
    UNBOARDED: 12,
    UNPROFILED: 13,
    ONBOARDING: 14,
    SUSPEND: 15,
    LOCK: 16,
    UNVERIFIED: 17,
    VERIFIED: 18,
    CONFIRMED: 19,
    UNCONFIRMED: 20,
    REJECTED: 21,
    UNPROCESSED: 22, 
    APPROVED: 23,
    REVOKED: 24,
    EXPIRED: 25,
}

 const RESPONSE_STATUS = {
     WILD_CARD: { code: 100, msg: 'special operation' },
     SUCCESS: { code: 200, msg: 'operation successfull' },
     CREATED: { code: 201, msg: 'resource was created' }, 
     ACCEPTED: { code: 202, msg: 'request was recived successfully' },
     BAD_REQUEST: { code: 400, msg: 'this request is not allowed.' }, 
     UNAUTHORIZED: { code: 401, msg: 'you are not authorized to perform this action.' },
     FORBIDDEN: { code: 403, msg: 'this action is forbidden.' },
     NOT_FOUND: { code: 404, msg: 'the resource requested was not found.' },
     METHOD_NOT_ALLOWED: { code: 405, msg: 'this request method is not allowed.' },
     RESOURCE_EXIST: { code: 406, msg: 'this resource already exists.' },
     PAYLOAD_TOO_LARGE: { code: 413, msg: 'request payload is too heavy.' },
     INTERNAL_SERVER_ERROR: { code: 500, msg: 'encountered an internal server error.' },
     BAD_GATEWAY: { code: 502, msg: 'bad gateway error encountered.' },
     SERVICE_UNAVAILABLE: { code: 503, msg: 'this service requested is currently unavailable.' },
     GATEWAY_TIMEOUT: { code: 504, msg: 'server timeout error occured.' },
 }

 const CRUD_TYPE = {
    CREATE: { code: 1, success_msg: 'Operation was successfull', error_msg: 'Operation was unsuccessful.' }, 
    RETRIVE: { code: 2, success_msg: 'Record(s) requested was retrived successfully.', error_msg: 'Retrival operation was unsuccessful.' }, 
    UPDATE: { code: 3, success_msg: 'Update operation was successful.', error_msg: 'Update operation was unsuccessful.' },
    DELETE: { code: 4, success_msg: 'Delete operation was successful.', error_msg: 'Delete operation was unsuccessful.' }, 
 }
  
 const RANDOM_STRING = (len) => {  
     let text = '';
     let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
 
     for( let i=0; i < len; i++ )
       text += possible.charAt(Math.floor(Math.random() * possible.length));
     return text;
 }
 
  
// const RANDOM_STRING = (len) => {  
//     let text = '';
//     let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

//     for( let i=0; i < len; i++ )
//       text += possible.charAt(Math.floor(Math.random() * possible.length));
//     return text;
// }

const EVEN_NUMBER = (num) => { 
    return ( num % 2 == 0 ) ? true : false; 
}

const RANDOM_INT = (len) => {  
    let num = '';
    let sample = '0123456789';

    for( let i=0; i < len; i++ )
      num += sample.charAt(Math.floor(Math.random() * sample.length));

    return num;
}

const CHECK_EMAIL_PATTERN = (email) => {  
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
  
const CHECK_IF_EMPTY = (v) => {  
          let type = typeof v;
          if (type === 'undefined') {
              return true;
          }
          if (type === 'boolean') {
              return !v;
          }
          if (v === null) {
              return true;
          }
          if (v === undefined) {
              return true;
          }
          if (v instanceof Array) {
              if (v.length < 1) {
                  return true;
              }
          } else if (type === 'string') {
              if (v.length < 1) {
                  return true;
              }
              if (v === '0') {
                  return true;
              }
          } else if (type === 'object') {
              if (Object.keys(v).length < 1) {
                  return true;
              }
          } else if (type === 'number') {
              if (v === 0) {
                  return true;
              }
          }
          return false;
}





module.exports = { 
    CHECK_ENV,
    GET_COLOR, GET_HEAD_OFFICE,
    EXTRACT_DATE, DAYS_REMAINING, 
    READABLE_DATE, INTERSECTION_OBSERVER,
    TRUNCATE_BEFORE_CHARC, PARSE_AUTH,
    BOOLEN_VALUE, NOTIFYER,
    MAX_DP_SIZE, THIRTY_DAYS_IN_MILLIS,
    FUTURE_DATE_MILI_SECS, TEN_MINUTES_IN_MILIS, 
    STATUS_TAG_COLOR, STATUS_CODE,
    RESPONSE_STATUS, ONBOARDING_STATUS,
    DAYS_IN_MILLISECONDS, MILLISECONDS_TO_DAYS,
    ENCRYPT, DECRYPT, 
    ENCRYPT_JSON_PAYLOAD, DECRYPT_JSON_PAYLOAD,
    CONVERT_TO_BASE64, CONVERT_BASE64_TO_HEX,
    CONVERT_FROM_BASE64, ENCRYPT_ID,
    DECRYPT_ID, HOST,
    RANDOM_STRING, RANDOM_INT,
    CHECK_EMAIL_PATTERN, CHECK_IF_EMPTY, 
    CALCU_YEARS, MONTH_NAMES, 
    HASH_NUMBER, GENERATE_RANDOM_NUM, 
    RAND_STRING, CHECK_ACCESS_LEVEL,
    GET_ACCESS_LEVEL, ENCRYPTION_HASH, 
    BOTTOM_VISIBLE, CHECK_PLURAL,
    CAPITALIZE_STR, TRUNCATE_STRING,
    GET_INITIAL, CALCU_PERCENTAGE,
    THOUSAND_SEPARATOR, RATING,
    REMOVE_CHARC, STRIP_STRING,  
    PUNCTUATE, EVEN_NUMBER,
    CHECK_VOWEL, GENERATE_RAND_STRING, 
    DECODE_HEX, ENCRYPTION_BYTE,
    CHECK_SELECTED, IS_EMPTY,
    CRUD_TYPE, APP_INFO,
    APP_CONSTANTS, GRANT_ACCESS,
    ACCESS_PRIVELEDGES, CHECK_BOOLEAN, ADMINISTRATIVE_CLASS,
    
}