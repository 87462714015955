
const PORTFOLIO_URLS = (dest) => {
    //PORTFOLIO URLS
    let url = ''; 
    switch (dest) {

        case 'subsidiary':
            url = `/dashboard-s`
        break; 

        case 'subsidiaries':
            url = `/subsidiaries`
        break; 

        case 'employee-management':
            url = `/employee-management`
        break; 

        case 'settings':
            url = `/settings`
        break; 

        case 'management':
            url = `/management`
        break; 

        case 'offices':
            url = `/offices`
        break;  
 
        default:
            url = `/organizations`
        break;
    }   
    return url 
}
 
module.exports = { 
    PORTFOLIO_URLS
}