<template>
    <div class="skeleton"> <!-- -->
        <div class="text h-10px rounded-2" v-for="(n, i) in cn" :key="i"></div>  
    </div> 
</template>

<script>    
export default {
name: "title-loader",
props: ["count"],
data() {
    return{ cn: this.count }
}, 
};
</script>
