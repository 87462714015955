<template>
    <div class="skeleton"> <!-- -->
        <div class="text w-250px w-sm-300 w-xl-400px h-25px"></div> 
        <div class="text w-200px w-sm-200 w-xl-300px h-10px my-3" v-if="checkSub > 0"></div> 
        <div class="text w-150px w-sm-200 w-xl-250px h-10px" v-if="checkSub > 1"></div> 
    </div> 
</template>

<script>    
export default {
name: "title-loader",
props: ["subText"],
data() {
    return{ checkSub: this.subText }
}, 
};
</script>
