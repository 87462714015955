import dashboard from "@/views/user/dashboard.vue";
// import Messages from "../../views/users/messages.vue"; 
// import EditAccount from "../../views/users/editAccount.vue"; 
// import Notifications from "../../views/users/notifications.vue";  

const USER_ROUTE = {
    'dashboard': dashboard, 
    // 'Messages': Messages, 
    // 'EditAccount': EditAccount, 
    // 'Notifications': Notifications, 
};

export default USER_ROUTE;