<template>
<div>


        
 
        <CModal alignment="center" size="sm" :visible="delModal" @close="() => { delModal = false }"> 
            <div class="card card-body py-15">
                <span class="fs-6 text-center fw-bold">Are you sure you want to delete this record?</span>
                <div class="d-flex justify-content-between mt-5">
                    <button type="button" @click="() => { delModal = false }" class="btn btn-sm btn-light-primary">No abort</button>
                    <button type="button" @click="DELETE_WORKEXP_RECORD(workExp.id), delModal = false" class="btn btn-sm btn-light-danger">Yes continue</button>
                </div>
            </div>
        </CModal>
         
            <div class="card card-flush pb-0 bgi-position-y-center bgi-no-repeat mb-10 shadow-lite">
                <!--begin::Card header-->
                <div class="card-header py-10">
                    <div class="d-flex align-items-center">
                        <!--begin::Icon-->
                        <div class="symbol symbol-circle symbol-60px me-4"> 
                            <div class="symbol-label fs-2 bg-transparent border border-secondary border-dashed fw-bolder " >{{GET_INITIAL(user.surname+ ' '+user.first_name).toUpperCase()}}</div>
                        </div>
                        <!--end::Icon-->
                        <!--begin::Title-->
                        <div class="d-flex flex-column fs-8">
                            <h2 class="mb-1">File Manager</h2>
                            <div class="text-muted fw-bolder">
                            <a href="#">Keenthemes</a>
                            <span class="mx-3">|</span>
                            <a href="#">File Manager</a> 
                            <span class="mx-3">|</span>758 items</div>
                        </div>
                        <!--end::Title-->
                    </div>
                </div>
                <!--end::Card header--> 
            </div>

            <div class="card card-flush">
								<!--begin::Card header-->
								<div class="card-header p-0">
									<div class="card-title">
										<!--begin::Search-->
										<div class="d-flex align-items-center position-relative my-1">
											<!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
											<span class="svg-icon svg-icon-1 position-absolute ms-6">
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
													<rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
													<path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black"></path>
												</svg>
											</span>
											<!--<input type="text" data-kt-filemanager-table-filter="search" class="form-control form-control-solid w-250px ps-15" placeholder="Search Files &amp; Folders">-->
                                        </div>
										<!--end::Search-->
									</div>
									<!--begin::Card toolbar-->
									<div class="card-toolbar">
										<!--begin::Toolbar-->
										<div class="d-flex justify-content-end" data-kt-filemanager-table-toolbar="base"> 
											<!--begin::Add customer-->
											<button type="button" class="btn btn-light-primary me-3" v-if="files_pane" @click="files_pane = false">
											<!--begin::Svg Icon | path: icons/duotune/files/fil018.svg-->
											<span class="svg-icon svg-icon-2">
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M9.60001 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13H9.60001V11Z" fill="black"/>
                                                    <path opacity="0.3" d="M9.6 20V4L2.3 11.3C1.9 11.7 1.9 12.3 2.3 12.7L9.6 20Z" fill="black"/>
                                                </svg>
											</span>
											<!--end::Svg Icon-->Back
                                            </button>
											<!--end::Add customer-->

                                            
											<!--<button type="button" class="btn btn-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_upload"> 
											<span class="svg-icon svg-icon-2">
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
													<path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="black"></path>
													<path d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM16 11.6L12.7 8.29999C12.3 7.89999 11.7 7.89999 11.3 8.29999L8 11.6H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H16Z" fill="black"></path>
													<path opacity="0.3" d="M11 11.6V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H11Z" fill="black"></path>
												</svg>
											</span>
                                            Upload Files
                                            </button>-->
                                            
										</div>
										<!--end::Toolbar-->
										<!--begin::Group actions-->
										<div class="d-flex justify-content-end align-items-center d-none" data-kt-filemanager-table-toolbar="selected">
											<div class="fw-bolder me-5">
											<span class="me-2" data-kt-filemanager-table-select="selected_count"></span>Selected</div>
											<button type="button" class="btn btn-danger" data-kt-filemanager-table-select="delete_selected">Delete Selected</button>
										</div>
										<!--end::Group actions-->
									</div>
									<!--end::Card toolbar-->
								</div>
								<!--end::Card header-->
								<!--begin::Card body-->
								<div class="card-body p-0"> 
									<!--begin::Table-->
									<div id="kt_file_manager_list_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer animated fadeIn" v-if="!files_pane">
                                        <div class="table-responsive"> 
                                                <div class="dataTables_scrollBody" style="position: relative; overflow: auto; max-height: 300px; width: 100%;">
                                            <table id="kt_file_manager_list" data-kt-filemanager-table="folders" class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" style="width: 100%;">
                                                                
                                                <!--end::Table head-->
                                                <!--begin::Table body-->
                                                <tbody class="fw-bold text-gray-600" v-if="folders.length !== 0">
                                                <tr class="odd animated fadeIn" v-for="(folder, i) in folders" :key="i" @click="LOAD_FILES(folder)">
                                                        <!--begin::Checkbox-->
                                                        <td>
                                                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                                                {{i+1}}
                                                            </div>
                                                        </td>
                                                        <!--end::Checkbox-->
                                                        <!--begin::Name=-->
                                                        <td>
                                                            <div class="d-flex align-items-center">
                                                                <!--begin::Svg Icon | path: icons/duotune/files/fil012.svg-->
                                                                <span class="svg-icon svg-icon-2x svg-icon-primary me-4">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="black"></path>
                                                                        <path d="M9.2 3H3C2.4 3 2 3.4 2 4V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V7C22 6.4 21.6 6 21 6H12L10.4 3.60001C10.2 3.20001 9.7 3 9.2 3Z" fill="black"></path>
                                                                    </svg>
                                                                </span>
                                                                <!--end::Svg Icon-->
                                                                <span class="text-gray-800 text-hover-primary" @click="LOAD_FILES(folder.id)">{{CAPITALIZE_STR(folder.name)}}</span>
                                                            </div>
                                                        </td>
                                                        <!--end::Name=--> 
                                                        <!--begin::Size-->
                                                        <td>{{folder.total}} files</td>
                                                        <!--end::Size-->
                                                        <!--begin::Last modified-->
                                                        <td class="text-end">{{READABLE_DATE(folder.updated_at)}}</td>
                                                        <!--end::Last modified--> 
                                                    </tr>
                                                </tbody>
                                                <!--end::Table body-->
                                            </table> 
                                    </div>
                                </div> 
                            </div>
									<!--end::Table-->
								</div>
                                 
                                <FILESCOMPONENT :filter="filter" :user="user" v-if="files_pane" v-on:action="CHILD_ACTION" />
								<!--end::Card body-->
							</div> 
</div>
</template>
<script>   
import FILESCOMPONENT from "./files.vue";  
import { CModal } from '@coreui/vue'
import { TRUNCATE_STRING, GET_INITIAL, CHECK_PLURAL, GET_COLOR, REMOVE_CHARC, RESPONSE_STATUS, IS_EMPTY, READABLE_DATE, RANDOM_STRING, CAPITALIZE_STR} from "@/utils/";  
import {EXTRACT_DATE} from "@/utils/date_utils.js";  
import { ENCRYPT, DECRYPT } from "@/utils/security/";  
import UTILITY_SERVICE  from "@/services/utility_services.js"; 

export default {
    props: ["user"],
    data() {
      return {  
        delModal: false,
        files_pane: false, 
        edit_pane: false, 
        filter: null,
        folders: "",       
        TRUNCATE_STRING, EXTRACT_DATE, GET_COLOR, CHECK_PLURAL, GET_INITIAL, REMOVE_CHARC, ENCRYPT, DECRYPT, RESPONSE_STATUS, IS_EMPTY, READABLE_DATE, RANDOM_STRING, CAPITALIZE_STR
      }
    },

    components: { CModal, FILESCOMPONENT }, 

    methods: {    
    
        LOAD_FILES: function(x){ 
            console.log(x)   
           this.filter = x;
           this.files_pane = true
        },
 
        LOAD_FILE_CATEGORIES: function(){ // FETCH ALL MESSAGES FROM DATABASE   
            UTILITY_SERVICE.LOAD_FILE_CATEGORIES() 
            .then(result =>{ 

                !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) : null;

                if(result.data.success){ 
                    this.folders = result.data.data;  
                    // this.remItem =  DECRYPT(result.data.data).rem;
                    // this.page = DECRYPT(result.data.data).curPage;  
                    // this.totalSubs = DECRYPT(result.data.data).total; 
                    // let pLoad = DECRYPT(result.data.data).employees; 
                    
                    // setTimeout(() => this.employees = [...this.employees, ...pLoad],500)  

                }

                if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1000); 
            })
            .catch(err => {
                console.log('Error:' + err.message) 
                this.$store.commit('PAGE_NOTIFICATION', {
                    "success": false, 
                    "msg": 'error occured loading contents' 
                });
            })
        },   

        
        CHILD_ACTION: function(action){    
            if(action.reload){
                this.RELOAD_CONTENT()
            }   
            if(action.close == 'files'){
                this.files_pane = false;
            } 
            if(action.close == 'add'){
                this.add_pane = false;
            } 
            if(action.close == 'edit'){
                this.edit_pane = false;
            } 
        },   


    }, 
         
    mounted() { 
        this.LOAD_FILE_CATEGORIES()  
    } 
    
  }
</script>