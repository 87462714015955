<template> 

				<CModal alignment="center" backdrop="static" :visible="loadModal" @close="() => { loadModal = false }"> 
					<div class="card card-body">  
						<!--begin::Input group-->

						<div class="row">
							<div class="col-md-12 mb-7">
									<label class="form-label">Address <span class="text-danger">*</span></label> 
									<input v-model="address" type="text" class="form-control form-control-lg form-control-solid" placeholder="Enter name of institution" required="">
							</div>  
							
							<div class="col-md-12 mb-7">
								<label class="form-label">Country <span class="text-danger">*</span></label> 
								<select v-model="country" @change="GET_STATES()" class="form-select form-control form-control-lg form-control-solid shadow-sm"> 
									<option v-bind:value="country.id" v-for="(country, i) in countries" :key="i"> {{CAPITALIZE_STR(country.name)}}</option> 
								</select>
							</div> 
                            
							<div class="col-md-6 mb-7">
                                <label class="form-label">State <span class="text-danger">*</span></label> 
                                <select v-model="state" @change="GET_CITIES()" class="form-select form-control form-control-lg form-control-solid shadow-sm" :disabled="IS_EMPTY(states)"> 
                                    <option v-bind:value="st.id" v-for="(st, si) in states" :key="si"> {{CAPITALIZE_STR(st.name)}}</option> 
                                </select>
                            </div> 

							<div class="col-md-6 mb-7">
                                <label class="form-label">City <span class="text-danger">*</span></label> 
                                <select v-model="city" class="form-select form-control form-control-lg form-control-solid shadow-sm" id="orgCity" :disabled="IS_EMPTY(cities)"> 
                                    <option v-bind:value="city.id" v-for="(city, ci) in cities" :key="ci"> {{CAPITALIZE_STR(city.name)}}</option> 
                                    <option v-bind:value="0">others</option> 
                                </select>
							</div> 

						</div>
 
					</div>
                    <div class="card-footer py-4 d-flex justify-content-between"> 
                        <button class="btn btn-light-danger btn-sm" @click="loadModal = false, $emit('action', {'close': 'add'})">
                            Close
                        </button>
                        <button class="btn btn-light-primary btn-sm" id="add_btn" @click="ADD_OFFICE()">
                            Submit
                        </button>
                    </div>
				</CModal> 
</template>

<script>
// @ is an alias to /src 
import utility_services from "@/services/utility_services.js"; 
import PORTFOLIO_SERVICE  from "@/services/portfolio.js";  
//TABS COMPONENT  
import { CModal } from '@coreui/vue'
// import { PORTFOLIO_URLS } from "@/router/urls";
import { IS_EMPTY, APP_CONSTANTS, GET_INITIAL, TRUNCATE_STRING, GET_COLOR, THOUSAND_SEPARATOR, APP_INFO, STATUS_CODE, REMOVE_CHARC, RESPONSE_STATUS, STATUS_TAG_COLOR, CAPITALIZE_STR, CHECK_BOOLEAN, READABLE_DATE} from "@/utils/";  
import { PARSE_AUTH, ENCRYPT, DECRYPT } from "@/utils/security/"; 
export default {
name: "add-office",
props: ["subsidiary"],
data() {
    return{ 
        // Theme: this.$store.state.currentTheme,     
		loadModal: false, 
        editable_office: this.office,
        // PORTFOLIO_URLS, 
        READABLE_DATE, APP_CONSTANTS, ENCRYPT, GET_INITIAL, GET_COLOR, TRUNCATE_STRING, IS_EMPTY, THOUSAND_SEPARATOR, PARSE_AUTH, APP_INFO, STATUS_CODE, REMOVE_CHARC, STATUS_TAG_COLOR, CAPITALIZE_STR, CHECK_BOOLEAN,
        states: null,
        cities: null, 
        countries: this.$store.state.countries,
        country: '',
        state: '',
        city: '',
        typ: 'b',
        address: '',
        ref: this.$route.query.ref, // truncate ref url data to get user id 
    }
}, 
components: { CModal }, 
methods: {    

    GET_COUNTRIES: function() {
        utility_services.GET_COUNTRIES()
        .then(result => { // TRY ALL RESOURCE NEEDED FOR REGISTRATION 

        console.log(result.data)   
            if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1500); 
            !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" }) : this.countries = DECRYPT(result.data.data);
        })
        .catch(err => {
            this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" })
            console.log(err.message)
        }) 
    },
    
    GET_STATES: function() {
        this.states  = null; 
        this.cities  = null;  
        utility_services.GET_STATES({
            'id': this.country
        })
        .then(result => { // TRY ALL RESOURCE NEEDED FOR REGISTRATION  
            if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1500); 
            !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" }) : this.states = result.data.data; 
        })
        .catch(err => {
            this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" })
            console.log(err.message)
        })  
    },

    GET_CITIES: function(){
        this.cities  = null; 
        utility_services.GET_CITIES({'id':this.state})
        .then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION 
            if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1500); 
            !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" }) : this.cities  = result.data.data;   
        })
        .catch(err => {
            this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" })
            console.log(err.message)    
        })
    },
 
     ADD_OFFICE: function() { // FETCH ALL MESSAGES FROM DATABASE    
        let payload = ENCRYPT({
            id: this.subsidiary.id,
            country: this.country,
            state: this.state,
            city: this.city,
            typ: this.typ,
            address: this.address,
        })
        PORTFOLIO_SERVICE.ADD_OFFICE({ payload })
        .then(result => {  
            this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }); 
            if(result.data.success) this.$emit('action', { 'reload': true, 'close': 'add'}) //CLOSE ADD PANE AND RELOAD RECORDS 
            document.getElementById('add_btn').removeAttribute(`disabled`);
            document.getElementById('add_btn').innerHTML = 'Submit';
            if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 3000);          
        })
        .catch(err => {
            console.log('Error:' + err.message)
            document.getElementById('add_btn').removeAttribute(`disabled`);
            document.getElementById('add_btn').innerHTML = 'Submit';
            this.$emit('notifyer', { 'success': 'error', 'msg': 'error occured loading form contents' })
        })
    },
  
},
mounted() {
    this.loadModal = true;  
	this.GET_COUNTRIES() 
} 

};
</script>
