import register from "@/views/auth/register.vue";
import login from "@/views/auth/login.vue";
import welcome from "@/views/auth/welcome.vue"; 
import lockAccount from "@/views/auth/lock_account.vue"; 
// import unlockAccount from "@/views/auth/unlock_account.vue"; 
import verify from "@/views/auth/verify.vue"; 
import forgotPassword from "@/views/auth/forgot_password.vue";
import resetPassword from "@/views/auth/reset.vue";
// import selfServiceRegistration from "@/views/auth/self_service_registration.vue";


const AUTH_ROUTE = {
    'register': register,
    'login': login,
    'welcome': welcome, 
    'lockAccount': lockAccount,
//     'UnlockAccount': unlockAccount,
    'verify': verify,
    'forgotPassword': forgotPassword,
    'resetPassword': resetPassword, 
};

export default AUTH_ROUTE;