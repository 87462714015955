const CryptoJS = require("crypto-js"); 
 
const ENCRYPT = (str) => {

	let encrypt = CryptoJS.AES.encrypt(JSON.stringify(str), 'tasquik').toString();
	return encrypt.replace(/\+/g,'p1L2u3S').replace(/\//g,'s1L2a3S4h').replace(/=/g,'e1Q2u3A4l');
}  

const DECRYPT = (str) => { 
	let decrypt = str.replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=');
	return JSON.parse(CryptoJS.AES.decrypt(decrypt, 'tasquik').toString(CryptoJS.enc.Utf8));  
}  

const PARSE_AUTH = (auth, typ) => {     
    let res = '';
    let payload = DECRYPT(auth);
    switch (typ) {

        case 'user':
            res =  JSON.parse(payload).user
            break;  
			
        case 'header':
            res =  JSON.parse(payload).headers
            break;
    
        default:
            break;
    }   
    return res
} 
 
module.exports = { ENCRYPT, DECRYPT, PARSE_AUTH }