<template>
<BLANKLOADER v-if="loading" :ht="`h-400px`" />  
<div v-else class="d-flex flex-center flex-column flex-column-fluid">
    <!--begin::Wrapper-->
    <div class="w-lg-900px p-10 p-lg-5 mx-auto">
        <!--begin::Form-->
        <form class="my-auto fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate" id="kt_create_account_form"> 
            <!--begin::Step 5-->
            <div class="card shadow-none">
                <OVERLAYLOADER v-if="processing"/>
                <!--begin::Wrapper-->
                <div class="w-100">
                    <!--begin::Heading-->
                    <div class="pb-8 pb-lg-10">
                        <!--begin::Title-->
                        <h2 class="fw-bolder text-dark">Bio information!</h2> 
                        <!--end::Title--> 
                    </div>
                    <!--end::Heading-->
                    <!--begin::Body-->
                    <div class="mb-0"> 
                     <!--begin::Input group-->
                        <div class="row mb-6">
                            <!--begin::Label--> 
                            <!--end::Label-->
                            <!--begin::Col-->
                            <div class="col-lg-12">
                                <!--begin::Image input-->
                                    <div class="image-input image-input-outline" data-kt-image-input="true" :style="`background-image: url(${ dp })`">
                                        <!--begin::Preview existing avatar-->
                                    <div class="image-input-wrapper w-125px h-125px" :style="`background-image: url(${ dp })`">
                                    </div>
                                    <!--end::Preview existing avatar-->
                                    <!--begin::Label-->
                                    <label class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change avatar">
                                        <i class="bi bi-camera-fill fs-7"></i>
                                        <!--begin::Inputs-->
                                        <input type="file" name="avatar" accept=".png, .jpg, .jpeg" @change="PREVIEW_DP($event)" />
                                        <input type="hidden" name="avatar_remove" />
                                        <!--end::Inputs-->
                                    </label>
                                    <!--end::Label--> 
                                </div>
                                <!--end::Image input-->
                                <!--begin::Hint-->
                                <div class="form-text">Allowed file types: png, jpg, jpeg.</div>
                                <!--end::Hint-->
                            </div>
                            <!--end::Col-->
                        </div>
                        <!--end::Input group-->
                        <div class="row mb-4">     

                            <!--begin::Col-->
                            <div class="col-lg-6 mt-md-3">
                                <label class="col-form-label required fw-bold fs-6">Surname</label>
                                <input type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Surname" v-model="editable_user.surname"/> 
                            </div>
                            <!--end::Col-->

                            <!--begin::Col-->
                            <div class="col-lg-6 mt-md-3">
                                <label class="col-form-label required fw-bold fs-6">First name</label>
                                <input type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Last name" v-model="editable_user.first_name"/> 
                            </div>
                            <!--end::Col-->

                            <!--begin::Col-->
                            <div class="col-lg-6 mt-md-3">
                                <label class="col-form-label required fw-bold fs-6">Other name</label>
                                <input type="text" class="form-control form-control-lg form-control-solid" placeholder="Other name" v-model="editable_user.other_name"/> 
                            </div>
                            <!--end::Col-->  

                            <!--begin::Col-->
                            <div class="col-6 col-lg-3 mt-md-3">
                                <label class="col-form-label required fw-bold fs-6">Date of birth</label>
                                <input type="date" class="form-control form-control-lg form-control-solid" v-model="editable_user.dob"/> 
                            </div>
                            <!--end::Col-->  

                            <!--begin::Col-->
                            <div class="col-6 col-lg-3 mt-md-3">
                                <label class="col-form-label required fw-bold fs-6">Gender</label>
                                <select class="form-select form-select-solid form-select-lg fw-bold" v-model="editable_user.gender" >
                                    <option value="male" :selected="editable_user.gender == 'male' ">Male</option>
                                    <option value="female" :selected="editable_user.gender == 'female' ">Female</option>
                                </select>
                            </div>
                            <!--end::Col--> 

                            <!--begin::Col-->
                            <div class="col-lg-5 mt-md-3">
                                <label class="col-form-label required fw-bold fs-6">Hometown</label>
                                <input type="text" class="form-control form-control-lg form-control-solid" v-model="editable_user.hometown"/> 
                            </div>
                            <!--end::Col-->   

                            <!--begin::Col-->
                            <div class="col-lg-3 mt-md-3">
                                <label class="col-form-label required fw-bold fs-6">Phone number</label>
                                <input type="text" class="form-control form-control-lg form-control-solid" placeholder="Phone number" v-model="editable_user.phone"/> 
                            </div>
                            <!--end::Col-->        

                            <!--begin::Col-->
                            <div class="col-lg-4 mt-md-3">
                                <label class="col-form-label required fw-bold fs-6">Local Govt. Area</label>
                                <input type="text" class="form-control form-control-lg form-control-solid" placeholder="Local government area" v-model="editable_user.lga"/>
                            </div>
                            <!--end::Col-->     


                            <!--begin::Col-->
                            <div class="col-lg-4 mt-md-3">
                                <label class="col-form-label required fw-bold fs-6">Country of origin</label>
                                <select class="form-select form-select-solid form-select-lg fw-bold" v-model="editable_user.country_id" @change="GET_STATES()">
                                    <option v-bind:value="country.id" v-for="(country, i) in countries" :key="i"> {{country.name}}</option>
                                </select>
                            </div>
                            <!--end::Col-->  
                            
                            <!--begin::Col-->
                            <div class="col-lg-4 mt-md-3">
                                <label class="col-form-label required fw-bold fs-6">State of origin</label>
                                <select class="form-select form-select-solid form-select-lg fw-bold" v-model="editable_user.state_id"  @change="GET_CITIES()">
                                    <option v-bind:value="st.id" v-for="(st, i) in states" :key="i"> {{st.name}}</option>
                                </select>
                            </div>
                            <!--end::Col-->  

                            <!--begin::Col-->
                            <div class="col-lg-4 mt-md-3">
                                <label class="col-form-label required fw-bold fs-6">Resident city</label>
                                <select class="form-select form-select-solid form-select-lg fw-bold" v-model="editable_user.city_id"> 
                                    <option v-bind:value="city.id" v-for="(city, i) in cities" :key="i"> {{city.name}}</option> 
                                    <option v-bind:value="0">others</option> 
                                </select>
                            </div>
                            <!--end::Col-->  

                            <!--begin::Col-->
                            <div class="col-lg-12 mt-md-3">
                                <label class="col-form-label required fw-bold fs-6">Residential address</label>
                                <input type="text" class="form-control form-control-lg form-control-solid" placeholder="Phone number" v-model="editable_user.address"/> 
                            </div>
                            <!--end::Col-->   
                            
                            <!--begin::Col-->
                            <div class="col-lg-12">
                                <label class="col-form-label required fw-bold fs-6">About me</label>
                                <textarea class="form-control" id="kt_docs_maxlength_textarea" placeholder="" rows="6" v-model="editable_user.about" />
                                <span class="fs-6 text-muted">Max. 500 words</span>
                            </div>
                            <!--end::Col-->   
                        </div>
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Wrapper-->
                <div class="mt-8 mb-1 d-flex justify-content-end"> 
                    <button @click="DO_SUBMIT()" v-if="IS_EMPTY(user.onboarding)" type="button" class="btn btn-sm btn-light-primary shadow-lite rounded-0 animated fadeIn me-2">Continue <i class="mdi mdi-arrow-right"></i></button> 
                    <button @click="DO_SUBMIT()" v-if="!IS_EMPTY(user.onboarding) && user.onboarding.stage > ONBOARDING_STATUS.bio" type="button" class="btn btn-sm btn-light-primary shadow-lite rounded-0 animated fadeIn me-2">Update <i class="mdi mdi-arrow-right"></i></button> 
                    <button @click="$emit('action', {stage: ONBOARDING_STATUS.education, processing: false})" v-if="!IS_EMPTY(user.onboarding) && user.onboarding.stage > ONBOARDING_STATUS.bio" type="button" class="btn btn-sm btn-light shadow-lite rounded-0 animated fadeIn me-2">Skip <i class="mdi mdi-arrow-right"></i></button> 
                </div> 
            </div>
            <!--end::Step 5--> 

        </form>
        <!--end::Form-->
    </div>
    <!--end::Wrapper-->
</div>
</template>

<script>  
import utility_services from "@/services/utility_services"; 
import AUTH_SERVICE from "@/services/auth.js"; 
//EMPLOYEE COMPONENT
import BLANKLOADER from "@/components/loaders/blank-loader.vue";
import OVERLAYLOADER from "@/components/loaders/overlay-loader.vue" 
import { ENCRYPT, DECRYPT } from "@/utils/security/";  
import { IS_EMPTY, ADMINISTRATIVE_CLASS, CHECK_BOOLEAN, ONBOARDING_STATUS, READABLE_DATE, APP_INFO, REMOVE_CHARC, RESPONSE_STATUS } from "@/utils/"; 
export default {
    name: "self-service-bio", 
    props: ["user"],  
    data() {
        return{  
            active_drop: 1, 
            processing: false, 
            loading: true,
            notifyer: null,
            IS_EMPTY, CHECK_BOOLEAN, ONBOARDING_STATUS, ADMINISTRATIVE_CLASS, READABLE_DATE, REMOVE_CHARC, RESPONSE_STATUS, APP_INFO,
            editable_user: this.user,
            chkPassword: '',
            states: '',
            countries: '',
            cities: '',
            photo: '',
            dp: IS_EMPTY(this.user.photo) ? APP_INFO.default_img : this.user.photo,
            token: this.$route.query.token, // truncate ref url data to get user id 
        }
    }, 
	methods: {
         
        PREVIEW_DP: function(event) { 
            this.photo = event.target.files[0];
            (this.photo) ? this.dp = URL.createObjectURL(this.photo) : null
            // this.UPLOAD_USER_PHOTO() // PROCESS IMAGE UPLOAD
        },
        
		GET_COUNTRIES: function() {
			utility_services.GET_COUNTRIES()
            .then(result => { // TRY ALL RESOURCE NEEDED FOR REGISTRATION 
                this.loading = false; 
                if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1500); 
                !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" }) : this.countries = DECRYPT(result.data.data);
            })
            .catch(() => {
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" })
            }) 
		},

		GET_STATES: function() {
			this.states = '';
            this.cities  = ''; 
			utility_services.GET_STATES({
                'id': this.editable_user.country_id
            })
            .then(result => { // TRY ALL RESOURCE NEEDED FOR REGISTRATION  
                if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1500); 
                !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" }) : this.states = result.data.data; 
            })
            .catch(() => {
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" })
            })  
		},

        GET_CITIES: function(){
            this.cities  = null; 
            utility_services.GET_CITIES({'id':this.editable_user.state_id})
            .then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION 
                if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1500); 
                !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" }) : this.cities  = result.data.data;   
            })
            .catch(() => {
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" })
            })
        },

        // VALIDATE FORM DATA
        // DO_VALIDATE: function(){
        //     !GRANT_ACCESS(this.editable_user.access_priviledge, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT) ? this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " you don`t have the access right to complete this process!" }) : this.DO_PROFILE_UPDATE();
        // },

        // VALIDATE FORM DATA
        DO_VALIDATE: function(){
          this.DO_PROFILE_UPDATE();
        },

        //PROCESS IMAGE UPLOAD
        
		UPLOAD_USER_PHOTO: function() {  
			let formData = new FormData();
			formData.append("photo", this.photo)  
 
            let payload = ENCRYPT({"id": this.user.id, "token": this.token}) 

            AUTH_SERVICE.UPLOAD_SELF_SERVICE_PHOTO(payload, formData)
            .then(result => {   
                if(result.data.success) this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg })
                else this.$store.commit('PAGE_NOTIFICATION', {"success": true, "msg": "bio information saved but was unable to upload photo." });
            })
            .catch(() => {  
                this.$emit('action', {stage: null, processing: false, reload: true});
                this.$store.commit('PAGE_NOTIFICATION', {"success": true, "msg": "bio information saved but was unable to upload user photo." }) 
            }) 
        },

        //PROFILE UPDATE
        DO_SUBMIT: function(){
            this.$emit('action', {stage: null, processing: true}) // trigger the processing overlay
            let payload = ENCRYPT(this.editable_user)  
            AUTH_SERVICE.SELF_SERVICE_BIO_SUBMISSION({payload})
            .then(result => {       
                if(result.data.success){  // no photo to upload
                    this.$emit('action', {stage: null, processing: false, reload: true}); // reload parent component
                    !IS_EMPTY(this.photo) ? this.UPLOAD_USER_PHOTO() : this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }); 
                }
                else this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg });
            })
            .catch(() => { 
                this.$emit('action', {stage: null, processing: false})
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured processing request, try again!" }); 
            })
        }, 
    },
    components: { BLANKLOADER, OVERLAYLOADER }, 
    mounted() {  
        this.GET_COUNTRIES()
        this.GET_STATES()
        this.GET_CITIES()
        let extScript = document.createElement('script')
        extScript.setAttribute('src', '/js/custom/documentation/forms/image-input.js') 
        document.head.appendChild(extScript)  
    }    
}
</script> 