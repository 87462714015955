import { createRouter, createWebHistory } from "vue-router"; 
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import store from "@/store";
import {APP_INFO, IS_EMPTY, ACCESS_PRIVELEDGES, GRANT_ACCESS, REMOVE_CHARC, ADMINISTRATIVE_CLASS} from "@/utils";
import { PARSE_AUTH } from "@/utils/security/";  

//app routes
import URLS from "@/router/urls"; //AUTH_ROUTE 
import AUTH_ROUTE from "@/router/routes/auth.js"; //AUTH_ROUTE 
import SELF_REG_ROUTE from "@/router/routes/self_registration.js"; //AUTH_ROUTE 
import USER_ROUTE from "@/router/routes/user.js"; //USER_ROUTE  
import PORTFOLIO_ROUTE from "@/router/routes/portfolio.js"; //USER_ROUTE   



const routes = [
  
  {
    path: "/",
    name: "Home",
    component: AUTH_ROUTE.login,
    meta: { requires_auth: false, title: "Home | " + APP_INFO.name },
  },

  //AUTH ROUTES
  {
    path: "/auth/login",
    name: "Login",
    component: AUTH_ROUTE.login,
    meta: { requires_auth: false, title: "Login | " + APP_INFO.name },
  },
  {
    path: "/auth/register",
    name: "register",
    component: AUTH_ROUTE.register,
    meta: { requires_auth: false, title: "Register | " + APP_INFO.name },
  },
  {
    path: "/auth/welcome",
    name: "welcome",
    component: AUTH_ROUTE.welcome,
    meta: { requires_auth: false, title: "Welcome | " + APP_INFO.name },
  },
  {
    path: "/auth/verify-email",
    name: "verifyEmail",
    component: AUTH_ROUTE.verify,
    meta: { requires_auth: false, title: "Verify email| " + APP_INFO.name },
  },
  {
    path: "/auth/forgot-password",
    name: "forgotPassword",
    component: AUTH_ROUTE.forgotPassword,
    meta: { requires_auth: false, title: "Forgot password | " + APP_INFO.name },
  },
  {
    path: "/auth/reset-password",
    name: "resetPassword",
    component: AUTH_ROUTE.resetPassword,
    meta: { requires_auth: false, title: "Reset password | " + APP_INFO.name },
  },
  {
    path: "/auth/lock-account",
    name: "lockAccount",
    component: AUTH_ROUTE.lockAccount,
    meta: { requires_auth: false, title: "Lock account | " + APP_INFO.name },
  },
  {
    path: "/auth/complete-self-service-registration",
    name: "lockAccount",
    component: SELF_REG_ROUTE.index,
    meta: { requires_auth: false, title: "Self Registration | " + APP_INFO.name },
  },

  //USER ROUTES
  {
    path: "/dashboard",
    name: "Dashboard",
    component: USER_ROUTE.dashboard,
    meta: { 
      title: "Dashboard | Tasquik.com", 
      requires_auth: true, /* CHECK USER ACCESS */ 
      admin_class: ADMINISTRATIVE_CLASS.PASS.clearance_level, 
      access: ACCESS_PRIVELEDGES.PASS /* END CHECK */
    },
  },
  {
    path: URLS.PORTFOLIO_URLS('organizations'),
    name: "Organizations",
    component: PORTFOLIO_ROUTE.organizations,
    meta: { 
      title: "Organizations | Tasquik.com", 
      requires_auth: true, /* CHECK USER ACCESS */ 
      admin_class: ADMINISTRATIVE_CLASS.SUPER_ADMINISTRATOR.clearance_level, 
      access: ACCESS_PRIVELEDGES.WILD_CARD /* END CHECK */
    },
  },
  // {
  //   path: "/dashboard-s",
  //   name: "Portfolio",
  //   component: PORTFOLIO_ROUTE.dashboard,
  //   meta: { 
  //     title: "Portfolio | Tasquik.com", 
  //     requires_auth: true, /* CHECK USER ACCESS */ 
  //     admin_class: ADMINISTRATIVE_CLASS.ADMINISTRATOR.clearance_level, 
  //     access: ACCESS_PRIVELEDGES.MAIN_ADMIN /* END CHECK */
  //   },
  // },
  {
    path: URLS.PORTFOLIO_URLS('subsidiaries'),
    name: "SUBSIDIARIES",
    component: PORTFOLIO_ROUTE.subsidiaries,
    meta: { 
      title: "SUBSIDIARIES | Tasquik.com", 
      requires_auth: true, /* CHECK USER ACCESS */ 
      admin_class: ADMINISTRATIVE_CLASS.MAIN_ADMINISTRATOR.clearance_level, 
      access: ACCESS_PRIVELEDGES.PASS /* END CHECK */
    },
  },
  {
    path: URLS.PORTFOLIO_URLS('subsidiary'),
    name: "Dashboard-s",
    component: PORTFOLIO_ROUTE.dashboard,
    meta: { 
      title: "Dashboard | Tasquik.com", 
      requires_auth: true, /* CHECK USER ACCESS */ 
      admin_class: ADMINISTRATIVE_CLASS.ADMINISTRATOR.clearance_level, 
      access: ACCESS_PRIVELEDGES.PASS /* END CHECK */
    },
  },
  {
    path: URLS.PORTFOLIO_URLS('offices'),
    name: "OFFICE_LOCATIONS",
    component: PORTFOLIO_ROUTE.offices,
    meta: { 
      title: "Offices | Tasquik.com", 
      requires_auth: true, /* CHECK USER ACCESS */ 
      admin_class: ADMINISTRATIVE_CLASS.MAIN_ADMINISTRATOR.clearance_level, 
      access: ACCESS_PRIVELEDGES.PASS /* END CHECK */
    },
  },
  {
    path: URLS.PORTFOLIO_URLS('management'),
    name: "COMPANY_MANAGEMENT",
    component: PORTFOLIO_ROUTE.management,
    meta: { 
      title: "Management | Tasquik.com", 
      requires_auth: true, /* CHECK USER ACCESS */ 
      admin_class: ADMINISTRATIVE_CLASS.MAIN_ADMINISTRATOR.clearance_level, 
      access: ACCESS_PRIVELEDGES.PASS /* END CHECK */
    },
  },
  {
    path: URLS.PORTFOLIO_URLS('employee-management'),
    name: "EMPLOYEE_MANAGEMENT",
    component: PORTFOLIO_ROUTE.employeeManagement,
    meta: { 
      title: "ALL EMPLOYEES | Tasquik.com", 
      requires_auth: true, /* CHECK USER ACCESS */ 
      admin_class: ADMINISTRATIVE_CLASS.MAIN_ADMINISTRATOR.clearance_level, 
      access: ACCESS_PRIVELEDGES.PASS /* END CHECK */
    },
  },
  {
    path: URLS.PORTFOLIO_URLS('settings'),
    name: "SETTINGS",
    component: PORTFOLIO_ROUTE.settings,
    meta: { 
      title: "Settings | Tasquik.com", 
      requires_auth: true, /* CHECK USER ACCESS */ 
      admin_class: ADMINISTRATIVE_CLASS.MAIN_ADMINISTRATOR.clearance_level, 
      access: ACCESS_PRIVELEDGES.PASS /* END CHECK */
    },
  }, 
  {
    path: "/employees",
    name: "SUBSIDIARY_EMPLOYEES",
    component: PORTFOLIO_ROUTE.employees,
    meta: { 
      title: "Employees | Tasquik.com", 
      requires_auth: true, /* CHECK USER ACCESS */ 
      admin_class: ADMINISTRATIVE_CLASS.MAIN_ADMINISTRATOR.clearance_level, 
      access: ACCESS_PRIVELEDGES.PASS /* END CHECK */
    },
  },


 
  // //error page route
  // {
  //     path: "/not-authorized",
  //     name: "401",
  //     component: ERROR_ROUTE.error401,
  //     meta: { requires_auth: true, title: "Not authorized | " + APP_INFO.name },
  // }, 
  // {
  //     path: "/bad-request",
  //     name: "401",
  //     component: ERROR_ROUTE.errorBadRequest,
  //     meta: { requires_auth: true, title: "Bad Request | " + APP_INFO.name },
  // }, 
  // {
  //     path: "/404",
  //     name: "404",
  //     component: ERROR_ROUTE.error404,
  //     meta: { requires_auth: true, title: "Page Not Found  | " + APP_INFO.name },
  // },
  // {
  //     path: "/:pathMatch(.*)*",
  //     name: "404",
  //     component: ERROR_ROUTE.error_404,
  //     meta: { requires_auth: true, title: "Page Not Found  | " + APP_INFO.name },
  // },
  // // {
  // //   path: "/about",
  // //   name: "About",
  // //   // route level code-splitting
  // //   // this generates a separate chunk (about.[hash].js) for this route
  // //   // which is lazy-loaded when the route is visited.
  // //   component: () =>
  // //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeResolve((to, from, next) => { 
  if (to.name) { 
    NProgress.configure({ showSpinner: false }).start();
  }
    NProgress.configure({ showSpinner: false }).start();
    next();
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title // paget title setter
  let date = new Date();
  let auth = store.state.auth // get user auth data 
  let is_authenticated = store.state.is_authenticated // get user auth data  
  store.commit('PAGE_NOTIFICATION', null)

  if (to.matched.some(record => record.meta.requires_auth)) {  
    store.commit('PAGE_NOTIFICATION', null)
    if (is_authenticated || !IS_EMPTY(auth)) { //check if user is authenticated 
        if(PARSE_AUTH(auth, "header").meta >= date){ // check if token is still active using date comparism (7days age long)  
            if (GRANT_ACCESS(PARSE_AUTH(store.state.auth, "user"), to.meta.admin_class, to.meta.access))  next()  
            else if (!GRANT_ACCESS(PARSE_AUTH(store.state.auth, "user"), to.meta.admin_class, to.meta.access))  next("/not-authorized");     
        }
        else store.commit('AUTH_LOGOUT', REMOVE_CHARC(to.path, '/')); // log this user out to refresh token   
    }
    else  next(`/auth/login?action=redirect_login&refferer_page=` + REMOVE_CHARC(to.path, '/')); // send user to login page with intending page so as to execute a redirect after login 
  } 
  else next(); 

})


router.afterEach(() => { NProgress.done() });

export default router;
