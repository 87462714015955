<template>
<div class="d-flex flex-center flex-column flex-column-fluid">
    <!--begin::Wrapper-->
    <div class="w-lg-900px p-10 p-lg-5 mx-auto">
        <!--begin::Form-->
        <form class="my-auto pb-5 fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate" id="kt_create_account_form"> 
            <!--begin::Step 5-->
            <div >
                <!--begin::Wrapper-->
                <div class="w-100">
                    <!--begin::Heading-->
                    <div class="pb-8 pb-lg-10">
                        <!--begin::Title-->
                        <h2 class="fw-bolder text-dark">Surity Information</h2>
                        <!--end::Title-->
                        <!--begin::Notice-->
                        <div class="text-muted fw-bold fs-6">If you need more info, please
                        <a href="../../demo3/dist/authentication/sign-in/basic.html" class="link-primary fw-bolder">Sign In</a>.</div>
                        <!--end::Notice-->
                    </div>
                    <!--end::Heading-->
                    <!--begin::Body-->
                    <div class="mb-0">
                        <div class="row mt-10 animated fadeIn border shadow-lite px-md-10 pt-md-10 p-5" v-for="(surity, i) in surity_list" :key="i">

                            <div class="col-md-3 mb-3">
                                <label class="form-label">Surity type <span class="text-danger">*</span></label> 
                                <select class="form-select form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="surity.surity_type_id">
                                    <option v-for="(st, i) in surityTypes" :key="i" :value="st.id">{{CAPITALIZE_STR(st.name)}}</option>
                                </select>
                            </div>

                            <div class="col-md-5 mb-3">
                                    <label class="form-label">Name <span class="text-danger">*</span></label> 
                                    <input v-model="surity.name" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                            </div>

                            <div class="col-md-4 mb-3">
                                <label class="form-label">Email <span class="text-danger">*</span></label> 
                                <input v-model="surity.email" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                            </div> 

                            <div class="col-md-4 mb-3">
                                <label class="form-label">Phone <span class="text-danger">*</span></label> 
                                <input v-model="surity.phone" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                            </div> 

                            <div class="col-md-4 mb-3">
                                <label class="form-label">Relationship <span class="text-danger">*</span></label> 
                                <input v-model="surity.relationship" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                            </div> 

                            <div class="col-md-4 mb-3">
                                <label class="form-label">Ocuppation <span class="text-danger">*</span></label> 
                                <input v-model="surity.occupation" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                            </div> 

                            <div class="col-md-3 mb-3">
                                <label class="form-label">Status <span class="text-danger">*</span></label> 
                                <input value="Unverified" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" disabled> 
                            </div> 
                            
                            <div class="col-md-5 mb-3">
                                <label class="form-label">Address <span class="text-danger">*</span></label> 
                                <input v-model="surity.address" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                            </div> 

                            
                            <div class="col-md-4 mb-3"> 
                                <label class="form-label">Country of residence<span class="text-danger">*</span></label> 
                                <div class="form-icon position-relative"> 
                                <select v-model="surity.country_id" class="form-select form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="country"> 
                                    <option v-bind:value="country.id" v-for="(country, coi) in countries" :key="coi"> {{CAPITALIZE_STR(country.name)}}</option> 
                                </select>
                                </div> 
                            </div><!--end col--> 

                            <div class="col-md-4 col-5 mb-5" v-if="i !== 0"> 
                                <button @click="DELETE_ENTRY(surity.id, i)" type="button" class="btn btn-light-danger rounded-0 shadow-lite">remove</button>
                            </div>
 
                        </div> 
 
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Wrapper-->
            </div>
            <!--end::Step 5--> 
           <div class="mt-8 mb-1 d-flex justify-content-between">
                <button @click="$emit('action', {stage: ONBOARDING_STATUS.work_experience, processing: false})" type="button" class="btn btn-sm btn-light-primary shadow-lite rounded-0 animated fadeIn"><i class="mdi mdi-arrow-left"></i> Back</button>
                
                <div class="d-flex justify-content-between">
                    <button @click="MORE_SURITY()" type="button" class="btn btn-sm btn-primary shadow-lite rounded-0 animated fadeIn me-2 me-sm-3">More <i class="mdi mdi-plus fw-bolder"></i></button>
                    <button @click="DO_SUBMIT()" type="button" class="btn btn-sm btn-light-primary shadow-lite rounded-0 animated fadeIn me-1 me-sm-3" v-if="user.onboarding.stage == ONBOARDING_STATUS.surity">Continue <i class="mdi mdi-arrow-right"></i></button>
                    <button @click="DO_SUBMIT()" type="button" class="btn btn-sm btn-light-primary shadow-lite rounded-0 animated fadeIn me-1 me-sm-3" v-if="user.onboarding.stage > ONBOARDING_STATUS.surity">Update <i class="mdi mdi-arrow-right"></i></button>
                    <button @click="this.$emit('action', {stage: ONBOARDING_STATUS.completed, processing: false})" type="button" class="btn btn-sm btn-light shadow-lite rounded-0 animated fadeIn me-1 me-sm-3" v-if="user.onboarding.stage > ONBOARDING_STATUS.surity">Skip <i class="mdi mdi-arrow-right"></i></button>
                </div> 
            </div> 

        </form>
        <!--end::Form-->
    </div>
    <!--end::Wrapper-->
</div>
</template>

<script>
// @ is an alias to /src   
import utility_services from "@/services/utility_services.js"; 
import AUTH_SERVICE  from "@/services/auth.js"; 
import {MILLISECONDS_TO_DAYS, CAPITALIZE_STR, REMOVE_CHARC, ONBOARDING_STATUS, IS_EMPTY , RESPONSE_STATUS, STATUS_CODE} from "@/utils/"
import { ENCRYPT, DECRYPT } from "@/utils/security/"; 
export default {
name: "add-employee-surty",
props: ['user'], 
data() {
    return{ 
        // Theme: this.$store.state.currentTheme,  
        ref: this.$route.query.ref, // truncate ref url data to get user id
        token: this.$route.query.token, // truncate ref url data to get user id 
        MILLISECONDS_TO_DAYS, CAPITALIZE_STR, REMOVE_CHARC, ONBOARDING_STATUS, ENCRYPT, DECRYPT, STATUS_CODE, RESPONSE_STATUS,
        surityTypes: '',
        countries: null,
        states: null, 
        cities: null,
        address: '',  
        surity_list: [{ 
            "id": null, 
            "employee_id": this.user.employee.id,  
            "surity_type_id": "", 
            "city_id": null,
            "state_id": null,
            "country_id": "",
            "status_id": STATUS_CODE.UNVERIFIED,   
            "name": "",
            "email": "", 
            "phone": "", 
            "relationship": "", 
            "address": "", 
            "occupation": "", 
            "photo": null,   
        }]
    }
},
components: { }, 
methods: {

    
    MORE_SURITY: function() {
        if( this.surity_list.length < 5){ 
            this.surity_list.push({
                "id": null, 
                "employee_id": this.user.employee.id,  
                "surity_type_id": "", 
                "city_id": null,
                "state_id": null,
                "country_id": "",
                "status_id": STATUS_CODE.UNVERIFIED,   
                "name": "",
                "email": "", 
                "phone": "", 
                "relationship": "", 
                "address": "", 
                "occupation": "", 
                "photo": null, 
            });   
        }else{
            this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " only 5 records can be added." })
        }
    },

     
    GET_COUNTRIES: function() {
        utility_services.GET_COUNTRIES()
        .then(result => { // TRY ALL RESOURCE NEEDED FOR REGISTRATION  
            if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1500); 
            !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" }) : this.countries = DECRYPT(result.data.data);
        })
        .catch(() => {
            this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" }) 
        }) 
    },

    // GET_STATES: function(id, i) { 
    //     console.log(i)
    //     this.states+i  = null; 
    //     this.cities+i  = null;  
    //     utility_services.GET_STATES({id})
    //     .then(result => { // TRY ALL RESOURCE NEEDED FOR REGISTRATION  
    //         if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1500); 
    //         !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" }) : this.states = result.data.data; 
    //     })
    //     .catch(err => {
    //         this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" })
    //         console.log(err.message)
    //     })  
    // },
    

    // GET_CITIES: function(id){
    //     this.cities  = null; 
    //     utility_services.GET_CITIES({id})
    //     .then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION 
    //         if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1500); 
    //         !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" }) : this.cities  = result.data.data;   
    //     })
    //     .catch(err => {
    //         this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" })
    //         console.log(err.message)    
    //     })
    // },

    DELETE_ENTRY: function(id, i) { // FETCH ALL MESSAGES FROM DATABASE 
         this.$emit('action', {stage: ONBOARDING_STATUS.surity, processing: true})
        if(!IS_EMPTY(id)){
            const payload = ENCRYPT(id)  
            AUTH_SERVICE.DELETE_SERVICE_SURITY({payload})  
            .then(result => {  
                this.$emit('action', {stage: ONBOARDING_STATUS.surity, processing: false})
                this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg });  
                if(result.data.success) this.surity_list.splice(i, 1); // remove item
            })
            .catch(err => { 
                this.$emit('action', {stage: ONBOARDING_STATUS.surity, processing: false})
                console.log('Error:' + err.message) 
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": "encountered a fatal server error" })
            })
        }
        else{
            this.$emit('action', {stage: ONBOARDING_STATUS.surity, processing: false})
            this.surity_list.splice(i, 1); // remove item 
        }  

    },

    LOAD_SURITIES: function() { // FETCH ALL MESSAGES FROM DATABASE    
        let payload = ENCRYPT({'id': this.user.employee.id, 'token': DECRYPT(this.token)}); 
        AUTH_SERVICE.LOAD_SURITIES({payload})   
        .then(result =>{ 
            this.loading = false; 
            if(result.data.success){ 
                let resp = DECRYPT(result.data.data); 
                resp.length > 0 ? this.surity_list = resp : null
                // this.surity_list = DECRYPT(result.data.data);
            }
            else{
                this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) 
            }
        })
        .catch(() => {
            this.loading = false; 
            this.$store.commit('PAGE_NOTIFICATION', {  "success": false, "msg": 'error occured loading contents' });
        })
    },
    
    LOAD_SURITY_TYPES: function() { // FETCH ALL MESSAGES FROM DATABASE 
        this.retry = ''
        utility_services.LOAD_SUTIRY_TYPES()
        .then(result => { 
            console.log(result.data)
            if (!result.data.success) { 
                this.$emit('notifyer', { 'success': 'error', 'msg': 'error occured loading form contents' })
            } 
            else if (result.data.success) {  
                this.surityTypes = DECRYPT(result.data.data)  
            }
        })
        .catch(err => {
            console.log('Error:' + err.message)
            this.isLoading = 'error';
            this.$emit('notifyer', { 'success': 'error', 'msg': 'error occured loading form contents' })
        })
    }, 

     //PROFILE UPDATE
    DO_SUBMIT: function(){
        this.$emit('action', {stage: ONBOARDING_STATUS.surity, processing: true}) // trigger the processing overlay
        let payload = ENCRYPT(this.surity_list)  
        const id = ENCRYPT(this.user.id) 
        AUTH_SERVICE.SELF_SERVICE_SURITY_SUBMISSION({payload, id})
        .then(result => {       
            this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) 
            result.data.success ? this.$emit('action', {stage: ONBOARDING_STATUS.completed, processing: false, reload: true})  : null
        })
        .catch(() => { 
            this.$emit('action', {stage: ONBOARDING_STATUS.surity, processing: false})
            this.processing = false; 
            this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured processing request, try again!" }); 
        })
    },

}, 
mounted() {
    
    this.LOAD_SURITIES()
    this.LOAD_SURITY_TYPES()
    IS_EMPTY(this.countries) ? this.GET_COUNTRIES() : null
},

};
</script>