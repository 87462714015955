<template>
 <!--begin::Root-->
		<div class="d-flex flex-column flex-root">
			<!--begin::Authentication - Signup Welcome Message -->
			<div class="d-flex flex-column flex-column-fluid">
				<!--begin::Content-->
				<div class="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
					<!--begin::Logo-->
					<router-link to="/" class="mb-5">
						<img alt="Logo" src="/media/logos/logo-1.svg" class="h-20px" />
					</router-link>
					<!--end::Logo-->
					<!--begin::Wrapper-->
					<div class="pt-lg-5 mb-5">
						<!--begin::Logo-->
						<h1 class="fw-bolder fs-2qx text-gray-800 mb-5">Welcome onboard!</h1>
						<!--end::Logo-->
						<!--begin::Message-->
						<div class="text-muted mb-5">A confirmation mail has been sent to your email address, <br class="d-none d-md-inline-block"> kindly confirm to activate your account.</div> 
						<!--end::Message--> 
					</div>
					<!--end::Wrapper-->
					<!--begin::Illustration-->
					<div class="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-250px min-h-lg-350px" style="background-image: url(/media/illustrations/sketchy-1/17.png"></div>
					<!--end::Illustration-->
				</div>
				<!--end::Content-->
				<!--begin::Footer-->
				<div class="d-flex flex-center flex-column-auto p-10" style="bottom: 0 !important;">
					<!--begin::Links-->
					<div class="d-flex align-items-center fw-bold fs-6">
						<router-link to="https://keenthemes.com" class="text-muted text-hover-primary px-2">About</router-link>
						<router-link to="mailto:support@keenthemes.com" class="text-muted text-hover-primary px-2">Contact</router-link>
						<router-link to="https://1.envato.market/EA4JP" class="text-muted text-hover-primary px-2">Contact Us</router-link>
					</div>
					<!--end::Links-->
				</div>
				<!--end::Footer-->
			</div>
			<!--end::Authentication - Signup Welcome Message-->
		</div>
		<!--end::Root-->
</template>

<script>
// @ is an alias to /src 
import { APP_INFO } from "@/utils/";  

export default {
name: "Welcome",
data() {
    return{  
        APP_INFO: APP_INFO,
    }
}
};
</script>