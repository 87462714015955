<template>
<div>
<Notification />  
    <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper"> 
        <TopNav />
        <SideNav /> 
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
            <!--begin::Container-->
            <div class="container-xxl px-0 px-md-4" id="kt_content_container">  
 
        <!--end::Card toolbar-->

									<div class="bg-white py-2 px-5 mb-4">
										<!--begin::Card header-->
										<div class="d-flex justify-content-between pt-3">
											<!--begin::Tabs-->
											<div class="pt-3 mb-0 gap-4 gap-lg-10 gap-xl-15 nav nav-tabs border-bottom-0" data-kt-table-widget-3="tabs_nav">
												<!--begin::Tab item-->
												<div class="fs-7 fw-bolder pb-3 cursor-pointer" :class="active_tab == i ? 'border-bottom border-3 border-primary' : 'text-muted'" v-for="(tab, i) in tabs" :key="i" @click="FILTER_SUBSIDIARIES(i)">{{tab}} ({{THOUSAND_SEPARATOR(subsidiaries.length)}})</div>
												<!--end::Tab item--> 
											</div>
											<!--end::Tabs--> 
                                            <AddSubsidiary />
										</div>
                                    </div> 
 
									<!--begin::Table Widget 3-->
									<div class="card card-flush h-xl-100 mb-5"> 
										<!--begin::Card body-->
										<div class="card-body pt-1">  
											<!--end::Seprator-->
											<!--begin::Table-->
											<div  class="dataTables_wrapper dt-bootstrap4 no-footer">
                                                <div class="table-responsive" v-if="!IS_EMPTY(subsidiaries)">
                                                    <table id="kt_widget_table_3" class="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3 dataTable no-footer" data-kt-table-widget-3="all">
                                                        
                                                        <tbody>  
                                                        <tr v-for="(subsidiary, i) in subsidiaries" :key="i">
                                                            <td class="min-w-175px">
                                                                <div class="position-relative ps-6 pe-3 py-2">
                                                                    <div class="position-absolute start-0 top-0 w-4px h-100 rounded-2" :class="`bg-`+STATUS_TAG_COLOR(subsidiary.status_id)"></div>
                                                                    <a :href="PORTFOLIO_URLS('subsidiary')+'?ref='+ENCRYPT(subsidiary.id)" class="mb-1 text-dark text-hover-primary fw-bolder fs-6">{{TRUNCATE_STRING(CAPITALIZE_STR(subsidiary.name), 25)}}</a>
                                                                    <div class="fs-8 text-muted fw-bold">Created {{READABLE_DATE(subsidiary.created_at)}}</div> 
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <!--begin::Icons  "-->
                                                                <div class="d-flex gap-2 mb-2" v-if="subsidiary.administrators.length !== 0">
                                                                    <a  class="symbol symbol-circle symbol-25px" v-for="(admin, i) in subsidiary.administrators" :key="i">
                                                                        <img :src="admin.user.photo" alt="">
                                                                    </a> 
                                                                </div>
                                                                
                                                                <span v-else> 0 administrator</span>
                                                                <!--end::Icons--> 
                                                            </td>
                                                            <td> 
                                                                <span class="badge" :class="`badge-light-`+STATUS_TAG_COLOR(subsidiary.status_id)">Currently {{subsidiary.status.name}}</span> 
                                                            </td>
                                                            <td class="min-w-125px">
                                                                <!--begin::Team members-->
                                                               <span> {{subsidiary.offices.length}} locations </span>
                                                            </td>
                                                            <td class="min-w-150px">
                                                                <div class="mb-2 fw-bolder">{{READABLE_DATE(subsidiary.created_at)}} </div>
                                                                <div class="fs-8 text-muted fw-bold">Subscription due</div>
                                                            </td>
                                                            <td class="d-none">Pending</td>
                                                            <td class="text-end">
                                                                <a :href="PORTFOLIO_URLS('subsidiary')+'?ref='+ENCRYPT(subsidiary.id)" class="btn btn-icon btn-sm btn-light btn-active-primary w-25px h-25px">
                                                                    <!--begin::Svg Icon | path: icons/duotune/arrows/arr001.svg-->
                                                                    <span class="mdi mdi-arrow-right"></span>
                                                                    <!--end::Svg Icon-->
                                                                </a>
                                                            </td>
                                                        </tr> 
                                                        </tbody>
                                                    </table>
                                                </div> 
                                                <div class="d-flex justify-content-center pt-5 fs-4" v-if="subsidiaries.length == 0">
                                                        No Subsidiaries available.
                                                </div>
                                            </div>
											<!--end::Table-->
										</div>
										<!--end::Card body-->
                                        
       
									</div>
									<!--end::Table Widget 3-->

                                 <div class="bg-white py-3 border-top-1" v-if="subsidiaries.length !== 0"> 
                                        <div class="d-flex justify-content-center">
                                            <button class="btn btn-secondary btn-sm rounded-0" type="button" @click="LOAD_MORE()">Load more...</button>
                                        </div>
                                </div>
			</div>

 
        </div>

    </div>
</div> 
</template>

<script>
// @ is an alias to /src
import Notification from "@/components/global/notification.vue";
import SideNav from "@/components/global/side_nav.vue";
import TopNav from "@/components/global/top_nav.vue";
import PORTFOLIO_SERVICE  from "@/services/portfolio.js"; 

//TABS COMPONENT 
import AddSubsidiary from "@/views/portfolio/subsidiary/components/add_subsidiary.vue";  
// import { CPlaceholder } from '@coreui/vue'
import { PORTFOLIO_URLS } from "@/router/urls";
import { IS_EMPTY, APP_CONSTANTS, TRUNCATE_STRING, THOUSAND_SEPARATOR, APP_INFO, STATUS_CODE, REMOVE_CHARC, RESPONSE_STATUS, STATUS_TAG_COLOR, CAPITALIZE_STR, CHECK_BOOLEAN, READABLE_DATE} from "@/utils/";  
import { PARSE_AUTH, ENCRYPT, DECRYPT } from "@/utils/security/"; 
export default {
name: "Login",
data() {
    return{ 
        // Theme: this.$store.state.currentTheme,   
        subsidiaries: [],  
        PORTFOLIO_URLS, 
        READABLE_DATE, APP_CONSTANTS, ENCRYPT, TRUNCATE_STRING, IS_EMPTY, THOUSAND_SEPARATOR, PARSE_AUTH, APP_INFO, STATUS_CODE, REMOVE_CHARC, STATUS_TAG_COLOR, CAPITALIZE_STR, CHECK_BOOLEAN,
        tabs: ["All subsidiaries", "Active", "Inactive"],
        active_tab: 0,  
        page: 1, 
		dp: APP_CONSTANTS.default_photo,
        totalSubs: null,   
        nextPage: 0,
        remItem: 0,
        subsidiary_active: '',

        ref: this.$route.query.ref, // truncate ref url data to get user id 
    }
},
components: {
    Notification, SideNav, TopNav, AddSubsidiary, /* CPlaceholder */
},  
methods: {    

        RELOAD_CONTENT: function(){ 
            this.LOAD_SUBSIDIARIES()
        },

        FILTER_SUBSIDIARIES: function(){ 
            this.subsidiaries.filter((element) => element.status_id == 2 );
            console.log(this.subsidiaries)
        },

        LOAD_SUBSIDIARIES: function(){ // FETCH ALL MESSAGES FROM DATABASE 
        let payload = ENCRYPT({'id': DECRYPT(this.ref), 'page':this.page})

        PORTFOLIO_SERVICE.LOAD_SUBSIDIARIES({payload})
        .then(result =>{   

            !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) : null;

            if(result.data.success){ 
                this.nextPage = DECRYPT(result.data.data).nextPage;
                this.remItem =  DECRYPT(result.data.data).rem;
                this.page = DECRYPT(result.data.data).curPage;  
                this.totalSubs = DECRYPT(result.data.data).total;  
                this.subsidiaries = DECRYPT(result.data.data).subsidiaries; 

            }

            if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1000); 
        })
        .catch(err => {
            console.log('Error:' + err.message) 
            this.$store.commit('PAGE_NOTIFICATION', {
                "success": false, 
                "msg": 'error occured loading contents' 
            });
        })
    }, 
    // GET_NEXT_PAYLOAD() {  // get paginated payloads
    //   window.onscroll = () => {  
    //     let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
    //     if (bottomOfWindow & this.remItem !== 0) { 
    //         this.loadingMore = 'yes';  
    //      apiServices.LOAD_USER_MESSAGES({'id': this.ref, 'page': this.nextPage})
    //     .then(result =>{
    //           this.loadingMore = 'no'
    //           this.subs.push(result.data.data.subs[0]); 
    //           this.remItem =  result.data.data.rem
    //           this.nextPage =  result.data.data.nextPage      
    //     }).catch(err => {
    //         this.loadingMore = 'error'
    //         this.loadingMoreError = true
    //         console.log(err.message)
    //     })
    //     }
    //   }
    // }, 

        LOAD_COMPONENT: function(tab){ 
            this.$store.commit('PAGE_NOTIFICATION', null) 
            this.active_tab = tab;
        }

}, 
mounted() { 
    this.LOAD_SUBSIDIARIES()
} 

};
</script>
