<template>
<div>
<Notification />  
    <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper"> 
        <TopNav />
        <SideNav /> 
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
            <!--begin::Container-->
            <div class="container-xxl px-0 px-md-4" id="kt_content_container">  
 
        <!--end::Card toolbar-->

									<div class="bg-white py-2 px-5 mb-4">
										<!--begin::Card header-->
										<div class="d-flex justify-content-between pt-3">
											<!--begin::Tabs-->
											<h5>All Office Locations</h5>
											<!--end::Tabs--> 
                                            <!-- <AddSubsidiary /> -->
										</div>
                                    </div> 
 
									<!--begin::Table Widget 3-->
									<div class="card card-flush h-xl-100 mb-5"> 
										<!--begin::Card body-->
										<div class="card-body pt-1">  
											<!--end::Seprator-->
											<!--begin::Table-->
											<div  class="dataTables_wrapper dt-bootstrap4 no-footer">
                                                <div class="table-responsive" v-if="!IS_EMPTY(offices)">
                                                    <table id="kt_widget_table_3" class="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3 dataTable no-footer" data-kt-table-widget-3="all">
                                                        
                                                        <tbody>  
                                                        <tr v-for="(office, i) in offices" :key="i">
                                                            <td class="min-w-300px">
                                                                <div class="position-relative ps-6 pe-3 py-2">
                                                                    <div class="position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-primary"></div>
                                                                    <span class="mb-1 text-dark text-hover-primary fw-bolder fs-6">{{TRUNCATE_STRING(CAPITALIZE_STR(office.address), 34)}}</span>
                                                                    <div class="fs-8 text-muted fw-bold">{{office.state.name}}, {{office.country.name}}</div> 
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <!--begin::Icons  "-->
                                                                <a :href="PORTFOLIO_URLS('employees')+'?ref='+ref+' &filter='+ENCRYPT('branch')+' &_id='+ENCRYPT(office.id)" class="symbol-group symbol-hover" v-if="office.employees.length !== 0">
                                                                    <div class="symbol symbol-35px symbol-circle" v-for="(employee, i) in office.employees" :key="i">
                                                                        <img alt="" :src="employee.user.photo" />
                                                                    </div>
                                                                    <span class="symbol symbol-35px symbol-circle" data-bs-toggle="modal" data-bs-target="#kt_modal_view_users">
                                                                        <span class="symbol-label bg-secondary text-dark fs-8 fw-bolder"><i class="mdi mdi-account-group"></i></span>
                                                                    </span>
                                                                </a>
                                                                
                                                                <a :href="PORTFOLIO_URLS('employees')+'?ref='+ref+' &filter='+ENCRYPT('branch')+' &_id='+ENCRYPT(office.id)" class="fs-8 text-muted fw-bold"> all employees</a>
                                                                <!--end::Icons--> 
                                                            </td>
                                                            <td class="min-w-150px"> 
                                                                <div class="mb-2 fw-bolder">{{CHECK_BOOLEAN(office.head_office) ? 'Head' : 'Branch'}} office</div>
                                                                <div class="fs-8 text-muted fw-bold">Branch type</div> 
                                                            </td> 
                                                            <td class="min-w-150px">
                                                                <div class="mb-2 fw-bolder">{{READABLE_DATE(office.created_at)}} </div>
                                                                <div class="fs-8 text-muted fw-bold">Subscription due</div>
                                                            </td>
                                                            <td class="d-none">Pending</td>
                                                            <td class="text-end">
                                                                 <!--begin::Menu-->
                                                                <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                                    <!--begin::Svg Icon | path: icons/duotune/general/gen024.svg-->
                                                                    <span class="svg-icon svg-icon-2">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                <rect x="5" y="5" width="5" height="5" rx="1" fill="#000000" />
                                                                                <rect x="14" y="5" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
                                                                                <rect x="5" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
                                                                                <rect x="14" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                    <!--end::Svg Icon-->
                                                                </button> 
                                                            </td>
                                                        </tr> 
                                                        </tbody>
                                                    </table>
                                                </div> 
                                                <div class="d-flex justify-content-center pt-5 fs-4" v-if="offices.length == 0">
                                                        No office locations available.
                                                </div>
                                            </div>
											<!--end::Table-->
										</div>
										<!--end::Card body-->
                                        
       
									</div>
									<!--end::Table Widget 3-->

                                 <div class="bg-white py-3 border-top-1" v-if="remItem !== 0"> 
                                        <div class="d-flex justify-content-center">
                                            <button class="btn btn-secondary btn-sm rounded-0" type="button" @click="LOAD_MORE()">Load more...</button>
                                        </div>
                                </div>
			</div>

 
        </div>

    </div>
</div> 
</template>

<script>
// @ is an alias to /src
import Notification from "@/components/global/notification.vue";
import SideNav from "@/components/global/side_nav.vue";
import TopNav from "@/components/global/top_nav.vue";
import PORTFOLIO_SERVICE  from "@/services/portfolio.js"; 

//TABS COMPONENT 
// import AddSubsidiary from "@/views/portfolio/office/components/add_office.vue";  
// import { CPlaceholder } from '@coreui/vue'
import { PORTFOLIO_URLS } from "@/router/urls";
import { IS_EMPTY, APP_CONSTANTS, GET_INITIAL, TRUNCATE_STRING, GET_COLOR, THOUSAND_SEPARATOR, APP_INFO, STATUS_CODE, REMOVE_CHARC, RESPONSE_STATUS, STATUS_TAG_COLOR, CAPITALIZE_STR, CHECK_BOOLEAN, READABLE_DATE} from "@/utils/";  
import { PARSE_AUTH, ENCRYPT, DECRYPT } from "@/utils/security/"; 
export default {
name: "Offices",
data() {
    return{ 
        // Theme: this.$store.state.currentTheme,   
        offices: [],  
        PORTFOLIO_URLS, 
        READABLE_DATE, APP_CONSTANTS, ENCRYPT, GET_INITIAL, GET_COLOR, TRUNCATE_STRING, IS_EMPTY, THOUSAND_SEPARATOR, PARSE_AUTH, APP_INFO, STATUS_CODE, REMOVE_CHARC, STATUS_TAG_COLOR, CAPITALIZE_STR, CHECK_BOOLEAN,
        page: 1, 
		dp: APP_CONSTANTS.default_photo,
        totalSubs: null,   
        nextPage: 0,
        remItem: 0,  
        ref: this.$route.query.ref, // truncate ref url data to get user id 
    }
},
components: {
    Notification, SideNav, TopNav, /*  AddSubsidiary, CPlaceholder */
},  
methods: {    

        RELOAD_CONTENT: function(){ 
            this.LOAD_OFFICES()
        },

        FILTER_SUBSIDIARIES: function(){ 
            this.offices.filter((element) => element.status_id == 2 );
            console.log(this.offices)
        },

        LOAD_OFFICES: function(){ // FETCH ALL MESSAGES FROM DATABASE 
        let payload = ENCRYPT({'id': DECRYPT(this.ref), 'page':this.page})

        PORTFOLIO_SERVICE.LOAD_OFFICES({payload})
        .then(result =>{   
console.log(DECRYPT(result.data.data))
            !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) : null;

            if(result.data.success){ 
                this.nextPage = DECRYPT(result.data.data).nextPage;
                this.remItem =  DECRYPT(result.data.data).rem;
                this.page = DECRYPT(result.data.data).curPage;  
                this.totalSubs = DECRYPT(result.data.data).total;  
                this.offices = DECRYPT(result.data.data).offices; 

            }

            if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1000); 
        })
        .catch(err => {
            console.log('Error:' + err.message) 
            this.$store.commit('PAGE_NOTIFICATION', {
                "success": false, 
                "msg": 'error occured loading contents' 
            });
        })
    }, 
    // GET_NEXT_PAYLOAD() {  // get paginated payloads
    //   window.onscroll = () => {  
    //     let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
    //     if (bottomOfWindow & this.remItem !== 0) { 
    //         this.loadingMore = 'yes';  
    //      apiServices.LOAD_USER_MESSAGES({'id': this.ref, 'page': this.nextPage})
    //     .then(result =>{
    //           this.loadingMore = 'no'
    //           this.subs.push(result.data.data.subs[0]); 
    //           this.remItem =  result.data.data.rem
    //           this.nextPage =  result.data.data.nextPage      
    //     }).catch(err => {
    //         this.loadingMore = 'error'
    //         this.loadingMoreError = true
    //         console.log(err.message)
    //     })
    //     }
    //   }
    // }, 

        LOAD_COMPONENT: function(tab){ 
            this.$store.commit('PAGE_NOTIFICATION', null) 
            this.active_tab = tab;
        }

}, 
mounted() { 
    this.LOAD_OFFICES()
} 

};
</script>
