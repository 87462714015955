<template>
<div>


        

        <ADDEDUCATIONCOMPONENT class="animated fadeIn" :employee="user.employee" v-if="add_pane" v-on:action="CHILD_ACTION" />

        <EDITEDUCATIONCOMPONENT class="animated fadeIn" :education="edu" :employee="user.employee" v-if="edit_pane" v-on:action="CHILD_ACTION" />


        <CModal alignment="center" size="sm" :visible="delModal" @close="() => { delModal = false }"> 
            <div class="card card-body py-15">
                <span class="fs-6 text-center fw-bold">Are you sure you want to delete this record?</span>
                <div class="d-flex justify-content-between mt-5">
                    <button type="button" @click="() => { delModal = false }" class="btn btn-sm btn-light-primary">No abort</button>
                    <button type="button" @click="DELETE_EDUCATIONAL_RECORD(edu.id), delModal = false" class="btn btn-sm btn-light-danger">Yes continue</button>
                </div>
            </div>
        </CModal>

        <!--begin::Card title-->
        <div class="card-title d-flex justify-content-between my-5" v-if="!add_pane && !edit_pane">
        <span>
            <div class="mb-1 fs-4 fw-bolder">{{user.surname}}'s certifications</div>
            <div class="fs-9 text-muted">Total of {{total}} upcoming events</div> 
        </span> 
             <button class="btn btn-light-primary btn-sm rounded-1" @click="add_pane = true">Add record</button>
        </div>
        <!--end::Card title--> 

        <BLOCKLOADER ht="h-300px" v-if="loading"/> 

        <div v-else>
            <div class="animated fadeIn row gx-9 gy-6" v-if="!add_pane && !edit_pane"> 
                <!--begin::Col-->
                <div class="col-xl-6" v-for="(education, i) in edu_list" :key="i">
                    <!--begin::Address-->
                    <div class="card card-dashed h-xl-100 animated fadeIn flex-row flex-stack flex-wrap p-6">
                    <!--begin::Details-->
                    <div class="d-flex align-items-center mb-2">
                            <!--begin::Avatar-->
                            <div class="me-5 position-relative">
                                <div class="symbol symbol-35px symbol-circle">
                                    <span class="symbol-label fs-6x fw-bold bg-secondary">{{GET_INITIAL(education.institution).toUpperCase()}}</span>
                                </div> 
                            </div>
                            <!--end::Avatar-->
                            <!--begin::Details-->
                            <div class="fw-bold">
                                <a href="#" class="fs-5 fw-bolder text-gray-900 text-hover-primary">{{CAPITALIZE_STR(education.institution)}}</a>
                                <div class="text-gray-400 fs-8">Graduation year: {{education.graduation_year}} || Degree: {{education.education_qualification.name}} || Specialization: {{education.specialization}}</div>
                            </div>
                            <!--end::Details--> 
                        </div>
                        <!--end::Details-->
                        <!--begin::Actions-->
                        <div class="d-flex align-items-center">
                            <button type="button" class="btn btn-sm btn-icon py-1 rounded-1 px-2 btn-light btn-light-danger me-1" @click="CONFIRM_DELETE(education), delModal = true"><i class="mdi mdi-trash-can-outline"></i></button>
                            <a class="btn btn-sm btn-icon py-1 rounded-1 px-2 btn-light btn-light-light me-1" target="_blank" v-if="!IS_EMPTY(education.file)" :href="education.file"><i class="mdi mdi-download"></i></a>
                            <button class="btn btn-sm btn-icon py-1 rounded-1 px-2 btn-light btn-light-primary me-1" @click="UPDATE_RECORD(education), edit_pane = true"><i class="mdi mdi-lead-pencil"></i></button>
                        </div>
                        <!--end::Actions-->
                    </div>
                    <!--end::Address-->
                </div>
                <!--end::Col--> 
            </div> 
            <div class="bg-white py-3 border-top-1" v-if="remItem !== 0"> 
                <div class="d-flex justify-content-center">
                    <button class="btn btn-secondary btn-sm rounded-0" type="button" @click="LOAD_EDUCATIONS()">Load more...</button>
                </div>
            </div>
        </div> 

        <EMPERROR v-if="resp" :status="respStat" :msg="respMsg" /> 

</div>
</template>
<script> 

import ADDEDUCATIONCOMPONENT from "./form/education/add.vue"; 
import EDITEDUCATIONCOMPONENT from "./form/education/edit.vue"; 
import { CModal } from '@coreui/vue'
import { TRUNCATE_STRING, GET_INITIAL, GET_COLOR, CAPITALIZE_STR, REMOVE_CHARC, RESPONSE_STATUS, IS_EMPTY, READABLE_DATE, RANDOM_STRING} from "@/utils/";  
import { ENCRYPT, DECRYPT } from "@/utils/security/"; 
import EMPLOYEE_SERVICE  from "@/services/employee.js"; 
import BLOCKLOADER from "@/components/loaders/blank-loader.vue";
import EMPERROR from "@/components/loaders/response.vue";
// import FILEMAN_SERVICE  from "@/services/fileman.js"; 


  export default {
    props: ["user"],
    data() {
      return { 
        respMsg: '',
        resp: false,
        respStat: '',
        loading: true, 
        delModal: false,
        add_pane: false, 
        edit_pane: false,
        page: 1,
        edu: '', 
        total: null,   
        nextPage: 0,
        remItem: 0,
        edu_list: [],       
        TRUNCATE_STRING, GET_COLOR, GET_INITIAL, REMOVE_CHARC, CAPITALIZE_STR, ENCRYPT, DECRYPT, RESPONSE_STATUS, IS_EMPTY, READABLE_DATE, RANDOM_STRING
      }
    },
    components: { ADDEDUCATIONCOMPONENT, EDITEDUCATIONCOMPONENT, CModal, EMPERROR, BLOCKLOADER }, 
methods: {    

    RELOAD_CONTENT: function(){ 
        this.page = 1, 
        this.total = null,   
        this.nextPage = 0,
        this.remItem = 0,
        this.edu_list = [],
        this.LOAD_EMPLOYEE_EDUCATION_HISTORY()
    },  
    
    CONFIRM_DELETE: function(edu){    
        this.edu = edu;
    },

    UPDATE_RECORD: function(edu){    
        this.edu = edu;
    },

    CHILD_ACTION: function(action){    
        if(action.reload){
            this.RELOAD_CONTENT()
        }  
        if(action.close == 'add'){
            this.add_pane = false;
        } 
        if(action.close == 'edit'){
            this.edit_pane = false;
        } 
    },

    DELETE_EDUCATIONAL_RECORD: function(id) { // FETCH ALL MESSAGES FROM DATABASE  
        this.retry = '' 
        let payload = ENCRYPT({ 'id': id }) 

        EMPLOYEE_SERVICE.DELETE_EDUCATIONAL_RECORD({payload})   
        .then(result => {  
            console.log(result.data)
            this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg });
            if(result.data.success) {
                this.edu_list = this.edu_list.filter(item => item.id !== id);
                if(this.edu_list.length == 0) this.resp = true; this.respStat="empty";
            }
            if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1000); 
 
        })

        .catch(err => {
            console.log(err.message)
            this.isLoading = 'error';
            this.$emit('notifyer', {
                'success': 'error',
                'msg': 'error occured loading form contents'
            })
        })

    },

    // DOWNLOAD_FILE: function(url) { // FETCH ALL MESSAGES FROM DATABASE  
    //     this.retry = '' 
    //     let payload = ENCRYPT({ 'url': url }) 

    //     FILEMAN_SERVICE.DOWNLOAD({payload})   
    //     .then(result => {  
    //         console.log(result.data)
    //         this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }); 
    //         // if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1000); 
 
    //     })

    //     .catch(err => {
    //         console.log(err.message)
    //         this.isLoading = 'error';
    //         this.$emit('notifyer', {
    //             'success': 'error',
    //             'msg': 'error occured loading form contents'
    //         })
    //     })

    // },
 
   LOAD_EMPLOYEE_EDUCATION_HISTORY: function() { // FETCH ALL MESSAGES FROM DATABASE    
        let payload = ENCRYPT({'id': this.user.employee.id, 'page':this.page}); 
        EMPLOYEE_SERVICE.LOAD_EMPLOYEE_EDUCATION_HISTORY({payload})   
        .then(result =>{ 
            this.loading = false; 
            if(result.data.success){ 
                this.nextPage = DECRYPT(result.data.data).nextPage;
                this.remItem =  DECRYPT(result.data.data).rem;
                this.page = DECRYPT(result.data.data).curPage;  
                this.total = DECRYPT(result.data.data).total; 
                let pLoad = DECRYPT(result.data.data).education; 
                this.edu_list = [...this.edu_list, ...pLoad];  
                if(this.edu_list.length == 0) this.resp = true; this.respStat="empty";
            }
            else{
                this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg })
                this.resp = true;
                this.respStat="err"
                this.respMsg=result.data.msg
            }

            if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1000); 
        })
        .catch(err => {
            this.loading = false;
            console.log('Error:' + err.message) 
            this.$store.commit('PAGE_NOTIFICATION', {
                "success": false, 
                "msg": 'error occured loading contents' 
            });
        })
    },

}, 

mounted() { 
    this.LOAD_EMPLOYEE_EDUCATION_HISTORY()  
} 
    
  }
</script>