<template> 
    <div class="card mb-5 mb-xxl-10"> 
        <!--begin::Body-->
        <div class="card-body py-10">
            <!--begin::Row-->
            <div class="row">
                <!--begin::Col-->
                <div class="col-md-12 pb-lg-0">   

                        <div class="row ">
                            <!--begin::Label-->
                            <label class="col-lg-12 fs-4 fw-bolder text-muted mb-4">Achievments</label>
                            <!--end::Label-->
                            <!--begin::Col-->
                            <div class="col-sm-6 col-md-3" v-for="(achievment, i) in user.achievments" :key="i"> 

                                <div class="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-3"> 
                                <!--begin::Svg Icon | path: assets/media/icons/duotune/general/gen020.svg-->
                                <span class="svg-icon svg-icon-primary me-2 svg-icon-2tx "><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M14 18V16H10V18L9 20H15L14 18Z" fill="black"/>
                                <path opacity="0.3" d="M20 4H17V3C17 2.4 16.6 2 16 2H8C7.4 2 7 2.4 7 3V4H4C3.4 4 3 4.4 3 5V9C3 11.2 4.8 13 7 13C8.2 14.2 8.8 14.8 10 16H14C15.2 14.8 15.8 14.2 17 13C19.2 13 21 11.2 21 9V5C21 4.4 20.6 4 20 4ZM5 9V6H7V11C5.9 11 5 10.1 5 9ZM19 9C19 10.1 18.1 11 17 11V6H19V9ZM17 21V22H7V21C7 20.4 7.4 20 8 20H16C16.6 20 17 20.4 17 21ZM10 9C9.4 9 9 8.6 9 8V5C9 4.4 9.4 4 10 4C10.6 4 11 4.4 11 5V8C11 8.6 10.6 9 10 9ZM10 13C9.4 13 9 12.6 9 12V11C9 10.4 9.4 10 10 10C10.6 10 11 10.4 11 11V12C11 12.6 10.6 13 10 13Z" fill="black"/>
                                </svg></span>
                                <!--end::Svg Icon-->
                                <!--begin::Wrapper-->
                                <div class="d-flex flex-stack flex-grow-1">
                                    <!--begin::Content-->
                                    <div class="fw-bold">
                                        <div class="fs-6 text-gray-700"> 
                                        <h6 class="fw-bolder text-primary">{{achievment.name}}</h6> 
                                            <small>{{TRUNCATE_STRING(achievment.description, 37)}}</small>
                                        </div>
                                    </div>
                                    <!--end::Content-->
                                </div>
                                <!--end::Wrapper-->
                            </div> 
                            </div>
                            <!--end::Col-->
                        </div> 
                </div>
                          
            </div>
            <!--end::Row--> 
        </div>
        <!--end::Body-->
    </div>
</template>

<script> 
//EMPLOYEE COMPONENT 
import { IS_EMPTY, CHECK_BOOLEAN, READABLE_DATE, CHECK_PLURAL, THOUSAND_SEPARATOR, CAPITALIZE_STR, TRUNCATE_STRING } from "@/utils/"; 
export default {
    name: "User overview", 
    props: ["user"],  
    data() {
        return{  
            IS_EMPTY: IS_EMPTY, 
            CHECK_BOOLEAN: CHECK_BOOLEAN, 
            READABLE_DATE: READABLE_DATE,
            CHECK_PLURAL: CHECK_PLURAL,
            THOUSAND_SEPARATOR: THOUSAND_SEPARATOR,
            CAPITALIZE_STR: CAPITALIZE_STR, 
            TRUNCATE_STRING: TRUNCATE_STRING,
        }
    },
    methods: { 
         
    }  
}
</script>