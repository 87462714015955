<template>
    <div class="card">  
    <OVERLAYLOADER v-if="processing" /> 
        <div class="alert alert-danger d-flex align-items-center p-1 rounded-1 animated fadeIn" v-if="!IS_EMPTY(server_msg)">{{server_msg}}</div> 
        <div class="form-floating">
            <input type="text" class="form-control" @focusin="email_valid = false, server_msg = null" @focusout="processing = true, VALIDATE_EMAIL()" v-model="email">
            <label class="fs-6 fw-bolder">Email Address <b class="text-danger text-bolder">*</b></label>
        </div> 
        <div class="mt-8 mb-1 d-flex justify-content-end">
            <button @click="ADD_SELF_EMPLOYEE()" v-if="email_valid" type="button" class="btn btn-primary shadow-lite rounded-0 animated fadeIn">Continue <i class="mdi mdi-send"></i></button> 
        </div> 
    </div>
</template>
<script>  
import OVERLAYLOADER from "@/components/loaders/overlay-loader.vue"
//general resources  
import { CHECK_EMAIL_PATTERN, RESPONSE_STATUS, APP_CONSTANTS, IS_EMPTY } from "@/utils/" 
import UTILITY_SERVICE from "@/services/utility_services"
import EMPLOYEE_SERVICE from "@/services/employee" 
import { ENCRYPT, DECRYPT } from "@/utils/security/"; 
export default {
	name: "add-employee-self",
    props: ["subsidiary"],
	data() {
		return { 
        server_msg: null,
        email_valid: false, 
        email: '',
		ref: this.$route.query.ref, // truncate ref url data to get user id
		processing: false,  
		CHECK_EMAIL_PATTERN, RESPONSE_STATUS, APP_CONSTANTS, IS_EMPTY
		}
	},
	components: {  
		OVERLAYLOADER,
		// AddAdminModal
	},
	methods: {  
		VALIDATE_EMAIL: function() { // VALIDATE ORG IF NAME EXIST IN THE DATABASE   
			if(!CHECK_EMAIL_PATTERN(this.email)){
                 this.processing = false; 
                 this.server_msg = "this is an invalid email address"; 
            }
			else{
				UTILITY_SERVICE.CONFIRM_USER_EMAIL({ 'email': this.email })
				.then(result => { // TRY ALL RESOURCE NEEDED FOR REGISTRATION 
                    this.processing = false; 
                    this.server_msg = result.data.msg;
					if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 3000); 
                    if(result.data.success) this.email_valid = true; 
				})
				.catch(() => {
                    this.processing = false;
                    this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": "encountered a fatal server error" })
				})
			}
		},  
        
		ADD_SELF_EMPLOYEE: function() { 
 
            this.processing = true;
            let payload = ENCRYPT({ 'id': DECRYPT(this.ref), 'email': this.email })
 
            EMPLOYEE_SERVICE.ADD_SELF_EMPLOYEE({payload})
			.then(result => {   
                this.processing = false; 
                if(result.data.success) {
                    this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg })
                    this.email = "";
                    this.email_valid = false;
                }else{
                    this.server_msg = result.data.msg;  
                }
				if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 3000); 
			})
			.catch(() => { 
				this.processing = false; 
				this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": "encountered a fatal server error" })
			})
		},
	}, 
};
</script>