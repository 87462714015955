<template>
<div> 
    <button type="button" class="btn btn-primary rounded-0 btn-sm" data-bs-toggle="modal" data-bs-target="#new_org">
        <!--begin::Svg Icon | path: icons/duotune/files/fil005.svg-->
        <span class="svg-icon svg-icon-3"> 
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM16 13.5L12.5 13V10C12.5 9.4 12.6 9.5 12 9.5C11.4 9.5 11.5 9.4 11.5 10L11 13L8 13.5C7.4 13.5 7 13.4 7 14C7 14.6 7.4 14.5 8 14.5H11V18C11 18.6 11.4 19 12 19C12.6 19 12.5 18.6 12.5 18V14.5L16 14C16.6 14 17 14.6 17 14C17 13.4 16.6 13.5 16 13.5Z" fill="black"/>
            <rect x="11" y="19" width="10" height="2" rx="1" transform="rotate(-90 11 19)" fill="black"/>
            <rect x="7" y="13" width="10" height="2" rx="1" fill="black"/>
            <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black"/>
            </svg>
        </span>
        Add New
    </button>

    <div class="modal fade" tabindex="-1" id="new_org">
        <div class="modal-dialog modal-lg">
            <div class="modal-content rounded-0"> 

                <div class="modal-body">
                    <!--begin::Wrapper-->
									<div class="w-100">
										<!--begin::Heading-->
										<div class="pb-10 pb-lg-5">
											<!--begin::Title-->
											<h2 class="fw-bolder text-dark">Subsidiary Details</h2>
											<!--end::Title-->
											<!--begin::Notice-->
											<div class="text-muted fw-bold fs-6">Complete the form to setup new subsidiary</div>
											<!--end::Notice-->
										</div>
										<!--end::Heading-->
										<!--begin::Input group-->
										<div class="mb-7 row">
											<!--begin::Label-->
                                            
                                            <div class="col-md-6">
                                                <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                                                    <span>Parent organization</span>
                                                </label>
                                                <!--end::Label-->
                                                <input type="text" class="form-control form-control-solid" :value="org.name" disabled/>
                                            </div>
                                            <!--end::Input group-->
                                            <!--begin::Input group-->
                                            <div class="col-md-6">
                                                <!--begin::Label-->
                                                <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                                                    <span class="required">Subsidiary name</span>
                                                    <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Specify subsidiary's name"></i>
                                                </label>
                                                <!--end::Label-->
                                                <input type="text"  @focusout="CONFIRM_SUB_NAME()" class="form-control form-control-solid" v-model="name" placeholder="name of subsidiary" />
                                            </div>
                                            <!--end::Input group-->
                                        </div>
										<!--begin::Input group--> 
                                            <div class="mb-10 fv-row"> 

											<!--begin::Label-->
											<label class="d-flex align-items-center form-label mb-3">Specify workforce Size
											<i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Provide your team size to help us setup your billing"></i></label>
											<!--end::Label-->
											<!--begin::Row-->
											<div class="row mb-2" data-kt-buttons="true">
												<!--begin::Col-->
												<div class="col">
													<!--begin::Option-->
													<label class="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4">
														<input type="radio" class="btn-check" name="workforce_size" v-model="work_force" value="1-19" />
														<span class="fw-bolder fs-3">1-19</span>
													</label>
													<!--end::Option-->
												</div>
												<!--end::Col-->
												<!--begin::Col-->
												<div class="col">
													<!--begin::Option-->
													<label class="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4 active">
														<input type="radio" class="btn-check" name="workforce_size" v-model="work_force"  value="20-69" />
														<span class="fw-bolder fs-3">20-69</span>
													</label>
													<!--end::Option-->
												</div>
												<!--end::Col-->
												<!--begin::Col-->
												<div class="col">
													<!--begin::Option-->
													<label class="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4">
														<input type="radio" class="btn-check" name="workforce_size" v-model="work_force" value="70-100" />
														<span class="fw-bolder fs-3">70-100</span>
													</label>
													<!--end::Option-->
												</div>
												<!--end::Col-->
												<!--begin::Col-->
												<div class="col">
													<!--begin::Option-->
													<label class="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4">
														<input type="radio" class="btn-check" name="workforce_size" v-model="work_force" value="100+" />
														<span class="fw-bolder fs-3">100+</span>
													</label>
													<!--end::Option-->
												</div>
												<!--end::Col-->
											</div>
											<!--end::Row-->
											<!--begin::Hint-->
											<div class="form-text">Customers will see this shortened version of your statement descriptor</div>
											<!--end::Hint-->
										</div>
										<!--end::Input group-->  


										<!--begin::Input group-->
										<div class="row mb-10"> 
												<!--begin::Row-->
												<div class="row fv-row">
													<!--begin::Col-->
													<div class="col-12 col-md-6 col-lg-4">
                                                        <!--begin::Label-->
                                                        <label class="required fs-6 fw-bold form-label mb-2">Select type</label>
                                                        <!--end::Label-->
                                                        <select v-model="stype" class="form-select form-control-lg form-control-solid" id="orgType"> 
                                                            <option v-bind:value="ct.id" v-for="(ct, i) in business_types" :key="i"> {{CAPITALIZE_STR(ct.name)}}</option> 
                                                        </select> 
													</div>
													<!--end::Col-->

													<!--begin::Col-->
													<div class="col-12 col-md-6 col-lg-4 mt-4 mt-md-0 ">
                                                        <!--begin::Label-->
                                                        <label class="required fs-6 fw-bold form-label mb-2">Industry</label>
                                                        <!--end::Label-->
                                                        <select v-model="industry" class="form-select form-control-lg form-control-solid" id="orgIndustry"> 
                                                            <option v-bind:value="ind.id" v-for="(ind, h) in industries" :key="h"> {{CAPITALIZE_STR(ind.name)}}</option> 
                                                        </select> 
													</div>
													<!--end::Col-->

                                                    
                                            
												<!--begin::Col-->
												<div class="col-12 col-md-6 col-lg-4 mt-4 mt-xl-0">
													<!--begin::Input group-->
                                                    <div class="mb-5">
                                                        <!--begin::Label-->
                                                        <label class="form-label mb-3">Official Phone</label>
                                                        <!--end::Label-->
                                                        <!--begin::Input-->
                                                        <input type="text" class="form-control form-control-lg form-control-solid" v-model="phone" placeholder="Official phone"/>
                                                        <!--end::Input-->
                                                    </div>
                                                    <!--end::Input group-->
												</div>
												<!--end::Col--> 
                                                    
                                            
												<!--begin::Col-->
												<div class="col-12 col-md-6 col-lg-4 mt-4 mt-xl-0">
													<!--begin::Input group-->
                                                    <div class="mb-5">
                                                        <!--begin::Label-->
                                                        <label class="form-label mb-3">Official Email</label>
                                                        <!--end::Label-->
                                                        <!--begin::Input-->
                                                        <input type="email" class="form-control form-control-lg form-control-solid" v-model="email" placeholder="Official email address"/>
                                                        <!--end::Input-->
                                                    </div>
                                                    <!--end::Input group-->
												</div>
												<!--end::Col--> 
                                                
                                                
                                            
												<!--begin::Col-->
												<div class="col-12 col-md-6 col-lg-4 mt-4 mt-xl-0">
													<!--begin::Input group-->
                                                    <div class="mb-5">
                                                        <!--begin::Label-->
                                                        <label class="form-label mb-3">Official website</label>
                                                        <!--end::Label-->
                                                        <!--begin::Input-->
                                                        <input type="text" class="form-control form-control-lg form-control-solid" v-model="website" placeholder="Website url"/>
                                                        <!--end::Input-->
                                                    </div>
                                                    <!--end::Input group-->
												</div>
												<!--end::Col--> 
                                                
                                                
                                            
												<!--begin::Col-->
												<div class="col-12 col-md-6 col-lg-4 mt-4 mt-xl-0">
													<!--begin::Input group-->
                                                    <div class="mb-5">
                                                        <!--begin::Label-->
                                                        <label class="form-label mb-3">Head office address</label>
                                                        <!--end::Label-->
                                                        <!--begin::Input-->
                                                        <input type="text" class="form-control form-control-lg form-control-solid" v-model="address" placeholder="Head office address"/>
                                                        <!--end::Input-->
                                                    </div>
                                                    <!--end::Input group-->
												</div>
                                                
                                            
												<!--begin::Col-->
												<div class="col-12 col-md-6 col-lg-4">
													<!--begin::Input group-->
                                                    <div class="mb-5">
                                                        <!--begin::Label-->
                                                        <label class="form-label mb-3">Country</label>
                                                        <!--end::Label-->
                                                        <!--begin::Input-->
                                                        <select v-model="country" @change="GET_STATES()" class="form-select form-control shadow-sm" id="orgCountry"> 
                                                            <option v-bind:value="country.id" v-for="(country, coi) in countries" :key="coi"> {{CAPITALIZE_STR(country.name)}}</option> 
                                                        </select>
                                                        <!--end::Input-->
                                                    </div>
                                                    <!--end::Input group-->
												</div>
                                                
                                            
												<!--begin::Col-->
												<div class="col-12 col-md-6 col-lg-4">
													<!--begin::Input group-->
                                                    <div class="mb-5">
                                                        <!--begin::Label-->
                                                        <label class="form-label mb-3">State</label>
                                                        <!--end::Label-->
                                                        <!--begin::Input-->
                                                        <select v-model="state" @change="GET_CITIES()" class="form-select form-control shadow-sm" id="orgState" :disabled="IS_EMPTY(states)"> 
                                                            <option v-bind:value="st.id" v-for="(st, si) in states" :key="si"> {{CAPITALIZE_STR(st.name)}}</option> 
                                                        </select>
                                                        <!--end::Input-->
                                                    </div>
                                                    <!--end::Input group-->
												</div>
                                                
                                            
												<!--begin::Col-->
												<div class="col-12 col-md-6 col-lg-4">
													<!--begin::Input group-->
                                                    <div class="mb-5">
                                                        <!--begin::Label-->
                                                        <label class="form-label mb-3">City</label>
                                                        <!--end::Label-->
                                                        <!--begin::Input-->
                                                        <select v-model="city" class="form-select form-control shadow-sm" id="orgCity" :disabled="IS_EMPTY(cities)"> 
                                                            <option v-bind:value="city.id" v-for="(city, ci) in cities" :key="ci"> {{CAPITALIZE_STR(city.name)}}</option> 
                                                            <option v-bind:value="0">others</option> 
                                                        </select>
                                                        <!--end::Input-->
                                                    </div>
                                                    <!--end::Input group-->
												</div>
 

												<!--end::Col-->  
											</div>
											<!--end::Col-->
                                             
										</div>
										<!--end::Input group--> 
									</div>
									<!--end::Wrapper-->
                </div>

                <div class="modal-footer py-2">
                    <button type="button" class="btn btn-light-danger" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" id="reg_button" @click="DO_VALIDATE()"> Submit form</button>
                </div>
            </div>
        </div>
    </div>
                     

</div>
</template>

<script>
// @ is an alias to /src   
import utility_services from "@/services/utility_services.js"; 
// import apiServices from "@/services/apiServices"; 
import portfolio_services from "@/services/portfolio.js"; 

import {IS_EMPTY, SEARCH_USER_ARR, FILTER_ROLE, PAGINATE, READABLE_DATE, CHECK_PERMISSION, RESPONSE_STATUS, REMOVE_CHARC, CAPITALIZE_STR} from "@/utils"; 
import { PARSE_AUTH, ENCRYPT, DECRYPT } from "@/utils/security/"; 

export default {
name: "Add subsidiary",
data() {
    return{ 
        IS_EMPTY, SEARCH_USER_ARR, FILTER_ROLE, RESPONSE_STATUS, PAGINATE, READABLE_DATE, CHECK_PERMISSION, REMOVE_CHARC, CAPITALIZE_STR, PARSE_AUTH,
        addModal: false,
        name: '',
        work_force: '20-69', 
        phone: '',  
        website: '',   
        email: '',
        address: '', 
        stype: '',  
        industry: '',  
        country: null, 
        state: '', 
        city: '',   
        states: null,
        cities: null,
        countries: this.$store.state.countries,  
        business_types: '',
        industries: '',
        org: '',
        ref: this.$route.query.ref, // truncate ref url data to get user id 
    }
},
components: { 
},
methods: { 
    
    GET_COUNTRIES: function() {
        utility_services.GET_COUNTRIES()
        .then(result => { // TRY ALL RESOURCE NEEDED FOR REGISTRATION 

        console.log(result.data)   
            if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1500); 
            !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" }) : this.countries = DECRYPT(result.data.data);
        })
        .catch(err => {
            this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" })
            console.log(err.message)
        }) 
    },
    
    GET_STATES: function() {
        this.states  = null; 
        this.cities  = null;  
        utility_services.GET_STATES({
            'id': this.country
        })
        .then(result => { // TRY ALL RESOURCE NEEDED FOR REGISTRATION  
            if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1500); 
            !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" }) : this.states = result.data.data; 
        })
        .catch(err => {
            this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" })
            console.log(err.message)
        })  
    },

    GET_CITIES: function(){
        this.cities  = null; 
        utility_services.GET_CITIES({'id':this.state})
        .then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION 
            if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1500); 
            !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" }) : this.cities  = result.data.data;   
        })
        .catch(err => {
            this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" })
            console.log(err.message)    
        })
    },

    GET_NEW_COMPANY_RESOURCES: function(){
        utility_services.GET_NEW_COMPANY_RESOURCES()
		.then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION 
            !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) : null;
			if(result.data.success){
				let resp = DECRYPT(result.data.data) 
                this.business_types = resp.business_types;
                this.industries = resp.industries; 
			} 
        })
        .catch(err => {
            console.log(err.message)
			this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " encountered a fatal server error." })    
        }) 
    },

    CONFIRM_ORG: function(){ // VALIDATE ORG IF NAME EXIST IN THE DATABASE 
    

        const payload = ENCRYPT({'id': DECRYPT(this.ref) })  
        portfolio_services.CONFIRM_ORG({payload})
        .then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION 
        console.log(result.data)
            if(!result.data.success){
                this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg })
            }
            else{
                this.org = DECRYPT(result.data.data)
            }
        })
        .catch(err => {
        console.log(err.message)
        this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " encountered a fatal server error verifying parent organization" })        
        })
    },

    CONFIRM_SUB_NAME: function(){ // VALIDATE ORG IF NAME EXIST IN THE DATABASE 
    const payload = ENCRYPT({'id': DECRYPT(this.ref), 'name': this.name }) 
        utility_services.CONFIRM_SUB_NAME({payload})
        .then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION  
            result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) : null; 
        })
        .catch(err => {
        console.log(err.message)
        this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " encountered a fatal server error verifying subsidiary name" })        
        })
    },

    CONFIRM_SUB_EMAIL: function(){ // VALIDATE ORG IF NAME EXIST IN THE DATABASE  
    const payload = ENCRYPT({'email': this.email }) 
        utility_services.CONFIRM_SUB_EMAIL({payload})
        .then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION 
            !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) : null;
        })
        .catch(err => {
            console.log(err.message) 
            this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " encountered a fatal server error." })       
        })
    },

    RELOAD: function(){ // VALIDATE/CHECK DATA IF EXIST IN THE DATABASE    
        this.GET_NEW_COMPANY_RESOURCES();
    },

    DO_VALIDATE: function(){ // VALIDATE FORM DATA 
 
		if( !IS_EMPTY(this.name) || !IS_EMPTY(this.phone) || !IS_EMPTY(this.email) || !IS_EMPTY(this.address) || !IS_EMPTY(this.stype) || !IS_EMPTY(this.industry) || !IS_EMPTY(this.country) || !IS_EMPTY(this.state) || !IS_EMPTY(this.city)){
            document.getElementById('reg_button').innerHTML = '<span class="spinner-border spinner-border-sm align-middle ms-2"></span> processing';
            document.getElementById('reg_button').setAttribute(`disabled`, true); 
            this.DO_REGISTRATION();
        } 
    },

    DO_REGISTRATION: function(){ // TRY REGISTRATION ALGORITHM 
        const payload = ENCRYPT({ "id": PARSE_AUTH(this.$store.state.auth, "user").id,  "oid": this.org.id, 'name': this.name,'work_force': this.work_force, 'phone': this.phone, 'website': this.website, 'email': this.email, 'address': this.address, 'stype': this.stype,  'industry': this.industry,  'country': this.country, 'state': this.state, 'city': this.city}) 

		portfolio_services.SAVE_NEW_SUBSIDIARY({payload})
		.then(res =>{ // TRY REGISTRATION IN AUTH SERVICE RESOURCE 
			this.$store.commit('PAGE_NOTIFICATION', {"success": res.data.success, "msg": res.data.msg }) 
			if(res.data.success){ 
                console.log(res.data)
				document.getElementById('reg_button').innerHTML = 'Submit form'; 
				setTimeout(() => this.$router.push(`/dashboard-p?ref=${res.data.data}`), 2000) 
			} 
			else{ 
				document.getElementById('reg_button').innerHTML = 'Submit form'; 
				document.getElementById('reg_button').removeAttribute(`disabled`) 
			}
		})
		.catch(err => {  
			this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " encountered a fatal server error." })   
			console.log(err.message) 
			document.getElementById('reg_button').innerHTML = 'Submit form'; 
			document.getElementById('reg_button').removeAttribute(`disabled`);  
		})
    }

},

mounted(){
    this.CONFIRM_ORG()
    this.GET_COUNTRIES(); 
	this.GET_NEW_COMPANY_RESOURCES(); 
}

}
</script>