<template>
<div> 
 

        <div class="card"><!--begin::Card header-->
                <div class="card-header">
                    <!--begin::Card title-->
                    <div class="card-title flex-column">
                        <h3 class="fw-bolder mb-1">Available office locations</h3>
                        <div class="fs-8 text-gray-400">showing {{offices.length}} of {{total}} records</div>
                    </div>
                    <!--begin::Card title-->
                    <!--begin::Card toolbar-->
                    <div class="card-toolbar my-1"> 
						<button class="btn btn-light btn-sm btn-icon me-4" id="reload" @click="RELOAD_CONTENT()"><i class="mdi mdi-reload"></i></button>
						<button class="btn btn-light-primary btn-sm me-4 px-2" @click="add_modal = true"><i class="mdi mdi-plus"></i> Add new</button>
                        <div class="d-flex align-items-center position-relative my-1 ml-6">
                            <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                            <span class="svg-icon svg-icon-3 position-absolute ms-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
                                </svg>
                            </span>
                            <!--end::Svg Icon-->
                            <input type="text" class="form-control form-control-solid form-select-sm w-250px w-md-150px ps-9" placeholder="Search name" />
                        </div>
                        <!--end::Search-->
                    </div>
                    <!--begin::Card toolbar-->
                </div>
                <!--end::Card header-->

				<EDITOFFICECOMPONENT :office="office1" v-if="edit_modal" v-on:action="CHILD_ACTION" /> 

				<ADDOFFICECOMPONENT :subsidiary="subsidiary" v-if="add_modal" v-on:action="CHILD_ACTION" /> 
				
					<CModal alignment="center" size="sm" :visible="del_modal" @close="() => { del_modal = false }"> 
						<div class="card card-body py-15">
							<span class="fs-6 text-center fw-bold">Are you sure you want to delete this office?</span>
							<div class="d-flex justify-content-between mt-5">
								<button type="button" @click="() => { del_modal = false }" class="btn btn-sm btn-light-primary">No abort</button>
								<button type="button" @click="() => { DELETE_OFFICE(), del_modal = false }" class="btn btn-sm btn-light-danger">Yes continue</button>
							</div>
						</div>
					</CModal>

            <!--begin::Body-->
            <div class="card-body shadow-lite">   
            
                <EMPERROR v-if="resp" :status="respStat" />  
                <!--begin::Table-->
                <div  class="dataTables_wrapper dt-bootstrap4 no-footer" v-if="!loading && !resp">
                    <div class="table-responsive" v-if="!IS_EMPTY(offices)">
                        <table class="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3 dataTable no-footer">
                            
                            <tbody>  
                            <tr v-for="(office, i) in offices" :key="i" class="animated fadeIn">
                                <td class="min-w-300px">
                                    <div class="position-relative ps-6 pe-3 py-2">
                                        <div class="position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-primary"></div>
                                        <span class="mb-1 text-dark text-hover-primary fw-bolder fs-6">{{TRUNCATE_STRING(CAPITALIZE_STR(office.address), 40)}}</span>
                                        <div class="fs-8 text-muted fw-bold">{{office.state.name}}, {{office.country.name}}</div> 
                                    </div>
                                </td> 
                                <td class="min-w-150px"> 
                                    <div class="mb fw-bolder">{{CHECK_BOOLEAN(office.head_office) ? 'Head' : 'Branch'}} office</div>
                                    <div class="fs-8 text-muted fw-bold">Branch type</div> 
                                </td> 
                                <td class="min-w-150px">
                                    <div class="mb fw-bolder">{{READABLE_DATE(office.created_at)}} </div>
                                    <div class="fs-8 text-muted fw-bold">Created on</div>
                                </td> 
                                <td>
                                    <div class="d-flex my-3 ms-9">
                                        <!--begin::Edit-->
                                        <button class="btn btn-icon btn-light-primary w-30px h-30px me-3" @click="edit_modal = true, office1 = office"> 
                                            <span class="mdi mdi-lead-pencil"></span> 
                                        </button>
                                        <!--end::Edit--> 
                                        <!--begin::Delete-->
                                        <button type="button" class="btn btn-icon btn-light-danger w-30px h-30px me-3" @click="del_modal = true, oid = office.id">  
                                                <span class="mdi mdi-trash-can"></span> 
                                        </button>
                                        <!--end::Delete--> 
                                    </div>
                                </td> 
                                <!--begin::Form-->  
                            </tr> 
                            </tbody>
                        </table>
                    </div>  
                </div>
                <!--end::Table--> 

                <TABLELOADER :tdCnt="4" :trCnt="6"  v-if="loading && !resp" />

            </div> 
            <!--end::Body-->
        </div>
		
                <div class="d-flex justify-content-center mt-5" v-if="remItem !== 0">
                    <button class="btn btn-primary btn-sm rounded-0 shadow-sm" type="button" @click="LOAD_OFFICES()">Load more...</button>
                </div> 


</div> 
</template>

<script>
// @ is an alias to /src 
// import utility_services from "@/services/utility_services.js"; 
import PORTFOLIO_SERVICE  from "@/services/portfolio.js";  
//TABS COMPONENT 
import EDITOFFICECOMPONENT from "./modals/edit_office.vue"; 
import ADDOFFICECOMPONENT from "./modals/add_office.vue";  
import TABLELOADER from "@/components/loaders/table.vue";
import EMPERROR from "@/components/loaders/response.vue";
import { CModal } from '@coreui/vue'
import { PORTFOLIO_URLS } from "@/router/urls";
import { IS_EMPTY, APP_CONSTANTS, GET_INITIAL, TRUNCATE_STRING, GET_COLOR, THOUSAND_SEPARATOR, APP_INFO, STATUS_CODE, REMOVE_CHARC, RESPONSE_STATUS, STATUS_TAG_COLOR, CAPITALIZE_STR, CHECK_BOOLEAN, READABLE_DATE} from "@/utils/";  
import { PARSE_AUTH, ENCRYPT, DECRYPT } from "@/utils/security/"; 
export default {
name: "Offices",
props: ["subsidiary"],
data() {
    return{ 
        // Theme: this.$store.state.currentTheme,   
        offices: [],  
        resp: false,
        respStat: '',
        loading: true,
		edit_modal: false,
		add_modal: false,
		del_modal: false,
		oid: "",
		office1: "",
        PORTFOLIO_URLS, 
        READABLE_DATE, APP_CONSTANTS, ENCRYPT, GET_INITIAL, GET_COLOR, TRUNCATE_STRING, IS_EMPTY, THOUSAND_SEPARATOR, PARSE_AUTH, APP_INFO, STATUS_CODE, REMOVE_CHARC, STATUS_TAG_COLOR, CAPITALIZE_STR, CHECK_BOOLEAN,
        page: 1,  
        total: null,   
        nextPage: 0,
        remItem: 0,  
        states: null,
        cities: null,
        countries: this.$store.state.countries,
        ref: this.$route.query.ref, // truncate ref url data to get user id 
    }
}, 
components: { EDITOFFICECOMPONENT, ADDOFFICECOMPONENT, CModal, TABLELOADER, EMPERROR }, 
methods: {

	
        CHILD_ACTION: function(action){    
            if(action.reload){
                this.RELOAD_CONTENT()
            }    
            if(action.close == 'add'){
                this.add_modal = false;
            } 
            if(action.close == 'edit'){
                this.edit_modal = false;
            } 
        },     
 
        RELOAD_CONTENT: function(){ 
			this.page = 1,  
			this.total = null,   
			this.nextPage = 0,
			this.remItem = 0, 
			this.offices = [], 
			this.LOAD_OFFICES()
        }, 
		
		DELETE_OFFICE: function() { // FETCH ALL MESSAGES FROM DATABASE   
			let payload = ENCRYPT({"id": this.oid})  
			PORTFOLIO_SERVICE.DELETE_OFFICE({payload})   
			.then(result => {   
				this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg });
				if(result.data.success) {
					this.offices = this.offices.filter(item => item.id !== this.oid);
				}
				if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1000); 
	
			})

			.catch(err => {
				console.log(err.message)
				this.isLoading = 'error';
				this.$emit('notifyer', {
					'success': 'error',
					'msg': 'error occured loading form contents'
				})
			})

		},
 
 
        LOAD_OFFICES: function(){ // FETCH ALL MESSAGES FROM DATABASE  
        let payload = ENCRYPT({'id': this.subsidiary.id, 'page':this.page})

        PORTFOLIO_SERVICE.LOAD_OFFICES({payload})
        .then(result =>{    
            !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) : null;

            if(result.data.success){ 
                this.nextPage = DECRYPT(result.data.data).nextPage;
                this.remItem =  DECRYPT(result.data.data).rem;
                this.page = DECRYPT(result.data.data).nextPage;  
                this.total = DECRYPT(result.data.data).total;  
                let pLoad = DECRYPT(result.data.data).offices;  
                this.offices = [...this.offices, ...pLoad]   
                this.loading = false;
                if(this.offices.length == 0) this.resp = true; this.respStat="empty";
            }else{
                this.resp = true;
                this.respStat="err"
            }

            if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1000); 
        })
        .catch(err => {
            console.log('Error:' + err.message) 
            this.$store.commit('PAGE_NOTIFICATION', {
                "success": false, 
                "msg": 'error occured loading contents' 
            });
        })
    },  
}, 
mounted() { 
    this.LOAD_OFFICES()
	// this.GET_COUNTRIES()
} 

};
</script>
