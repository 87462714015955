// import new_organization from "../../views/organization/chiefHR/add_organization.vue"; 
// import org_dashboard from "../../views/chief/organization/dashboard.vue"; 
// import organizations from "../../views/chief/organization/all_organizations.vue";  
import subsidiaries from "@/views/portfolio/subsidiaries.vue"; 
import dashboard from "@/views/portfolio/index.vue"; 
import organizations from "@/views/portfolio/organizations.vue"; 
// import dashboard from "@/views/portfolio/dashboard.vue"; 
import employees from "@/views/portfolio/subsidiary/employee/employees.vue"; 
import employeeManagement from "@/views/employee_management/index.vue"; 
import management from "@/views/portfolio/management.vue";
import settings from "@/views/portfolio/settings.vue";
import offices from "../../views/portfolio/offices.vue";   
 
// import employee_dashboard from "../../views/organization/subsidiary/employee_dashboard.vue";
// import event_calendar from "../../views/organization/subsidiary/event_calendar.vue"; 

const PORTFOLIO_ROUTE = { 
    organizations, dashboard, 
    subsidiaries, employeeManagement, 
    employees, offices,
    management, settings 
};

export default PORTFOLIO_ROUTE; 