<template> 
    <div  class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div class="table-responsive">
            <table class="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3 dataTable no-footer"> 
                <tbody>  
                    <tr v-for="(tr, i) in tRowCnt" :key="i" class="animated fadeIn">
                        <td v-for="(td, j) in tDataCnt" :key="j" class="skeleton">
                            <div class="text h-10px"></div>
                            <div class="text h-4px"></div> 
                        </td>  
                    </tr> 
                </tbody>
            </table>
        </div>  
    </div> 
</template>

<script>    
export default {
name: "table-loader",
props: ["tdCnt", "trCnt"],
data() {
    return{ tDataCnt: this.tdCnt, tRowCnt: this.trCnt }
}, 
};
</script>
