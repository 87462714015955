<template>     
<div style="margin-bottom:1.5em !important; z-index:9999999 !important" v-if="!IS_EMPTY(notify)"> 
    <div class="alert alert-dismissible rounded-0 w-100 text-center py-1 animated slideInDown text-white" :class="notify.success ? `bg-primary` : `bg-danger`" style=" top: 0 !important; position:fixed !important; z-index: 99999999999999 !important;">
        <!--begin::Content-->
        <div> 
            <span class="text-sm"> <b v-if="!notify.success">Sorry!</b> {{notify.msg}}</span>
        </div>
        <!--end::Content--> 
    </div>  
</div>
</template> 
<script> 
import { IS_EMPTY } from "@/utils/"; 
import { mapGetters } from 'vuex';
export default {
    name: "Notification", 
    data() {
        return{ 
            IS_EMPTY: IS_EMPTY,  
        }
    }, 
    computed: {   
        ...mapGetters({
           notify: 'notify', 
        }), 
    }, 
}
 
</script>