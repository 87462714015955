<template>
<div class="animated fadeIn">

    <Loader v-if="loading" />   
    <div v-else> 
        <ChiefOverview v-if="CHECK_BOOLEAN(user.is_chief_hr)" :user="user" /> 

        <div class="card mb-5 mb-xxl-10">
            <!--begin::Header-->
            <div class="card-header">
                <!--begin::Title-->
                <div class="card-title">
                    <h3>Profile Overview</h3>
                </div>
                <!--end::Title-->
                <!--begin::Toolbar-->
                <div class="card-toolbar">
                Last updated - {{READABLE_DATE(user.updated_at)}}
                </div>
                <!--end::Toolbar-->
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body py-10">
                <!--begin::Row-->
                <div class="row mb-10">
                    <!--begin::Col-->
                    <div class="col-md-6 pb-10 pb-lg-0">
                        <h6 class="text-gray-800">Bio information</h6> 


                            <div class="row mb-4 mt-4">
                                <!--begin::Label-->
                                <label class="col-lg-4 fw-bold text-muted">Surname</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-8">
                                    <span class="fw-bolder fs-6 text-gray-800">{{user.surname}}</span>
                                </div>
                                <!--end::Col-->
                            </div> 
                            

                            <div class="row mb-4">
                                <!--begin::Label-->
                                <label class="col-lg-4 fw-bold text-muted">First name</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-8">
                                    <span class="fw-bolder fs-6 text-gray-800">{{user.first_name}}</span>
                                </div>
                                <!--end::Col-->
                            </div> 
                            

                            <div class="row mb-4">
                                <!--begin::Label-->
                                <label class="col-lg-4 fw-bold text-muted">Other name</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-8">
                                    <span class="fw-bolder fs-6 text-gray-800">{{user.other_name}}</span>
                                </div>
                                <!--end::Col-->
                            </div> 
                            

                            <div class="row mb-4">
                                <!--begin::Label-->
                                <label class="col-lg-4 fw-bold text-muted">Email</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-8">
                                    <span class="fw-bolder fs-6 text-gray-800">{{TRUNCATE_STRING(user.email, 25)}}</span>
                                </div>
                                <!--end::Col-->
                            </div> 
                            

                            <div class="row mb-4">
                                <!--begin::Label-->
                                <label class="col-lg-4 fw-bold text-muted">Phone number</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-8">
                                    <span class="fw-bolder fs-6 text-gray-800">{{user.phone}}</span>
                                </div>
                                <!--end::Col-->
                            </div> 
                            



                    </div>
                    <!--end::Col-->
                    <!--begin::Col-->
                    <div class="col-md-6">
                        <h6 class="text-gray-800">Location</h6> 
                        <div class="d-block d-md-none separator my-10"></div>



                            <div class="row  mb-4 mt-4">
                                <!--begin::Label-->
                                <label class="col-lg-4 fw-bold text-muted">Address</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-8">
                                    <span class="fw-bolder fs-6 text-gray-800">{{TRUNCATE_STRING(user.address, 25)}}</span>
                                </div>
                                <!--end::Col-->
                            </div> 

                            <div class="row mb-4 float-right">
                                <!--begin::Label-->
                                <label class="col-lg-4 fw-bold text-muted">Local Govt. Area</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-8">
                                    <span class="fw-bolder fs-6 text-gray-800">{{user.lga}}</span>
                                </div>
                                <!--end::Col-->
                            </div>

                            <div class="row mb-4 float-right">
                                <!--begin::Label-->
                                <label class="col-lg-4 fw-bold text-muted">Hometown</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-8">
                                    <span class="fw-bolder fs-6 text-gray-800">{{user.hometown}}</span>
                                </div>
                                <!--end::Col-->
                            </div>

                            <div class="row mb-4 float-right">
                                <!--begin::Label-->
                                <label class="col-lg-4 fw-bold text-muted">State</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-8">
                                    <span class="fw-bolder fs-6 text-gray-800">{{user.state.name}}</span>
                                </div>
                                <!--end::Col-->
                            </div>
                            
                            <div class="row mb-4 float-right">
                                <!--begin::Label-->
                                <label class="col-lg-4 fw-bold text-muted">Country</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-8">
                                    <span class="fw-bolder fs-6 text-gray-800">{{user.country.name}}</span>
                                </div>
                                <!--end::Col-->
                            </div>
                    </div>
                    <!--end::Col-->
                </div>
                <!--end::Row-->
                <!--begin::Notice-->
                <!--begin::Notice-->
                <div class="notice d-flex bg-light-dark rounded border-dark border border-dashed p-6"> 
                    <!--begin::Wrapper-->
                    <div class="d-flex flex-column">
                    <h6>About me</h6>
                        <!--begin::Content-->
                        <div class="fw-bold">
                            <div class="fs-6 text-gray-700">
                            {{user.about}}
                            </div>
                        </div>
                        <!--end::Content-->
                    </div>
                    <!--end::Wrapper-->
                </div>
                <!--end::Notice-->
                <!--end::Notice-->
            </div>
            <!--end::Body-->
        </div>

        <EmployeeOverview v-if="!CHECK_BOOLEAN(user.is_chief_hr)" :user="user" /> 
    </div>
</div>
</template>

<script> 
//EMPLOYEE COMPONENT
import Loader from "@/components/loaders/blank-loader.vue";
import EmployeeOverview  from "./employee/overview.vue"; 
import ChiefOverview  from "./chief/overview.vue"; 
import { IS_EMPTY, CHECK_BOOLEAN, READABLE_DATE, TRUNCATE_STRING } from "@/utils/"; 
export default {
    name: "User overview", 
    props: ["user"],  
    data() {
        return{  
            loading: true,
            IS_EMPTY: IS_EMPTY, 
            CHECK_BOOLEAN: CHECK_BOOLEAN,
            READABLE_DATE: READABLE_DATE,
            TRUNCATE_STRING: TRUNCATE_STRING, 
        }
    }, 
    components: { EmployeeOverview, ChiefOverview, Loader },   
}
</script>