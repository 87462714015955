<template>
  <div> 

    <form id="workExpForm" class="login-form">
        <h5>New record form</h5>
        <div class="row">
            <div class="col-md-6 mb-3">
                    <label class="form-label fs-5 fw-bolder text-muted">Employer <span class="text-danger">*</span></label> 
                    <input v-model="work_exp.employer" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" required="">
            </div>
            <div class="col-md-6 col-7 mb-3">
                <label class="form-label fs-5 fw-bolder text-muted">Job role <span class="text-danger">*</span></label> 
                <input v-model="work_exp.role" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" required="">
            </div> 
            <div class="col-md-4 col-5 mb-3">
                <label class="form-label fs-5 fw-bolder text-muted">Salary <span class="text-danger">*<span class="fs-8">(numbers only)</span></span></label> 
                <input v-model="work_exp.salary" type="number" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" required="">
            </div> 
            <div class="col-md-4 col-6 mb-3">
                <label class="form-label fs-5 fw-bolder text-muted">Start year <span class="text-danger">*</span></label> 
                <input v-model="work_exp.start_year" type="date" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" required="">
            </div> 
            <div class="col-md-4 col-6 mb-3">
                <label class="form-label fs-5 fw-bolder text-muted">End year <span class="text-danger">*</span></label> 
                <input v-model="work_exp.end_year" type="date" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" required="">
            </div> 
            <div class="col-md-12 col-12 mb-3">
                <label class="form-label fs-5 fw-bolder text-muted">Reason for termination</label> 
                <input v-model="work_exp.reason_for_termination" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" required="">
            </div> 
            <div class="col-md-12 mb-3">
                <label class="form-label fs-5 fw-bolder text-muted">Job description</label> 
                <textarea v-model="work_exp.job_description" rows="2" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" required=""></textarea>
            </div>  
        </div> 
        <div class="d-flex justify-content-end mt-10">
                <button type="button" @click="$emit('action', {'close': 'add'})" class="btn btn-light-danger btn-flat me-4">Close form</button> 
                <button type="button" @click="VALIDATE_NEW_FORM()" id="workExp_sub_btn" class="btn btn-light-primary btn-flat ml-2">Submit</button> 
        </div>   
    </form>

  </div>
</template>

<script>
// @ is an alias to /src    
import EMPLOYEE_SERVICE  from "@/services/employee.js"; 
import {MILLISECONDS_TO_DAYS, CAPITALIZE_STR, REMOVE_CHARC , RESPONSE_STATUS, STATUS_CODE} from "@/utils/"
import { ENCRYPT, DECRYPT } from "@/utils/security/"; 
export default {
name: "add-employee-work-exp",
props: ['employee'], 
data() {
    return{ 
        // Theme: this.$store.state.currentTheme,  
        ref: this.$route.query.ref, // truncate ref url data to get user id 
        MILLISECONDS_TO_DAYS, CAPITALIZE_STR, REMOVE_CHARC, ENCRYPT, DECRYPT, RESPONSE_STATUS, STATUS_CODE,
        work_exp: {
            employee_id: this.employee.id, 
            employer: "", 
            role: "", 
            salary: "", 
            start_year: "", 
            end_year: "", 
            reason_for_termination: "", 
            job_description: "",
        }, 
    }
},
components: { }, 
methods: {

    CLOSE_NEW_FORM: function() { // reload content  
        this.$emit('notifyer', {'success': null}) // close form
        this.$emit('notifyer', {'paneStatus': 'workExp'}) 
    },  
    
    VALIDATE_NEW_FORM: function() { // FETCH ALL MESSAGES FROM DATABASE    
        document.getElementById('workExp_sub_btn').innerHTML = 'Submitting <i class="mdi mdi-loading mdi-spin"></i>';
        document.getElementById('workExp_sub_btn').setAttribute(`disabled`, true); 
        // if( IS_EMPTY(this.workExpType)  || IS_EMPTY(this.name)  || IS_EMPTY(this.email)  || IS_EMPTY(this.phone)  || IS_EMPTY(this.country)  || IS_EMPTY(this.state)  || IS_EMPTY(this.city) ){
        //     this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " ensure no required filed is empty."}); 
        //     document.getElementById('workExp_sub_btn').innerHTML = 'Submit <i class="mdi mdi-upload"></i>';
        //     document.getElementById('workExp_sub_btn').removeAttribute(`disabled`);
        // }
        // else{
            this.SUBMIT_FORM() 
        // }
    },

    SUBMIT_FORM: function() { // FETCH ALL MESSAGES FROM DATABASE    
 
        let payload = ENCRYPT(this.work_exp)
        EMPLOYEE_SERVICE.SUBMIT_WORK_EXPERIENCE({payload})
        .then(result => {  
            console.log(result.data)
            this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }); 
            // if (result.data.success && !IS_EMPTY(this.file)) this.UPLOAD_EDUCATION_FILE(DECRYPT(result.data.data)) // UPLOAD FILE IF THERE IS ANY ATTACHED
            if(result.data.success) this.$emit('action', { 'reload': true, 'close': 'add'}) //CLOSE ADD PANE AND RELOAD RECORDS 
            document.getElementById('workExp_sub_btn').removeAttribute(`disabled`);
            document.getElementById('workExp_sub_btn').innerHTML = 'Submit'; 
            if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 3000); 
        })
        .catch(err => { 
            console.log('Error:' + err.message) 
            this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": "encountereda fatal server error" })
            document.getElementById('workExp_sub_btn').removeAttribute(`disabled`);
            document.getElementById('workExp_sub_btn').innerHTML = 'Submit'; 
        })
    },

},
beforeMount() {},
mounted() { },

};
</script>