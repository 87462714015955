<template>
<div>
<Notification />  
    <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper"> 
        <TopNav />
        <SideNav /> 
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
            <!--begin::Container-->
            <div class="container-xxl px-0 px-md-4" id="kt_content_container">  

             <!--begin::Card toolbar-->
        <div class="mb-5">
			<AddOrganization />
        </div>
        <!--end::Card toolbar-->
				<Loader v-if="loading" />     
                <div class="animated fadeIn row stretch" v-if="!IS_EMPTY(organizations) && !loading">

				<div class="d-flex align-items-sm-center my-2 rounded shadow-lite bg-white p-4" v-for="(organization, i) in organizations" :key="i">
					<!--begin::Symbol-->
					<div class="symbol symbol-circle symbol-50px me-5" data-bs-toggle="tooltip" title="" data-bs-original-title="Emma Smith">
													<span class="symbol-label bg-light text-muted fw-bolder fs-3">{{GET_INITIAL(organization.name).toUpperCase()}}</span>
												</div>
					<!--end::Symbol-->
					<!--begin::Section-->
					<div class="d-flex align-items-center flex-row-fluid flex-wrap">
						<div class="flex-grow-1 me-2">
							<a :href="PORTFOLIO_URLS('subsidiary')+'?ref='+ENCRYPT(organization.main_subsidiary)" class="text-gray-800 text-hover-primary fs-4 fw-bolder">{{CAPITALIZE_STR(organization.name)}}</a>
							<span class="text-muted fw-bold d-block fs-7">created {{READABLE_DATE(organization.created_at)}}</span>
						</div>
						<span class="badge badge-light fw-bolder my-2 d-none d-sm-inline"><i class="mdi mdi-arrow-right"></i></span>
					</div>
					<!--end::Section-->
				</div> 
                </div>
            </div>
        </div>

    </div>
</div> 
</template>

<script>
// @ is an alias to /src
import Notification from "@/components/global/notification.vue";
import SideNav from "@/components/global/side_nav.vue";
import TopNav from "@/components/global/top_nav.vue";
import CHIEF_SERVICE  from "@/services/chief.js"; 

//TABS COMPONENT 
import Loader from "@/components/loaders/blank-loader.vue";
import AddOrganization from "@/views/portfolio/components/chief/add_organization.vue";  
// import { CPlaceholder } from '@coreui/vue'
import { IS_EMPTY, APP_INFO, REMOVE_CHARC, RESPONSE_STATUS, READABLE_DATE, GET_INITIAL, STATUS_TAG_COLOR, CAPITALIZE_STR, CHECK_BOOLEAN} from "@/utils/";  
import { ENCRYPT, DECRYPT, PARSE_AUTH } from "@/utils/security/"; 
import { PORTFOLIO_URLS } from "@/router/urls";
export default {
name: "Login",
data() {
    return{ 
        // Theme: this.$store.state.currentTheme,
		loading: true,   
        organizations: '', 
		PORTFOLIO_URLS,  
        IS_EMPTY, PARSE_AUTH, ENCRYPT, APP_INFO, REMOVE_CHARC, STATUS_TAG_COLOR, CAPITALIZE_STR, CHECK_BOOLEAN,READABLE_DATE, GET_INITIAL,
        user_tabs: ["overview", "settings", "On queue", "Calendar"],
        active_tab: 0, 
    }
},
components: {
    Notification, SideNav, TopNav, AddOrganization, Loader /* CPlaceholder */
},  
methods: {    

        RELOAD_CONTENT: function(value){ 
            value ? this.LOAD_ORGANIZATIONS() : null 
        },
            
        LOAD_ORGANIZATIONS: function(){   
            let payload = ENCRYPT({'id': PARSE_AUTH(this.$store.state.auth, "user").id})
            CHIEF_SERVICE.LOAD_ORGANIZATIONS({payload}) 
            .then(res =>{ // TRY USER LOGIN PROCESS   
				console.log(res.data)  
				this.loading = false; 
                !res.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": res.data.success, "msg": res.data.msg }) : null;
                if(res.data.success){
                    let resp = DECRYPT(res.data.data);
                    this.organizations = resp.orgs;
                }
                if(res.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1000);
            }) 
            .catch(err => { 
                console.log(err.message) 
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading page contents, reload page" }); 
            })   
        },

        LOAD_COMPONENT: function(tab){ 
            this.$store.commit('PAGE_NOTIFICATION', null) 
            this.active_tab = tab;
        }

}, 
mounted() { 
    this.LOAD_ORGANIZATIONS(0)
} 

};
</script>
