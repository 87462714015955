// const CALCU_YEARS = (y1, y2) => { 
//     var dif = Number(y2) - Number(y1);

//     return dif + ' year'+CHECK_PLURAL(dif);
// }


const GET_CUR_WEEK = () =>{
    let curr = new Date() 
    let week = []

    for (let i = 0; i <= 13; i++) {
        let first = curr.getDate() - curr.getDay() + i 
        let day = new Date(curr.setDate(first)).toISOString().slice(0, 10)
        week.push(day)
    }

    return week;
}
 
const DAYS_IN_MILLISECONDS = (days) => { // CONVERT DAYS TO MILLI SECONDS
    const oneDayMS = 86400000; // milliseconds for a day  
    return Number(oneDayMS) * Number(days);
}

const MILLISECONDS_TO_DAYS = (ms) => { // CONVERT DAYS TO MILLI SECONDS
    const oneDayMS = 86400000; // milliseconds for a day  
    return Number(ms) / Number(oneDayMS);
}

const DATE_TO_TIMESTAMP = (date) => { // CONVERT DAYS TO MILLI SECONDS
    const datum = Date.parse(date); // milliseconds for a day  
    return datum / 1000;
}

   
const MONTH_NAME = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];
   
const WEEK_DAYS = [
    "sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"
];

const GET_DAY_FROM_DATE = (date) => { 
    let new_date = new Date(date);
    let day = new_date.getDay()
    // for (let day of WEEK_DAYS) {
    //     i.toString().length == 1 ? hrs.push('0'+i) : hrs.push(i);    
    // } 
 return day;
}

const GET_DATE_TODAY = (date) => { 
    let new_date = new Date(date);
    let day = new_date.getDate()
    // for (let day of WEEK_DAYS) {
    //     i.toString().length == 1 ? hrs.push('0'+i) : hrs.push(i);    
    // } 
    return day;
}

const FILTER_TODAY_EVENTS = (arr, day) => {
    let new_arr = [];
    for (let item of arr) {
        if(item.start_date == day || item.end_date == day){
            new_arr.push(item)
        } 
    }
    return new_arr;
}

const HOURS_12 = () => { 
    let hrs = [];
    for (let i = 1; i < 13; i++) {
        i.toString().length == 1 ? hrs.push('0'+i) : hrs.push(i);    
    } 
 return hrs;
}

const HOURS_24 = () => { 
    let hrs = [];
    for (let i = 0; i < 24; i++) {
        i.toString().length == 1 ? hrs.push('0'+i) : hrs.push(i);    
    } 
 return hrs;
}

const MINUTES = () => { 
    let mins = [];
    for (let i = 0; i < 60; i++) {
        i.toString().length == 1 ? mins.push('0'+i) : mins.push(i);    
    } 
 return mins;
}
   
const MONTH_NAME_SHORT = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];
     
const THIRTY_DAYS_IN_MILLIS = 2592000000;
 
const FUTURE_DATE_MILI_SECS = (date, days) => date.setDate(date.getDate() + Number(days)); 
 
const TEN_MINUTES_IN_MILIS = ((60 * 10) * 1000)    
  
const READABLE_DATE = (d) => {  
    const dd = new Date(`${d}`) 
    return dd.toDateString();
}
 
const FORMAT_TIME = (d, typ) => { 
    const dd = new Date(`${d}`)
    const dd2 = Date.parse(d); // milliseconds for a day  
    if(typ == 'am_pm') return dd.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    if(typ == 'timestamp') return dd2 / 1000;
}
 
const EXTRACT_DATE = (d, typ) => {  

    const dd = new Date(`${d}`) 

    if(typ == "year") return dd.getFullYear() 
    
    if(typ == "month") return (Number(dd.getMonth()) + 1).toString().length > 1 ? Number(dd.getMonth()) + 1 : ''+Number(dd.getMonth()) + 1
    
    if(typ == "month_short_name") return MONTH_NAME_SHORT[dd.getMonth()]
    
    if(typ == "month_name") return  MONTH_NAME[dd.getMonth()]
    
    if(typ == "day") return dd.getDate().toString().length > 1 ? dd.getDate() : '0'+dd.getDate()

    if(typ == "hour"){
        let hr = dd.getHours();
        let x; 
        hr.toString().length == 1 ? x = '0'+hr : x = hr;
        return x;
    } 
    
    if(typ == "mins"){
        let mins = dd.getMinutes();
        let x; 
        mins.toString().length == 1 ? x = '0'+mins : x = mins;
        return x;
    } 
    
}
 
const FILTER_ARRAY_BY_DATE = (arr, typ, year, month = null, day = null) => {   
    let new_arr = [];
    for (let item of arr) {
        if( typ === "year" && EXTRACT_DATE(item.start, 'year') === year) new_arr.push(item)  
        
        else if( typ === "month" && EXTRACT_DATE(item.start, 'year') === year && EXTRACT_DATE(item.start, 'month') === (month +1)) new_arr.push(item) 
        
        else if( typ === "day" && EXTRACT_DATE(item.start, 'year') === year && EXTRACT_DATE(item.start, 'month') === (month +1) && EXTRACT_DATE(item.start, 'day') === day) new_arr.push(item)  
    }  
    
    return new_arr; 
}
 
const FILTER_ARRAY_BY_UPCOMING_DATE = (arr) => {   
    let new_arr = [];
    let today = new Date();
    for (let item of arr) {
        if( FORMAT_TIME(item.start, 'timestamp') > FORMAT_TIME(today, 'timestamp') ) new_arr.push(item)  
    }  
    
    return new_arr; 
}


const CALCU_FUTURE_DATE = (start_date, days) => {
    // let res; 
        let date = new Date(start_date);
        let f_date = new Date(date.setDate(date.getDate() + days)); 
       return EXTRACT_DATE(f_date, 'year') + '-' +EXTRACT_DATE(f_date, 'month') + '-' +EXTRACT_DATE(f_date, 'day');  
}

const DAYS_REMAINING = (date, otherDate) => Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));
   
module.exports = {  
    MONTH_NAME_SHORT: MONTH_NAME_SHORT,
    FORMAT_TIME: FORMAT_TIME,
    DATE_TO_TIMESTAMP: DATE_TO_TIMESTAMP,
    FILTER_ARRAY_BY_DATE: FILTER_ARRAY_BY_DATE,
    FILTER_ARRAY_BY_UPCOMING_DATE: FILTER_ARRAY_BY_UPCOMING_DATE,
    EXTRACT_DATE: EXTRACT_DATE,
    DAYS_REMAINING: DAYS_REMAINING, 
    READABLE_DATE: READABLE_DATE, 
    THIRTY_DAYS_IN_MILLIS: THIRTY_DAYS_IN_MILLIS,
    FUTURE_DATE_MILI_SECS: FUTURE_DATE_MILI_SECS,
    TEN_MINUTES_IN_MILIS: TEN_MINUTES_IN_MILIS,  
    DAYS_IN_MILLISECONDS: DAYS_IN_MILLISECONDS,
    MILLISECONDS_TO_DAYS: MILLISECONDS_TO_DAYS, 
    GET_DAY_FROM_DATE: GET_DAY_FROM_DATE, 
    // CALCU_YEARS: CALCU_YEARS,
    MONTH_NAME: MONTH_NAME,  
    HOURS_12: HOURS_12,
    HOURS_24: HOURS_24,
    MINUTES: MINUTES,
    CALCU_FUTURE_DATE: CALCU_FUTURE_DATE,
    WEEK_DAYS: WEEK_DAYS,
    GET_CUR_WEEK: GET_CUR_WEEK,
    GET_DATE_TODAY: GET_DATE_TODAY,
    FILTER_TODAY_EVENTS: FILTER_TODAY_EVENTS,
}