<template> 
    <div class="card"> 
        <div class="card-body p-0"  > 
            <!--begin::Illustration-->
            <div class="text-center px-4">
                <img class="mw-100 mh-300px" alt="" src="/img/soon.png" />
            </div>
            <!--end::Illustration-->
            <!--begin::Wrapper-->
            <div class="text-center">
                <!--begin::Title-->
                <h6 class="fs-2x fw-bolder">OOPs!</h6>
                <!--end::Title-->
                <!--begin::Description-->
                <p class="text-gray-400 fs-8 fw-bold mb-10">Sorry, this resource is not available at this time.</p>
                <!--end::Description--> 
            </div>
            <!--end::Wrapper-->
        </div>
        <!--end::Card body--> 
    </div> 
</template> 