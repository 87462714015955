<template> 
    <div class="overlay fw-bolder text-gray-800">
        <span class="spinner-border spinner-border-sm align-middle me-2"></span> Please wait...</div>
</template>

<script>    
export default {
name: "overlay-loader",  
};
</script>
