<template>
  <div> 
    <form id="workExpForm" class="login-form">
        <h5>Edit record</h5>
         <div class="row">
            <div class="col-md-6 mb-3">
                    <label class="form-label fs-5 fw-bolder text-muted">Employer <span class="text-danger">*</span></label> 
                    <input v-model="work_exp.employer" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" required="">
            </div>
            <div class="col-md-6 col-7 mb-3">
                <label class="form-label fs-5 fw-bolder text-muted">Job role <span class="text-danger">*</span></label> 
                <input v-model="work_exp.role" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" required="">
            </div> 
            <div class="col-md-4 col-5 mb-3">
                <label class="form-label fs-5 fw-bolder text-muted">Salary <span class="text-danger">*<span class="fs-8">(numbers only)</span></span></label> 
                <input v-model="work_exp.salary" type="number" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" required="">
            </div> 
            <div class="col-md-4 col-6 mb-3">
                <label class="form-label fs-5 fw-bolder text-muted">Start year <span class="text-danger">*</span></label> 
                <input v-model="work_exp.start_year" type="date" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" required="">
            </div> 
            <div class="col-md-4 col-6 mb-3">
                <label class="form-label fs-5 fw-bolder text-muted">End year <span class="text-danger">*</span></label> 
                <input v-model="work_exp.end_year" type="date" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" required="">
            </div> 
            <div class="col-md-12 col-12 mb-3">
                <label class="form-label fs-5 fw-bolder text-muted">Reason for termination</label> 
                <input v-model="work_exp.reason_for_termination" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" required="">
            </div> 
            <div class="col-md-12 mb-3">
                <label class="form-label fs-5 fw-bolder text-muted">Job description</label> 
                <textarea v-model="work_exp.job_description" rows="2" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" required=""></textarea>
            </div>  
        </div> 
        <div class="d-flex justify-content-end mt-10">
            <button type="button" @click="$emit('action', {'close': 'edit'})" class="btn btn-light-danger btn-flat me-4">Close form</button> 
            <button type="button" @click="UPDATE_WORK_EXP_RECORD()" id="edit_btn" class="btn btn-light-primary btn-flat ml-2">Submit</button> 
        </div>   
    </form>

  </div>
</template>


<script>
// @ is an alias to /src   
import EMPLOYEE_SERVICE  from "@/services/employee.js"; 
import {MILLISECONDS_TO_DAYS, CAPITALIZE_STR, REMOVE_CHARC, IS_EMPTY , RESPONSE_STATUS, STATUS_CODE} from "@/utils/"
import { ENCRYPT } from "@/utils/security/"; 

export default {
name: "edit-employee-surity",
props: ["employee", "workExp"], 
data() {
    return{ 
        // Theme: this.$store.state.currentTheme,    
        MILLISECONDS_TO_DAYS, CAPITALIZE_STR, REMOVE_CHARC, ENCRYPT, RESPONSE_STATUS, STATUS_CODE, IS_EMPTY, 
        work_exp: this.workExp, 
    }
},
components: {  }, 
methods: {
 
    UPDATE_WORK_EXP_RECORD: function() { // FETCH ALL MESSAGES FROM DATABASE   
        let payload = ENCRYPT(this.work_exp)
        EMPLOYEE_SERVICE.UPDATE_WORK_EXP_RECORD({ payload })
        .then(result => {  
            this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }); 
            // if (result.data.success && !IS_EMPTY(this.file)) this.UPLOAD_EDUCATION_FILE(DECRYPT(result.data.data)) // UPLOAD FILE IF THERE IS ANY ATTACHED
            if(result.data.success) this.$emit('action', { 'reload': true, 'close': 'edit'}) //CLOSE ADD PANE AND RELOAD RECORDS 
            document.getElementById('edit_btn').removeAttribute(`disabled`);
            document.getElementById('edit_btn').innerHTML = 'Update';
            if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 3000);          
        })
        .catch(err => {
            console.log('Error:' + err.message)
            document.getElementById('edit_btn').removeAttribute(`disabled`);
            document.getElementById('edit_btn').innerHTML = 'Update';
            this.$emit('notifyer', { 'success': 'error', 'msg': 'error occured loading form contents' })
        })
    },

},
beforeMount() {}, 

};
</script>