<template>
<div> 

        <div class="card">
									<!--begin::Body-->
									<div class="card-body p-lg-17">
										<!--begin::Meet-->
										<div class="mb-18">
											<!--begin::Wrapper-->
											<div class="mb-5">
												<!--begin::Top-->
												<div class="text-start mb-2">
													<!--begin::Title-->
													<span class="fs-1 fw-bolder text-dark">Who we are</span>
													<!--end::Title-->
													<!--begin::Text-->
													<div class="fs-9 text-muted fw-bold">Our coprate vision, mission and goals</div>
													<!--end::Text-->
												</div>
												<!--end::Top--> 
											</div>
											<!--end::Wrapper-->

 
											<!--begin::Carousel-->
											<div id="kt_security_guidelines" class="carousel carousel-custom carousel-stretch slide pointer-event" data-bs-ride="carousel" data-bs-interval="8000">
												<!--begin::Heading-->
												<div class="d-flex justify-content-end align-items-center flex-wrap"> 
													<!--begin::Carousel Indicators-->
													<ol class="p-0 m-0 carousel-indicators carousel-indicators-dots">
														<li data-bs-target="#kt_security_guidelines" data-bs-slide-to="0" class="ms-1 active" aria-current="true"></li>
														<li data-bs-target="#kt_security_guidelines" data-bs-slide-to="1" class="ms-1"></li>
														<li data-bs-target="#kt_security_guidelines" data-bs-slide-to="2" class="ms-1"></li>
													</ol>
													<!--end::Carousel Indicators-->
												</div>
												<!--end::Heading-->
												<!--begin::Carousel inner-->
												<div class="carousel-inner pt-6">
													<!--begin::Item-->
													<div class="carousel-item active">
														<!--begin::Wrapper-->
														<div class="carousel-wrapper">
															<!--begin::Description-->
															<div class="d-flex flex-column flex-grow-1">
                                                                <h6 class="fs-5 fw-bolder text-dark text-hover-primary">About us</h6>
                                                                <p class="text-gray-600 fs-6 fw-bold pt-3 mb-0">It’s no doubt that when a development takes longer to complete, additional costs to integrate and test each extra feature creeps up and haunts most of us.</p>
                                                            </div>
															<!--end::Description-->
															<!--begin::Summary-->
															<div class="d-flex flex-stack pt-8">
																<span class="text-muted fw-bold fs-6 pe-2">34, Soho Avenue, Tokio</span>
																<a href="#" class="btn btn-sm btn-light">Register</a>
															</div>
															<!--end::Summary-->
														</div>
														<!--end::Wrapper-->
													</div>
													<!--end::Item-->
													<!--begin::Item-->
													<div class="carousel-item">
														<!--begin::Wrapper-->
														<div class="carousel-wrapper">
															<!--begin::Description-->
															<div class="d-flex flex-column flex-grow-1">
																<a href="#" class="fw-bolder text-dark text-hover-primary">Security Policy Update</a>
																<p class="text-gray-600 fs-6 fw-bold pt-3 mb-0">As we approach one year of working remotely, we wanted to take a look back and share some ways teams around the world have collaborated effectively.</p>
															</div>
															<!--end::Description-->
															<!--begin::Summary-->
															<div class="d-flex flex-stack pt-8">
																<span class="badge badge-light-primary fs-7 fw-bolder me-2">Oct 05, 2021</span>
																<a href="#" class="btn btn-light btn-sm btn-color-muted fs-7 fw-bolder px-5">Explore</a>
															</div>
															<!--end::Summary-->
														</div>
														<!--end::Wrapper-->
													</div>
													<!--end::Item-->
													<!--begin::Item-->
													<div class="carousel-item">
														<!--begin::Wrapper-->
														<div class="carousel-wrapper">
															<!--begin::Description-->
															<div class="d-flex flex-column flex-grow-1">
																<a href="#" class="fw-bolder text-dark text-hover-primary">Terms Of Use Document</a>
																<p class="text-gray-600 fs-6 fw-bold pt-3 mb-0">Today we are excited to share an amazing certification opportunity which is designed to teach you everything</p>
															</div>
															<!--end::Description-->
															<!--begin::Summary-->
															<div class="d-flex flex-stack pt-8">
																<span class="badge badge-light-primary fs-7 fw-bolder me-2">Nov 10, 2021</span>
																<a href="#" class="btn btn-light btn-sm btn-color-muted fs-7 fw-bolder px-5">Discover</a>
															</div>
															<!--end::Summary-->
														</div>
														<!--end::Wrapper-->
													</div>
													<!--end::Item-->
												</div>
												<!--end::Carousel inner-->
											</div>
											<!--end::Carousel-->




















											<!--begin::Description-->
											<div class="fs-7 text-gray-600 mb-8">
												<!--begin::Text-->
                                                <h6>About us</h6>
												<p class="m-0">It’s no doubt that when a development takes longer to complete, additional costs to integrate and test each extra feature creeps up and haunts most of us.</p>
												<!--end::Text-->
											</div>
											<!--end::Description-->
											<!--begin::Description-->
											<div class="fs-7 text-gray-600 mb-8">
												<!--begin::Text-->
                                                <h6>Vision</h6>
												<p class="m-0">It’s no doubt that when a development takes longer to complete, additional costs to integrate and test each extra feature creeps up and haunts most of us.</p>
												<!--end::Text-->
											</div>
											<!--end::Description-->
											<!--begin::Description-->
											<div class="fs-7 text-gray-600 mb-8">
												<!--begin::Text-->
                                                <h6>Mission</h6>
												<p class="m-0">It’s no doubt that when a development takes longer to complete, additional costs to integrate and test each extra feature creeps up and haunts most of us.</p>
												<!--end::Text-->
											</div>
											<!--end::Description-->
											<!--begin::Description-->
											<div class="fs-7 text-gray-600 mb-8">
												<!--begin::Text-->
                                                <h6>Goal</h6>
												<p class="m-0">It’s no doubt that when a development takes longer to complete, additional costs to integrate and test each extra feature creeps up and haunts most of us.</p>
												<!--end::Text-->
											</div>
											<!--end::Description-->
										</div>
										<!--end::Meet-->
										<!--begin::Team-->
										<div class="mb-2">
											<!--begin::Heading-->
											<div class="text-center mb-17">
												<!--begin::Title-->
												<span class="fs-1 fw-bolder text-dark">Meet Our Directors</span>
												<!--end::Title-->
												<!--begin::Sub-title-->
												<div class="fs-9 text-muted fw-bold">It’s no doubt that when a development takes longer to complete.</div>
												<!--end::Sub-title=-->
											</div>
											<!--end::Heading-->
											<!--begin::Wrapper-->
											<div class="row row-cols-1 row-cols-sm-2 row-cols-xl-4 gy-10">
												<!--begin::Item-->
												<div class="col text-center mb-9">
													<!--begin::Photo-->
													<div class="octagon mx-auto mb-2 d-flex w-150px h-150px bgi-no-repeat bgi-size-contain bgi-position-center" style="background-image:url('/media/avatars/300-1.jpg')"></div>
													<!--end::Photo-->
													<!--begin::Person-->
													<div class="mb-0">
														<!--begin::Name-->
														<a href="../../demo1/dist/pages/user-profile/projects.html" class="text-dark fw-bolder text-hover-primary fs-3">Paul Miles</a>
														<!--end::Name-->
														<!--begin::Position-->
														<div class="text-muted fs-6 fw-bold">Development Lead</div>
														<!--begin::Position-->
													</div>
													<!--end::Person-->
												</div>
												<!--end::Item--> 
											</div>
											<!--end::Wrapper-->
                                            <div class="text-center">
                                                <!--begin::Action-->
                                                <a href="../../demo1/dist/pages/careers/apply.html" class="btn btn-light rounded-0 mb-5">View more...</a>
                                                <!--end::Action-->
                                            </div>

										</div>
										<!--end::Team--> 
									</div>
									<!--end::Body-->
								</div>


</div> 
</template>

<script>
// @ is an alias to /src 
// import PORTFOLIO_SERVICE  from "@/services/portfolio.js";  




import { PORTFOLIO_URLS } from "@/router/urls";
  
//TABS COMPONENT    
import { IS_EMPTY, APP_INFO, REMOVE_CHARC, /*RESPONSE_STATUS,*/ STATUS_TAG_COLOR, CAPITALIZE_STR, CHECK_BOOLEAN, CHECK_PLURAL, READABLE_DATE, TRUNCATE_STRING} from "@/utils/";  
import { PARSE_AUTH, ENCRYPT, /*DECRYPT*/ } from "@/utils/security/";  
export default {
name: "Organization-Dashboard",
props: ["subsidiary"],
data() {
    return{ 
        // Theme: this.$store.state.currentTheme, 
            // notifications: this.$store.getters.notifications,
        PORTFOLIO_URLS,
        editModal: false,  
        admins: [], 
        administrator: '', 
        subsidiaries: [],
        // subsidiary: '',
        employees: [],
        offices: [],  
        events: [], 
        priviledges: [],  
        admin_classes: [],
        adClassDesc: '',
        HQ: '', 
        CHECK_PLURAL, ENCRYPT, IS_EMPTY, PARSE_AUTH, APP_INFO, REMOVE_CHARC, STATUS_TAG_COLOR, CAPITALIZE_STR, CHECK_BOOLEAN, READABLE_DATE, TRUNCATE_STRING, 
        ref: this.$route.query.ref, // truncate ref url data to get user id 
		user: PARSE_AUTH(this.$store.state.auth, "user"),
    }
},
components: {
    // Notification, SideNav, TopNav /*EVENTSCOMPONENT, ACTIVITYCOMPONENT /* CPlaceholder */
},  
methods: {  
          
        // LOAD_PORTFOLIO_DASHBOARD: function(){    
        //     PORTFOLIO_SERVICE.LOAD_PORTFOLIO_DASHBOARD({"payload": this.ref}) 
        //     .then(res =>{ // TRY USER LOGIN PROCESS    
        //         !res.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": res.data.success, "msg": res.data.msg }) : null;
        //         if(res.data.success){
        //             this.subsidiary = DECRYPT(res.data.data).payload;  
        //             this.admins = this.subsidiary.administrators; 
        //             // this.subsidiaries = this.subsidiary,
        //             this.employees = this.subsidiary.employees;
        //             this.offices = this.subsidiary.offices;
        //             this.events = this.subsidiary.events; 
        //             console.log(this.offices) 
        //             this.GET_HEAD_OFFICE()
        //         }
        //         if(res.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 2500);
        //     }) 
        //     .catch(err => { 
        //         console.log(err.message) 
        //         this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading page contents, reload page" }); 
        //     })   
        // },

        LOAD_COMPONENT: function(tab){ 
            this.$store.commit('PAGE_NOTIFICATION', null) 
            this.active_tab = tab;
        },

        GET_HEAD_OFFICE: function(){  
            this.HQ = this.subsidiary.offices.find(element => CHECK_BOOLEAN(element.head_office) == true) 
        }

}, 
mounted() {    
	let extScript = document.createElement('script')
	extScript.setAttribute('src', '/js/widgets.bundle.js')
	document.head.appendChild(extScript) 

	let extScript1 = document.createElement('script')
	extScript1.setAttribute('src', '/js/custom/widgets.js')
	document.head.appendChild(extScript1) 
}, 
};
</script>
