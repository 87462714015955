<template>
<div class="d-flex flex-column flex-root"> 
        <Notification :notify="notify" /> 
        <!--begin::Authentication - Sign-in -->
        <div class="d-flex flex-column flex-column-fluid">
            <!--begin::Content-->
            <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-5">
                <!--begin::Logo-->
                <a href="../../demo3/dist/index.html" class="mb-10">
                    <img alt="Logo" src="/media/logos/logo-1.svg" class="h-30px" />
                </a>
                <!--end::Logo-->
                <!--begin::Wrapper-->
                <div class="w-lg-500px w-100 w-md-50 bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                    <!--begin::Form-->
                    <form class="form w-100" novalidate="novalidate" id="kt_sign_in_form" action="#">
                        <!--begin::Heading-->
                        <div class="text-center mb-5">
                            <!--begin::Title-->
                            <h3 class="text-dark mb-3">Recover password</h3>
                            <!--end::Title-->
                            <!--begin::Link-->
                            <p class="text-gray-400">To recover your password, kindly provide your email address used to register on {{APP_INFO.name}} </p>
                            <!--end::Link-->
                        </div>
                        <!--begin::Heading-->
                        <!--begin::Input group-->
                        <div class="fv-row mb-5">
                            <!--begin::Label-->
                            <label class="form-label fs-6 fw-bolder text-dark">Email <span class="text-danger">*</span></label>
                            <!--end::Label-->
                            <!--begin::Input-->
                            <input class="form-control" type="email" v-model="email" placeholder="Valid email address" />
                            <!--end::Input-->
                        </div>
                        <!--end::Input group--> 
                        <!--begin::Actions-->
                        <div class="text-center">
                            <!--begin::Submit button-->
                            <button type="button" id="submit_btn" @click="CHECK_EMAIL()" class="btn btn-primary w-100 w-sm-50 mt-5">Submit</button>
                            <!--end::Submit button--> 
                        </div>
                        <!--end::Actions-->
                    </form>
                    <!--end::Form-->
                </div>
                <!--end::Wrapper-->
            </div>
            <!--end::Content--> 
        </div>
        <!--end::Authentication - Sign-in-->
    </div>
    <!--end::Root--> 
</template>

<script>
// @ is an alias to /src 
import Notification from "@/components/global/notification.vue";
import apiServices from "@/services/apiServices"; 
import { RESPONSE_STATUS, ENCRYPT_STR, APP_INFO} from "@/utils/"

export default {
name: "Complete_registration",
data() {
    return{ 
        email: '',  
        isLoading: 'no', 
        vStatus: 0, 
        notify: {"success": null, 'msg': null},  
        RESPONSE_STATUS: RESPONSE_STATUS,
        ENCRYPT_STR: ENCRYPT_STR,
        APP_INFO: APP_INFO,
        // Theme: this.$store.state.currentTheme,    
    }
},
components: { 
    Notification,
},  
methods: { 
    
    CHECK_EMAIL: function(){   
    document.getElementById('submit_btn').innerHTML = '<span class="spinner-border spinner-border-sm align-middle ms-2"></span>';
    document.getElementById('submit_btn').setAttribute(`disabled`, true); 
      apiServices.SEND_PASSWORD_RECOVERY_EMAIL({"email": ENCRYPT_STR(this.email)})
      .then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION
            console.log(result.data)
          this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg}) 
          !result.data.success ? document.getElementById('submit_btn').removeAttribute(`disabled`) : null;  
          document.getElementById('submit_btn').innerHTML = 'Submit'; 
      })
      .catch(err => { 
          console.log(err.message)  
          document.getElementById('submit_btn').innerHTML = 'Submit'; 
          document.getElementById('submit_btn').removeAttribute(`disabled`);
          this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": 'error occured processig your request' })    
      })

    },
     

}, 
mounted() { 
} 



} 
</script>