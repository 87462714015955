<template>
<div class="animated fadeIn">
 
<!--begin::Card-->
<div class="card card-flush mb-6 mb-xl-9">

    <!--begin::Card header-->
    <div class="card-header px-0">
        <!--begin::Card title-->
        <div class="card-title flex-column">
            <h2 class="mb-1">{{user.surname}}'s schedule</h2>
            <div class="fs-7 text-muted">Total of {{events.length}} upcoming events</div> 
        </div>
        <!--end::Card title-->
        <!--begin::Card toolbar-->
        <div class="card-toolbar">
            <button type="button" class="btn btn-light-primary btn-sm" data-bs-toggle="modal" data-bs-target="#kt_modal_add_task">
            <!--begin::Svg Icon | path: icons/duotune/files/fil005.svg-->
            <span class="svg-icon svg-icon-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM16 13.5L12.5 13V10C12.5 9.4 12.6 9.5 12 9.5C11.4 9.5 11.5 9.4 11.5 10L11 13L8 13.5C7.4 13.5 7 13.4 7 14C7 14.6 7.4 14.5 8 14.5H11V18C11 18.6 11.4 19 12 19C12.6 19 12.5 18.6 12.5 18V14.5L16 14C16.6 14 17 14.6 17 14C17 13.4 16.6 13.5 16 13.5Z" fill="black"></path>
                    <rect x="11" y="19" width="10" height="2" rx="1" transform="rotate(-90 11 19)" fill="black"></rect>
                    <rect x="7" y="13" width="10" height="2" rx="1" fill="black"></rect>
                    <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black"></path>
                </svg>
            </span>
            <!--end::Svg Icon-->Add Task</button>
        </div>
        <!--end::Card toolbar-->
    </div>
    <!--end::Card header-->
<div class="card-body px-0">
   <!--begin::Dates-->
    <ul class="nav nav-pills d-flex flex-nowrap hover-scroll-x pb-2">
        <!--begin::Date-->
        <li class="nav-item me-1" v-for="(day, i) in GET_CUR_WEEK()" :key="i" @click=" FILTER_TODAY_EVENTS(day)">
            <a class="nav-link btn d-flex flex-column flex-center rounded-pill w-60px h-60px me-2 me-2 py-4 btn-active-primary" :class=" tDate.getDate() == GET_DATE_TODAY(day) ? 'active' : null" data-bs-toggle="tab" :href="`#kt_schedule_day_`+GET_DATE_TODAY(day)">
                <span class="opacity-50 fs-7 fw-bolder">{{CAPITALIZE_STR(GET_INITIAL(WEEK_DAYS[GET_DAY_FROM_DATE(day)]))}}</span>
                <span class="fs-6 fw-boldest">{{GET_DATE_TODAY(day)}}</span>
            </a>
        </li>
        <!--end::Date--> 
    </ul>
    <!--end::Dates-->
</div>
</div>


<div class="card mb-5 mb-xxl-10"> 
    <!--begin::Card body-->
    <div class="card-body d-flex flex-column">
        <!--begin::Tab Content-->
			<div class="tab-content">
				<!--begin::Day-->
				<div :id="`kt_schedule_day_`+tab_day" class="tab-pane fade show active" v-for="(event, i) in event_arr" :key="i">
					<!--begin::Time-->
					<div class="d-flex flex-stack position-relative mt-6">
						<!--begin::Bar-->
						<div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
						<!--end::Bar-->
						<!--begin::Info-->
						<div class="fw-bold ms-5">
							<!--begin::Time-->
							<div class="fs-7 mb-1">{{event.start}} - {{event.end}}
							<span class="fs-7 text-muted text-uppercase">pm</span></div>
							<!--end::Time-->
							<!--begin::Title-->
							<a href="#" class="fs-5 fw-bolder text-dark text-hover-primary mb-2">{{event.title}}</a>
							<!--end::Title-->
							<!--begin::User-->
							<div class="fs-7 text-muted">{{TRUNCATE_STRING(event.details, 130)}}</div>
							<!--end::User-->
						</div>
						<!--end::Info-->
						<!--begin::Action-->
						<a href="#">
							<!--begin::Svg Icon | path: assets/media/icons/duotune/art/art005.svg-->
							<span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
							<path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="black"/>
							<path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="black"/>
							</svg></span>
							<!--end::Svg Icon-->
						</a>
						<!--end::Action-->
					</div>
					<!--end::Time--> 
				</div>
				<!--end::Day--> 
			</div>
			<!--end::Tab Content-->
    </div>
    <!--end::Card body-->
</div>
 
    
</div>
</template>

<script> 
import USER_SERVICE  from "@/services/user.js"; 
//EMPLOYEE COMPONENT 
import { IS_EMPTY, CHECK_BOOLEAN, READABLE_DATE, TRUNCATE_STRING, GET_INITIAL, CAPITALIZE_STR, RESPONSE_STATUS } from "@/utils/"; 
import { GET_CUR_WEEK, WEEK_DAYS, GET_DAY_FROM_DATE, GET_DATE_TODAY } from "@/utils/date_utils.js"; 
import { ENCRYPT, DECRYPT } from "@/utils/security/";  
export default {
    name: "Calendar", 
    props: ["user"],  
    data() {
        return{  
			tDate: new Date(), 
			CAPITALIZE_STR, IS_EMPTY, CHECK_BOOLEAN, READABLE_DATE, TRUNCATE_STRING, GET_CUR_WEEK, GET_DATE_TODAY, WEEK_DAYS, GET_INITIAL, GET_DAY_FROM_DATE, 
			events: '',
			event_arr: [],
			tab_day: '',
        }
    }, 
	methods: { 
        LOAD_EVENTS: function(){   
            let payload = ENCRYPT({'id': this.user.id})
            USER_SERVICE.LOAD_EVENTS({payload}) 
            .then(res =>{ // TRY USER LOGIN PROCESS   
                !res.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": res.data.success, "msg": res.data.msg }) : this.events = DECRYPT(res.data.data);
                if(res.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 2500);
            }) 
            .catch(err => { 
                console.log(err.message) 
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading page contents, reload page" }); 
            })   
        },
		
		FILTER_TODAY_EVENTS: function(day){ 

			this.event_arr = [] 
			for (let item of this.events) {
			// console.log(item)
				if(item.start == day || item.end == day) this.event_arr.push(item); this.tab_day = GET_DATE_TODAY(day);
			}
			console.log(this.event_arr)
		}

	},
    components: {  },
    mounted() { 
		this.LOAD_EVENTS() 
		this.FILTER_TODAY_EVENTS(this.todayDate)  
		console.log(this.todayDate) 
    } 
}
</script> 