import API from "./api.js";
  
export default {

     //USER AUTHENTICATION SERVER SERVICES  
     
     DO_USER_LOGIN(data) { /**** PROCESS LOGIN*****/ 
        return API("auth").post("login", data)
    },

    DO_OTP_AUTH(data) { /**** PROCESS LOGIN*****/ 
        return API("auth").post("otp_auth", data)
    },
    
    DO_LOGOUT(data){
        return API("auth").post("logout", data) 
    },
    
    DO_REGISTRATION(data) { /**** PROCESS REGISTRATION*****/ 
        return API("auth").post("register", data)
    }, 

    RESEND_VERIFICATION_EMAIL(data) { /**** PROCESS REGISTRATION*****/ 
        return API("auth").post("resend_verification_email", data)
    }, 

    CHECK_USER_EMAIL(data) { /**** GET REGISTRATION RECOURCES*****/  
        return API("auth").post("check_user_email", data)
    },

    SEND_PASSWORD_RECOVERY_EMAIL(data) { /**** GET REGISTRATION RECOURCES*****/  
        return API("auth").post("password_recovery_email", data)
    },

    RESET_PASWORD(data) { /**** GET REGISTRATION RECOURCES*****/  
        return API("auth").post("reset_password", data)
    },

    DO_EMAIL_CONFIRMATION_FOR_PASSWORD_RESET(data) { /**** PROCESS REGISTRATION*****/ 
        return API("auth").post("register", data)
    }, 
    
    LOCK_ACCOUNT(data) {
        return API("auth").post("lock_account", data)
    },

    SAVE_NEW_ORGANIZATION(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/  
        console.log(data)
        return API("auth").post("save_org", data)
    },

    VERIFY_USER_EMAIL(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/  
        return API("auth").post("verify_email", data)
    },
    
    UPLOAD_EDUCATION_FILE2(data){
        return API('auth').post('upload_employee_education_file', data)
    },

    //SELF SERVICE 
    CONFIRM_SELF_REGISTRATION(data) {
        return API('auth').post('confirm_self_user', data)
    }, 

    UPLOAD_SELF_SERVICE_PHOTO(payload, photo){ 
        return API('auth').post(`save_self_service_photo/` + payload, photo)
    },
    
    SELF_SERVICE_BIO_SUBMISSION(data){
        return API('auth').post('save_self_service_bio', data)
    }, 

    LOAD_EDUCATION_HISTORY(data){
        return API('auth').post('load_education_history', data)
    }, 
    SELF_SERVICE_EDUCATION_SUBMISSION(data){
        return API('auth').post('save_self_service_education', data)
    },
    DELETE_SERVICE_EDUCATION(data){
        return API('auth').post('delete_self_service_education', data)
    },
    
    SELF_SERVICE_WORK_SUBMISSION(data){
        return API('auth').post('save_self_service_workexp', data)
    },
    DELETE_SERVICE_WORK(data){
        return API('auth').post('delete_self_service_workexp', data)
    },
    LOAD_WORKEXP_HISTORY(data){
        return API('auth').post('load_workexp_history', data)
    }, 
    
    SELF_SERVICE_SURITY_SUBMISSION(data){
        return API('auth').post('save_self_service_surity', data)
    },
    DELETE_SERVICE_SURITY(data){
        return API('auth').post('delete_self_service_surity', data)
    },
    LOAD_SURITIES(data){
        return API('auth').post('load_surities', data)
    },
    
     
 
     
}
