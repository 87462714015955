<template>
<div> 

        <div class="card mb-5 mb-xl-10">
            <!--begin::Card body-->
            <div class="card-body">
                <!--begin::Notice-->
                <div class="notice d-flex bg-light-danger rounded border-danger border border-dashed mb-12 p-6">
                    <!--begin::Icon-->
                    <!--begin::Svg Icon | path: icons/duotune/general/gen044.svg-->
                    <span class="svg-icon svg-icon-2tx svg-icon-danger me-4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"></rect>
                            <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"></rect>
                            <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"></rect>
                        </svg>
                    </span>
                    <!--end::Svg Icon-->
                    <!--end::Icon-->
                    <!--begin::Wrapper-->
                    <div class="d-flex flex-stack flex-grow-1">
                        <!--begin::Content-->
                        <div class="fw-bold">
                            <h4 class="text-gray-900 fw-bolder">We need your attention!</h4>
                            <div class="fs-6 text-gray-700">Your payment was declined. To start using tools, please
                            <a href="#" class="fw-bolder text-danger" data-bs-toggle="modal" data-bs-target="#kt_modal_new_card">Add Payment Method</a>.</div>
                        </div>
                        <!--end::Content-->
                    </div>
                    <!--end::Wrapper-->
                </div>
                <!--end::Notice-->
                <!--begin::Row-->
                <div class="row">
                    <!--begin::Col-->
                    <div class="col-lg-7">
                        <!--begin::Heading-->
                        <h3 class="mb-2">Active until Dec 09, 2022</h3>
                        <p class="fs-6 text-gray-600 fw-bold mb-6 mb-lg-15">We will send you a notification upon Subscription expiration</p>
                        <!--end::Heading-->
                        <!--begin::Info-->
                        <div class="fs-5 mb-2">
                            <span class="text-gray-800 fw-bolder me-1">$24.99</span>
                            <span class="text-gray-600 fw-bold">Per Month</span>
                        </div>
                        <!--end::Info-->
                        <!--begin::Notice-->
                        <div class="fs-6 text-gray-600 fw-bold">Extended Pro Package. Up to 100 Agents &amp; 25 Projects</div>
                        <!--end::Notice-->
                    </div>
                    <!--end::Col-->
                    <!--begin::Col-->
                    <div class="col-lg-5">
                        <!--begin::Heading-->
                        <div class="d-flex text-muted fw-bolder fs-5 mb-3">
                            <span class="flex-grow-1 text-gray-800">Users</span>
                            <span class="text-gray-800">86 of 100 Used</span>
                        </div>
                        <!--end::Heading-->
                        <!--begin::Progress-->
                        <div class="progress h-8px bg-light-primary mb-2">
                            <div class="progress-bar bg-primary" role="progressbar" style="width: 86%" aria-valuenow="86" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <!--end::Progress-->
                        <!--begin::Description-->
                        <div class="fs-6 text-gray-600 fw-bold mb-10">14 Users remaining until your plan requires update</div>
                        <!--end::Description-->
                        <!--begin::Action-->
                        <div class="d-flex justify-content-end pb-0 px-0">
                            <a href="#" class="btn btn-light btn-active-light-primary me-2">Cancel Subscription</a>
                            <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_upgrade_plan">Upgrade Plan</button>
                        </div>
                        <!--end::Action-->
                    </div>
                    <!--end::Col-->
                </div>
                <!--end::Row-->
            </div>
            <!--end::Card body-->
        </div>

        <div class="card">
								<!--begin::Header-->
								<div class="card-header card-header-stretch">
									<!--begin::Title-->
									<div class="card-title">
										<h3 class="m-0 text-gray-800">Statement</h3>
									</div>
									<!--end::Title--> 
								</div>
								<!--end::Header-->
								<!--begin::Tab Content--> 
										<div class="table-responsive">
											<!--begin::Table-->
											<table class="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9">
												<!--begin::Thead-->
												<thead class="border-gray-200 fs-5 fw-bold bg-lighten">
													<tr>
														<th class="min-w-175px ps-9">Date</th>
														<th class="min-w-150px px-0">Order ID</th>
														<th class="min-w-350px">Details</th>
														<th class="min-w-125px">Amount</th>
														<th class="min-w-125px text-center">Invoice</th>
													</tr>
												</thead>
												<!--end::Thead-->
												<!--begin::Tbody-->
												<tbody class="fs-6 fw-bold text-gray-600">
													<tr>
														<td class="ps-9">Nov 01, 2020</td>
														<td class="ps-0">102445788</td>
														<td>Darknight transparency 36 Icons Pack</td>
														<td class="text-success">$38.00</td>
														<td class="text-center">
															<button class="btn btn-light btn-sm btn-active-light-primary">Download</button>
														</td>
													</tr>
													<tr>
														<td class="ps-9">Oct 24, 2020</td>
														<td class="ps-0">423445721</td>
														<td>Seller Fee</td>
														<td class="text-danger">$-2.60</td>
														<td class="text-center">
															<button class="btn btn-light btn-sm btn-active-light-primary">Download</button>
														</td>
													</tr>
													<tr>
														<td class="ps-9">Oct 08, 2020</td>
														<td class="ps-0">312445984</td>
														<td>Cartoon Mobile Emoji Phone Pack</td>
														<td class="text-success">$76.00</td>
														<td class="text-center">
															<button class="btn btn-light btn-sm btn-active-light-primary">Download</button>
														</td>
													</tr>
													<tr>
														<td class="ps-9">Sep 15, 2020</td>
														<td class="ps-0">312445984</td>
														<td>Iphone 12 Pro Mockup Mega Bundle</td>
														<td class="text-success">$5.00</td>
														<td class="text-center">
															<button class="btn btn-light btn-sm btn-active-light-primary">Download</button>
														</td>
													</tr>
													<tr>
														<td class="ps-9">May 30, 2020</td>
														<td class="ps-0">523445943</td>
														<td>Seller Fee</td>
														<td class="text-danger">$-1.30</td>
														<td class="text-center">
															<button class="btn btn-light btn-sm btn-active-light-primary">Download</button>
														</td>
													</tr>
													<tr>
														<td class="ps-9">Apr 22, 2020</td>
														<td class="ps-0">231445943</td>
														<td>Parcel Shipping / Delivery Service App</td>
														<td class="text-success">$204.00</td>
														<td class="text-center">
															<button class="btn btn-light btn-sm btn-active-light-primary">Download</button>
														</td>
													</tr>
													<tr>
														<td class="ps-9">Feb 09, 2020</td>
														<td class="ps-0">426445943</td>
														<td>Visual Design Illustration</td>
														<td class="text-success">$31.00</td>
														<td class="text-center">
															<button class="btn btn-light btn-sm btn-active-light-primary">Download</button>
														</td>
													</tr>
													<tr>
														<td class="ps-9">Nov 01, 2020</td>
														<td class="ps-0">984445943</td>
														<td>Abstract Vusial Pack</td>
														<td class="text-success">$52.00</td>
														<td class="text-center">
															<button class="btn btn-light btn-sm btn-active-light-primary">Download</button>
														</td>
													</tr>
													<tr>
														<td class="ps-9">Jan 04, 2020</td>
														<td class="ps-0">324442313</td>
														<td>Seller Fee</td>
														<td class="text-danger">$-0.80</td>
														<td class="text-center">
															<button class="btn btn-light btn-sm btn-active-light-primary">Download</button>
														</td>
													</tr>
												</tbody>
												<!--end::Tbody-->
											</table>
											<!--end::Table-->
										</div> 
								<!--end::Tab Content-->
							</div>

</div> 
</template>

<script>
// @ is an alias to /src 
// import PORTFOLIO_SERVICE  from "@/services/portfolio.js";  




import { PORTFOLIO_URLS } from "@/router/urls";
  
//TABS COMPONENT    
import { IS_EMPTY, APP_INFO, REMOVE_CHARC, ADMINISTRATIVE_CLASS, ACCESS_PRIVELEDGES , GRANT_ACCESS , /*RESPONSE_STATUS,*/ STATUS_TAG_COLOR, CAPITALIZE_STR, CHECK_BOOLEAN, CHECK_PLURAL, READABLE_DATE, TRUNCATE_STRING} from "@/utils/";  
import { PARSE_AUTH, ENCRYPT, /*DECRYPT */ } from "@/utils/security/";  
export default {
name: "Organization-Dashboard",
props: ["subsidiary"],
data() {
    return{ 
        // Theme: this.$store.state.currentTheme, 
            // notifications: this.$store.getters.notifications,
        PORTFOLIO_URLS,
        editModal: false,  
        admins: [], 
        administrator: '', 
        subsidiaries: [],
        // subsidiary: '',
        employees: [],
        offices: [],  
        events: [], 
        priviledges: [],  
        admin_classes: [],
        adClassDesc: '',
        HQ: '', 
        editable_subsidiary: this.subsidiary,
        logo: IS_EMPTY(this.subsidiary.logo,) ? APP_INFO.default_img : this.subsidiary.logo,
        ADMINISTRATIVE_CLASS, GRANT_ACCESS , ACCESS_PRIVELEDGES, CHECK_PLURAL, ENCRYPT, IS_EMPTY, PARSE_AUTH, APP_INFO, REMOVE_CHARC, STATUS_TAG_COLOR, CAPITALIZE_STR, CHECK_BOOLEAN, READABLE_DATE, TRUNCATE_STRING, 
        ref: this.$route.query.ref, // truncate ref url data to get user id 
		auth: PARSE_AUTH(this.$store.state.auth, "user"),
    }
},
components: {
    // Notification, SideNav, TopNav /*EVENTSCOMPONENT, ACTIVITYCOMPONENT /* CPlaceholder */
},  
methods: {  
          
        // LOAD_PORTFOLIO_DASHBOARD: function(){    
        //     PORTFOLIO_SERVICE.LOAD_PORTFOLIO_DASHBOARD({"payload": this.ref}) 
        //     .then(res =>{ // TRY USER LOGIN PROCESS    
        //         !res.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": res.data.success, "msg": res.data.msg }) : null;
        //         if(res.data.success){
        //             this.subsidiary = DECRYPT(res.data.data).payload;  
        //             this.admins = this.subsidiary.administrators; 
        //             // this.subsidiaries = this.subsidiary,
        //             this.employees = this.subsidiary.employees;
        //             this.offices = this.subsidiary.offices;
        //             this.events = this.subsidiary.events; 
        //             console.log(this.offices) 
        //             this.GET_HEAD_OFFICE()
        //         }
        //         if(res.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 2500);
        //     }) 
        //     .catch(err => { 
        //         console.log(err.message) 
        //         this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading page contents, reload page" }); 
        //     })   
        // },

        LOAD_COMPONENT: function(tab){ 
            this.$store.commit('PAGE_NOTIFICATION', null) 
            this.active_tab = tab;
        },

        GET_HEAD_OFFICE: function(){  
            this.HQ = this.subsidiary.offices.find(element => CHECK_BOOLEAN(element.head_office) == true) 
        }

}, 
mounted() {    
	let extScript = document.createElement('script')
	extScript.setAttribute('src', '/js/widgets.bundle.js')
	document.head.appendChild(extScript) 

	let extScript1 = document.createElement('script')
	extScript1.setAttribute('src', '/js/custom/widgets.js')
	document.head.appendChild(extScript1) 
}, 
};
</script>