<template>
  <div>

        <!--begin::Card title-->
        <div class="card-title d-flex justify-content-between my-5">
        <span>
            <div class="mb-1 fs-4 fw-bolder">Edit record</div>
            <div class="fs-9 text-muted">Complete form below update record</div> 
        </span>  
        </div>

    <form id="eduForm" class="login-form" enctype="multipart/form-data"> 
        
      <div class="row mt-10">
            <div class="col-md-6 mb-3">
                    <label class="form-label">Institution <span class="text-danger">*</span></label> 
                    <input v-model="editable_edu.institution" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Enter name of institution" required="">
            </div> 
            <div class="col-md-6 col-7 mb-3">
                <label class="form-label">Qualification <span class="text-danger">*</span></label> 
                <select class="form-select form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="editable_edu.education_qualification_id">
                    <option v-for="(degree, i) in degrees" :key="i" :value="degree.id">{{degree.name}}</option>
                </select>
            </div> 
            <div class="col-md-6 col-5 mb-3">
                <label class="form-label">Specilization <span class="text-danger">*</span></label> 
                <input v-model="editable_edu.specialization" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Enter degree" required="">
            </div> 
            <div class="col-md-6 col-7 mb-3">
                <label class="form-label">Grade <span class="text-danger">*</span></label> 
                <input v-model="editable_edu.grade" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Eg: first class hounors" required="">
            </div> 
            <div class="col-md-6 col-5 mb-3">
                <label class="form-label">Grad. year <span class="text-danger">*</span> <span class="fs-9 text-muted">(numbers only)</span></label> 
                <input v-model="editable_edu.graduation_year" type="number" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Eg: 2020" required="">
            </div> 
            
            <div class="col-md-6 col-7 mb-3">
                <label class="form-label">Duration <span class="text-danger">*</span> <span class="fs-9 text-muted">(numbers only)</span></label>
                <div class="input-group mb-5">
                    <input v-model="editable_edu.duration" type="number" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Eg: 4" required="">
                    <span class="input-group-text border-0 fs-8" id="basic-addon2">years</span>
                </div> 
            </div>
            <div class="col-md-5 col-5 mt-10 mt-sm-0">
                <div class="form-group">
                  <div class="btn btn-light btn-file px-4">
                    <div class="d-flex justify-content-between align-items-center fs-7"> <i class="mdi mdi-file-image"></i> Attachment <span class="ml-2 fs-9 text-muted"> (JPEG, JPG, PNG only)</span></div>
                    <input type="file" id="file" rel="file" @change="UPLOAD_FILE($event)">
                  </div> 
                </div>
            </div>  
            </div> 
            <div class="d-flex justify-content-end"> 
                    <button type="button" @click="$emit('action', {'close': 'edit'})" class="btn btn-light-danger btn-flat me-4">Close form</button> 
                    <button type="button" @click="VALIDATE_FORM()" id="edu_edit_btn" class="btn btn-light-primary btn-flat ml-2">Update record</button> 
            </div>  
    </form>

    <hr class="my-4">

  </div>
</template>


<script>
// @ is an alias to /src  
import apiServices from "@/services/apiServices"
import {MILLISECONDS_TO_DAYS, CAPITALIZE_STR, REMOVE_CHARC, RESPONSE_STATUS, IS_EMPTY } from "@/utils/"
import { ENCRYPT, DECRYPT } from "@/utils/security/"; 
import EMPLOYEE_SERVICE  from "@/services/employee.js"; 
export default {
name: "edit-education-component",
props: ['education', 'employee'], 
data() {
    return{ 
        // Theme: this.$store.state.currentTheme, 
        isAuthenticated: this.$store.state.isAuthenticated, 
        ref: this.$route.query.ref, // truncate ref url data to get user id 
        operationStatus: '', 
        MILLISECONDS_TO_DAYS, CAPITALIZE_STR, REMOVE_CHARC, ENCRYPT, DECRYPT, RESPONSE_STATUS, IS_EMPTY,
        institution: '',
        qualification: '',
        specialization: '',
        grade: '',
        grad_year: '',
        duration: '', 
        degrees: '',
        file: '', 
        editable_edu: this.education,
    }
},
components: {  
    
}, 
methods: {
 
    LOAD_UTILS: function() { // FETCH ALL MESSAGES FROM DATABASE 
        this.retry = ''
        apiServices.LOAD_DEGREES({})
            .then(result => {
                console.log(result.data.data)
                !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) : this.degrees = result.data.data;
            })
            .catch(err => {
                console.log('Error:' + err.message)
                this.isLoading = 'error';
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": "encountereda fatal server error" })
            })
    },

    UPLOAD_FILE: function(event) {
    //    let fData = new FormData();
    this.file = event.target.files[0];
        // console.log(file)
    },

    
    VALIDATE_FORM: function() { // FETCH ALL MESSAGES FROM DATABASE  
    console.log('hey')   
        // document.getElementById('edu_edit_btn').innerHTML = 'Submitting <i class="mdi mdi-loading mdi-spin"></i>';
        // document.getElementById('edu_edit_btn').setAttribute(`disabled`, true); 
        // if( IS_EMPTY(this.institution) || IS_EMPTY(this.qualification) || IS_EMPTY(this.specialization) || IS_EMPTY(this.grade) || IS_EMPTY(this.grad_year) || IS_EMPTY(this.duration)){
        //     this.$emit('notifyer', { 'success': 'error', 'msg': 'required input cannot be empty' }) 
        //     document.getElementById('edu_edit_btn').innerHTML = 'Submit <i class="mdi mdi-upload"></i>';
        //     document.getElementById('edu_edit_btn').removeAttribute(`disabled`);
        // }
        // else{
            this.UPDATE_EDUCATION_FORM() 
        // }
    },
    

    UPDATE_EDUCATION_FORM: function() { // FETCH ALL MESSAGES FROM DATABASE  
        let payload = ENCRYPT(this.editable_edu) 
        EMPLOYEE_SERVICE.UPDATE_EDUCATION_FORM({payload})  
        .then(result => {   

            if (result.data.success && !IS_EMPTY(this.file)) this.UPLOAD_EDUCATION_FILE(); // UPLOAD FILE IF THERE IS ANY ATTACHED
            if(result.data.success && IS_EMPTY(this.file)) this.$emit('action', { 'reload': true, 'close': 'edit'}) //CLOSE ADD PANE AND RELOAD RECORDS 
            this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }); 
            if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1000); 
        })
        .catch(err => { 
            console.log('Error:' + err.message) 
            this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": "encountered a fatal server error" })
        })

    },

    UPLOAD_EDUCATION_FILE: function() { // FETCH ALL MESSAGES FROM DATABASE  
        let formData = new FormData();      
        formData.append("file", this.file)     
        let payload = ENCRYPT({'oid': this.employee.subsidiary.organization_id, 'sid': this.employee.subsidiary.id, 'uid': this.employee.users_id, 'id': this.education.id}) 

        EMPLOYEE_SERVICE.UPLOAD_EDUCATION_FILE(payload, formData) 
        .then(result => { 
             this.$emit('action', { 'reload': true, 'close': 'edit'})  
            if (!result.data.success) this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg });                
        })
        .catch(err => { 
            console.log('Error:' + err.message) 
            this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": "encountereda fatal server error" })
        })
    },

},
beforeMount() {},
mounted() {
    this.LOAD_UTILS() 
},

};
</script>