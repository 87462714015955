<template> 
    <div class="d-flex flex-column flex-root"> 
        <Notification /> 
        <!--begin::Authentication - Sign-in -->
        <div class="d-flex flex-column flex-column-fluid mt-10 mt-md-20">
            <!--begin::Content-->
            <div class="d-flex flex-center flex-column flex-column-fluid p-4 pb-lg-5">
                <!--begin::Logo-->
                <span class="mb-10">
                    <img alt="Logo" :src="APP_INFO.logo" class="h-30px" />
                </span>
                <!--end::Logo-->
                <!--begin::Wrapper-->
                <div class="w-lg-500px w-100 w-md-50 bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                    <!--begin::Form-->
                    <form class="form w-100" novalidate="novalidate" id="login_form">
                        <!--begin::Heading-->
                        <div class="text-center mb-5">
                            <!--begin::Title-->
                            <h5 class="text-dark mb-3">Sign In</h5> 
                            <!--end::Title-->
                            <!--begin::Link-->
                            <div class="text-gray-400 fw-bold fs-6">New Here?
                            <router-link to="/auth/register" class="link-primary">Create an Account</router-link>
                            </div>
                            <!--end::Link-->
                        </div>
                        <!--begin::Heading-->
                        <!--begin::Input group-->
                        <div class="fv-row mb-5">
                            <!--begin::Label-->
                            <label class="form-label fs-6 fw-bolder text-dark">Email <span class="text-danger">*</span></label>
                            <!--end::Label-->
                            <!--begin::Input-->
                            <input class="form-control" type="email" v-model="email" placeholder="Email address" />
                            <!--end::Input-->
                        </div>
                        <!--end::Input group-->
                        <!--begin::Input group-->
                        <div class="fv-row mb-5">
                            <!--begin::Wrapper-->
                            <div class="d-flex flex-stack mb-2">
                                <!--begin::Label-->
                                <label class="form-label fw-bolder text-dark fs-6 mb-0">Password <span class="text-danger">*</span></label>
                                <!--end::Label-->
                                <!--begin::Link-->
                                <router-link to="/auth/forgot-password" class="link-primary fs-6 fw-bolder">Forgot Password ?</router-link>
                                <!--end::Link-->
                            </div>
                            <!--end::Wrapper-->
                            <!--begin::Input-->
                            <input class="form-control" type="password" v-model="password" placeholder="Password" />
                            <!--end::Input-->
                        </div>
                        <!--end::Input group-->
                        <!--begin::Actions-->
                        <div class="text-center mt-3">
                            <!--begin::Submit button-->
                            <small class="text-danger animated fadeIn cusor-pointer d-block d-none" id="resendMail" @click="RESEND_EMAIL_VERIFICATION()">Resend verification email</small>
                            <button type="button" id="sign_in_btn" @click="DO_LOGIN()" class="btn btn-primary w-100 w-sm-50 mt-3 rounded-1">Submit</button>
                            <!--end::Submit button--> 
                        </div>
                        <!--end::Actions-->
                    </form>
                    <!--end::Form-->

                    <!--begin::Form-->
                    <form class="form w-100 animated fadeIn d-none" novalidate="novalidate" id="otp_form">
                        <!--begin::Heading-->
                        <div class="text-center mb-5">
                            <!--begin::Title-->
                            <h5 class="text-dark mb-3">Enter OTP</h5>
                            <!--end::Title--> 
                        </div>
                        <!--begin::Heading-->
                        <!--begin::Input group-->
                        <div class="fv-row mb-5">  
                            <div class="input-group mb-5"> 
                                <input type="text" class="form-control rounded-0" placeholder="OTP" aria-label="Username" v-model="otp">
                                <button type="button" class="input-group-text btn btn-light-primary rounded-0" id="otp_btn" @click="OTP_AUTH()">GO</button>
                            </div>
                        </div>
                        <!--end::Input group--> 
                    </form>
                    <!--end::Form-->

                </div>
                <!--end::Wrapper-->
            </div>
            <!--end::Content-->
            <!--begin::Footer-->
            <div class="d-flex flex-center flex-column-auto p-10">
                <!--begin::Links-->
                <div class="d-flex align-items-center fw-bold fs-6">
                    <a href="https://keenthemes.com" class="text-muted text-hover-primary px-2">About</a>
                    <a href="mailto:support@keenthemes.com" class="text-muted text-hover-primary px-2">Contact</a>
                    <a href="https://1.envato.market/EA4JP" class="text-muted text-hover-primary px-2">Contact Us</a>
                </div>
                <!--end::Links-->
            </div>
            <!--end::Footer-->
        </div>
        <!--end::Authentication - Sign-in-->
    </div>
    <!--end::Root--> 
</template>

<script>
// @ is an alias to /src
import Notification from "@/components/global/notification.vue";
import AUTH_SERVICE  from "@/services/auth.js"; 
import { IS_EMPTY, RESPONSE_STATUS, APP_INFO } from "@/utils/"; 
import { ENCRYPT, DECRYPT } from "@/utils/security/";  

export default {
name: "Login",
data() {
    return{ 
        // Theme: this.$store.state.currentTheme,  
        remember: !this.$store.state.loginDetails ? false : this.$store.state.loginDetails.remember,
        redirect_page: this.$route.query.action,
        email: !this.$store.state.loginDetails ? null : this.$store.state.loginDetails.email,
        password: !this.$store.state.loginDetails ? null : this.$store.state.loginDetails.password,
        otp: '',
        oid: '',
        uid: '',   
        IS_EMPTY, DECRYPT, RESPONSE_STATUS, APP_INFO
    }
},
components: {
    Notification,
},  
methods: {  
      
    DO_LOGIN: function(){  
         
        if(!this.email) this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": "user email field cannot be empty."}) 
        
        else if(!this.password) this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": "user password field cannot be empty."})

        else{
            document.getElementById('sign_in_btn').setAttribute(`disabled`, true); 
            document.getElementById('sign_in_btn').innerHTML = '<span class="spinner-border spinner-border-sm align-middle ms-2"></span> processing'; 
            let payload = ENCRYPT({'email': this.email, 'password': this.password})
 
            if(this.remember) this.$store.commit('AUTH_REMEMBER', { 'email': this.email, 'password': this.password, 'remember': this.remember});   
                
                AUTH_SERVICE.DO_USER_LOGIN({payload}) 
                .then(res =>{ // TRY USER LOGIN PROCESS   
                console.log(res.data)
                     this.$store.commit('PAGE_NOTIFICATION', {"success": res.data.success, "msg": res.data.msg}) 
                    
                    if(res.data.code === RESPONSE_STATUS.FORBIDDEN.code) document.getElementById("resendMail").classList.remove('d-none'); 

                    if(res.data.success && res.data.code === RESPONSE_STATUS.ACCEPTED.code) { 
                        document.getElementById("login_form").classList.add('d-none'); 
                        document.getElementById("otp_form").classList.remove('d-none'); 
                        this.oid = DECRYPT(res.data.data).oid
                        this.uid = DECRYPT(res.data.data).uid
                    }

                    if(res.data.success && res.data.code === RESPONSE_STATUS.SUCCESS.code) { 
                        document.getElementById('sign_in_btn').innerHTML = 'Sign in';  this.$store.commit('AUTH_SUCCESS', res.data.data)
                        if(this.redirect_page === "redirect_login" && !IS_EMPTY(this.$route.query.refferer_page)){ setTimeout(() => this.$router.push('/' + this.$route.query.refferer_page), 2500); }
                        else{ setTimeout(() => this.$router.push('/dashboard'), 3000); } 
                    }
                    
                    else{  document.getElementById('sign_in_btn').innerHTML = 'Sign in'; document.getElementById('sign_in_btn').removeAttribute(`disabled`); } 
                }) 
                .catch(err => { 
                    console.log(err.message) 
                    this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": 'error occured processig your request' }) 
                    document.getElementById('sign_in_btn').innerHTML = 'Sign in'; document.getElementById('sign_in_btn').removeAttribute(`disabled`);  
                })  
        }
    },

    OTP_AUTH: function(){ 
         
        if(this.otp.length == 0) this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": "OTP cannot be empty."}) 
         
        else{
            document.getElementById('otp_btn').setAttribute(`disabled`, true); 
            document.getElementById('otp_btn').innerHTML = '<span class="spinner-border spinner-border-sm align-middle ms-2"></span> verifying'; 
            let payload = ENCRYPT({"otp": this.otp, "oid": this.oid, "uid": this.uid})
                AUTH_SERVICE.DO_OTP_AUTH({payload}) 
                .then(res =>{ // TRY USER LOGIN PROCESS   

                    this.$store.commit('PAGE_NOTIFICATION', {"success": res.data.success, "msg": res.data.msg}) 
                       
                    if(res.data.success && res.data.code === RESPONSE_STATUS.SUCCESS.code) { 
                        document.getElementById('otp_btn').innerHTML = 'GO';  this.$store.commit('AUTH_SUCCESS', res.data.data)
                        if(this.redirect_page === "redirect_login" && !IS_EMPTY(this.$route.query.refferer_page)){ setTimeout(() => this.$router.push('/' + this.$route.query.refferer_page), 2500); }
                        else{ setTimeout(() => this.$router.push('/dashboard'), 2500); } 
                    }
                    
                    else{  document.getElementById('otp_btn').innerHTML = 'Go'; document.getElementById('otp_btn').removeAttribute(`disabled`); } 
                }) 
                .catch(err => { 
                    console.log(err.message) 
                    this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": 'error occured processig your request' }) 
                    document.getElementById('otp_btn').innerHTML = 'Go'; document.getElementById('otp_btn').removeAttribute(`disabled`);  
                })  
        }
    },

    RESEND_EMAIL_VERIFICATION: function(){ 
        
        this.redirect_page = ""; 

        if(!this.email) this.$store.commit("PAGE_NOTIFICATION", {"success": false, "msg": "user email field cannot be empty."})  

        else{
            document.getElementById("sign_in_btn").setAttribute(`disabled`, true); 
            document.getElementById("resendMail").innerHTML = "<span class='spinner-border spinner-border-sm align-middle ms-2'></span> Sending verification mail...";  
            let encrypt = ENCRYPT({"email": this.email })  
                
            AUTH_SERVICE.RESEND_VERIFICATION_EMAIL({"payload": encrypt}) 
            .then(res =>{ // TRY USER LOGIN PROCESS     
                this.$store.commit("PAGE_NOTIFICATION", {"success": res.data.success, "msg": res.data.msg});
                document.getElementById("sign_in_btn").removeAttribute(`disabled`);   
                res.data.success ? document.getElementById("resendMail").classList.add("d-none") : document.getElementById("resendMail").innerHTML = "Resend verification email"; 
            }) 
            .catch(err => { 
                console.log(err.message) 
                this.$store.commit("PAGE_NOTIFICATION", {"success": false, "msg": "error occured processig your request" })  
                document.getElementById("sign_in_btn").removeAttribute(`disabled`);  
                document.getElementById("resendMail").innerHTML = "Resend verification email"; 
            })  
        }
    }
}, 
mounted() {   
} 

};
</script>
