<template> 
    <div class="card mb-5 mb-xxl-10">
        <!--begin::Header-->
        <div class="card-header">
            <!--begin::Title-->
            <div class="card-title">
                <h3>Job overview</h3>
            </div>
            <!--end::Title--> 
            <!--begin::Toolbar-->
            <div class="card-toolbar">
               Last updated - {{READABLE_DATE(user.employee.updated_at)}}
            </div>
            <!--end::Toolbar-->
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body py-10">
            <!--begin::Row-->
            <div class="row mb-10">
                <!--begin::Col-->
                <div class="col-md-8 pb-10 pb-lg-0">  

                        <div class="row mb-4 mt-4">
                            <!--begin::Label-->
                            <label class="col-lg-6 fw-bold text-muted">Department</label>
                            <!--end::Label-->
                            <!--begin::Col-->
                            <div class="col-lg-6">
                                <span class="fw-bolder fs-6 text-gray-800">{{!IS_EMPTY(user.employee.employee_department) ? CAPITALIZE_STR(user.employee.employee_department.name) : '-----------'}}</span>
                            </div>
                            <!--end::Col-->
                        </div> 
                        

                        <div class="row mb-4">
                            <!--begin::Label-->
                            <label class="col-lg-6 fw-bold text-muted">Level</label>
                            <!--end::Label-->
                            <!--begin::Col-->
                            <div class="col-lg-6">
                                <span class="fw-bolder fs-6 text-gray-800">{{!IS_EMPTY(user.employee.employee_level) ? user.employee.employee_level.name : '-----------'}}</span>
                            </div>
                            <!--end::Col-->
                        </div> 
                        

                        <div class="row mb-4">
                            <!--begin::Label-->
                            <label class="col-lg-6 fw-bold text-muted">Position</label>
                            <!--end::Label-->
                            <!--begin::Col-->
                            <div class="col-lg-6">
                                <span class="fw-bolder fs-6 text-gray-800">{{!IS_EMPTY(user.employee.employee_position) ? user.employee.employee_position.name : '-----------'}}</span>
                            </div>
                            <!--end::Col-->
                        </div> 
                        

                        <div class="row mb-4">
                            <!--begin::Label-->
                            <label class="col-lg-6 fw-bold text-muted">Team</label>
                            <!--end::Label-->
                            <!--begin::Col-->
                            <div class="col-lg-6">
                                <span class="fw-bolder fs-6 text-gray-800">{{!IS_EMPTY(user.employee.employee_team) ? user.employee.employee_team.name : "-----------" }}</span>
                            </div>
                            <!--end::Col-->
                        </div> 
                        

                        <div class="row mb-4">
                            <!--begin::Label-->
                            <label class="col-lg-6 fw-bold text-muted">Number of working days</label>
                            <!--end::Label-->
                            <!--begin::Col-->
                            <div class="col-lg-6">
                                <span class="fw-bolder fs-6 text-gray-800">{{!IS_EMPTY(user.employee.number_of_work_days) ? user.employee.number_of_work_days + ' day'+CHECK_PLURAL(user.employee.number_of_work_days) : "-----------" }}</span>
                            </div>
                            <!--end::Col-->
                        </div> 
                        

                        <div class="row mb-4">
                            <!--begin::Label-->
                            <label class="col-lg-6 fw-bold text-muted">Employement Type</label>
                            <!--end::Label-->
                            <!--begin::Col-->
                            <div class="col-lg-6">
                                <span class="fw-bolder fs-6 text-gray-800">{{!IS_EMPTY(user.employee.employment_type) ? user.employee.employment_type.name : "-----------" }}</span>
                            </div>
                            <!--end::Col-->
                        </div>  
                        

                        <div class="row mb-4">
                            <!--begin::Label-->
                            <label class="col-lg-6 fw-bold text-muted">Salary</label>
                            <!--end::Label-->
                            <!--begin::Col-->
                            <div class="col-lg-6"> 
                                <span class="fw-bolder fs-6 text-gray-800">{{!IS_EMPTY(user.employee.salary) ? THOUSAND_SEPARATOR(user.employee.salary) : "-----------" }}</span>
                            </div>
                            <!--end::Col-->
                        </div>  
                        

                        <div class="row mb-4">
                            <!--begin::Label-->
                            <label class="col-lg-6 fw-bold text-muted">Job role</label>
                            <!--end::Label-->
                            <!--begin::Col-->
                            <div class="col-lg-6"> 
                                <span class="fw-bolder fs-6 text-gray-800">{{!IS_EMPTY(user.employee.job_role) ? user.employee.job_role : "-----------" }}</span>
                            </div>
                            <!--end::Col-->
                        </div> 
                        

                        <div class="row mb-4">
                            <!--begin::Label-->
                            <label class="col-lg-6 fw-bold text-muted">Hiring source</label>
                            <!--end::Label-->
                            <!--begin::Col-->
                            <div class="col-lg-6">
                                <span class="fw-bolder fs-6 text-gray-800">{{!IS_EMPTY(user.employee.hiring_source) ? user.employee.hiring_source : "-----------" }}</span>
                            </div>
                            <!--end::Col-->
                        </div>  
                        

                        <div class="row mb-4">
                            <!--begin::Label-->
                            <label class="col-lg-6 fw-bold text-muted">Resumption date</label>
                            <!--end::Label-->
                            <!--begin::Col-->
                            <div class="col-lg-6">
                                <span class="fw-bolder fs-6 text-gray-800">{{!IS_EMPTY(user.employee.resumption_date) ? READABLE_DATE(user.employee.resumption_date) : "-----------" }}</span>
                            </div>
                            <!--end::Col-->
                        </div>  

                        <div class="row mb-4" v-if="!IS_EMPTY(user.employee.end_date)">
                            <!--begin::Label-->
                            <label class="col-lg-6 fw-bold text-muted">End date</label>
                            <!--end::Label-->
                            <!--begin::Col-->
                            <div class="col-lg-6">
                                <span class="fw-bolder fs-6 text-gray-800">{{ READABLE_DATE(user.employee.end_date) }}</span>
                            </div>
                            <!--end::Col-->
                        </div> 


                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-md-4 mt-md-4">
                    <h6 class="text-gray-800">Office Location</h6> 
                    <div class="d-block d-md-none separator my-4"></div> 

                        <div class="row  mb-4 mt-4">
                            <!--begin::Label-->
                            <label class="col-lg-4 fw-bold text-muted">Branch</label>
                            <!--end::Label-->
                            <!--begin::Col-->
                            <div class="col-lg-8 d-flex justify-content-end">
                                <span class="fw-bolder fs-6 text-gray-800">{{!IS_EMPTY(user.employee.office) ? user.employee.office.address : '-----------'}}</span>
                            </div>
                            <!--end::Col-->
                        </div> 

                        <div class="row mb-4 float-right">
                            <!--begin::Label-->
                            <label class="col-lg-4 fw-bold text-muted">City</label>
                            <!--end::Label-->
                            <!--begin::Col-->
                            <div class="col-lg-8 d-flex justify-content-end">
                                <span class="fw-bolder fs-6 text-gray-800">{{!IS_EMPTY(user.employee.office) ? user.employee.office.city.name : '-----------'}}</span>
                            </div>
                            <!--end::Col-->
                        </div>

                        <div class="row mb-4 float-right">
                            <!--begin::Label-->
                            <label class="col-lg-4 fw-bold text-muted">State</label>
                            <!--end::Label-->
                            <!--begin::Col-->
                            <div class="col-lg-8 d-flex justify-content-end">
                                <span class="fw-bolder fs-6 text-gray-800">{{!IS_EMPTY(user.employee.office) ? user.employee.office.state.name : '-----------'}}</span>
                            </div>
                            <!--end::Col-->
                        </div>
                        
                        <div class="row mb-4 float-right">
                            <!--begin::Label-->
                            <label class="col-lg-4 fw-bold text-muted">Country</label>
                            <!--end::Label-->
                            <!--begin::Col-->
                            <div class="col-lg-8 d-flex justify-content-end">
                                <span class="fw-bolder fs-6 text-gray-800">{{!IS_EMPTY(user.employee.office) ? user.employee.office.country.name : '-----------'}}</span>
                            </div>
                            <!--end::Col-->
                        </div> 

                </div>
                <!--end::Col-->
                
                        <div class="row mb-4">
                            <!--begin::Label-->
                            <label class="col-lg-3 fw-bold text-muted">Work days</label>
                            <!--end::Label-->
                            <!--begin::Col-->
                            <div class="col-lg-8">
                                <span class="fw-bolder fs-6 text-gray-800" v-if="IS_EMPTY(user.employee.work_days)">-----------</span>
                                <span class="fw-bolder fs-6 text-gray-800" v-if="!IS_EMPTY(user.employee.work_days)"> 
                                    <span v-for="(day, i) in JSON.parse(user.employee.work_days)" :key="i" >{{day}}, </span>
                                </span>
                            </div>
                            <!--end::Col-->
                        </div>   
            </div>
            <!--end::Row-->
            <!--begin::Notice-->
            <!--begin::Notice-->
            <div class="notice d-flex bg-light-dark rounded border-dark border border-dashed p-6"> 
                <!--begin::Wrapper-->
                <div class="d-flex flex-column">
                <h6>Job description</h6>
                    <!--begin::Content-->
                    <div class="fw-bold">
                        <div class="fs-6 text-gray-700">
                        {{user.employee.job_desc}}
                        </div>
                    </div>
                    <!--end::Content-->
                </div>
                <!--end::Wrapper-->
            </div>
            <!--end::Notice-->
            <!--end::Notice-->
        </div>
        <!--end::Body-->
    </div>
</template>

<script> 
//EMPLOYEE COMPONENT 
import { IS_EMPTY, CHECK_BOOLEAN, READABLE_DATE, CHECK_PLURAL, THOUSAND_SEPARATOR, CAPITALIZE_STR } from "@/utils/"; 
export default {
    name: "User overview", 
    props: ["user"],  
    data() {
        return{  
            IS_EMPTY: IS_EMPTY, 
            CHECK_BOOLEAN: CHECK_BOOLEAN, 
            READABLE_DATE: READABLE_DATE,
            CHECK_PLURAL: CHECK_PLURAL,
            THOUSAND_SEPARATOR: THOUSAND_SEPARATOR,
            CAPITALIZE_STR: CAPITALIZE_STR,
        }
    },  
}
</script>