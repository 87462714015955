<template>
<div>
    <Notification />  
    <div class="wrapper d-flex flex-column flex-row-fluid mb-15" id="kt_wrapper"> 
        <TopNav />
        <SideNav /> 
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
            <!--begin::Container-->  
            <div class="container-xxl" id="kt_content_container">
                <div class="card mb-6 mb-xl-9">
                    <div class="card-body pt-9 pb-0 shadow-lite">
                        <!--begin::Details-->
                        <div class="d-flex flex-wrap flex-sm-nowrap mb-6">
                            <!--begin::Image-->
                            <div class="d-flex flex-center flex-shrink-0 bg-light rounded w-150px h-150px w-sm-150px h-sm-150px me-7 mb-4">
                                <img class="mw-100px mw-lg-75px" v-if="!loading" :src="subsidiary.logo" alt="logo"> 
                                <IMAGELOADER v-else />
                            </div>  
                            <!--end::Image-->
                            <!--begin::Wrapper-->
                            <div class="flex-grow-1">
                                <!--begin::Head-->
                                <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                    <!--begin::Details-->
                                    <div class="d-flex flex-column">
                                        <!--begin::Status-->
                                        <div class="d-flex align-items-center">
                                            <span class="text-gray-800 text-hover-primary fs-md-2 fs-4 fw-bolder me-3" v-if="!loading">
                                                {{TRUNCATE_STRING(subsidiary.name, 50)}}
                                            </span>
                                            <TITLELOADER class="mb-3" v-else :subText="2" />
                                            <!-- <span  v-if="!IS_EMPTY(subsidiary)" class="badge  me-auto" :class="`badge-light-${STATUS_TAG_COLOR(subsidiary.status_id)}`">{{subsidiary.status.name}}</span> -->
                                        </div>
                                        <!--end::Status-->
                                        <!--begin::Description-->
                                        <div class="d-flex flex-wrap fw-bold fs-9 fs-sm-7 text-gray-400" v-if="!loading">{{HQ.address}}</div>
                                        <!--end::Description-->
                                        <!--begin::Description-->
                                        <div class="d-flex flex-wrap fw-bold mb-4 fs-9 fs-sm-7 text-gray-400" v-if="!loading">Registered - {{READABLE_DATE(subsidiary.created_at)}}</div>
                                        <!--end::Description-->
                                    </div>
                                    <!--end::Details--> 
                                </div>
                                <!--end::Head--> 
                                    <Settings :subsidiary="subsidiary" /> 
                                    <!--begin::Stats-->
                                    <div class="row g-5 g-xl-2" v-if="!loading">   
                                        <!--begin::Stat-->
                                        <div class="col-6 col-sm-3 col-md-3">
                                            <div class="card card-body py-2 px-4 border border-gray-300 border-dashed">
                                            <!--begin::Number-->
                                            <div class="d-flex align-items-center"> 
                                                <div class="fs-4 fw-bolder counted">{{employees.length}}</div>
                                            </div>
                                            <!--end::Number-->
                                            <!--begin::Label-->
                                            <div class="fw-bold fs-6 text-gray-400">Workforce</div>
                                            <!--end::Label-->
                                            </div>
                                        </div>
                                        <!--end::Stat-->

                                        <!--begin::Stat-->
                                        <div class="col-6 col-sm-3 col-md-3">
                                            <div class="card card-body py-2 px-4 border border-gray-300 border-dashed">
                                            <!--begin::Number-->
                                            <div class="d-flex align-items-center"> 
                                                <div class="fs-4 fw-bolder counted">{{offices.length}}</div>
                                            </div>
                                            <!--end::Number-->
                                            <!--begin::Label-->
                                            <div class="fw-bold fs-6 text-gray-400">Locations</div>
                                            <!--end::Label-->
                                            </div>
                                        </div>
                                        <!--end::Stat-->

                                        <!--begin::Stat-->
                                        <div class="col-6 col-sm-3 col-md-3">
                                            <div class="card card-body py-2 px-4 border border-gray-300 border-dashed">
                                            <!--begin::Number-->
                                            <div class="d-flex align-items-center"> 
                                                <div class="fs-4 fw-bolder counted">{{administrators.length}}</div>
                                            </div>
                                            <!--end::Number-->
                                            <!--begin::Label-->
                                            <div class="fw-bold fs-6 text-gray-400">Administrators</div>
                                            <!--end::Label-->
                                            </div>
                                        </div>
                                        <!--end::Stat--> 

                                    </div>
                                    <!--end::Stats-->
                                    <GRIDLOADER class="mt-md-3" v-else :count="3" grid="col-6 col-sm-3 col-md-3" ht="h-55px" />  
                            </div>
                            <!--end::Wrapper-->
                        </div>
                        <!--end::Details--> 
                            <div class="separator"></div>
                            <PARAGRAPHLOADER class="my-5" :count="1"  v-if="loading" />  

                            <div class="me-0 py-md-0 d-flex justify-content-between align-items-center" v-else> 
                                <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-9 fs-md-7 fw-bolder cursor-pointer">
                                    <!--begin::Nav item-->
                                    <li class="nav-item">
                                        <router-link :to="PORTFOLIO_URLS('subsidiary')+'?ref='+ENCRYPT(subsidiary.id)+' &tab='+ENCRYPT(1)" class="nav-link text-active-primarypy-5 me-3 me-sm-6" :class="active_tab == 1 ? 'active' : null" @click="LOAD_COMPONENT(1)">Overview</router-link>
                                    </li>  
                                    <!--begin::Nav item-->
                                    <li class="nav-item">
                                        <router-link :to="PORTFOLIO_URLS('subsidiary')+'?ref='+ENCRYPT(subsidiary.id)+' &tab='+ENCRYPT(2)" class="nav-link text-active-primary py-5 me-3 me-sm-6" :class="active_tab == 2 ? 'active' : null" @click="LOAD_COMPONENT(2)"> Control center</router-link>
                                    </li>
                                    <!--end::Nav item-->  

                                    <!--begin::MOBILE DEVICE HIDDEN NAVS-->
                                    <li class="nav-item">
                                        <router-link :to="PORTFOLIO_URLS('subsidiary')+'?ref='+ENCRYPT(subsidiary.id)+' &tab='+ENCRYPT(3)" class="nav-link text-active-primary py-5" :class="active_tab == 3 ? 'active' : null" @click="LOAD_COMPONENT(3)"> Subscriptions</router-link>
                                    </li>
                                    <li class="nav-item d-none d-sm-block">
                                        <router-link :to="PORTFOLIO_URLS('subsidiary')+'?ref='+ENCRYPT(subsidiary.id)+' &tab='+ENCRYPT(4)" class="nav-link text-active-primary py-5" :class="active_tab == 4 ? 'active' : null" @click="LOAD_COMPONENT(4)"> Branch locations</router-link>
                                    </li> 
                                    <li class="nav-item d-none d-md-block">
                                        <router-link :to="PORTFOLIO_URLS('subsidiary')+'?ref='+ENCRYPT(subsidiary.id)+' &tab='+ENCRYPT(5)" class="nav-link text-active-primary py-5" :class="active_tab == 5 ? 'active' : null" @click="LOAD_COMPONENT(5)"> Employee management</router-link>
                                    </li> 
                                    <!--end::Nav item--> 
                                </ul>
                        
                                <div class="dropdown d-md-none">
                                    <button class="btn btn-sm btn-icon btn-bg-light rounded-circle btn-active-color-primary me-2 dropbtn">
                                        <i class="bi bi-three-dots fs-3"></i>
                                    </button>
                                    <div class="fs-8 py-4 menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px dropdown-content">
                                       <div class="menu-item px-3 d-sm-none">
                                            <router-link  @click="LOAD_COMPONENT(7)" :to="PORTFOLIO_URLS('subsidiary')+'?ref='+ENCRYPT(subsidiary.id)+' &tab='+ENCRYPT(4)" :class="active_tab == 4 ? 'active' : null" class="menu-link flex-stack px-3">Branch locations</router-link>
                                        </div>  
                                        <div class="menu-item px-3">
                                            <router-link  @click="LOAD_COMPONENT(7)" :to="PORTFOLIO_URLS('subsidiary')+'?ref='+ENCRYPT(subsidiary.id)+' &tab='+ENCRYPT(5)" :class="active_tab == 5 ? 'active' : null" class="menu-link px-3">Employee management</router-link>
                                        </div> 
                                    </div>
                                </div> 
                            </div>
                            <!--end::Menu-->
                    </div>
                </div>
                <!--begin::Row--> 
                    <div v-if="!loading"> 
                        <OVERVIEWCOMPONENT class="animated fadeIn" :subsidiary="subsidiary" v-if="active_tab == 1 & !IS_EMPTY(subsidiary)" /> 
                        <CONTROLPANELCOMPONENT class="animated fadeIn" :subsidiary="subsidiary" v-if="active_tab == 2" />
                        <SUBSCRIPTIONCOMPONENT class="animated fadeIn" :subsidiary="subsidiary" v-if="active_tab == 3" /> 
                        <OFFICEMANAGEMENTCOMPONENT class="animated fadeIn" :subsidiary="subsidiary" v-if="active_tab == 4" />
                        <EMPLOYEEMANAGEMENTCOMPONENT class="animated fadeIn" :subsidiary="subsidiary" v-if="active_tab == 5" />  
                        <SETUPANDSTRUCTURECOMPONENT class="animated fadeIn" :subsidiary="subsidiary" v-if="active_tab == 6" /> 
                        <CONDUCTANDCOMPLIANCECOMPONENT class="animated fadeIn" :subsidiary="subsidiary" v-if="active_tab == 7" /> 
                    </div> 
                    <BLANKLOADER v-else /> 

                </div>

        </div> 
    </div>
</div> 
</template>

<script>
// @ is an alias to /src
import Notification from "@/components/global/notification.vue";
import SideNav from "@/components/global/side_nav.vue";
import TopNav from "@/components/global/top_nav.vue";
import PORTFOLIO_SERVICE  from "@/services/portfolio.js"; 
// import CPlaceholder from "coreui/vue/src/components/placeholder/CPlaceholder"

// Administrator components 
import CONTROLPANELCOMPONENT from "./components/control.vue";
import CONDUCTANDCOMPLIANCECOMPONENT from "./components/cond_and_comp.vue";

import SETUPANDSTRUCTURECOMPONENT from "./components/setup_and_structure.vue";
import OVERVIEWCOMPONENT from "./components/overview.vue";
import SUBSCRIPTIONCOMPONENT from "./components/subscription.vue"; 
import EMPLOYEEMANAGEMENTCOMPONENT from "./components/employee_management/index.vue";
import OFFICEMANAGEMENTCOMPONENT from "./components/office_management.vue";

import IMAGELOADER from "@/components/loaders/image.vue";
import TITLELOADER from "@/components/loaders/title.vue";
import GRIDLOADER from "@/components/loaders/grid.vue";
import PARAGRAPHLOADER from "@/components/loaders/paragraph.vue";
import BLANKLOADER from "@/components/loaders/blank-loader.vue";

import { PORTFOLIO_URLS } from "@/router/urls";
  
//TABS COMPONENT    
import { IS_EMPTY, APP_INFO, REMOVE_CHARC, RESPONSE_STATUS, STATUS_TAG_COLOR, CAPITALIZE_STR, CHECK_BOOLEAN, CHECK_PLURAL, READABLE_DATE, TRUNCATE_STRING} from "@/utils/";  
import { PARSE_AUTH, ENCRYPT, DECRYPT } from "@/utils/security/";  
export default {
name: "subsidiary-dashboard",
data() {
    return{ 
        // Theme: this.$store.state.currentTheme, 
            // notifications: this.$store.getters.notifications,
        PORTFOLIO_URLS,
        editModal: false,  
        triggerSettings: false, 
        administrator: '', 
        subsidiaries: [],
        subsidiary: '',
        employees: [],
        administrators: [],
        offices: [],  
        events: [], 
        priviledges: [],  
        admin_classes: [],
        adClassDesc: '',
        HQ: '',
        active_tab: 1,
        tabs: ["Overview", "Employee Managetment", "Departments","Control center", "Activity", "Files"],
        loading: true,
        CHECK_PLURAL, IS_EMPTY, PARSE_AUTH, ENCRYPT, APP_INFO, REMOVE_CHARC, STATUS_TAG_COLOR, CAPITALIZE_STR, CHECK_BOOLEAN, READABLE_DATE, TRUNCATE_STRING, 
        ref: this.$route.query.ref,
        tab: this.$route.query.tab, // truncate ref url data to get user id 
    }
},
components: {
    Notification, SideNav, TopNav, IMAGELOADER, TITLELOADER, PARAGRAPHLOADER, GRIDLOADER, BLANKLOADER, OVERVIEWCOMPONENT, SETUPANDSTRUCTURECOMPONENT, CONDUCTANDCOMPLIANCECOMPONENT, CONTROLPANELCOMPONENT, SUBSCRIPTIONCOMPONENT, EMPLOYEEMANAGEMENTCOMPONENT, OFFICEMANAGEMENTCOMPONENT /*DEPARTMENTCOMPONENT, Settings EVENTSCOMPONENT, ACTIVITYCOMPONENT /* CPlaceholder */
},  
methods: {  

        RELOAD_CONTENT: function(x){ 
            if(x) this.LOAD_PORTFOLIO_DASHBOARD(); this.loading = true;
        },

        LOAD_PORTFOLIO_DASHBOARD: function(){   
             
            let payload = ENCRYPT({'id': DECRYPT(this.ref)})
            PORTFOLIO_SERVICE.LOAD_PORTFOLIO_DASHBOARD({payload}) 
            .then(res =>{ // TRY USER LOGIN PROCESS  
                !res.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": res.data.success, "msg": res.data.msg }) : null;
                if(res.data.success){
                    this.subsidiary = DECRYPT(res.data.data);  
                    this.administrators = this.subsidiary.administrators; 
                    // this.subsidiaries = this.subsidiary,
                    this.employees = this.subsidiary.employees;
                    this.offices = this.subsidiary.offices;
                    this.events = this.subsidiary.events; 
                    console.log(this.offices) 
                    this.GET_HEAD_OFFICE()
                    this.loading = false;
                }
                if(res.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': REMOVE_CHARC(this.$route.path, '/'), 'ref': this.ref } ), 2000);
            }) 
            .catch(err => { 
                console.log(err.message) 
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading page contents, reload page" }); 
            })   
        },

        LOAD_COMPONENT: function(n){ 
            this.$store.commit('PAGE_NOTIFICATION', null) 
            this.active_tab = n;
        },

        GET_HEAD_OFFICE: function(){  
            this.HQ = this.subsidiary.offices.find(element => CHECK_BOOLEAN(element.head_office) == true) 
        }

}, 
mounted() {   

    this.LOAD_PORTFOLIO_DASHBOARD()

    if(IS_EMPTY(this.tab)){
        this.active_tab = 1;
    }else{
       this.active_tab = DECRYPT(this.tab);
    }

    // console.log(DECRYPT(this.tab))
    
    // this.active_tab == 1 ? this.LOAD_PORTFOLIO_DASHBOARD() : null;
    
	// let extScript = document.createElement('script')
	// extScript.setAttribute('src', '/plugins/custom/fullcalendar/fullcalendar.bundle.js')
	// document.head.appendChild(extScript) 
    
	// let extScript3 = document.createElement('script')
	// extScript3.setAttribute('src', '/js/widgets.bundle.js')
	// document.head.appendChild(extScript3) 
}, 
};
</script>
