<template>
<div class="d-flex flex-column flex-root"> 
        <Notification :notify="notify" /> 
        <!--begin::Authentication - Sign-in -->
        <div class="d-flex flex-column flex-column-fluid">
            <!--begin::Content-->
            <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-5">
                <!--begin::Logo-->
                <a href="../../demo3/dist/index.html" class="mb-10">
                    <img alt="Logo" src="/media/logos/logo-1.svg" class="h-30px" />
                </a>
                <!--end::Logo-->
                <!--begin::Wrapper-->
                <div class="w-lg-500px w-100 w-md-50 bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                    <!--begin::Form-->
                    <form class="form w-100" novalidate="novalidate" id="kt_sign_in_form" action="#">
                        <!--begin::Heading-->
                        <div class="text-center mb-5">
                            <!--begin::Title-->
                            <h3 class="text-dark mb-3">Reset password</h3>
                            <!--end::Title-->
                            <!--begin::Link-->
                            <p class="text-gray-400">Complete form to set your new password</p>
                            <!--end::Link-->
                        </div>
                        <!--begin::Heading-->
                        <!--begin::Input group-->
                        <div class="fv-row mb-5">
                            <!--begin::Label-->
                            <label class="form-label fs-6 fw-bolder text-dark">New password <span class="text-danger">*</span></label>
                            <!--end::Label-->
                            <!--begin::Input-->
                            <input class="form-control" type="password" v-model="password" placeholder="Enter password.." />
                            <!--end::Input-->
                        </div>
                        <!--end::Input group--> 
                        <!--begin::Input group-->
                        <div class="fv-row mb-5">
                            <!--begin::Label-->
                            <label class="form-label fs-6 fw-bolder text-dark">Confirm new password <span class="text-danger">*</span></label>
                            <!--end::Label-->
                            <!--begin::Input-->
                            <input class="form-control" type="password" v-model="cPassword" placeholder="Confirm new password.." />
                            <!--end::Input-->
                        </div>
                        <!--end::Input group--> 
                        <!--begin::Actions-->
                        <div class="text-center">
                            <!--begin::Submit button-->
                            <button type="button" id="submit_btn" @click="DO_RESET()" class="btn btn-primary w-100 w-sm-50 mt-5">Submit</button>
                            <!--end::Submit button--> 
                        </div>
                        <!--end::Actions-->
                    </form>
                    <!--end::Form-->
                </div>
                <!--end::Wrapper-->
            </div>
            <!--end::Content--> 
        </div>
        <!--end::Authentication - Sign-in-->
    </div>
    <!--end::Root--> 
</template>



<script>
// @ is an alias to /src 
import Notification from "@/components/global/notification.vue";
import apiServices from "@/services/apiServices"; 
import { RESPONSE_STATUS, ENCRYPT_STR, APP_INFO, ENCRYPT_JSON_PAYLOAD } from "@/utils/"

export default {
name: "Reset_password",
data() {
    return{ 
        id: this.$route.query.ref, // truncate ref url data to get user id
        token: this.$route.query.meta, // truncate ref url data to get user id 
        password: '',
        cPassword: '', 
        accTypes: null, 
        RESPONSE_STATUS: RESPONSE_STATUS,
        APP_INFO: APP_INFO,
        ENCRYPT_STR: ENCRYPT_STR,
        notify: {"success": null, 'msg': null},  
        // Theme: this.$store.state.currentTheme,    
    }
},
components: { Notification
},  
methods: { 
    
    DO_RESET: function(){  
        
        this.notify = {"success": null, 'msg': null},
        document.getElementById('submit_btn').innerHTML = '<span class="spinner-border spinner-border-sm align-middle ms-2"></span>';
        document.getElementById('submit_btn').setAttribute(`disabled`, true); 
        let payload = ENCRYPT_JSON_PAYLOAD({"id": this.id, "token": this.token, "password":this.password, "confirm_password": this.cPassword })
        apiServices.RESET_PASWORD({"payload": payload})
        .then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION
            console.log(result.data) 
            document.getElementById('submit_btn').innerHTML = 'Submit'; 
            if(!result.data.success){   
                this.notify = {"success": 'error', 'msg': result.data.msg}; 
                document.getElementById('submit_btn').removeAttribute(`disabled`);
            }
            else{  
                this.notify = {"success": 'success', 'msg': 'Password reset was successful.'}; 
                setTimeout(() => this.$router.push('/login'), 2000)  
            }  
        })
        .catch(err => { 
            console.log(err.message)  
            document.getElementById('submit_btn').innerHTML = 'Submit'; 
            document.getElementById('submit_btn').removeAttribute(`disabled`);
            this.notify = {"success": 'error', 'msg': ' an internal server error occured.'};     
        })

    },
     

}, 
mounted() { 
} 



} 
</script>