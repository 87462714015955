import axios from 'axios'; 
import store from "@/store"; 
import { APP_INFO } from '@/utils'; 
import { ENCRYPT, PARSE_AUTH } from "@/utils/security/index.js"; 

export default (x) => {  
    // let IP = `http://104.248.48.188`
    let IP = `http://localhost`
    let apiUrl = ''                                                                 
    let header = false
    let auth = store.state.auth 
    store.commit('PAGE_NOTIFICATION', null)

    switch(x) { // switch to define perfect api route

        case 'auth': apiUrl = IP + `:5000/api/v1/auth/` // if api request is for auth server  
        break;

        case 'user': apiUrl = IP + `:5001/api/v1/user/` // if api request is for user server 
        header = true; 
        break;

        case 'portfolio': apiUrl = IP + `:5002/api/v1/portfolio/` // organization, subsidiary server routes
        header = true; 
        break;

        case 'utility': apiUrl = IP + `:5003/api/v1/utility/`;   
        header = false; 
        break;

        case 'chiefHR': apiUrl = IP + `:5004/api/v1/ch/`; 
        header = true; 
        break;

        case 'management': apiUrl = IP + `:5005/api/v1/management/`; 
        header = true; 
        break; 

        case 'search': apiUrl = IP + `:5006/api/v1/search/`; 
        header = true; 
        break; 

        case 'employee': apiUrl = IP + `:5007/api/v1/employee/`; 
        header = true; 
        break; 

        case 'file_man': apiUrl = IP + `:8888/api/v1/file_management/`; 
        header = true; 
        break; 
         

        default: apiUrl = IP + `:9999/api/`; 
          
    }   

    if(header){   
        let encrypted_header = ENCRYPT({ // declare all header params 
            "_token": PARSE_AUTH(auth, 'header').token,
            "_chauth": PARSE_AUTH(auth, 'user').is_chief_hr,
            "_device": PARSE_AUTH(auth, 'header').device,
            "_os": PARSE_AUTH(auth, 'header').os,
            "_medium": PARSE_AUTH(auth, 'header').user_agent, // browser type or medium type
            "_agent":  PARSE_AUTH(auth, 'header').agent, 
        }) 
        return axios.create({ // dispatch api route
            baseURL: apiUrl,
            headers: {"authorization": encrypted_header, "api_key": ENCRYPT(APP_INFO.api_key) }
        }) 
    }else{
        return axios.create({ // dispatch api route
            baseURL: apiUrl, 
            headers: {"api_key": ENCRYPT(APP_INFO.api_key) }
        }) 
    }
    
}

