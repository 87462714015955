<template>
<div>  


            <!--begin::Table-->
            <div class="card card-flush my-6 mt-xl-2"> 
                <!--begin::Card header-->
                <div class="card-header">
                    <!--begin::Card title-->
                    <div class="card-title flex-column">
                        <h3 class="fw-bolder mb-1">Available workforce</h3>
                        <div class="fs-8 text-gray-400">showing {{employees.length}} of {{total}} records</div>
                    </div>
                    <!--begin::Card title-->
                    <!--begin::Card toolbar-->
                    <div class="card-toolbar my-1">
                        <!--begin::Select-->
                        <div class="me-1 my-1"> 
                            <select v-model="catFilter" @change="PARSE_CATEGORY()" class="w-130px form-select form-select-solid form-select-sm fs-8">
                                <option value="all">All employees</option>
                                <option value="level">All departments</option>
                                <option value="status">All positions</option>
                                <option value="office">All levels</option> 
                            </select>
                        </div>
                        <!--end::Select-->
                        <!--begin::Select-->
                        <div class="me-1 my-1"> 
                            <select v-model="catFilter" @change="PARSE_CATEGORY()" class="w-130px form-select form-select-solid form-select-sm fs-8">
                                <option value="all">All</option>
                                <option value="level">By level</option>
                                <option value="status">By status</option>
                                <option value="office">By location</option>
                                <option value="position">By position</option>
                                <option value="department">By department</option>
                            </select>
                        </div>
                        <!--end::Select-->
                        <!--begin::Select-->
                        <div class="me-1 my-1 animated fadeIn" v-if="catFilter == 'level'">
                            <select v-model="statusFilter" @change="DO_FILTER()" class="w-125px form-select form-select-solid form-select-sm">
                                <option value="0" selected="selected">filter level</option>
                                <option :value="level.id" v-for="(level, i) in subsidiary.employee_levels" :key="i">{{level.name}}</option> 
                            </select>
                        </div>
                        <!--end::Select-->
                        
                        <!--begin::Select-->
                        <div class="me-1 my-1 animated fadeIn" v-if="catFilter == 'status'">
                            <select v-model="statusFilter" @change="DO_FILTER()" class="w-125px form-select form-select-solid form-select-sm">
                                <option value="0" selected="selected">filter status</option>
                                <option value="1">active only</option> 
                                <option value="14">onboarding</option> 
                                <option value="11">unapproved</option>  
                                <option value="7">resigned only</option> 
                                <option value="8">retired only</option> 
                                <option value="9">terminated only</option> 
                                <option value="10">onleave only</option>   
                                <option value="15">suspended only</option>  
                            </select>
                        </div>
                        <!--end::Select-->
                        
                        <!--begin::Select-->
                        <div class="me-1 my-1 animated fadeIn" v-if="catFilter == 'office'">
                            <select v-model="statusFilter" @change="DO_FILTER()" class="w-125px form-select form-select-solid form-select-sm">
                                <option value="0" selected="selected">filter office</option>
                                <option :value="office.id" v-for="(office, i) in subsidiary.offices" :key="i">{{office.address}}</option> 
                            </select>
                        </div>
                        <!--end::Select-->
                        
                        <!--begin::Select-->
                        <div class="me-1 my-1 animated fadeIn" v-if="catFilter == 'position'">
                            <select v-model="statusFilter" @change="DO_FILTER()" class="w-125px form-select form-select-solid form-select-sm">
                                <option value="0" selected="selected">filter position</option> 
                                <option :value="position.id" v-for="(position, i) in subsidiary.employee_positions" :key="i">{{position.name}}</option>     
                            </select>
                        </div>
                        <!--end::Select-->
                        
                        <!--begin::Select-->
                        <div class="me-1 my-1 animated fadeIn" v-if="catFilter == 'department'">
                            <select v-model="statusFilter" @change="DO_FILTER()" class="w-125px form-select form-select-solid form-select-sm">
                                <option value="0" selected="selected">filter department</option>
                                <option :value="dept.id" v-for="(dept, i) in subsidiary.employee_departments" :key="i">{{dept.name}}</option>    
                            </select>
                        </div>
                        <!--end::Select--> 
                        <div class="d-flex align-items-center position-relative my-1 me-1">
                            <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                            <span class="svg-icon svg-icon-3 position-absolute ms-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
                                </svg>
                            </span>
                            <!--end::Svg Icon-->
                            <input type="text" class="form-control form-control-solid form-select-sm w-250px w-md-150px ps-9" placeholder="Search name" />
                        </div>
                        <!--end::Search-->

                        <ADDEMPLOYEE :subsidiary="subsidiary" v-on:action="CHILD_ACTION" /> 

                    </div>
                    <!--begin::Card toolbar-->
                </div>
                <!--end::Card header-->
            </div>

            <EMPERROR v-if="resp" :status="respStat" :msg="respMsg" /> 
            <div v-else>
                <div v-if="!loading">
                    <div class="row g-5 g-xl-8 justify-content-center">
                        <div class="col-xl-2 col-6">
                            <!--begin::Statistics Widget 5-->
                            <span class="card shadow-lite hoverable mb-xl-8 border border-gray-300 ">
                                <!--begin::Body-->
                                <div class="card-body px-4 px-md-8 py-4"> 
                                    <div class="text-gray-900 fw-bolder fs-2">{{total}}</div>
                                    <div class="fw-bold text-gray-400 fs-8">Employee{{CHECK_PLURAL(total)}}</div>
                                </div>
                                <!--end::Body-->
                            </span>
                            <!--end::Statistics Widget 5-->
                        </div>
                        <div class="col-xl-2 col-6">
                            <!--begin::Statistics Widget 5-->
                            <span class="card shadow-lite  hoverable mb-xl-8 border border-gray-300 ">
                                <!--begin::Body-->
                                <div class="card-body px-4 px-md-8 py-4"> 
                                    <div class="text-gray-900 fw-bolder fs-2">{{subsidiary.employee_departments.length}}</div>
                                    <div class="fw-bold text-gray-400 fs-8">Departments</div>
                                </div>
                                <!--end::Body-->
                            </span>
                            <!--end::Statistics Widget 5-->
                        </div>
                        <div class="col-xl-2 col-6">
                            <!--begin::Statistics Widget 5-->
                            <span class="card shadow-lite hoverable mb-xl-8 border border-gray-300 ">
                                <!--begin::Body-->
                                <div class="card-body px-4 px-md-8 py-4"> 
                                    <div class="text-gray-900 fw-bolder fs-2">500M$</div>
                                    <div class="fw-bold text-gray-400 fs-8">Teams</div>
                                </div>
                                <!--end::Body-->
                            </span>
                            <!--end::Statistics Widget 5-->
                        </div>
                        <div class="col-xl-2 col-6">
                            <!--begin::Statistics Widget 5-->
                            <span class="card shadow-lite hoverable mb-5 mb-xl-8">
                                <!--begin::Body-->
                                <div class="card-body px-4 px-md-8 py-4"> 
                                    <div class="text-gray-900 fw-bolder fs-2">500M$</div>
                                    <div class="fw-bold text-gray-400 fs-8">Units</div>
                                </div>
                                <!--end::Body-->
                            </span>
                            <!--end::Statistics Widget 5-->
                        </div>
                    </div>    

                    <!--begin::Card body--> 
                        <div class="row g-6 g-xl-9">
                            <!--begin::Followers-->
                            <!--begin::Col-->
                            <div class="col-6 col-sm-4 col-md-3 cursor-pointer" @click="LOAD_EMPLOYEE(employee.users_id)" v-for="(employee, i) in employees" :key="i">
                                
                                <!--begin::Card-->
                                <div class="card shadow-lite">
                                    <div class="ribbon ribbon-top ribbon-vertical" v-if="employee.status_id === STATUS_CODE.UNAPPROVE">
                                        <div class="ribbon-label bg-primary"><i class="mdi mdi-account-clock text-white fs-5"></i></div> 
                                    </div>
                                    <!--begin::Card body-->
                                    <div class="card-body d-flex flex-center flex-column">
                                        <!--begin::Avatar-->
                                        <div class="symbol symbol-75px symbol-circle mb-5">
                                            <img :src="employee.user.photo" />
                                            <div :class="'bg-'+STATUS_TAG_COLOR(employee.status_id)" class="position-absolute rounded-circle translate-middle start-100 top-100 border border-4 border-white h-15px w-15px ms-n3 mt-n3"></div>
                                        </div>
                                        <!--end::Avatar-->
                                        <!--begin::Name-->
                                        <span class="fs-6 fs-md-4 text-gray-800 fw-bolder mb-0">
                                            <!--<LOADUNAPPROVED :emp="employee" v-if="employee.status_id !== STATUS_CODE.UNAPPROVE || employee.status_id !== STATUS_CODE.ONBOARDING" /> -->
                                            <LOADEMPLOYEE :emp="employee" /> 
                                        </span>
                                        <!--end::Name-->
                                        <!--begin::Position-->
                                        <div class="fw-bold text-gray-400 fs-8">{{TRUNCATE_STRING(employee.user.email, 17)}}</div>
                                        <div class="fw-bold text-gray-400 fs-8">{{!IS_EMPTY(employee.user.phone) ? employee.user.phone : '------------'}}</div>
                                        <!--end::Position-->  
                                    </div>
                                    <!--begin::Card body-->
                                </div>
                                <!--begin::Card-->
                            </div>
                            <!--end::Col--> 
                        </div>
                        <!--end::Row--> 
                </div>
                
                <div v-else >
                    <GRIDLOADER class="mb-md-8" :count="6" grid="col-xl-2 col-6" ht="h-50px" />
                    <BLANKLOADER class="mt-5" /> 
                </div>
    
                <div class="d-flex justify-content-center mt-5" v-if="remItem !== 0">
                    <button class="btn btn-primary btn-sm rounded-0 shadow-sm" type="button" @click="LOAD_EMPLOYEES()">Load more...</button>
                </div> 
            </div>

</div> 
</template>

<script>
// @ is an alias to /src 
import EMPLOYEE_SERVICE  from "@/services/employee.js"; 

//TABS COMPONENT 
import LOADEMPLOYEE from "./modals/load_employee.vue"; 
// import LOADUNAPPROVED from "./modals/load_unapproved_employee.vue"; 
import GRIDLOADER from "@/components/loaders/grid.vue"; 
import BLANKLOADER from "@/components/loaders/blank-loader.vue";
import EMPERROR from "@/components/loaders/response.vue";
import ADDEMPLOYEE from "./modals/add_employee.vue";


// import { CPlaceholder } from '@coreui/vue'
import { PORTFOLIO_URLS } from "@/router/urls";
import { IS_EMPTY, APP_CONSTANTS, GET_INITIAL, TRUNCATE_STRING, GET_COLOR, THOUSAND_SEPARATOR, APP_INFO, STATUS_CODE, REMOVE_CHARC, RESPONSE_STATUS, STATUS_TAG_COLOR, CAPITALIZE_STR, CHECK_BOOLEAN,  CHECK_PLURAL, READABLE_DATE} from "@/utils/";  
import { PARSE_AUTH, ENCRYPT, DECRYPT } from "@/utils/security/"; 
export default {
name: "Offices",
props: ["subsidiary"],
data() {
    return{ 
        // Theme: this.$store.state.currentTheme,   
        uid: "",  
        respMsg: '',
        resp: false,
        respStat: '',
        load_user: false,
        add_modal: false,
        PORTFOLIO_URLS, 
        READABLE_DATE, APP_CONSTANTS, ENCRYPT, GET_INITIAL, GET_COLOR, TRUNCATE_STRING, IS_EMPTY, THOUSAND_SEPARATOR, PARSE_AUTH, APP_INFO, STATUS_CODE, REMOVE_CHARC, STATUS_TAG_COLOR, CAPITALIZE_STR, CHECK_BOOLEAN, CHECK_PLURAL,
        employees: [],
        catFilter: "all",
        statusFilter: "0",
		dp: APP_CONSTANTS.default_photo,
        page: 1, 
        total: null,   
        nextPage: 0,
        remItem: 0, 
        loading: true, 
        ref: this.$route.query.ref, // truncate ref url data to get user id 
        // payload: ENCRYPT({'id': DECRYPT(this.ref), 'page':this.page}),
        filter: 'subsidiary', // truncate ref url data to get user id 
        url_id: this.$route.query._id, // truncate ref url data to get user id  
    }
},
components: { LOADEMPLOYEE, GRIDLOADER, BLANKLOADER, EMPERROR, ADDEMPLOYEE },  
methods: {    
        
        CHILD_ACTION: function(action){  
            if(action.reload){
                this.RELOAD_CONTENT(); 
            }  
        },

        RELOAD_CONTENT: function(){  
            console.log('reload')
            this.page = 1; 
            this.total = null;   
            this.nextPage = 0;
            this.remItem = 0; 
            this.loading = true; 
            this.employees = []  
            this.LOAD_EMPLOYEES()
        }, 

        LOAD_EMPLOYEE: function(id){  
            this.uid = id;
            this.load_user = true;
        },

        PARSE_CATEGORY: function(){ 
            this.catFilter == 'all' ? this.DO_FILTER() : null ; 
        },

        PARSE_SERVICE: function(){  
            switch (this.catFilter) {
                case 'office':  
                    return EMPLOYEE_SERVICE.LOAD_EMPLOYEES_FILTER_OFFICE({payload: ENCRYPT({'id': DECRYPT(this.ref), '_id': this.statusFilter, 'page':this.page})}) 
                
                case 'department':  
                    return EMPLOYEE_SERVICE.LOAD_EMPLOYEES_FILTER_DEPARTMENT({payload: ENCRYPT({'id': DECRYPT(this.ref), '_id': this.statusFilter, 'page':this.page})}) 
                
                case 'level':  
                    return EMPLOYEE_SERVICE.LOAD_EMPLOYEES_FILTER_LEVEL({payload: ENCRYPT({'id': DECRYPT(this.ref), '_id': this.statusFilter, 'page':this.page})}) 
                
                case 'position':  
                    return EMPLOYEE_SERVICE.LOAD_EMPLOYEES_FILTER_POSITION({payload: ENCRYPT({'id': DECRYPT(this.ref), '_id': this.statusFilter, 'page':this.page})})  
                
                case 'status':  
                    return EMPLOYEE_SERVICE.LOAD_EMPLOYEES_FILTER_STATUS({payload: ENCRYPT({'id': DECRYPT(this.ref), '_id': this.statusFilter, 'page':this.page})}) 
                 
                default: 
                    return EMPLOYEE_SERVICE.LOAD_EMPLOYEES_FILTER_SUBSIDIARY({payload: ENCRYPT({'id': DECRYPT(this.ref), 'page':this.page})}) 
            }  
 
        }, 

        DO_FILTER: function(){  
        this.resp = false;
        this.loading = true; 
        this.employees = [];
        this.page = 1; 
        this.total = null;   
        this.nextPage = 0;
        this.remItem = 0;
        this.LOAD_EMPLOYEES()
        },

        // CHECK_QUERY: function(x){  
        //     switch (x) {
        //         case 'branch': 
        //             return EMPLOYEE_SERVICE.LOAD_EMPLOYEES_FILTER_BRANCH({payload}) 
                
        //         case 'department': 
        //             return EMPLOYEE_SERVICE.LOAD_EMPLOYEES_FILTER_DEPARTMENT({payload})  
                
        //         case 'subsidiary': 
        //             return EMPLOYEE_SERVICE.LOAD_EMPLOYEES_FILTER_SUBSIDIARY({payload}) 
            
        //         default:
        //             return EMPLOYEE_SERVICE.LOAD_EMPLOYEES_FILTER_SUBSIDIARY({payload}) 
        //     }   
        // },

        // FILTER_SUBSIDIARIES: function(){ 
        //     this.offices.filter((element) => element.status_id == 2 );
        //     console.log(this.offices)
        // },

        LOAD_EMPLOYEES: function(){ // FETCH ALL MESSAGES FROM DATABASE 
        
        this.PARSE_SERVICE() 
        .then(result =>{  
                this.loading = false;  
            if(result.data.success){
                this.page = DECRYPT(result.data.data).nextPage;
                this.remItem =  DECRYPT(result.data.data).rem;
                // this.page = DECRYPT(result.data.data).curPage;  
                this.total = DECRYPT(result.data.data).total; 
                let pLoad = DECRYPT(result.data.data).employees;  
                this.employees = [...this.employees, ...pLoad]  
                console.log(this.employees[0])
                if(this.employees.length == 0) this.resp = true; this.respStat="empty";
            }else{
                this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg })
                this.resp = true;
                this.respStat="err"
                this.respMsg=result.data.msg
            }

            if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': REMOVE_CHARC(this.$route.path, '/'), 'ref': this.ref } ), 2000);
        })
        .catch(err => { 
            console.log(err.message)
            this.$store.commit('PAGE_NOTIFICATION', {
                "success": false, 
                "msg": 'error occured loading contents' 
            });
        })
    }, 
    // GET_NEXT_PAYLOAD() {  // get paginated payloads
    //   window.onscroll = () => {  
    //     let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
    //     if (bottomOfWindow & this.remItem !== 0) { 
    //         this.loadingMore = 'yes';  
    //      apiServices.LOAD_USER_MESSAGES({'id': this.ref, 'page': this.nextPage})
    //     .then(result =>{
    //           this.loadingMore = 'no'
    //           this.subs.push(result.data.data.subs[0]); 
    //           this.remItem =  result.data.data.rem
    //           this.nextPage =  result.data.data.nextPage      
    //     }).catch(err => {
    //         this.loadingMore = 'error'
    //         this.loadingMoreError = true
    //         console.log(err.message)
    //     })
    //     }
    //   }
    // }, 

        LOAD_COMPONENT: function(tab){ 
            this.$store.commit('PAGE_NOTIFICATION', null) 
            this.active_tab = tab;
        }

}, 
mounted() { 
    this.LOAD_EMPLOYEES()
} 

};
</script>