<template>
<div class="d-flex flex-center flex-column flex-column-fluid">
    <!--begin::Wrapper-->
    <div class="w-lg-900px p-10 p-lg-5 mx-auto">
        <!--begin::Form-->
        <form class="my-auto pb-5 fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate" id="kt_create_account_form"> 
            <!--begin::Step 5-->
            <div >
                <!--begin::Wrapper-->
                <div class="w-100"> 
                        <!--begin::Heading-->
                        <div class="pb-8 pb-lg-10">
                            <!--begin::Title-->
                            <h2 class="fw-bolder text-dark">Education history!</h2>
                            <!--end::Title-->
                            <!--begin::Notice-->
                            <div class="text-muted fw-bold fs-6">If you need more info, please
                            <a href="../../demo3/dist/authentication/sign-in/basic.html" class="link-primary fw-bolder">Sign In</a>.</div>
                            <!--end::Notice-->
                        </div>
                        <!--end::Heading-->
                        <!--begin::Body-->
                        <!--begin::Details-->
                        <div class="row mt-10 animated fadeIn border px-md-10 pt-md-10 p-5" v-for="(education, i) in edu_list" :key="i">
                            <div class="col-md-6 mb-5"> 
                                    <label class="form-label">Institution <span class="text-danger">*</span></label> 
                                    <input v-model="education.institution" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Enter name of institution" required="">
                            </div> 
                            <div class="col-md-6 col-12 mb-5">
                                <label class="form-label">Qualification <span class="text-danger">*</span></label> 
                                <select class="form-select form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="education.education_qualification_id">
                                    <option v-for="(degree, i) in degrees" :key="i" :value="degree.id">{{degree.name}}</option>
                                </select>
                            </div> 
                            <div class="col-md-4 col-12 mb-5">
                                <label class="form-label">Specilization <span class="text-danger">*</span></label> 
                                <input v-model="education.specialization" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Enter degree" required="">
                            </div> 
                            <div class="col-md-4 col-12 mb-5">
                                <label class="form-label">Grade <span class="text-danger">*</span></label> 
                                <input v-model="education.grade" type="text" class="form-control form-control-lg form-control-solid 4 mb-lg-0" placeholder="Eg: first class hounors" required="">
                            </div> 
                            <div class="col-md-4 col-12 mb-5">
                                <label class="form-label">Grad. year <span class="text-danger">*</span> <span class="fs-9 text-muted">(numbers only)</span></label> 
                                <input v-model="education.graduation_year" type="number" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Eg: 2020" required="">
                            </div> 
                            
                            <div class="col-md-4 col-12 mb-5">
                                <label class="form-label">Duration <span class="text-danger">*</span> <span class="fs-9 text-muted">(numbers only)</span></label>
                                <div class="input-group mb-5">
                                    <input v-model="education.duration" type="number" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Eg: 4" required="">
                                    <span class="input-group-text border-0 fs-8">year{{CHECK_PLURAL(education.duration)}}</span>
                                </div> 
                            </div> 
                            <div class="col-md-3 col-7">
                                <label class="form-label">Attachment <span class="text-danger">*</span> <span class="fs-9 text-muted">(image file)</span></label>
                                <div class="form-group">
                                <div class="btn btn-light btn-file px-4 py-4">
                                    <div class="d-flex justify-content-between align-items-center fs-7"> <i class="mdi mdi-file-image"></i>  JPEG, JPG, PNG only </div>
                                    <input type="file" id="file" rel="file" @change="UPLOAD_FILE($event)">
                                </div> 
                                </div>
                            </div>

                            <div class="col-md-4 col-5 mb-5" v-if="i !== 0">
                                <label class="form-label mt-md-14 mt-14"></label>
                                <button @click="DELETE_EDUCATION(education.id, i)" type="button" class="btn btn-light-danger rounded-0 shadow-lite">remove</button> 
                            </div>


                            
                        </div>  
     
                    <!--end::Body-->
                </div> 
                <!--end::Wrapper-->
            </div>
            <!--end::Step 5--> 
            <div class="mt-8 mb-1 d-flex justify-content-between">
                <button @click="this.$emit('action', {stage: ONBOARDING_STATUS.bio, processing: false})" type="button" class="btn btn-sm btn-light-primary shadow-lite rounded-0 animated fadeIn"><i class="mdi mdi-arrow-left"></i> Back</button>
                
                <div class="d-flex justify-content-between">
                    <button @click="MORE_EDU()" type="button" class="btn btn-sm btn-primary shadow-lite rounded-0 animated fadeIn me-2 me-sm-3">More <i class="mdi mdi-plus fw-bolder"></i></button>
                    <button @click="DO_SUBMIT()" type="button" class="btn btn-sm btn-light-primary shadow-lite rounded-0 animated fadeIn me-1 me-sm-3" v-if="user.onboarding.stage == ONBOARDING_STATUS.education">Continue <i class="mdi mdi-arrow-right"></i></button>
                    <button @click="DO_SUBMIT()" type="button" class="btn btn-sm btn-light-primary shadow-lite rounded-0 animated fadeIn me-1 me-sm-3" v-if="user.onboarding.stage > ONBOARDING_STATUS.education">Update <i class="mdi mdi-arrow-right"></i></button>
                    <button @click="this.$emit('action', {stage: ONBOARDING_STATUS.work_experience, processing: false})" type="button" class="btn btn-sm btn-light shadow-lite rounded-0 animated fadeIn me-1 me-sm-3" v-if="user.onboarding.stage > ONBOARDING_STATUS.education">Skip <i class="mdi mdi-arrow-right"></i></button>
                </div> 
            </div> 

        </form>
        <!--end::Form-->
    </div>
    <!--end::Wrapper-->
</div>
</template>

<script>
// @ is an alias to /src  
import apiServices from "@/services/apiServices"
import {MILLISECONDS_TO_DAYS, CAPITALIZE_STR, REMOVE_CHARC, RESPONSE_STATUS, IS_EMPTY, CHECK_PLURAL, ONBOARDING_STATUS } from "@/utils/"
import { ENCRYPT, DECRYPT } from "@/utils/security/";  
import AUTH_SERVICE  from "@/services/auth.js"; 
export default {
name: "add-self-education",
props: ['user'], 
data() {
    return{  
        ref: this.$route.query.ref, // truncate ref url data to get user id
        token: this.$route.query.token, // truncate ref url data to get user id 
        loading: false, 
        operationStatus: '', 
        MILLISECONDS_TO_DAYS, CAPITALIZE_STR, REMOVE_CHARC, ENCRYPT, DECRYPT,
        institution: '',
        qualification: '',
        specialization: '',
        grade: '',
        grad_year: '',
        duration: '', 
        degrees: '',
        file: '',
        paneStatus: 'edu',
        RESPONSE_STATUS, CHECK_PLURAL, IS_EMPTY, ONBOARDING_STATUS,
        editable_user: this.user,  
        edu_list: [{
            "id": null, 
            "employee_id": this.user.employee.id, 
            "subsidiary_id": this.user.employee.subsidiary_id,
            "specialization": "", 
            "education_qualification_id": "",
            "institution": "",
            "graduation_year": "",
            "duration": "",   
            "grade": "",
        }],
    }
},
components: {  
    
}, 
methods: {
 
    LOAD_UTILS: function() { // FETCH ALL MESSAGES FROM DATABASE 
        this.retry = ''
        apiServices.LOAD_DEGREES({})
            .then(result => {
                console.log(result.data.data)
                !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) : this.degrees = result.data.data;
            })
            .catch(err => {
                console.log('Error:' + err.message)
                this.isLoading = 'error';
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": "encountereda fatal server error" })
            })
    },

    LOAD_EDUCATION_HISTORY: function() { // FETCH ALL MESSAGES FROM DATABASE    
        let payload = ENCRYPT({'id': this.user.employee.id, 'token': DECRYPT(this.token)}); 
        AUTH_SERVICE.LOAD_EDUCATION_HISTORY({payload})   
        .then(result =>{ 
            this.loading = false; 
            if(result.data.success){  
                let resp = DECRYPT(result.data.data);
                resp.length > 0 ? this.edu_list = resp : null
                // this.edu_list = DECRYPT(result.data.data);
            }
            else{
                this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) 
            }
        })
        .catch(() => {
            this.loading = false; 
            this.$store.commit('PAGE_NOTIFICATION', {  "success": false, "msg": 'error occured loading contents' });
        })
    },

    UPLOAD_FILE: function(event) {
    //    let fData = new FormData();
    this.file = event.target.files[0];
        // console.log(file)
    },

    MORE_EDU: function() {
        if( this.edu_list.length < 5){ 
            this.edu_list.push({
                "id": null,
                "employee_id": this.user.employee.id, 
                "subsidiary_id": this.user.employee.subsidiary_id,
                "specialization": "", 
                "education_qualification_id": "",
                "institution": "",
                "graduation_year": "",
                "duration": "",   
                "grade": "",
            });   
        }else{
            this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " only 5 records can be added." })
        }
    }, 

    DO_SUBMIT: function() { // FETCH ALL MESSAGES FROM DATABASE 

        this.$emit('action', {stage: this.user.onboarding.stage, processing: true})
        const payload = ENCRYPT(this.edu_list) 
        console.log(this.edu_list)
        const id = ENCRYPT(this.user.id) 
        AUTH_SERVICE.SELF_SERVICE_EDUCATION_SUBMISSION({payload, id})  
        .then(result => {  
            this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }); 
            // if (result.data.success && !IS_EMPTY(this.file)) this.UPLOAD_EDUCATION_FILE(DECRYPT(result.data.data)) // UPLOAD FILE IF THERE IS ANY ATTACHED
            if(result.data.success) this.$emit('action', {stage: ONBOARDING_STATUS.work_experience, processing: false, reload: true});   
        })
        .catch(err => { 
            this.$emit('action', {stage: ONBOARDING_STATUS.education, processing: false})
            console.log('Error:' + err.message) 
            this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": "encountereda fatal server error" })
        })

    },
    

    DELETE_EDUCATION: function(id, i) { // FETCH ALL MESSAGES FROM DATABASE 
         this.$emit('action', {stage: ONBOARDING_STATUS.education, processing: true})
        if(!IS_EMPTY(id)){
            const payload = ENCRYPT(id)  
            AUTH_SERVICE.DELETE_SERVICE_EDUCATION({payload})  
            .then(result => {  
                this.$emit('action', {stage: ONBOARDING_STATUS.education, processing: false})
                this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg });  
                if(result.data.success) this.edu_list.splice(i, 1); // remove item
            })
            .catch(err => { 
                this.$emit('action', {stage: ONBOARDING_STATUS.education, processing: false})
                console.log('Error:' + err.message) 
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": "encountereda fatal server error" })
            })
        }
        else{
            this.$emit('action', {stage: ONBOARDING_STATUS.education, processing: false})
            this.edu_list.splice(i, 1); // remove item 
        }  

    },
 
    UPLOAD_EDUCATION_FILE: function(id) { // FETCH ALL MESSAGES FROM DATABASE 
        let formData = new FormData();      
        formData.append("file", this.file)     
        let payload = ENCRYPT({'oid': this.user.employee.subsidiary.organization_id, 'sid': this.user.employee.subsidiary.id, 'uid': this.user.employee.users_id, 'id': id}) 

        AUTH_SERVICE.UPLOAD_EDUCATION_FILE(payload, formData) 
        .then(result => {  
            if(result.data.success) this.$emit('action', { 'reload': true, 'close': 'add'}) //CLOSE ADD PANE AND RELOAD RECORDS
            if (!result.data.success) this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg });                
        })
        .catch(err => { 
            console.log('Error:' + err.message) 
            this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": "encountereda fatal server error" })
        })
    },

}, 
mounted() {
    this.LOAD_EDUCATION_HISTORY()
    this.LOAD_UTILS() 
},

};
</script>