<template>
  <div>
            <Notification :notify="notify" /> 
        <!--begin::Root-->
		<div class="d-flex flex-column flex-root">
			<!--begin::Authentication - Multi-steps-->
			<div class="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column" id="kt_create_account_stepper">
				<!--begin::Aside-->
				<div class="d-flex flex-column flex-lg-row-auto w-xl-350px">
					<!--begin::Wrapper-->
					<div class="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-350px scroll-y">
						<!--begin::Header-->
						<div class="d-flex flex-row-fluid flex-column flex-center p-10 pt-lg-20">
							<!--begin::Logo-->
							<a href="../../demo1/dist/index.html" class="mb-10 mb-lg-20">
								<img alt="Logo" src="/media/logos/logo-1.svg" class="h-40px" />
							</a>
							<!--end::Logo-->
							<!--begin::Nav-->
							<div class="stepper-nav">
								<!--begin::Step 1-->
								<div class="stepper-item current" data-kt-stepper-element="nav">
									<!--begin::Line-->
									<div class="stepper-line w-40px"></div>
									<!--end::Line-->
									<!--begin::Icon-->
									<div class="stepper-icon w-40px h-40px">
										<i class="stepper-check fas fa-check"></i>
										<span class="stepper-number">1</span>
									</div>
									<!--end::Icon-->
									<!--begin::Label-->
									<div class="stepper-label">
										<h3 class="stepper-title">Account Type</h3>
										<div class="stepper-desc fw-bold">Setup Your Account Details</div>
									</div>
									<!--end::Label-->
								</div>
								<!--end::Step 1-->
								<!--begin::Step 2-->
								<div class="stepper-item" data-kt-stepper-element="nav">
									<!--begin::Line-->
									<div class="stepper-line w-40px"></div>
									<!--end::Line-->
									<!--begin::Icon-->
									<div class="stepper-icon w-40px h-40px">
										<i class="stepper-check fas fa-check"></i>
										<span class="stepper-number">2</span>
									</div>
									<!--end::Icon-->
									<!--begin::Label-->
									<div class="stepper-label">
										<h3 class="stepper-title">Personal Information</h3>
										<div class="stepper-desc fw-bold">Setup Your personal profile</div>
									</div>
									<!--end::Label-->
								</div>
								<!--end::Step 2-->
								<!--begin::Step 3-->
								<div class="stepper-item" data-kt-stepper-element="nav">
									<!--begin::Line-->
									<div class="stepper-line w-40px"></div>
									<!--end::Line-->
									<!--begin::Icon-->
									<div class="stepper-icon w-40px h-40px">
										<i class="stepper-check fas fa-check"></i>
										<span class="stepper-number">3</span>
									</div>
									<!--end::Icon-->
									<!--begin::Label-->
									<div class="stepper-label">
										<h3 class="stepper-title">Coporate Information</h3>
										<div class="stepper-desc fw-bold">Your job role related info</div>
									</div>
									<!--end::Label-->
								</div>
								<!--end::Step 3-->
								<!--begin::Step 4-->
								<div class="stepper-item" data-kt-stepper-element="nav">
									<!--begin::Line-->
									<div class="stepper-line w-40px"></div>
									<!--end::Line-->
									<!--begin::Icon-->
									<div class="stepper-icon w-40px h-40px">
										<i class="stepper-check fas fa-check"></i>
										<span class="stepper-number">4</span>
									</div>
									<!--end::Icon-->
									<!--begin::Label-->
									<div class="stepper-label">
										<h3 class="stepper-title">Organization Details</h3>
										<div class="stepper-desc fw-bold">Set Your Payment Methods</div>
									</div>
									<!--end::Label-->
								</div>
								<!--end::Step 4-->
								<!--begin::Step 5-->
								<div class="stepper-item" data-kt-stepper-element="nav">
									<!--begin::Line-->
									<div class="stepper-line w-40px"></div>
									<!--end::Line-->
									<!--begin::Icon-->
									<div class="stepper-icon w-40px h-40px">
										<i class="stepper-check fas fa-check"></i>
										<span class="stepper-number">5</span>
									</div>
									<!--end::Icon-->
									<!--begin::Label-->
									<div class="stepper-label">
										<h3 class="stepper-title">Completed</h3>
										<div class="stepper-desc fw-bold">Woah, we are here</div>
									</div>
									<!--end::Label-->
								</div>
								<!--end::Step 5-->
							</div>
							<!--end::Nav-->
						</div>
						<!--end::Header--> 
					</div>
					<!--end::Wrapper-->
				</div>
				<!--begin::Aside-->
				<!--begin::Body-->
				<div class="d-flex flex-column flex-lg-row-fluid py-10">
					<!--begin::Content-->
					<div class="d-flex flex-center flex-column flex-column-fluid">
						<!--begin::Wrapper-->
						<div class="w-lg-900px p-10 p-lg-15 mx-auto">
							<!--begin::Form-->
							<form class="my-auto pb-5" novalidate="novalidate" id="kt_create_account_form">
								<!--begin::Step 1-->
								<div class="current" data-kt-stepper-element="content">
									<!--begin::Wrapper-->
									<div class="w-100">
										<!--begin::Heading-->
										<div class="pb-10 pb-lg-15">
											<!--begin::Title-->
											<h2 class="fw-bolder d-flex align-items-center text-dark">Choose Account Type
											<i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Select the type of account that best suits you. "></i></h2>
											<!--end::Title-->
											<!--begin::Notice-->
											<div class="text-muted fw-bold fs-6">If you need more info, please check out
											<a href="#" class="link-primary fw-bolder">Help Page</a>.</div>
											<!--end::Notice-->
										</div>
										<!--end::Heading-->
										<!--begin::Input group-->
										<div class="fv-row">
											<!--begin::Row-->
											<div class="row" v-if="account_types.length > 0">

												<!--begin::Col-->
												<div class="col-lg-6" v-for="(aType, i) in account_types" :key="i"> 
													<!--begin::Option-->
													<input type="radio" class="btn-check" name="account_type" v-model="account_type" :value="aType.id" :id="`type_${aType.name}`" />
													<label class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10" :for="`type_${aType.name}`">
														<!--begin::Svg Icon | path: icons/duotune/communication/com005.svg-->
														<span class="svg-icon svg-icon-3x me-5">
															<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																<path d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z" fill="black" />
																<path opacity="0.3" d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z" fill="black" />
															</svg>
														</span>
														<!--end::Svg Icon-->
														<!--begin::Info-->
														<span class="d-block fw-bold text-start">
															<span class="text-dark fw-bolder d-block fs-4 mb-2">{{CAPITALIZE_STR(aType.name)}}</span>
															<span class="text-muted fw-bold fs-6">If you need more info, please check it out</span>
														</span>
														<!--end::Info-->
													</label>
													<!--end::Option-->
												</div>
												<!--end::Col--> 

											</div>
											<!--end::Row-->
										</div>
										<!--end::Input group-->
									</div>
									<!--end::Wrapper-->
								</div>
								<!--end::Step 1-->



                                
								<!--begin::Step 2-->
								<div class="" data-kt-stepper-element="content">
									<!--begin::Wrapper-->
									<div class="w-100">
										<!--begin::Heading-->
										<div class="pb-10 pb-lg-15">
											<!--begin::Title-->
											<h2 class="fw-bolder text-dark">Personal Info</h2>
											<!--end::Title-->
											<!--begin::Notice-->
											<div class="text-muted fw-bold fs-6">If you need more info, please check out
											<a href="#" class="link-primary fw-bolder">Help Page</a>.</div>
											<!--end::Notice-->
										</div>
										<!--end::Heading-->
                                        
											<!--begin::Row-->
											<div class="row mb-2" data-kt-buttons="true">
                                            
												<!--begin::Col-->
												<div class="col-6 col-md-4">
													<!--begin::Input group-->
                                                    <div class="mb-5">
                                                        <!--begin::Label-->
                                                        <label class="form-label mb-3">Surname</label>
                                                        <!--end::Label-->
                                                        <!--begin::Input-->
                                                        <input type="text" class="form-control form-control-lg form-control-solid" placeholder="Surname" v-model="surname" />
                                                        <!--end::Input-->
                                                    </div>
                                                    <!--end::Input group-->
												</div>
												<!--end::Col--> 
                                            
												<!--begin::Col-->
												<div class="col-6 col-md-4">
													<!--begin::Input group-->
                                                    <div class="mb-5">
                                                        <!--begin::Label-->
                                                        <label class="form-label mb-3">First Name</label>
                                                        <!--end::Label-->
                                                        <!--begin::Input-->
                                                        <input type="text" class="form-control form-control-lg form-control-solid" placeholder="" v-model="firstname" />
                                                        <!--end::Input-->
                                                    </div>
                                                    <!--end::Input group-->
												</div>
												<!--end::Col--> 
                                            
												<!--begin::Col-->
												<div class="col-6 col-md-4">
													<!--begin::Input group-->
                                                    <div class="mb-5">
                                                        <!--begin::Label-->
                                                        <label class="form-label mb-3">Last Name</label>
                                                        <!--end::Label-->
                                                        <!--begin::Input-->
                                                        <input type="text" class="form-control form-control-lg form-control-solid" placeholder="" v-model="othername" />
                                                        <!--end::Input-->
                                                    </div>
                                                    <!--end::Input group-->
												</div>
												<!--end::Col--> 
                                            
												<!--begin::Col-->
												<div class="col-6 col-md-5">
													<!--begin::Input group-->
                                                    <div class="mb-5">
                                                        <!--begin::Label-->
                                                        <label class="form-label mb-3">Email Address</label>
                                                        <!--end::Label-->
                                                        <!--begin::Input-->
                                                        <input type="text" @focusout="CONFIRM_USER_EMAIL()" class="form-control form-control-lg form-control-solid" placeholder="Valid email address" v-model="user_email" />
                                                        <!--end::Input-->
                                                    </div>
                                                    <!--end::Input group-->
												</div>
												<!--end::Col--> 
                                            
												<!--begin::Col-->
												<div class="col-6 col-md-4">
													<!--begin::Input group-->
                                                    <div class="mb-5">
                                                        <!--begin::Label-->
                                                        <label class="form-label mb-3">Phone number</label>
                                                        <!--end::Label-->
                                                        <!--begin::Input-->
                                                        <input type="text" class="form-control form-control-lg form-control-solid" placeholder="valid phone number" v-model="user_phone" />
                                                        <!--end::Input-->
                                                    </div>
                                                    <!--end::Input group-->
												</div>
												<!--end::Col--> 
                                            
												<!--begin::Col-->
												<div class="col-6 col-md-3">
													<!--begin::Input group-->
                                                    <div class="mb-5">
                                                        <!--begin::Label-->
                                                        <label class="form-label mb-3">Gender</label>
                                                        <!--end::Label-->
                                                        <!--begin::Input-->  
                                                        <select v-model="gender" class="form-control custom-select form-control-lg form-control-solid">
                                                            <option value="">--select--</option>
                                                            <option value="male">male</option>
                                                            <option value="female">female</option>
                                                        </select>     
                                                        <!--end::Input-->
                                                    </div>
                                                    <!--end::Input group-->
												</div>
												<!--end::Col--> 
                                            
												<!--begin::Col-->
												<div class="col-12 col-md-4">
													<!--begin::Input group-->
                                                    <div class="mb-5">
                                                        <!--begin::Label-->
                                                        <label class="form-label mb-3">Address</label>
                                                        <!--end::Label-->
                                                        <!--begin::Input-->
                                                        <input type="text" class="form-control form-control-lg form-control-solid" placeholder="Residential address" v-model="user_address" />
                                                        <!--end::Input-->
                                                    </div>
                                                    <!--end::Input group-->
												</div>
												<!--end::Col--> 
                                            
												<!--begin::Col-->
												<div class="col-6 col-md-3">
													<!--begin::Input group-->
                                                    <div class="mb-5">
                                                        <!--begin::Label-->
                                                        <label class="form-label mb-3">Country</label>
                                                        <!--end::Label-->
                                                        <!--begin::Input-->
                                                        <select v-model="user_country" @change="GET_STATES()" class="form-select custom-select form-control-lg form-control-solid shadow-sm" id="country"> 
                                                            <option v-bind:value="country.id" v-for="(country, coi) in countries" :key="coi"> {{country.name}}</option> 
                                                        </select>
                                                         <!--end::Input-->
                                                    </div>
                                                    <!--end::Input group-->
												</div>
												<!--end::Col--> 
                                            
												<!--begin::Col-->
												<div class="col-6 col-md-3">
													<!--begin::Input group-->
                                                    <div class="mb-5">
                                                        <!--begin::Label-->
                                                        <label class="form-label mb-3">State</label>
                                                        <!--end::Label-->
                                                        <!--begin::Input-->
                                                        <select v-model="user_state" @change="GET_CITIES()" class="form-select custom-select form-control-lg form-control-solid shadow-sm" id="state" :disabled="states == null"> 
                                                            <option v-bind:value="st.id" v-for="(st, si) in states" :key="si"> {{st.name}}</option> 
                                                        </select>
                                                         <!--end::Input-->
                                                    </div>
                                                    <!--end::Input group-->
												</div>
												<!--end::Col--> 
                                            
												<!--begin::Col-->
												<div class="col-6 col-md-2">
													<!--begin::Input group-->
                                                    <div class="mb-5">
                                                        <!--begin::Label-->
                                                        <label class="form-label mb-3">City</label>
                                                        <!--end::Label-->
                                                        <!--begin::Input-->
                                                        <select v-model="user_city" class="form-select shadow-sm custom-select form-control-lg form-control-solid" id="city" :disabled="cities == null"> 
                                                            <option v-bind:value="city.id" v-for="(city, ci) in cities" :key="ci"> {{city.name}}</option> 
                                                            <option v-bind:value="0">others</option> 
                                                        </select>
                                                         <!--end::Input-->
                                                    </div>
                                                    <!--end::Input group-->
												</div>
												<!--end::Col--> 
                                                
                                            
												<!--begin::Col-->
												<div class="col-6 col-md-4">
													<!--begin::Input group-->
                                                    <div class="mb-5">
                                                        <!--begin::Label-->
                                                        <label class="form-label mb-3">Date of birth</label>
                                                        <!--end::Label-->
                                                        <!--begin::Input-->
                                                        <input type="date" class="form-control form-control-lg form-control-solid"  placeholder=" Date of birth" v-model="dob" />
                                                        <!--end::Input-->
                                                    </div>
                                                    <!--end::Input group-->
												</div>
												<!--end::Col--> 
                                            
												<!--begin::Col-->
												<div class="col-6 col-md-4">
													<!--begin::Input group-->
                                                    <div class="mb-5">
                                                        <!--begin::Label-->
                                                        <label class="form-label mb-3">Password</label>
                                                        <!--end::Label-->
                                                        <!--begin::Input-->
                                                        <input type="password" class="form-control form-control-lg form-control-solid" placeholder="Password.." v-model="password" />
                                                        <!--end::Input-->
                                                    </div>
                                                    <!--end::Input group-->
												</div>
												<!--end::Col--> 
                                            
												<!--begin::Col-->
												<div class="col-6 col-md-4">
													<!--begin::Input group-->
                                                    <div class="mb-5">
                                                        <!--begin::Label-->
                                                        <label class="form-label mb-3">Confirm Password</label>
                                                        <!--end::Label-->
                                                        <!--begin::Input-->
                                                        <input type="password" class="form-control form-control-lg form-control-solid" placeholder="Confirm password" v-model="c_password" />
                                                        <!--end::Input-->
                                                    </div>
                                                    <!--end::Input group-->
												</div>
												<!--end::Col--> 

                                            </div>
 
									</div>
									<!--end::Wrapper-->
								</div>
								<!--end::Step 2-->
                                
								<!--begin::Step 3-->
								<div class="" data-kt-stepper-element="content">
									<!--begin::Wrapper-->
									<div class="w-100">
										<!--begin::Heading-->
										<div class="pb-10 pb-lg-12">
											<!--begin::Title-->
											<h2 class="fw-bolder text-dark">Business Details</h2>
											<!--end::Title-->
											<!--begin::Notice-->
											<div class="text-muted fw-bold fs-6">If you need more info, please check out
											<a href="#" class="link-primary fw-bolder">Help Page</a>.</div>
											<!--end::Notice-->
										</div>
										<!--end::Heading-->										<!--begin::Input group-->
										<div class="mb-0 fv-row">
											<!--begin::Label-->
											<label class="d-flex align-items-center form-label mb-5">Select any that applies to your status
											<i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="What status best describes you."></i></label>
											<!--end::Label-->
											<!--begin::Options-->
											<div class="mb-0">
												<!--begin:Option-->
												<label class="d-flex flex-stack mb-5 cursor-pointer">
													<!--begin:Label-->
													<span class="d-flex align-items-center me-2">
														<!--begin::Icon-->
														<span class="symbol symbol-50px me-6">
															<span class="symbol-label">
																<!--begin::Svg Icon | path: icons/duotune/finance/fin001.svg-->
																<span class="svg-icon svg-icon-1 svg-icon-gray-600">
																	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																		<path d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z" fill="black" />
																		<path opacity="0.3" d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z" fill="black" />
																	</svg>
																</span>
																<!--end::Svg Icon-->
															</span>
														</span>
														<!--end::Icon-->
														<!--begin::Description-->
														<span class="d-flex flex-column">
															<span class="fw-bolder text-gray-800 text-hover-primary fs-5">Enteprenuer</span>
															<span class="fs-6 fw-bold text-muted">Use images to enhance your post flow</span>
														</span>
														<!--end:Description-->
													</span>
													<!--end:Label-->
													<!--begin:Input-->
													<span class="form-check form-check-custom form-check-solid">
														<input class="form-check-input" type="radio" v-model="user_class" :value="`enterprenuer`" name="user_class" />
													</span>
													<!--end:Input-->
												</label>
												<!--end::Option-->
												<!--begin:Option-->
												<label class="d-flex flex-stack mb-5 cursor-pointer">
													<!--begin:Label-->
													<span class="d-flex align-items-center me-2">
														<!--begin::Icon-->
														<span class="symbol symbol-50px me-6">
															<span class="symbol-label">
																<!--begin::Svg Icon | path: icons/duotune/graphs/gra006.svg-->
																<span class="svg-icon svg-icon-1 svg-icon-gray-600">
																	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																		<path d="M13 5.91517C15.8 6.41517 18 8.81519 18 11.8152C18 12.5152 17.9 13.2152 17.6 13.9152L20.1 15.3152C20.6 15.6152 21.4 15.4152 21.6 14.8152C21.9 13.9152 22.1 12.9152 22.1 11.8152C22.1 7.01519 18.8 3.11521 14.3 2.01521C13.7 1.91521 13.1 2.31521 13.1 3.01521V5.91517H13Z" fill="black" />
																		<path opacity="0.3" d="M19.1 17.0152C19.7 17.3152 19.8 18.1152 19.3 18.5152C17.5 20.5152 14.9 21.7152 12 21.7152C9.1 21.7152 6.50001 20.5152 4.70001 18.5152C4.30001 18.0152 4.39999 17.3152 4.89999 17.0152L7.39999 15.6152C8.49999 16.9152 10.2 17.8152 12 17.8152C13.8 17.8152 15.5 17.0152 16.6 15.6152L19.1 17.0152ZM6.39999 13.9151C6.19999 13.2151 6 12.5152 6 11.8152C6 8.81517 8.2 6.41515 11 5.91515V3.01519C11 2.41519 10.4 1.91519 9.79999 2.01519C5.29999 3.01519 2 7.01517 2 11.8152C2 12.8152 2.2 13.8152 2.5 14.8152C2.7 15.4152 3.4 15.7152 4 15.3152L6.39999 13.9151Z" fill="black" />
																	</svg>
																</span>
																<!--end::Svg Icon-->
															</span>
														</span>
														<!--end::Icon-->
														<!--begin::Description-->
														<span class="d-flex flex-column">
															<span class="fw-bolder text-gray-800 text-hover-primary fs-5">Human resource officer</span>
															<span class="fs-6 fw-bold text-muted">Use images to your post time</span>
														</span>
														<!--end:Description-->
													</span>
													<!--end:Label-->
													<!--begin:Input-->
													<span class="form-check form-check-custom form-check-solid">
														<input class="form-check-input" type="radio" v-model="user_class" :value="`human resource officer`" name="user_class"/>
													</span>
													<!--end:Input-->
												</label>
												<!--end::Option--> 
											</div>
											<!--end::Options-->
										</div>
										<!--end::Input group-->
                                        
										<!--begin::Input group-->
										<div class="fv-row my-10">
											<!--begin::Label-->
											<label class="d-flex align-items-center form-label">
												<span class="required">Years in selected position</span>
												<i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-html="true" data-bs-content="&lt;div class='p-4 rounded bg-light'&gt; &lt;div class='d-flex flex-stack text-muted mb-4'&gt; &lt;i class='fas fa-university fs-3 me-3'&gt;&lt;/i&gt; &lt;div class='fw-bold'&gt;INCBANK **** 1245 STATEMENT&lt;/div&gt; &lt;/div&gt; &lt;div class='d-flex flex-stack fw-bold text-gray-600'&gt; &lt;div&gt;Amount&lt;/div&gt; &lt;div&gt;Transaction&lt;/div&gt; &lt;/div&gt; &lt;div class='separator separator-dashed my-2'&gt;&lt;/div&gt; &lt;div class='d-flex flex-stack text-dark fw-bolder mb-2'&gt; &lt;div&gt;USD345.00&lt;/div&gt; &lt;div&gt;KEENTHEMES*&lt;/div&gt; &lt;/div&gt; &lt;div class='d-flex flex-stack text-muted mb-2'&gt; &lt;div&gt;USD75.00&lt;/div&gt; &lt;div&gt;Hosting fee&lt;/div&gt; &lt;/div&gt; &lt;div class='d-flex flex-stack text-muted'&gt; &lt;div&gt;USD3,950.00&lt;/div&gt; &lt;div&gt;Payrol&lt;/div&gt; &lt;/div&gt; &lt;/div&gt;"></i>
											</label>
											<!--end::Label-->
											<!--begin::Input-->
											<input type="number" v-model="class_years" class="form-control form-control-lg form-control-solid" />
											<!--end::Input--> 
										</div>
										<!--end::Input group-->

										<!--begin::Input group-->
										<div class="fv-row my-10">
											<!--end::Label-->
											<label class="form-label">Achivements</label>
											<!--end::Label-->
											<!--begin::Input-->
											<textarea v-model="achievments" class="form-control form-control-lg form-control-solid" rows="3"></textarea>
											<!--end::Input-->
										</div>
										<!--end::Input group--> 
									</div>
									<!--end::Wrapper-->
								</div>
								<!--end::Step 3-->

								<!--begin::Step 4-->
								<div class="" data-kt-stepper-element="content">
									<!--begin::Wrapper-->
									<div class="w-100">
										<!--begin::Heading-->
										<div class="pb-10 pb-lg-5">
											<!--begin::Title-->
											<h2 class="fw-bolder text-dark">Organization Details</h2>
											<!--end::Title-->
											<!--begin::Notice-->
											<div class="text-muted fw-bold fs-6">Complete the form to setup your first organization
											<a href="#" class="text-primary fw-bolder">Help Page</a>.</div>
											<!--end::Notice-->
										</div>
										<!--end::Heading-->
										<!--begin::Input group-->
										<div class="d-flex flex-column mb-7 fv-row">
											<!--begin::Label-->
											<label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
												<span class="required">Organization name</span>
												<i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Specify a card holder's name"></i>
											</label>
											<!--end::Label-->
											<input type="text"  @focusout="CONFIRM_ORG()" class="form-control form-control-solid" v-model="org_name" placeholder="name of organization/company" />
										</div>
										<!--end::Input group-->
										<!--begin::Input group--> 
                                            <div class="mb-10 fv-row"> 

											<!--begin::Label-->
											<label class="d-flex align-items-center form-label mb-3">Specify workforce Size
											<i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Provide your team size to help us setup your billing"></i></label>
											<!--end::Label-->
											<!--begin::Row-->
											<div class="row mb-2" data-kt-buttons="true">
												<!--begin::Col-->
												<div class="col">
													<!--begin::Option-->
													<label class="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4">
														<input type="radio" class="btn-check" name="workforce_size" v-model="work_force" value="1-19" />
														<span class="fw-bolder fs-3">1-19</span>
													</label>
													<!--end::Option-->
												</div>
												<!--end::Col-->
												<!--begin::Col-->
												<div class="col">
													<!--begin::Option-->
													<label class="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4 active">
														<input type="radio" class="btn-check" name="workforce_size" v-model="work_force"  value="20-69" />
														<span class="fw-bolder fs-3">20-69</span>
													</label>
													<!--end::Option-->
												</div>
												<!--end::Col-->
												<!--begin::Col-->
												<div class="col">
													<!--begin::Option-->
													<label class="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4">
														<input type="radio" class="btn-check" name="workforce_size" v-model="work_force" value="70-100" />
														<span class="fw-bolder fs-3">70-100</span>
													</label>
													<!--end::Option-->
												</div>
												<!--end::Col-->
												<!--begin::Col-->
												<div class="col">
													<!--begin::Option-->
													<label class="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4">
														<input type="radio" class="btn-check" name="workforce_size" v-model="work_force" value="100+" />
														<span class="fw-bolder fs-3">100+</span>
													</label>
													<!--end::Option-->
												</div>
												<!--end::Col-->
											</div>
											<!--end::Row-->
											<!--begin::Hint-->
											<div class="form-text">Customers will see this shortened version of your statement descriptor</div>
											<!--end::Hint-->
										</div>
										<!--end::Input group-->  


										<!--begin::Input group-->
										<div class="row mb-10"> 
												<!--begin::Row-->
												<div class="row fv-row">
													<!--begin::Col-->
													<div class="col-4">
                                                        <!--begin::Label-->
                                                        <label class="required fs-6 fw-bold form-label mb-2">Organization type</label>
                                                        <!--end::Label-->
                                                        <select v-model="org_type" class="form-select form-control-lg form-control-solid" id="orgType"> 
                                                            <option v-bind:value="ct.id" v-for="(ct, i) in business_types" :key="i"> {{CAPITALIZE_STR(ct.name)}}</option> 
                                                        </select> 
													</div>
													<!--end::Col-->

													<!--begin::Col-->
													<div class="col-4 ">
                                                        <!--begin::Label-->
                                                        <label class="required fs-6 fw-bold form-label mb-2">Industry</label>
                                                        <!--end::Label-->
                                                        <select v-model="org_industry" class="form-select form-control-lg form-control-solid" id="orgIndustry"> 
                                                            <option v-bind:value="ind.id" v-for="(ind, h) in industries" :key="h"> {{CAPITALIZE_STR(ind.name)}}</option> 
                                                        </select> 
													</div>
													<!--end::Col-->

                                                    
                                            
												<!--begin::Col-->
												<div class="col-4">
													<!--begin::Input group-->
                                                    <div class="mb-5">
                                                        <!--begin::Label-->
                                                        <label class="form-label mb-3">Organization`s Phone</label>
                                                        <!--end::Label-->
                                                        <!--begin::Input-->
                                                        <input type="text" class="form-control form-control-lg form-control-solid" v-model="org_phone" placeholder="Official phone"/>
                                                        <!--end::Input-->
                                                    </div>
                                                    <!--end::Input group-->
												</div>
												<!--end::Col--> 
                                                    
                                            
												<!--begin::Col-->
												<div class="col-4">
													<!--begin::Input group-->
                                                    <div class="mb-5">
                                                        <!--begin::Label-->
                                                        <label class="form-label mb-3">Organization`s Email</label>
                                                        <!--end::Label-->
                                                        <!--begin::Input-->
                                                        <input type="email" @focusout="CONFIRM_ORG_EMAIL()" class="form-control form-control-lg form-control-solid" v-model="org_email" placeholder="Official email address"/>
                                                        <!--end::Input-->
                                                    </div>
                                                    <!--end::Input group-->
												</div>
												<!--end::Col--> 
                                                
                                                
                                            
												<!--begin::Col-->
												<div class="col-4">
													<!--begin::Input group-->
                                                    <div class="mb-5">
                                                        <!--begin::Label-->
                                                        <label class="form-label mb-3">Organization`s website</label>
                                                        <!--end::Label-->
                                                        <!--begin::Input-->
                                                        <input type="text" class="form-control form-control-lg form-control-solid" v-model="org_website" placeholder="Website url"/>
                                                        <!--end::Input-->
                                                    </div>
                                                    <!--end::Input group-->
												</div>
												<!--end::Col--> 
                                                
                                                
                                            
												<!--begin::Col-->
												<div class="col-4">
													<!--begin::Input group-->
                                                    <div class="mb-5">
                                                        <!--begin::Label-->
                                                        <label class="form-label mb-3">Head office address</label>
                                                        <!--end::Label-->
                                                        <!--begin::Input-->
                                                        <input type="text" class="form-control form-control-lg form-control-solid" v-model="org_address" placeholder="Head office address"/>
                                                        <!--end::Input-->
                                                    </div>
                                                    <!--end::Input group-->
												</div>
                                                
                                            
												<!--begin::Col-->
												<div class="col-3">
													<!--begin::Input group-->
                                                    <div class="mb-5">
                                                        <!--begin::Label-->
                                                        <label class="form-label mb-3">Country</label>
                                                        <!--end::Label-->
                                                        <!--begin::Input-->
                                                        <select v-model="org_country" @change="GET_ORG_STATES()" class="form-select form-control shadow-sm" id="orgCountry"> 
                                                            <option v-bind:value="country.id" v-for="(country, coi) in countries" :key="coi"> {{CAPITALIZE_STR(country.name)}}</option> 
                                                        </select>
                                                        <!--end::Input-->
                                                    </div>
                                                    <!--end::Input group-->
												</div>
                                                
                                            
												<!--begin::Col-->
												<div class="col-3">
													<!--begin::Input group-->
                                                    <div class="mb-5">
                                                        <!--begin::Label-->
                                                        <label class="form-label mb-3">State</label>
                                                        <!--end::Label-->
                                                        <!--begin::Input-->
                                                        <select v-model="org_state" @change="GET_ORG_CITIES()" class="form-select form-control shadow-sm" id="orgState" :disabled="IS_EMPTY(org_states)"> 
                                                            <option v-bind:value="st.id" v-for="(st, si) in org_states" :key="si"> {{CAPITALIZE_STR(st.name)}}</option> 
                                                        </select>
                                                        <!--end::Input-->
                                                    </div>
                                                    <!--end::Input group-->
												</div>
                                                
                                            
												<!--begin::Col-->
												<div class="col-3">
													<!--begin::Input group-->
                                                    <div class="mb-5">
                                                        <!--begin::Label-->
                                                        <label class="form-label mb-3">City</label>
                                                        <!--end::Label-->
                                                        <!--begin::Input-->
                                                        <select v-model="org_city" class="form-select form-control shadow-sm" id="orgCity" :disabled="IS_EMPTY(org_cities)"> 
                                                            <option v-bind:value="city.id" v-for="(city, ci) in org_cities" :key="ci"> {{CAPITALIZE_STR(city.name)}}</option> 
                                                            <option v-bind:value="0">others</option> 
                                                        </select>
                                                        <!--end::Input-->
                                                    </div>
                                                    <!--end::Input group-->
												</div>
 

												<!--end::Col-->  
											</div>
											<!--end::Col-->
                                             
										</div>
										<!--end::Input group-->
										<!--begin::Input group-->
										<div class="d-flex flex-stack">
											<!--begin::Label-->
											<div class="me-5">
												<label class="fs-6 fw-bold form-label">Terms & conditions</label>
												<div class="fs-7 fw-bold text-muted">To finish your registration process, you must accept the terms & conditions</div>
											</div>
											<!--end::Label-->
											<!--begin::Switch-->
											<label class="form-check form-switch form-check-custom form-check-solid">
												<input v-model="accept" class="form-check-input" type="checkbox" value="1"  />
												<span class="form-check-label fw-bold text-muted">Yes i accept</span>
											</label>
											<!--end::Switch-->
										</div>
										<!--end::Input group-->
									</div>
									<!--end::Wrapper-->
								</div>
								<!--end::Step 4--> 
								<!--begin::Actions-->
								<div class="d-flex flex-stack pt-15">
									<div class="mr-2">
										<button type="button" class="btn btn-lg btn-light-primary me-3" data-kt-stepper-action="previous">
										<!--begin::Svg Icon | path: icons/duotune/arrows/arr063.svg-->
										<span class="svg-icon svg-icon-4 me-1">
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
												<rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="black" />
												<path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="black" />
											</svg>
										</span>
										<!--end::Svg Icon-->Previous</button>
									</div>
									<div>
										<button type="button" id="reg_button"  data-kt-stepper-action="submit" class="btn btn-lg btn-primary" @click="DO_VALIDATE()" :disabled="!accept">Submit form</button>
										<button type="button" class="btn btn-lg btn-primary" data-kt-stepper-action="next">Continue
										<!--begin::Svg Icon | path: icons/duotune/arrows/arr064.svg-->
										<span class="svg-icon svg-icon-4 ms-1">
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
												<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black" />
												<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black" />
											</svg>
										</span>
										<!--end::Svg Icon-->
                                        </button>
									</div>
								</div>
								<!--end::Actions-->
							</form>
							<!--end::Form-->
						</div>
						<!--end::Wrapper-->
					</div>
					<!--end::Content--> 
				</div>
				<!--end::Body-->
			</div>
			<!--end::Authentication - Multi-steps-->
		</div>
		<!--end::Root-->
   
  </div>
</template> 
<script>
// @ is an alias to /src
import Notification from "@/components/global/notification.vue"; 
import AUTH_SERVICE  from "@/services/auth.js"; 
import apiServices from "@/services/apiServices"; 
import utility_services from "@/services/utility_services"; 
import { RESPONSE_STATUS, IS_EMPTY, CAPITALIZE_STR, ENCRYPT_JSON_PAYLOAD, DECRYPT_JSON_PAYLOAD } from "@/utils/";

export default {
name: "Complete_registration",
data() {
    return{ 
        account_type: '',
        surname: '', 
        firstname: '', 
        othername: '', 
        user_email: '', 
        user_phone: '',
        user_address: '',
        gender: '',
        password: '', 
        c_password: '',
        user_country: '',
        user_state: '',
        user_city: '',
        user_class: '',
        class_years: '',
        achievments: '',
        dob: '',

        org_name: '',
        work_force: '', 
        org_phone: '',  
        website: '',   
        org_email: '',
        org_address: '', 
        org_type: '',  
        org_industry: '',  
        org_country: null, 
        org_state: '', 
        org_city: '',
 
        notify: {"success": null, 'msg': null},  
        RESPONSE_STATUS: RESPONSE_STATUS,
        CAPITALIZE_STR: CAPITALIZE_STR,
        IS_EMPTY: IS_EMPTY,
        states: null, 
        cities: null,  
        org_states: null,
        org_cities: null,
        accept: false,
        isLoading: 'yes',
        countries: this.$store.state.countries, 
        account_types: '',  
        business_types: '',
        industries: '',
        // Theme: this.$store.state.currentTheme,    
    }
},
components: {
    Notification
},  
methods: {  
     
    GET_COUNTRIES: function(){ 
        utility_services.GET_COUNTRIES()
        .then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION 
		console.log(result.data)
           !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) : null;  
			if(result.data.success){
				this.countries = DECRYPT_JSON_PAYLOAD(result.data.data).payload; 
                this.$store.commit('STORE_COUNTRIES', this.countries); 
            }  
        })
        .catch(err => { 
			console.log(err.message)
          this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " encountered a fatal server error." });  
        }) 
    },

    GET_STATES: function(){
        this.states  = null; 
        this.cities  = null; 
        utility_services.GET_STATES({'id':this.user_country})
        .then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION 
           !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) : this.states  = result.data.data;
        })
        .catch(err => { 
          this.notify = {"success": 'error', 'msg': err.message};
          this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " encountered a fatal server error." });   
        })  
    },

    GET_CITIES: function(){
        this.cities  = null; 
        apiServices.GET_CITIES({'id':this.user_state})
        .then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION 
           !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) : this.cities  = result.data.data;
        })
        .catch(err => { 
            console.log(err.message)    
          this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " encountered a fatal server error." }); 
        })
    },


	GET_ORG_STATES: function(){
        this.org_states  = null; 
        this.org_cities  = null; 
        apiServices.GET_STATES({'id':this.org_country})
        .then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION
            console.log(result.data)
           !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) : this.org_states  = result.data.data;
        })
        .catch(err => { 
            console.log(err.message) 
          this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " encountered a fatal server error." });  
        })  
    },

    GET_ORG_CITIES: function(){
        this.org_cities  = null; 
        apiServices.GET_CITIES({'id':this.org_state})
        .then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION 
           !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) : this.org_cities  = result.data.data;
        })
        .catch(err => {
            console.log(err.message) 
          this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " encountered a fatal server error." });    
        })
    }, 

    GET_NEW_COMPANY_RESOURCES: function(){
        utility_services.GET_NEW_COMPANY_RESOURCES()
		.then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION 
            !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) : null;
			if(result.data.success){
				let resp = DECRYPT_JSON_PAYLOAD(result.data.data).payload
                this.account_types = resp.account_types;
                this.business_types = resp.business_types;
                this.industries = resp.industries; 
			} 
        })
        .catch(err => {
            console.log(err.message)
			this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " encountered a fatal server error." })    
        }) 
    },

    CONFIRM_USER_EMAIL: function(){ // VALIDATE/CHECK DATA IF EXIST IN THE DATABASE  
		utility_services.CONFIRM_USER_EMAIL({'email': this.user_email })
		.then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION
			console.log(result.data.msg) 
            !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) : null;
			// this.$store.commit('AUTH_SUCCESS', result.data.data);   
		})
		.catch(err => {
			console.log(err.message)    
			this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " encountered a fatal server error." })    
		})
    },

    
            CONFIRM_ORG: function(){ // VALIDATE ORG IF NAME EXIST IN THE DATABASE  
				utility_services.CONFIRM_ORG_NAME({'name': this.org_name })
				.then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION 
				!result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) : null; 
				})
				.catch(err => {
				console.log(err.message)
				this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " encountered a fatal server error." })        
				})
            },

            CONFIRM_ORG_EMAIL: function(){ // VALIDATE ORG IF NAME EXIST IN THE DATABASE  
                utility_services.CONFIRM_ORG_EMAIL({'email': this.org_email })
				.then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION 
					!result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) : null;
                })
                .catch(err => {
                    console.log(err.message) 
					this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " encountered a fatal server error." })       
                })
            },

    RELOAD: function(){ // VALIDATE/CHECK DATA IF EXIST IN THE DATABASE    
        this.GET_NEW_COMPANY_RESOURCES();
    },

    DO_VALIDATE: function(){ // VALIDATE FORM DATA 

        !this.accept ? this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " You must accept our terms & condition to continue." }) : null;  
        
		if(this.accept){
            document.getElementById('reg_button').innerHTML = '<span class="spinner-border spinner-border-sm align-middle ms-2"></span>';
            document.getElementById('reg_button').setAttribute(`disabled`, true); 
            this.DO_REGISTRATION();
        } 
    },

    DO_REGISTRATION: function(){ // TRY REGISTRATION ALGORITHM 
        const payload = ENCRYPT_JSON_PAYLOAD({ 'account_type': this.account_type, 'surname': this.surname, 'firstname': this.firstname, 'othername': this.othername, 'user_email': this.user_email, 'user_phone': this.user_phone,'user_address': this.user_address,'gender': this.gender,'password': this.password, 'c_password': this.c_password,'user_country': this.user_country,'user_state': this.user_state,'user_city': this.user_city,'user_class': this.user_class,'class_years': this.class_years,'achievments': this.achievments,'dob': this.dob, 'org_name': this.org_name,'work_force': this.work_force, 'org_phone': this.org_phone,  'website': this.website,   'org_email': this.org_email,'org_address': this.org_address, 'org_type': this.org_type,  'org_industry': this.org_industry,  'org_country': this.org_country, 'org_state': this.org_state, 'org_city': this.org_city}) 

		AUTH_SERVICE.DO_REGISTRATION({"payload": payload})
		.then(res =>{ // TRY REGISTRATION IN AUTH SERVICE RESOURCE 
			this.$store.commit('PAGE_NOTIFICATION', {"success": res.data.success, "msg": res.data.msg }) 
			if(res.data.success){ 
				document.getElementById('reg_button').innerHTML = 'Submit form'; 
				setTimeout(() => this.$router.push('/auth/welcome'), 2000) 
			} 
			else{ 
				document.getElementById('reg_button').innerHTML = 'Submit form'; 
				document.getElementById('reg_button').removeAttribute(`disabled`) 
			}
		})
		.catch(err => {  
			this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " encountered a fatal server error." })   
			console.log(err.message) 
			document.getElementById('reg_button').innerHTML = 'Submit form'; 
			document.getElementById('reg_button').removeAttribute(`disabled`);  
		})
    }

}, 
beforeMount() { 
},
mounted() {
	!IS_EMPTY(this.countries) ? this.GET_COUNTRIES() : null; 
	this.GET_NEW_COMPANY_RESOURCES(); 
	let extScript = document.createElement('script')
	extScript.setAttribute('src', '/js/custom/create-account.js')
	document.head.appendChild(extScript)
},

};
</script>
