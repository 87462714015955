import { createStore } from 'vuex'
import axios from "axios";
import router from "@/router";
import createPersistedState from 'vuex-persistedstate';
import apiServices from "@/services/apiServices"; 
import { IS_EMPTY} from '../utils';
import { PARSE_AUTH, ENCRYPT } from "@/utils/security/"; 
// import {REMOVE_CHARC} from "@/utils/";   
 
const store = new createStore({


    state: {
        auth: localStorage.getItem('auth') || null,
        employee: null,
        countries: null,
        is_authenticated: localStorage.getItem('is_authenticated') || false,
        loginDetails: null,
        tempLogonData: '', 
        theme: 'light',
        formStage: 1,
        self_service_stage: null,
        temp_newOrg_data_basic: '',
        temp_newOrg_data_structure: '',
        search_history: [],
        search_status: false,
        notify: null,
        loginForm: 'login',
    },

    mutations: {
        

        PAGE_NOTIFICATION(state, payload) {  
            state.notify = payload 
            setTimeout(() => state.notify = null, 10000)
        },

        STORE_COUNTRIES(state, payload) {  
            state.countries = payload  
        },

        SEARCH_TOGGLE(state, payload){
            state.search_status = payload
        },

        STORE_SELF_SERVICE_STAGE(state, payload) {  
            state.self_service_stage = payload  
        },

        SET_LOGIN_FORM_STATUS(state, payload) {  
            state.loginForm = payload  
        },

        AUTH_LOGOUT(state, payload) {    
            apiServices.DO_LOGOUT({'id': PARSE_AUTH(state.auth, 'user').id, 'token': PARSE_AUTH(state.auth, 'header').token})
            .then(res => {
                console.log(res)
            })
            .catch(err => { console.log(err.message) })  

                state.auth = null  
                localStorage.setItem('auth', null)
                localStorage.setItem('is_authenticated', false) 
                state.is_authenticated = false

                if( IS_EMPTY(payload)){
                    router.push('/auth/login')// auth logout
                }
                else{
                    const ref = payload.ref
                    const resource = payload.resource
                    const refQ = IS_EMPTY(ref) ? null : `?ref=${ref}`; // ref url query check 
                    const resourceQ = IS_EMPTY(resource) ? null : `&resource=${resource}`; // check if there is any query data for resouce {check if empty or undefined} 
                    const meta = refQ + resourceQ; // merge url query
                    const loginUrl = '/auth/login?action=redirect_login&refferer_page=' + payload.path + meta; 
                    router.push(loginUrl) // force auth to logout with redirect params
                }
        },

        AUTH_SUCCESS(state, payload) { 
            localStorage.setItem('auth', payload)  
            state.auth = payload  
            state.is_authenticated = true
            localStorage.setItem('is_authenticated', true) 
        },

        AUTH_REMEMBER(state, payload) { 
            state.loginDetails = payload 
        },

        TEMP_LOGIN_DATA(state, payload){
            state.tempLogonData = payload  
        },

        THEME(state, payload) {
            if (payload == 'dark') {
                state.theme =  'dark';
            }else {
                state.theme = 'light'
            }
        },

        STORE_SEARCH(state, payload) { 
            const history = state.search_history; // create state object from search history.
            let n = true;
            for (const item of history) { 
                if(item.name === payload){
                    n = false;
                    break
                }
            }   
            if(n){ 
                if (history.length > 10) { state.search_history.pop(); } // if false, adds to search history array 
                state.search_history.unshift({'name': payload, 'date': new Date().getFullYear() + '-' +new Date().getMonth() + '-'+ new Date().getDate()}); // if false, adds to search history array 
            }
        },

        STORE_NEW_ORG_TEMP_DATA(state, payload) { 
            state.formStage = 2
            state.temp_newOrg_data_basic = ENCRYPT(payload)
        },

        STORE_NEW_ORG_TEMP_DATA_STRUCTURE(state, payload) {
            state.formStage = 2
            state.temp_newOrg_data_structure = ENCRYPT(payload)
        },

        CLEAR_ORG_TEMP_DATA(state) {
            state.formStage = 1
            state.temp_newOrg_data_basic = ''
            state.temp_newOrg_data_structure = ''
        },

        SUPER_ADMIN_UPDATE_INSTITUTIONS(state, institution) {
            const item = state.institutions.findIndex(institution => institution._id === institution._id);
            if (item >= 0) { state.institutions.splice(item, 1, institution); } 
        },



    },

    actions: {

        doAuth: ({ commit }, auth, headers) => {
            console.log(auth)
                axios.defaults.headers.common['Authorization'] = headers
                    commit('AUTH_SUCCESS', auth, headers); 
        },

        setSuperAdminTheme: ({ commit }, payload) => {
            commit('THEME', payload);
        },

        storeCompanyReg: ({ commit }, payload) => {
            commit('STORE_temp_newOrg_data', payload);
        },

        storeResentSearch: ({ commit }, payload) => {
            commit('STORE_SEARCH', payload);
        },

        updateInstitutions: ({ commit }, payload) => { 
            commit('SUPER_ADMIN_UPDATE_INSTITUTIONS', payload);
        }
    },

    getters: {
        
        notify: state => state.notify,

        is_authenticated: state => state.is_authenticated,

        theme: state => state.theme,

        searchStatus: state => state.search_status,

        getresentSearch: state => state.search_status,

        getInstitutionById(state) {
            return (id) => state.institutions.find(institution => institution._id === id);
        },

        getresentSearchSuggestions(state) {
            return (suggest) => state.search_history.find(suggestion => suggestion === suggest);
        },
    },

    modules: {},

    plugins: [createPersistedState()],
});

store.subscribe((mutation, state) => { 
    localStorage.setItem('store', JSON.stringify(state));
});

export default store;