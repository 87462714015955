import API from './api.js';


export default {

     //USER AUTHENTICATION SERVER SERVICES 
    DO_REGISTRATION(data) { /**** PROCESS REGISTRATION*****/ 
        return API('auth').post('register', data)
    },  
     
    SELF_SERVICE_BASIC_FORM_SUBMISSION(data){
        return API('auth').post('self_service_basic_registration', data)
    },
     
    SELF_SERVICE_EDUCATION_FORM_SUBMISSION(data){
        return API('auth').post('self_service_education_registration', data)
    },
    CHECK_SELF_SERVICE_EDUCATION_RECORD(data){
        return API('auth').post('self_service_education_confirmation', data)
    },

    CHECK_SELF_SERVICE_WORK_EXPERIENCE_RECORD(data){
        return API('auth').post('self_service_work_experience_confirmation', data)
    },

    SUBMIT_SELF_SERVICE_WORK_EXPERIENCE_FORM(data){
        return API('auth').post('self_service_work_experience_registration', data)
    },

    SKIP_SELF_SERVICE_WORK_EXPERIENCE_FORM(data){ 
        return API('auth').post('skip_service_work_experience_registration', data)
    },

    UPLOAD_USER_PHOTO1(data){
        return API('auth').post('upload_user_photo', data)
    },

    CHECK_USER_EMAIL(data) { /**** GET REGISTRATION RECOURCES*****/  
        return API('auth').post('check_user_email', data)
    },

    SEND_PASSWORD_RECOVERY_EMAIL(data) { /**** GET REGISTRATION RECOURCES*****/  
        return API('auth').post('password_recovery_email', data)
    },

    RESET_PASWORD(data) { /**** GET REGISTRATION RECOURCES*****/  
        return API('auth').post('reset_password', data)
    },

    DO_LOGIN(data) { /**** PROCESS LOGIN*****/ 
        return API('auth').post('login', data)
    },
    DO_LOGOUT(data){
        return API('auth').post('logout', data)
        
    },
    DO_EMAIL_CONFIRMATION_FOR_PASSWORD_RESET(data) { /**** PROCESS REGISTRATION*****/ 
        return API('auth').post('register', data)
    }, 
    
    LOCK_ACCOUNT(data) {
        return API('auth').post('lock_account', data)
    },


    VERIFY_USER_EMAIL(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/  
        return API('auth').post('verify_email', data)
    },

    // USER SERVER ROUTES
    LOAD_USER_MESSAGES(data) { /**** PROCESS FETCH USER MESSAGES*****/ 
        return API('user').post('messages', data)
    },

    LOAD_NOTIFICATIONS(data) { /**** PROCESS FETCH USER MESSAGES*****/ 
        return API('user').post('notifications', data)
    },

    CHECK_NOTIFICATIONS(data) { /**** PROCESS FETCH USER MESSAGES*****/ 
        return API('user').post('check_notifications', data)
    },

    //SUBSIDIARY SERVER SERVICES 
    LOAD_ADMINISTRATORS(data) { /**** PROCESS FETCH SUBSIDIARY ADMINISTRATORS*****/ 
        return API('portfolio').post('g/administrators', data)
    },
    UPDATE_ADMINISTRATOR(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/ 
        return API('portfolio').post('g/update/administrator', data)
    }, 

    //UTILITY SERVER SERVICES
    GET_COMPANY_TYPES() { /**** GET COMPANY TYPES RECOURCES*****/  
        return API('utility').get('get_company_types')
    },
    GET_COUNTRIES() { /**** GET COUNTRIES RECOURCES *****/ 
        return API('utility').post('countries')
    }, 
    GET_STATES(data) { /**** GET STATES RECOURCES *****/ 
        return API('utility').post('states', data)
    }, 
    GET_CITIES(data) { /**** GET CITIES RESOURCES*****/ 
        return API('utility').post('cities', data)
    }, 
    GET_ACCOUNT_TYPES() { /**** GET CITIES RESOURCES*****/ 
        return API('utility').post('account_types')
    }, 
    CHECK_ORG_NAME(data) { /**** GET REGISTRATION RECOURCES *****/  
        return API('utility').post('check_org_name', data)
    }, 
    CHECK_ORG_EMAIL(data) { /**** GET REGISTRATION RECOURCES *****/  
        return API('utility').post('check_org_email', data)
    }, 
    LOAD_DEGREES() { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/  
        return API('utility').post('load_degrees')
    },

    UPLOAD_USER_PHOTO(ref, data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/    
        return API('user').post(`upload_user_photo/` + ref, data)
    },
    UPDATE_USER(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/    
        return API('user').post(`update_user`, data)
    },
    SUBMIT_SELF_SERVICE_REGISTRATION(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('portfolio').post('init_self_service_registration', data)
    },
    UPDATE_EMPLOYEE_WITH_PHOTO_FORM(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('portfolio').post('update_employee_with_photo', data)
    }, 
    CONFIRM_EMPLOYEE(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('portfolio').post('confirm_employee', data)
    }, 
    // EMPLOYEE EDUCATIONAL HISTORY
    CONFIRM_EDUCATION_RECORD(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('portfolio').post('confirm_employee_education_record', data)
    },

    LOAD_EMPLOYEE_TIMELINE(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('portfolio').post('load_employee_timeline', data)
    },

    //global organization services
    GET_SUBSIDIARY_OFFICES(data, typ) { /**** LOAD SUBSIDIARY EMPLOYEES*****/   
        if(typ == undefined) {  // if request populates a paginated page
            return API('portfolio').post('load_subsidiary_offices_paginate', data); 
        }else if(typ == 'np'){  // if request is for populating a select dropdown or something similar(np ====> not paginate)
            return API('portfolio').post('load_subsidiary_offices', data);  
        }else{  // if request populates a paginated page
            return API('portfolio').post('load_subsidiary_offices_paginate', data); 
        }  
    },

    //global organization services
    GET_SUBSIDIARY_POSITIONS(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('portfolio').post('load_subsidiary_positions', data) 
    },
    GET_SUBSIDIARY_DEPARTMENTS(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('portfolio').post('load_subsidiary_departments', data) 
    },
    GET_SUBSIDIARY_LEVELS(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('portfolio').post('load_subsidiary_levels', data) 
    },
    GET_EMPLOYEE_ROLES(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('utility').post('load_employment_roles', data) 
    }, 
    CONFIRM_USER(data) {
        return API('utility').post('confirm_user', data)
    }, 

    //LEAVE MANAGEMENT API ROUTES
    LOAD_LEAVE_STATISTICS(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('management').post('load_leave_statistics', data) 
    },

    LOAD_LEAVE_TYPES(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('management').post('load_leave_types', data) 
    },

    LOAD_LEAVE_REQUESTS(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('management').post('load_leave_requests', data) 
    },

    LEAVE_REQUEST_ACTION(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('management').post('update_leave_request_status', data) 
    },

    LOAD_LEAVE_REQUEST(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('management').post('load_leave_request', data) 
    },

    LOAD_EMPLOYEE_LEAVE_REQUESTS(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('management').post('load_employee_leave_requests', data) 
    },

    LOAD_EMPLOYEE_LEAVE_ENTITLEMENT(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('management').post('load_employee_leave_entitlement', data) 
    },
    SUBMIT_LEAVE_REQUEST(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('management').post('submit_employee_leave_request', data) 
    },
    

    //LEAVE MANAGEMENT API ROUTES
    ADD_NEW_LEAVE_TYPE(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('management').post('add_new_leave_type', data) 
    }, 
    ADD_NEW_LEAVE_ENTITLEMENT(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('management').post('add_new_leave_entitlement', data) 
    }, 
    DELETE_ENTITLEMENT(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('management').post('delete_leave_entitlement', data) 
    }, 
    UPDATE_LEAVE_ENTITLEMENT(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('management').post('update_leave_entitlement', data) 
    }, 
    DELETE_LEAVE_TYPE(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('management').post('delete_leave_type', data) 
    }, 

    DELETE_EVENT(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('management').post('delete_event', data) 
    },  
    
    UPDATE_EVENT(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('management').post('update_event', data) 
    }, 
    
    LOAD_EVENTS(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('management').post('load_events', data) 
    }, 
    
    ADD_EVENT(data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/  
        return API('management').post('add_event', data) 
    },  

    //search api routes
    SEARCH_EMPLOYEE_BY_NAME(data){
        return API('search').post('employees_by_name', data) 
    },

    SEARCH_EMPLOYEE_BY_POSITION(data){
        return API('search').post('employees_by_position', data) 
    },

    SEARCH_EMPLOYEE_BY_OFFICE(data){
        return API('search').post('employees_by_office', data) 
    },

    SEARCH_EMPLOYEE_BY_LEVEL(data){
        return API('search').post('employees_by_level', data) 
    },

    SEARCH_EMPLOYEE_BY_DEPARTMENT(data){
        return API('search').post('employees_by_department', data) 
    },

    SEARCH_EMPLOYEE_BY_SALARY(data){
        return API('search').post('employees_by_salary', data) 
    },

    SEARCH_EMPLOYEE_BY_BIRTHDAY(data){
        return API('search').post('employees_by_birthday', data) 
    },

    SEARCH_EMPLOYEE_BY_RESUMPTION_DATE(data){ 
        return API('search').post('employees_by_resumption_date', data) 
    },

    SEARCH_EMPLOYEE_BY_STATUS(data){
        return API('search').post('employees_by_status', data) 
    }

     
}
