import API from './api.js';
export default {
    //UTILITY SERVER SERVICES
    
    CONFIRM_ORG(data) { /**** GET REGISTRATION RECOURCES *****/  
        return API('portfolio').post('confirm_org', data)
    }, 

    COMPANY_TYPES() { /**** GET COMPANY TYPES RECOURCES*****/  
        return API('utility').get('get_company_types')
    },
    
    SAVE_NEW_ORGANIZATION(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/    
        return API('portfolio').post('save_org', data)
    },

    LOAD_OFFICES(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/    
        return API('portfolio').post('load_subsidiary_offices', data)
    },

    SAVE_NEW_SUBSIDIARY(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/    
        return API('portfolio').post('save_subsidiary', data)
    }, 
    
    UPDATE_PORTFOLIO(data) { /**** PROCESS UPDATE SUBSIDIARY OFFICES*****/   
        return API('portfolio').post('update', data)
    },

    UPLOAD_PORTFOLIO_LOGO(ref, data) { /**** LOAD SUBSIDIARY EMPLOYEES*****/    
        return API('portfolio').post(`upload_logo/` + ref, data)
    },  

    LOAD_PORTFOLIO_DASHBOARD(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/   
        return API('portfolio').post('load_portfolio', data)
    },
    
    UPDATE_ADMINISTRATOR(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/   
        return API('portfolio').post('update_administrator', data)
    },

    DELETE_ADMINISTRATOR(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/   
        return API('portfolio').post('delete_administrator', data)
    },

    ADD_ADMINISTRATOR(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/   
        return API('portfolio').post('add_administrator', data)
    },

    LOAD_ADMINISTRATORS(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/   
        return API('portfolio').post('load_administrators', data)
    },

    LOAD_SUBSIDIARIES(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/   
        return API('portfolio').post('load_subsidiaries', data)
    },

    LOAD_SUBSIDIARY(data) { /**** PROCESS UPDATE SUBSIDIARY ADMINISTRATORS*****/   
        return API('portfolio').post('load_subsidiary', data)
    },
    
    UPDATE_OFFICE(data) { /**** PROCESS UPDATE SUBSIDIARY OFFICES*****/   
        return API('portfolio').post('update_office', data)
    },
    
    ADD_OFFICE(data) { /**** PROCESS ADD SUBSIDIARY OFFICES*****/   
        return API('portfolio').post('add_office', data)
    },
    
    DELETE_OFFICE(data) { /**** PROCESS DELETE SUBSIDIARY OFFICES*****/   
        return API('portfolio').post('delete_office', data)
    },

    MAKE_BROADCAST(data) { /**** PROCESS DELETE SUBSIDIARY OFFICES*****/   
        return API('portfolio').post('make_broadcast', data)    
    },
    LOAD_BROADCASTS(data) { /**** PROCESS DELETE SUBSIDIARY OFFICES*****/   
        return API('portfolio').post('load_broadcasts', data)    
    },
}