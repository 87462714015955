<template>
<div class="animated fadeIn"> 
   <!--begin::Sign-in Method-->
        <div class="mb-5 mb-xl-10"> 
            <!--begin::Content-->
            <div class="collapse show"> 
                    <!--begin::Email Address-->
                    <div class="d-flex flex-wrap align-items-center" v-if="GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)">
                        <!--begin::Label-->
                        <div id="kt_signin_email">
                            <div class="fs-6 fw-bolder mb-1">Email Address</div>
                            <div class="fw-bold text-gray-600">{{user.email}}</div>
                        </div>
                        <!--end::Label-->
                        <!--begin::Edit-->
                        <div id="kt_signin_email_edit" class="flex-row-fluid d-none animated fadeIn">
                            <!--begin::Form-->
                            <form id="kt_signin_change_email" class="form" novalidate="novalidate">
                                <div class="row mb-6">
                                    <div class="col-lg-4 mb-4 mb-lg-0">
                                        <div class="fv-row mb-0">
                                            <label for="emailaddress" class="form-label fs-6 fw-bolder mb-3">Enter Old Email Address</label>
                                            <input type="email" class="form-control form-control-lg form-control-solid" placeholder="Old email address" v-model="oEmail" />
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-4 mb-4 mb-lg-0">
                                        <div class="fv-row mb-0">
                                            <label for="emailaddress" class="form-label fs-6 fw-bolder mb-3">Enter New Email Address</label>
                                            <input type="email" @focusout="CONFIRM_USER_EMAIL()" class="form-control form-control-lg form-control-solid" id="emailaddress" placeholder="New email address" v-model="email" />
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-4">
                                        <div class="fv-row mb-0">
                                            <label for="confirmemailpassword" class="form-label fs-6 fw-bolder mb-3">Confirm Password</label>
                                            <input type="password" class="form-control form-control-lg form-control-solid" autocomplete="off" v-model="password"  placeholder="User password"  />
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <button id="update_email_btn" type="button" @click="UPDATE_USER_EMAIL()" class="btn btn-primary me-2 px-6">Update Email</button>
                                    <button id="kt_signin_cancel" type="button" class="btn btn-color-gray-400 btn-active-light-primary px-6">Cancel</button>
                                </div>
                            </form>
                            <!--end::Form-->
                        </div>
                        <!--end::Edit-->
                        <!--begin::Action-->
                        <div id="kt_signin_email_button" class="ms-auto">
                            <button class="btn btn-light btn-active-light-primary">Change Email</button>
                        </div>
                        <!--end::Action-->
                    </div>
                    <!--end::Email Address-->
                    <!--begin::Separator-->
                    <div class="separator separator-dashed my-6" v-if="GRANT_ACCESS(auth, ADMINISTRATIVE_CLASS.PASS.clearance_level, ACCESS_PRIVELEDGES.EMPLOYEE_MANAGEMENT)"></div>
                    <!--end::Separator-->
                    <!--begin::Password-->
                    <div class="d-flex flex-wrap align-items-center mb-10">
                        <!--begin::Label-->
                        <div id="kt_signin_password">
                            <div class="fs-6 fw-bolder mb-1">Password</div>
                            <div class="fw-bold text-gray-600">************</div>
                        </div>
                        <!--end::Label-->
                        <!--begin::Edit-->
                        <div id="kt_signin_password_edit" class="flex-row-fluid d-none">
                            <!--begin::Form-->
                            <form id="kt_signin_change_password" class="form  animated fadeIn" novalidate="novalidate">
                                <div class="row mb-1">
                                    <div class="col-lg-4">
                                        <div class="fv-row mb-0">
                                            <label for="currentpassword" class="form-label fs-6 fw-bolder mb-3">Current Password</label>
                                            <input type="password" class="form-control form-control-lg form-control-solid" name="currentpassword" id="currentpassword" v-model="oPassword" />
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="fv-row mb-0">
                                            <label for="newpassword" class="form-label fs-6 fw-bolder mb-3">New Password</label>
                                            <input type="password" class="form-control form-control-lg form-control-solid" name="newpassword" id="newpassword" v-model="nPassword" />
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="fv-row mb-0">
                                            <label for="confirmpassword" class="form-label fs-6 fw-bolder mb-3">Confirm New Password</label>
                                            <input type="password" class="form-control form-control-lg form-control-solid" name="confirmpassword" id="confirmpassword" v-model="cPassword"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-text mb-5">Password must be at least 8 character and contain symbols</div>
                                <div class="d-flex">
                                    <button id="kt_password_submit" type="button" @click="UPDATE_USER_PASSWORD()" class="btn btn-primary me-2 px-6">Update Password</button>
                                    <button id="kt_password_cancel" type="button" class="btn btn-color-gray-400 btn-active-light-primary px-6">Cancel</button>
                                </div>
                            </form>
                            <!--end::Form-->
                        </div>
                        <!--end::Edit-->
                        <!--begin::Action-->
                        <div id="kt_signin_password_button" class="ms-auto">
                            <button class="btn btn-light btn-active-light-primary">Reset Password</button>
                        </div>
                        <!--end::Action-->
                    </div>
                    <!--end::Password-->
                    <!--begin::Notice-->
                    <div class="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6">
                        <!--begin::Icon--> 
                        <!--begin::Svg Icon | path: assets/media/icons/duotune/general/gen051.svg-->
                        <span class="svg-icon svg-icon-2tx svg-icon-primary me-4">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"/>
                            <path d="M14.854 11.321C14.7568 11.2282 14.6388 11.1818 14.4998 11.1818H14.3333V10.2272C14.3333 9.61741 14.1041 9.09378 13.6458 8.65628C13.1875 8.21876 12.639 8 12 8C11.361 8 10.8124 8.21876 10.3541 8.65626C9.89574 9.09378 9.66663 9.61739 9.66663 10.2272V11.1818H9.49999C9.36115 11.1818 9.24306 11.2282 9.14583 11.321C9.0486 11.4138 9 11.5265 9 11.6591V14.5227C9 14.6553 9.04862 14.768 9.14583 14.8609C9.24306 14.9536 9.36115 15 9.49999 15H14.5C14.6389 15 14.7569 14.9536 14.8542 14.8609C14.9513 14.768 15 14.6553 15 14.5227V11.6591C15.0001 11.5265 14.9513 11.4138 14.854 11.321ZM13.3333 11.1818H10.6666V10.2272C10.6666 9.87594 10.7969 9.57597 11.0573 9.32743C11.3177 9.07886 11.6319 8.9546 12 8.9546C12.3681 8.9546 12.6823 9.07884 12.9427 9.32743C13.2031 9.57595 13.3333 9.87594 13.3333 10.2272V11.1818Z" fill="black"/>
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                        <!--end::Icon-->
                        <!--begin::Wrapper-->
                        <div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                            <!--begin::Content-->
                            <div class="mb-3 mb-md-0 fw-bold">
                                <h4 class="text-gray-900 fw-bolder">Add extra security</h4>
                                <div class="fs-6 text-gray-700 pe-7">Two-factor authentication adds an extra layer of security to your account. To log in, in addition you'll need to provide a 6 digit code sent to your email address.</div>
                            </div>
                            <!--end::Content-->
                            <!--begin::Action--> 
                            <button class="btn rounded-0 px-6 align-self-center text-nowrap btn-primary" type="button" id="en_2FA" @click="ENABLE_2FA()" v-if="!CHECK_BOOLEAN(user.two_fa)">Enable 2FA</button> 
                            <button class="btn rounded-0 px-6 align-self-center text-nowrap btn-danger" type="button" id="dis_2FA" @click="DISABLE_2FA()" v-if="CHECK_BOOLEAN(user.two_fa)">Disable 2FA</button> 
                            <!--end::Action-->
                        </div>
                        <!--end::Wrapper-->
                    </div>
                    <!--end::Notice--> 
            </div>
            <!--end::Content-->
        </div>
    <!--end::Sign-in Method-->
                            
    
</div>
</template>

<script> 
import utility_services from "@/services/utility_services.js"
import user_services from "@/services/user.js"
//EMPLOYEE COMPONENT 
import { ENCRYPT, PARSE_AUTH } from "@/utils/security/"; 
import { IS_EMPTY, CHECK_BOOLEAN, READABLE_DATE, ADMINISTRATIVE_CLASS, GRANT_ACCESS, ACCESS_PRIVELEDGES } from "@/utils/"; 
export default {
    name: "User overview", 
    props: ["user"],  
    data() {
        return{  
            IS_EMPTY, CHECK_BOOLEAN, READABLE_DATE, ADMINISTRATIVE_CLASS, GRANT_ACCESS, ACCESS_PRIVELEDGES,
            editable_user: this.user,
            password: '',
            email: '',
            oEmail: '',
            oPassword: '',
            cPassword: '',
            nPassword: '',
            auth: PARSE_AUTH(this.$store.state.auth, "user"),
        }
    }, 
    methods: { 

        CONFIRM_USER_EMAIL: function(){ // VALIDATE/CHECK DATA IF EXIST IN THE DATABASE  
            utility_services.CONFIRM_USER_EMAIL({'email': this.email })
            .then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION
                console.log(result.data.msg) 
                !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) : null;
                // this.$store.commit('AUTH_SUCCESS', result.data.data);   
            })
            .catch(err => {
                console.log(err.message)    
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " encountered a fatal server error." })    
            })
        },

        UPDATE_USER_EMAIL: function(){ // VALIDATE/CHECK DATA IF EXIST IN THE DATABASE  
        
            document.getElementById('update_email_btn').setAttribute(`disabled`, true); 
            document.getElementById('update_email_btn').innerHTML = '<span class="spinner-border spinner-border-sm align-middle ms-2"></span> updating'; 
            let payload = ENCRYPT({'id': this.user.id, 'oEmail': this.oEmail, 'email': this.email, 'password': this.password})

            user_services.UPDATE_USER_EMAIL({payload})
            .then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION
                console.log(result.data) 
                this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) 
                result.data.success ?  setTimeout(() => this.INIT_RELOAD(true), 2000) : null;  
                document.getElementById('update_email_btn').innerHTML = 'Update Email'; 
                document.getElementById('update_email_btn').removeAttribute(`disabled`);  
                // this.$store.commit('AUTH_SUCCESS', result.data.data);   
            })
            .catch(err => {
                console.log(err.message)    
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " encountered a fatal server error." })   
                document.getElementById('update_email_btn').innerHTML = 'Update Email'; 
                document.getElementById('update_email_btn').removeAttribute(`disabled`);   
            })
        },  

        UPDATE_USER_PASSWORD: function(){ // VALIDATE/CHECK DATA IF EXIST IN THE DATABASE  
        
            document.getElementById('kt_password_submit').setAttribute(`disabled`, true); 
            document.getElementById('kt_password_submit').innerHTML = '<span class="spinner-border spinner-border-sm align-middle ms-2"></span> updating'; 
            let payload = ENCRYPT({ 'oPassword': this.oPassword, 'nPassword': this.nPassword,  'cPassword': this.cPassword, 'id': this.user.id})

            user_services.UPDATE_USER_PASSWORD({payload})
            .then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION
                console.log(result.data) 
                this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) 
                result.data.success ?  setTimeout(() => this.INIT_RELOAD(true), 2000) : null;  
                document.getElementById('kt_password_submit').innerHTML = 'Update Email'; 
                document.getElementById('kt_password_submit').removeAttribute(`disabled`);  
                // this.$store.commit('AUTH_SUCCESS', result.data.data);   
            })
            .catch(err => {
                console.log(err.message)    
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " encountered a fatal server error." })   
                document.getElementById('kt_password_submit').innerHTML = 'Update Email'; 
                document.getElementById('kt_password_submit').removeAttribute(`disabled`);   
            })
        }, 

        ENABLE_2FA: function(){ // VALIDATE/CHECK DATA IF EXIST IN THE DATABASE  
        
            document.getElementById('en_2FA').setAttribute(`disabled`, true); 
            document.getElementById('en_2FA').innerHTML = '<span class="spinner-border spinner-border-sm align-middle ms-2"></span> enabling'; 
            let payload = ENCRYPT({ 'id': this.user.id})

            user_services.USER_2FA({payload})
            .then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION
                console.log(result.data) 
                this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg })  
                document.getElementById('en_2FA').removeAttribute(`disabled`);  
                if(result.data.success)  {
                    setTimeout(() => this.INIT_RELOAD(true), 2000)
                    // document.getElementById("en_2FA").classList.add('btn-danger');
                    document.getElementById('en_2FA').innerHTML = 'Enable 2FA'; 
                }   
            })
            .catch(err => {
                console.log(err.message)    
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " encountered a fatal server error." })   
                document.getElementById('en_2FA').innerHTML = 'Enable 2FA'; 
                document.getElementById('en_2FA').removeAttribute(`disabled`);   
            })
        }, 

        DISABLE_2FA: function(){ // VALIDATE/CHECK DATA IF EXIST IN THE DATABASE  
        
            document.getElementById('dis_2FA').setAttribute(`disabled`, true); 
            document.getElementById('dis_2FA').innerHTML = '<span class="spinner-border spinner-border-sm align-middle ms-2"></span> disabling'; 
            let payload = ENCRYPT({ 'id': this.user.id})

            user_services.USER_2FA({payload})
            .then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION
                console.log(result.data) 
                this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg })  
                    document.getElementById('dis_2FA').removeAttribute(`disabled`);    
                if(result.data.success)  {
                    setTimeout(() => this.INIT_RELOAD(true), 2000)
                    // document.getElementById("dis_2FA").classList.add('btn-primary');
                    document.getElementById('dis_2FA').innerHTML = 'Disable 2FA'; 
                } 
            })
            .catch(err => {
                console.log(err.message)    
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " encountered a fatal server error." })   
                document.getElementById('dis_2FA').innerHTML = 'Disable 2FA'; 
                document.getElementById('dis_2FA').removeAttribute(`disabled`);   
            })
        }, 
         

        INIT_RELOAD: function(x){
            this.$emit('reload', x)
        }
    },
    mounted() {  
        let extScript = document.createElement('script')
        extScript.setAttribute('src', '/js/custom/account/settings/signin-methods.js') 
        document.head.appendChild(extScript)  
    }    
}
</script> 