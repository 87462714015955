<template>
<div> 
    <COMINGSOON class="animated fadeIn" />  
</div>
</template>
<script>  
import COMINGSOON from "@/components/loaders/coming_soon.vue";
// import FILEMAN_SERVICE  from "@/services/fileman.js";  
  export default {
    props: ["user"], 
    components: { COMINGSOON },    
}
</script>