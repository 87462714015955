<template>
<div> 

        <div class="card">
            <OVERLAYLOADER v-if="processing" />
            <!--begin::Body-->
            <div class="card-body">  
                <div class="me-1 my-1"> 
                    <select v-model="controlActive" @change="PARSE_CATEGORY()" class="w-200px form-select form-select-solid form-select-sm fs-8">
                        <option value="basic">Basic information</option>
                        <option value="coprate">Coprate information</option> 
                        <!--<option value="management">Setup & management</option>
                        <option value="access">Access control</option> -->
                    </select>
                </div>
            <!--begin::Form--> 
                    <!--begin::Form-->
                    <form id="kt_account_profile_details_form" class="form fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate" data-select2-id="select2-data-kt_account_profile_details_form">
                        <!--begin::Card body-->
                        <div class="card-body px-1">
                        <div v-if="controlActive == 'basic'">
                            <!--begin::Input group-->
                            <div class="row">
                                <!--begin::Label-->
                                <label class="col-lg-4 col-form-label fw-bold fs-6">Logo</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-8">
                                    <!--begin::Image input-->
                                    <div class="image-input image-input-outline" data-kt-image-input="true" :style="`background-image: url(${ dp })`">
                                        <!--begin::Preview existing avatar-->
                                        <div class="image-input-wrapper w-125px h-125px" :style="`background-image: url(${ dp })`">
                                            <OVERLAYLOADER class="h-100" v-if="processing2" />
                                        </div>
                                        <!--end::Preview existing avatar-->
                                        <!--begin::Label-->
                                        <label class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="" data-bs-original-title="Change avatar">
                                            <i class="mdi mdi-camera fs-7"></i>
                                            <!--begin::Inputs-->
                                            <input type="file" name="avatar" accept=".png, .jpg, .jpeg" @change="PREVIEW_DP($event)"> 
                                            <!--end::Inputs-->
                                        </label>
                                        <!--end::Label--> 
                                    </div>
                                    <!--end::Image input-->
                                    <!--begin::Hint-->
                                    <div class="form-text">Allowed file types: png, jpg, jpeg.</div>
                                    <!--end::Hint-->
                                </div>
                                <!--end::Col-->
                            </div>
                            <!--end::Input group-->
                            <!--begin::Input group-->
                            <div class="row mb-6 mt-8">
                                <!--begin::Label-->
                                <label class="col-lg-4 col-form-label required fw-bold fs-6">Official Name</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-8"> 
                                    <input type="text" name="fname" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="First name" v-model="editable_subsidiary.name">
                                </div>
                                <!--end::Col-->
                            </div>
                            <!--end::Input group-->
                            <!--begin::Input group-->
                            <div class="row mb-6">
                                <!--begin::Label-->
                                <label class="col-lg-4 col-form-label required fw-bold fs-6">Official email</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                    <input type="text" name="company" class="form-control form-control-lg form-control-solid" placeholder="Company name" v-model="editable_subsidiary.email">
                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                <!--end::Col-->
                            </div>
                            <!--end::Input group-->
                            <!--begin::Input group-->
                            <div class="row mb-6">
                                <!--begin::Label-->
                                <label class="col-lg-4 col-form-label fw-bold fs-6">
                                    <span class="required">Contact Phone</span>
                                    <i class="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Phone number must be active" aria-label="Phone number must be active"></i>
                                </label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                    <input type="tel" name="phone" class="form-control form-control-lg form-control-solid" placeholder="Phone number" v-model="editable_subsidiary.phone">
                                <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                <!--end::Col-->
                            </div>
                            <!--end::Input group-->
                            <!--begin::Input group-->
                            <div class="row mb-6">
                                <!--begin::Label-->
                                <label class="col-lg-4 col-form-label fw-bold fs-6">Official Site</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-8 fv-row">
                                    <input type="text" class="form-control form-control-lg form-control-solid" placeholder="Official website" v-model="editable_subsidiary.website">
                                </div>
                                <!--end::Col-->
                            </div>
                            <!--end::Input group--> 
                        </div>



                            
                        <div v-if="controlActive == 'coprate'">
                            <!--begin::Input group-->
                            <div class="row mb-6">
                                <!--begin::Label-->
                                <label class="col-lg-4 col-form-label fw-bold fs-6">Coprate Goal</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-8 fv-row">
                                    <textarea rows="5" type="text" class="form-control form-control-lg form-control-solid" placeholder="Official website" v-model="editable_subsidiary.goals"></textarea> 
                                </div>
                                <!--end::Col-->
                                <div class="separator mt-10 mb-5"></div>
                            </div>
                            <!--end::Input group-->  
                            <!--begin::Input group-->
                            <div class="row mb-6">
                                <!--begin::Label-->
                                <label class="col-lg-4 col-form-label fw-bold fs-6">Coprate Vision</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-8 fv-row">
                                    <textarea rows="5" type="text" class="form-control form-control-lg form-control-solid" placeholder="Official website" v-model="editable_subsidiary.vision"></textarea> 
                                </div>
                                <!--end::Col-->
                                <div class="separator mt-10 mb-5"></div>
                            </div>
                            <!--end::Input group-->  
                            <!--begin::Input group-->
                            <div class="row mb-6">
                                <!--begin::Label-->
                                <label class="col-lg-4 col-form-label fw-bold fs-6">Coprate Mission</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-8 fv-row">
                                    <textarea rows="5" type="text" class="form-control form-control-lg form-control-solid" placeholder="Official website" v-model="editable_subsidiary.mission"></textarea> 
                                </div>
                                <!--end::Col-->
                                <div class="separator mt-10 mb-5"></div>
                            </div>
                            <!--end::Input group--> 
                            
                            <!--begin::Input group-->
                            <div class="row mb-6">
                                <!--begin::Label-->
                                <label class="col-lg-4 col-form-label fw-bold fs-6">Policy</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-8 fv-row">
                                    <textarea rows="5" type="text" class="form-control form-control-lg form-control-solid" placeholder="Official website" v-model="editable_subsidiary.policy"></textarea> 
                                </div>
                                <!--end::Col-->
                                <div class="separator mt-10 mb-5"></div>
                            </div>
                            <!--end::Input group-->  
                            <!--begin::Input group-->
                            <div class="row mb-6">
                                <!--begin::Label-->
                                <label class="col-lg-4 col-form-label fw-bold fs-6">Core value</label>
                                <!--end::Label-->
                                <!--begin::Col-->
                                <div class="col-lg-8 fv-row">
                                    <textarea rows="5" type="text" class="form-control form-control-lg form-control-solid" placeholder="Official website" v-model="editable_subsidiary.core_value"></textarea> 
                                </div>
                                <!--end::Col--> 
                            </div>
                            <!--end::Input group-->   
                        </div>
 

                        </div>
                        <!--end::Card body-->
                        <!--begin::Actions-->
                        <div class="card-footer d-flex justify-content-end py-5 mb-n5 px-1"> 
                            <button type="button" class="btn btn-light-primary" @click="UPDATE_PORTFOLIO(), processing = true">Update</button>
                        </div>
                        <!--end::Actions-->
                    <input type="hidden">
                    <div> 
                    <!--end::Form--> 
                </div>
                <!--end::Card body--> 
            </form>
            <!--end::Form--> 




            </div>
            <!--end::Body-->
        </div>


</div> 
</template>

<script>
// @ is an alias to /src 
import PORTFOLIO_SERVICE  from "@/services/portfolio.js";  
import OVERLAYLOADER from "@/components/loaders/overlay-loader.vue";
import { PORTFOLIO_URLS } from "@/router/urls";
  
//TABS COMPONENT    
import { IS_EMPTY, APP_INFO, REMOVE_CHARC, RESPONSE_STATUS, ADMINISTRATIVE_CLASS, ACCESS_PRIVELEDGES , GRANT_ACCESS , /*RESPONSE_STATUS,*/ STATUS_TAG_COLOR, CAPITALIZE_STR, CHECK_BOOLEAN, CHECK_PLURAL, READABLE_DATE, TRUNCATE_STRING} from "@/utils/";  
import { PARSE_AUTH, ENCRYPT, /*DECRYPT */ } from "@/utils/security/";  
export default {
name: "portfolio-control",
props: ["subsidiary"],
data() {
    return{ 
        // Theme: this.$store.state.currentTheme, 
            // notifications: this.$store.getters.notifications,
        controlActive: "basic",
        PORTFOLIO_URLS,
        editModal: false,  
        admins: [], 
        administrator: '', 
        subsidiaries: [],
        processing: false, 
        processing2: false,
        employees: [],
        offices: [],  
        events: [], 
        priviledges: [],  
        admin_classes: [],
        adClassDesc: '',
        HQ: '', 
        editable_subsidiary: this.subsidiary,  
            logo: '',
            dp: IS_EMPTY(this.subsidiary) ? APP_INFO.default_img : this.subsidiary.logo,
        ADMINISTRATIVE_CLASS, GRANT_ACCESS , PORTFOLIO_SERVICE, RESPONSE_STATUS, ACCESS_PRIVELEDGES, CHECK_PLURAL, ENCRYPT, IS_EMPTY, PARSE_AUTH, APP_INFO, REMOVE_CHARC, STATUS_TAG_COLOR, CAPITALIZE_STR, CHECK_BOOLEAN, READABLE_DATE, TRUNCATE_STRING, 
        ref: this.$route.query.ref, // truncate ref url data to get user id 
		auth: PARSE_AUTH(this.$store.state.auth, "user"),
    }
},
components: { OVERLAYLOADER
    // Notification, SideNav, TopNav /*EVENTSCOMPONENT, ACTIVITYCOMPONENT /* CPlaceholder */
},  
methods: {  

    
        PREVIEW_DP: function(event) { 
            this.logo = event.target.files[0];
            (this.logo) ? this.dp = URL.createObjectURL(this.logo) : null
			this.processing2 = true;
            this.UPLOAD_USER_LOGO() // PROCESS IMAGE UPLOAD
        },

        UPLOAD_USER_LOGO: function() { 
			let formData = new FormData();
			formData.append("logo", this.logo)  
 
            let payload = ENCRYPT({'id': this.editable_subsidiary.id}) 

            PORTFOLIO_SERVICE.UPLOAD_PORTFOLIO_LOGO(payload, formData)
            .then(result => { 
                this.processing2 = false;
                console.log(result.data)
                this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) 
                result.data.success ? this.INIT_RELOAD(true) : null; 
                if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1500);
            })
            .catch(err => {
                this.processing2 = false;
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured processing request, try again!" });
                console.log(err.message) 
            }) 
        },
          
        INIT_RELOAD: function(x){
            this.$emit('reload', x)
        },

        UPDATE_PORTFOLIO: function(){    
            let payload = ENCRYPT(this.editable_subsidiary)
            PORTFOLIO_SERVICE.UPDATE_PORTFOLIO({payload}) 
            .then(res =>{ // TRY USER LOGIN PROCESS   
                this.processing = false; 
                console.log(res.data)
                this.$store.commit('PAGE_NOTIFICATION', {"success": res.data.success, "msg": res.data.msg });
                if(res.data.success){
                     console.log(res.data)
                }
                if(res.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 2500);
            }) 
            .catch(err => { 
                this.processing = false;
                console.log(err.message) 
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading page contents, reload page" }); 
            })   
        },
 
}, 
mounted() {    
	let extScript = document.createElement('script')
    extScript.setAttribute('src', '/js/custom/documentation/forms/image-input.js') 
    document.head.appendChild(extScript) 
}, 
};
</script>
