<template>
<div>
<Notification />  
    <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper"> 
        <TopNav />
        <SideNav /> 
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
            <!--begin::Container-->
            <div class="container-xxl px-0 px-md-4" id="kt_content_container">  

            <!--begin::Row-->
            <div class="row g-5 g-xl-8">
                <div class="col-xl-3 col-6">
                    <!--begin::Statistics Widget 5-->
                    <span class="card bg-gray-100 shadow-sm hoverable card-xl-stretch mb-xl-8">
                        <!--begin::Body-->
                        <div class="card-body px-4 px-md-8 py-4">
                            <!--begin::Svg Icon | path: icons/duotune/general/gen032.svg-->
                            <span class="mdi mdi-human-male mdi-48px"></span>
                            <!--end::Svg Icon-->
                            <div class="text-gray-900 fw-bolder fs-2">500M$</div>
                            <div class="fw-bold text-gray-400 fs-8">Total male employees</div>
                        </div>
                        <!--end::Body-->
                    </span>
                    <!--end::Statistics Widget 5-->
                </div>
                <div class="col-xl-3 col-6">
                    <!--begin::Statistics Widget 5-->
                    <span class="card bg-gray-100 shadow-sm  hoverable card-xl-stretch mb-xl-8">
                        <!--begin::Body-->
                        <div class="card-body px-4 px-md-8 py-4">
                            <!--begin::Svg Icon | path: icons/duotune/general/gen032.svg-->
                            <span class="mdi mdi-human-female mdi-48px"></span>
                            <!--end::Svg Icon-->
                            <div class="text-gray-900 fw-bolder fs-2">500M$</div>
                            <div class="fw-bold text-gray-400 fs-8">Total female employees</div>
                        </div>
                        <!--end::Body-->
                    </span>
                    <!--end::Statistics Widget 5-->
                </div>
                <div class="col-xl-3 col-6">
                    <!--begin::Statistics Widget 5-->
                    <span class="card bg-gray-100 shadow-sm hoverable card-xl-stretch mb-xl-8">
                        <!--begin::Body-->
                        <div class="card-body px-4 px-md-8 py-4">
                            <!--begin::Svg Icon | path: icons/duotune/general/gen032.svg-->
                            <span class="mdi mdi-account-star mdi-48px"></span>
                            <!--end::Svg Icon-->
                            <div class="text-gray-900 fw-bolder fs-2">500M$</div>
                            <div class="fw-bold text-gray-400 fs-8">Total active employees</div>
                        </div>
                        <!--end::Body-->
                    </span>
                    <!--end::Statistics Widget 5-->
                </div>
                <div class="col-xl-3 col-6">
                    <!--begin::Statistics Widget 5-->
                    <span class="card bg-gray-100 shadow-sm hoverable card-xl-stretch mb-5 mb-xl-8">
                        <!--begin::Body-->
                        <div class="card-body px-4 px-md-8 py-4">
                            <!--begin::Svg Icon | path: icons/duotune/general/gen032.svg-->
                            <span class="mdi mdi-account-alert mdi-48px"></span>
                            <!--end::Svg Icon-->
                            <div class="text-gray-900 fw-bolder fs-2">500M$</div>
                            <div class="fw-bold text-gray-400 fs-8">Total inactive employees</div>
                        </div>
                        <!--end::Body-->
                    </span>
                    <!--end::Statistics Widget 5-->
                </div>
            </div>
            <!--end::Row--> 
                            <!--end::Card toolbar-->

									<div class="bg-white py-2 px-5 mb-4">
										<!--begin::Card header-->
										<div class="d-flex justify-content-between pt-3">
											<!--begin::Tabs-->
											<h5>Total of 20 employes</h5>
											<!--end::Tabs--> 
                                            <!-- <AddSubsidiary /> -->
										</div>
                                    </div>

                            

							<div class="row g-6 mb-6 g-xl-9 mb-xl-9">
								<!--begin::Followers-->
								<!--begin::Col-->
								<div class="col-6 col-md-3 col-xxl-4 cursor-pointer" @click="LOAD_EMPLOYEE(employee.users_id)" v-for="(employee, i) in employees" :key="i">
                                    
									<!--begin::Card-->
									<div class="card shadow-sm">
										<!--begin::Card body-->
										<div class="card-body d-flex flex-center flex-column">
											<!--begin::Avatar-->
											<div class="symbol symbol-75px symbol-circle mb-5">
												<img :src="employee.user.photo" />
												<div :class="'bg-'+STATUS_TAG_COLOR(employee.status_id)" class="position-absolute rounded-circle translate-middle start-100 top-100 border border-4 border-white h-15px w-15px ms-n3 mt-n3"></div>
											</div>
											<!--end::Avatar-->
											<!--begin::Name-->
											<span class="fs-6 fs-md-4 text-gray-800 fw-bolder mb-0">
                                            <LOADEMPLOYEE :emp="employee" /> 
                                            </span>
											<!--end::Name-->
											<!--begin::Position-->
											<div class="fw-bold text-gray-400 fs-8">{{TRUNCATE_STRING(employee.user.email, 20)}}</div>
											<div class="fw-bold text-gray-400 fs-8">{{employee.user.phone}}</div>
											<!--end::Position-->  
										</div>
										<!--begin::Card body-->
									</div>
									<!--begin::Card-->
								</div>
								<!--end::Col--> 
							</div>
							<!--end::Row-->

                                 <div class="bg-white py-3 border-top-1" v-if="remItem !== 0"> 
                                        <div class="d-flex justify-content-center">
                                            <button class="btn btn-secondary btn-sm rounded-0" type="button" @click="LOAD_EMPLOYEES()">Load more...</button>
                                        </div>
                                </div>
			</div>

 
        </div>

    </div>
</div> 
</template>

<script>
// @ is an alias to /src
import Notification from "@/components/global/notification.vue";
import SideNav from "@/components/global/side_nav.vue";
import TopNav from "@/components/global/top_nav.vue";
import EMPLOYEE_SERVICE  from "@/services/employee.js"; 

//TABS COMPONENT 
// import LOADEMPLOYEE from "./components/modals/load_employee.vue";  
// import { CPlaceholder } from '@coreui/vue'
import { PORTFOLIO_URLS } from "@/router/urls";
import { IS_EMPTY, APP_CONSTANTS, GET_INITIAL, TRUNCATE_STRING, GET_COLOR, THOUSAND_SEPARATOR, APP_INFO, STATUS_CODE, REMOVE_CHARC, RESPONSE_STATUS, STATUS_TAG_COLOR, CAPITALIZE_STR, CHECK_BOOLEAN, READABLE_DATE} from "@/utils/";  
import { PARSE_AUTH, ENCRYPT, DECRYPT } from "@/utils/security/"; 
export default {
name: "Offices",
data() {
    return{ 
        // Theme: this.$store.state.currentTheme,   
        uid: "",  
        load_user: false,
        PORTFOLIO_URLS, 
        READABLE_DATE, APP_CONSTANTS, ENCRYPT, GET_INITIAL, GET_COLOR, TRUNCATE_STRING, IS_EMPTY, THOUSAND_SEPARATOR, PARSE_AUTH, APP_INFO, STATUS_CODE, REMOVE_CHARC, STATUS_TAG_COLOR, CAPITALIZE_STR, CHECK_BOOLEAN,
        page: 1, 
        employees: [],
		dp: APP_CONSTANTS.default_photo,
        totalSubs: null,   
        nextPage: 0,
        remItem: 0,  
        ref: this.$route.query.ref, // truncate ref url data to get user id 
        url_filter: this.$route.query.filter, // truncate ref url data to get user id 
        url_id: this.$route.query._id, // truncate ref url data to get user id  
    }
},
components: {
    Notification, SideNav, TopNav, /*  LOADEMPLOYEE AddSubsidiary, CPlaceholder */
},  
methods: {    

        RELOAD_CONTENT: function(){ 
            this.LOAD_EMPLOYEES()
        }, 

        LOAD_EMPLOYEE: function(id){ 
            console.log(id)
            this.uid = id;
            this.load_user = true;
        },

        CHECK_QUERY: function(x){  
            let payload = ENCRYPT({'id': DECRYPT(this.ref), '_id': DECRYPT(this.url_id), 'page':this.page}); 
            switch (x) {
                case 'branch': 
                    return EMPLOYEE_SERVICE.LOAD_EMPLOYEES_FILTER_BRANCH({payload}) 
                
                case 'department': 
                    return EMPLOYEE_SERVICE.LOAD_EMPLOYEES_FILTER_DEPARTMENT({payload})  
                
                case 'subsidiary': 
                    return EMPLOYEE_SERVICE.LOAD_EMPLOYEES_FILTER_SUBSIDIARY({payload}) 
            
                default:
                    return EMPLOYEE_SERVICE.LOAD_EMPLOYEES({payload}) 
            }   
        },

        FILTER_SUBSIDIARIES: function(){ 
            this.offices.filter((element) => element.status_id == 2 );
            console.log(this.offices)
        },

        LOAD_EMPLOYEES: function(){ // FETCH ALL MESSAGES FROM DATABASE 
        
        this.CHECK_QUERY(DECRYPT(this.url_filter)) 
        .then(result =>{ 

            console.log(result.data)
            !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) : null;

            if(result.data.success){ 
                this.nextPage = DECRYPT(result.data.data).nextPage;
                this.remItem =  DECRYPT(result.data.data).rem;
                this.page = DECRYPT(result.data.data).curPage;  
                this.totalSubs = DECRYPT(result.data.data).total; 
                let pLoad = DECRYPT(result.data.data).employees; 
                
                setTimeout(() => this.employees = [...this.employees, ...pLoad],500)  

            }

            if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1000); 
        })
        .catch(err => {
            console.log('Error:' + err.message) 
            this.$store.commit('PAGE_NOTIFICATION', {
                "success": false, 
                "msg": 'error occured loading contents' 
            });
        })
    }, 
    // GET_NEXT_PAYLOAD() {  // get paginated payloads
    //   window.onscroll = () => {  
    //     let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
    //     if (bottomOfWindow & this.remItem !== 0) { 
    //         this.loadingMore = 'yes';  
    //      apiServices.LOAD_USER_MESSAGES({'id': this.ref, 'page': this.nextPage})
    //     .then(result =>{
    //           this.loadingMore = 'no'
    //           this.subs.push(result.data.data.subs[0]); 
    //           this.remItem =  result.data.data.rem
    //           this.nextPage =  result.data.data.nextPage      
    //     }).catch(err => {
    //         this.loadingMore = 'error'
    //         this.loadingMoreError = true
    //         console.log(err.message)
    //     })
    //     }
    //   }
    // }, 

        LOAD_COMPONENT: function(tab){ 
            this.$store.commit('PAGE_NOTIFICATION', null) 
            this.active_tab = tab;
        }

}, 
mounted() { 
    this.LOAD_EMPLOYEES()
} 

};
</script>
