<template>
<div>
<EMPERROR v-if="resp" :status="respStat" :msg="respMsg" /> 
<BLANKLOADER class="mt-5" v-if="loading" /> 
<form v-else id="newEmployeeForm" class="card rounded border-0" enctype="multipart/form-data">
<OVERLAYLOADER v-if="processing" /> 
<div class="row mt-5">  
<div class="mb-3 col-md-2 offset-md-0 col-6 offset-3">  
<div class="form-group">
<label class="fs-7 fw-bolder">Click to select photo <b class="text-danger text-bolder">*</b></label>
<div class="btn btn-default btn-file p-0">
<img :src="dp" id="blah" class="img-fluid shadow-lite shadow-sm img-thumbnail" alt="">
<input type="file" @change="PREVIEW_DP($event)"> 
</div> 
</div> 
</div>  
<div class="mb-3 col-md-10"> 
<div class="row">
<div class="mb-3 col-lg-4 col-md-4"> 
<div class="form-floating mb-3"> 
<input type="text" class="form-control text-gray-700" id="floatingInput" placeholder=" " v-model="sName">
<label class="fs-6 fw-bolder">Surname <b class="text-danger text-bolder">*</b></label>
</div> 
</div>
<div class="mb-3 col-md-4">
<div class="form-floating mb-3">
<input type="text" class="form-control" id="floatingInput" placeholder=" " v-model="fName">
<label class="fs-6 fw-bolder">First name <b class="text-danger text-bolder">*</b></label>
</div>  
</div>
<div class="mb-3 col-md-4">
<div class="form-floating mb-3">
<input type="text" class="form-control" id="floatingInput" placeholder=" " v-model="oName">
<label class="fs-6 fw-bolder">Other name </label>
</div>
</div>
<div class="mb-3 col-md-4">
<div class="form-floating mb-3">
<input type="email" class="form-control" id="floatingInput" placeholder=" " v-model="email"  @focusout="VALIDATE_EMAIL()">
<label class="fs-6 fw-bolder">Email address <b class="text-danger text-bolder">*</b></label>
</div> 
</div>
<div class="mb-3 col-md-3">
<div class="form-floating mb-3">
<input type="text" class="form-control" id="floatingInput" placeholder=" " v-model="phone">
<label class="fs-6 fw-bolder">Phone <b class="text-danger text-bolder">*</b></label>
</div>  
</div>

<div class="mb-3 col-md-5">
<div class="form-floating mb-3">
<input type="text" class="form-control" id="floatingInput" placeholder=" " v-model="address">
<label class="fs-6 fw-bolder">Home address <b class="text-danger text-bolder">*</b></label>
</div>  
</div>

<div class="mb-3 col-md-2 col-lg-2">
<div class="form-floating mb-3">
<select v-model="gender" class="form-select form-control shadow-sm" id="floatingInput">
<option value="" selected="">--select--</option>
<option value="male">male</option>
<option value="female">female</option>
</select> 
<label class="fs-6 fw-bolder">Gender <b class="text-danger text-bolder">*</b></label>
</div> 
</div>

<div class="mb-3 col-md-3">
<div class="form-floating mb-3">
<input type="date" class="form-control" id="floatingInput" placeholder=" " v-model="dob">
<label class="fs-6 fw-bolder">Date of birth <b class="text-danger text-bolder">*</b></label>
</div>  
</div>

<div class="mb-3 col-md-4">
<div class="form-floating mb-3">
<select v-model="country" @change="GET_STATES()" class="form-select form-control shadow-sm" id="country">
<option value="" selected="">--select--</option>
<option v-bind:value="country.id" v-for="(country, coi) in countries" :key="coi"> {{CAPITALIZE_STR(country.name)}}</option>
</select>
<label class="fs-6 fw-bolder">Country of origin <b class="text-danger text-bolder">*</b></label>
</div> 
</div> 

<!--end col-->
<div class="mb-3 col-md-3"> 
<div class="form-floating mb-3">
<select v-model="oState" class="form-select form-control shadow-sm" id="orgState" :disabled="states == null">
<option value="" selected="">--select--</option>
<option v-bind:value="st.id" v-for="(st, si) in states" :key="si"> {{CAPITALIZE_STR(st.name)}}</option>
</select>
<label class="fs-6 fw-bolder">State of origin <b class="text-danger text-bolder">*</b></label>
</div> 
</div>


</div>
</div> 


<!--end col-->
<div class="mb-3 col-md-4"> 
<div class="form-floating mb-3">
<input type="text" class="form-control" id="floatingInput" placeholder=" " v-model="hometown">
<label class="fs-6 fw-bolder">Hometown <b class="text-danger text-bolder">*</b></label>
</div>  
</div>

<div class="mb-3 col-md-4">
<div class="form-floating mb-3">
<input type="text" class="form-control" id="floatingInput" placeholder=" " v-model="portfolio.name" disabled>
<label class="fs-6 fw-bolder">Works with <b class="text-danger text-bolder">*</b></label>
</div>   
</div>
 

<div class="mb-3 col-md-4">
<div class="form-floating mb-3"> 
<select v-model="office" class="form-select form-control shadow-sm" id="orgType">
<option value="" selected="">--select--</option>
<option v-bind:value="off.id" v-for="(off, i) in subsidiary.offices" :key="i"> {{ off.address + ' ' + off.state.name + ' ' + off.country.name}}.</option>
</select> 
<label class="fs-6 fw-bolder">Office <b class="text-danger text-bolder">*</b></label>
</div>
</div>


<div class="mb-3 col-md-3">
<div class="form-floating mb-4">
<input type="text" class="form-control" id="floatingInput" placeholder=" " v-model="role">
<label class="fs-6 fw-bolder">Job role <b class="text-danger text-bolder">*</b></label>
</div>   
</div>

<div class="mb-3 col-md-3">
<div class="form-floating mb-3"> 
<select v-model="level" class="form-select form-control shadow-sm" id="orgType">
<option value="" selected="">--select--</option>
<option v-bind:value="lvl.id" v-for="(lvl, i) in subsidiary.employee_levels" :key="i"> {{CAPITALIZE_STR(lvl.name)}}</option>
</select>
<label class="fs-6 fw-bolder">Level <b class="text-danger text-bolder">*</b></label>
</div>
</div>


<div class="mb-3 col-md-3">
<div class="form-floating mb-3"> 
<select v-model="department" class="form-select form-control shadow-sm" id="orgType"> 
<option value="" selected="">--select--</option>
<option v-bind:value="dept.id" v-for="(dept, i) in subsidiary.employee_departments" :key="i"> {{CAPITALIZE_STR(dept.name)}}</option>
</select>
<label class="fs-6 fw-bolder">Department <b class="text-danger text-bolder">*</b></label>
</div>
</div> 


<div class="mb-3 col-md-3">
<div class="form-floating mb-3"> 
<select v-model="position" class="form-select form-control shadow-sm" id="orgType">
<option value="" selected="">--select--</option>
<option v-bind:value="null">None</option>
<option v-bind:value="posi.id" v-for="(posi, i) in subsidiary.employee_positions" :key="i"> {{CAPITALIZE_STR(posi.name)}}</option>
</select>
<label class="fs-6 fw-bolder">Position <b class="text-danger text-bolder">*</b></label>
</div>
</div>


<div class="mb-3 col-md-4" v-if="!IS_EMPTY(units)">
<div class="form-floating mb-3"> 
<select v-model="unit" class="form-select form-control shadow-sm" id="orgType">
<option value="" selected="">--select--</option>
<option v-bind:value="uni.id" v-for="(uni, i) in subsidiary.employee_units" :key="i"> {{CAPITALIZE_STR(uni.name)}}</option>
</select>
<label class="fs-6 fw-bolder">Employee Unit</label>
</div>
</div>

<div class="mb-3 col-md-4" v-if="!IS_EMPTY(teams)">
<div class="form-floating mb-3"> 
<select v-model="team" class="form-select form-control shadow-sm" id="orgType">
<option value="" selected="">--select--</option>
<option v-bind:value="tea.id" v-for="(tea, i) in subsidiary.employee_teams" :key="i"> {{CAPITALIZE_STR(tea.name)}}</option>
</select>
<label class="fs-6 fw-bolder">Employee team</label>
</div>
</div>

<div class="mb-3 col-md-2">
<div class="form-floating mb-4">
<input type="text" class="form-control" id="floatingInput" placeholder=" " v-model="salary">
<label class="fs-6 fw-bolder">Salary <b class="text-danger text-bolder">*</b></label>
</div>   
</div> 

<div class="mb-3 col-md-2">
<div class="form-floating mb-4">
<input type="number" class="form-control" id="floatingInput" placeholder=" " v-model="noOfWorkDays">
<label class="fs-6 fw-bolder">work days<b class="text-danger text-bolder">*</b></label>
</div>   
</div> 

<div class="mb-3 col-md-4">
<div class="form-floating mb-4">
<input type="text" class="form-control" id="floatingInput" placeholder=" " v-model="hSource">
<label class="fs-6 fw-bolder">Hiring source <b class="text-danger text-bolder">*</b></label>
</div>   
</div> 

<div class="mb-3 col-md-4 col-lg-4">
<div class="form-floating mb-4">
<select v-model="empTypeData" @change="checkEndDate()" class="form-select form-control shadow-sm" id="orgType">
<option value="" selected="">--select--</option>
<option v-bind:value="(emp.id + '/' + emp.expires)" v-for="(emp, i) in empTypes" :key="i"> {{CAPITALIZE_STR(emp.name)}}</option>
</select>
<label class="fs-6 fw-bolder">Employment type <b class="text-danger text-bolder">*</b></label>
</div>   
</div> 

<div class="mb-3 col-md-4 col-lg-2">
<div class="form-floating mb-4">
<input type="date" class="form-control" id="floatingInput" placeholder=" " v-model="rDate">
<label class="fs-6 fw-bolder">Resumption date <b class="text-danger text-bolder">*</b></label>
</div>   
</div> 

<div class="mb-3 col-md-4 col-lg-2 animated fadeIn" v-if="eDateStatus">
<div class="form-floating mb-4">
<input type="date" class="form-control" id="floatingInput" placeholder=" " v-model="eDate">
<label class="fs-6 fw-bolder">End date <b class="text-danger text-bolder">*</b></label>
</div>   
</div> 

<div class="col-md-12 text-left">
<label class="form-label text-left">Working days <span class="text-danger fs-8">(leave at least one)*</span>
</label>
</div>
<div class="mb-3 col-auto" v-for="(wk, i) in workDays" :key="i">
<span class="animated fadeIn d-block rounded-5 border small border-1 fs-7 fw-bolder" style="padding-left:5px;">
{{wk}}
<button @click="REMOVE_WORK_DAY(i)" type="button" class="btn btn-icon btn-sm rounded-0 btn-light-danger text-white">
<i class="mdi mdi-window-close"></i>
</button>
</span>
</div>
<div class="mb-3 col-md-3 col-lg-2">
<div class="d-flex justify-content-between">
<div class="input-group mb-3">
<input :disabled="workDays.length == 7" v-model="newWorkDay" type="text" class="form-control form-control-sm" placeholder="add day...">
<button @click="ADD_WORK_DAY()" type="button" class="btn btn-pills btn-primary btn-sm">
<b>+</b>
</button>
</div>
</div>
</div>

<div class="col-md-12">
<div class="d-flex justify-content-end">
<button @click="VALIDATE_FORM()" id="submit" type="button" class="btn btn-light-primary shadow-lite rounded-0 disable">Submit <i class="mdi mdi-send"></i>
</button>
</div>
<!--end col-->
</div>
</div>
<!--end row-->
</form>
</div>
</template>
<script>
import BLANKLOADER from "@/components/loaders/blank-loader.vue";
import EMPERROR from "@/components/loaders/response.vue";
import OVERLAYLOADER from "@/components/loaders/overlay-loader.vue"
//general resources  
import { GENERATE_RAND_STRING, CHECK_EMAIL_PATTERN, MILLISECONDS_TO_DAYS, CAPITALIZE_STR, REMOVE_CHARC, RESPONSE_STATUS, APP_CONSTANTS, IS_EMPTY } from "@/utils/"
import utility_services from "@/services/utility_services"
import USER_SERVICES from "@/services/user"
import EMPLOYEE_SERVICE  from "@/services/employee.js"; 
import { ENCRYPT, DECRYPT } from "@/utils/security/"; 
export default {
	name: "add-employee",
    props: ["subsidiary"],
	data() {
		return {
             
        respMsg: '',
        resp: false,
        respStat: '',
		portfolio: this.subsidiary, 
		processing: false,
        hSource: 'direct hire', 
		sName: '', 
		fName: '',  
		oName: '', 
		phone: '', 
		email: '', 
		address: '', 
		gender: '',  
		dob: '', 
		country: null, 
		oState: '', 
		hometown: '',  
		office: '', 
		level: '', 
		position: '', 
		department: '',  
		salary: '', 
		noOfWorkDays: 30, 
		empType: '',  
		empTypeData: '', 
		rDate: '', 
		eDate: '', 
		subsidiary_id: '', 
		eDateStatus: false,
		regErr: false,
		orgNameErr: false,
		emailErr: false,
		success: null,
		msg: ' ',
		subsidiaries: [],
		offices: [],
		levels: [],
		departments: [],
		positions: [],
		teams: [],
		team: '',
		units: [],
		unit: '',
		workDays: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
		empTypes: '',
		role: '',
		photo: '',
		dp: APP_CONSTANTS.default_photo, 
		countries: null,
		states: null,
		ref: this.$route.query.ref, // truncate ref url data to get user id
		loading: true, 
		employee_subsidiary: this.subsidiary,
		MILLISECONDS_TO_DAYS, CAPITALIZE_STR, REMOVE_CHARC, RESPONSE_STATUS, APP_CONSTANTS, IS_EMPTY, GENERATE_RAND_STRING, CHECK_EMAIL_PATTERN
		}
	},
	components: {
		BLANKLOADER,
		EMPERROR,
		OVERLAYLOADER,
		// AddAdminModal
	},
	methods: { 

		PREVIEW_DP: function(event) {

			this.photo = event.target.files[0];
			if (this.photo) {
				this.dp = URL.createObjectURL(this.photo)
			}

		},

		RELOAD_CONTENT: function() { // reload content  
			this.LOAD_SUBSIDIARY();
		},

		checkEndDate: function() { // reload content
			var str = this.empTypeData.split('/')
			this.empType = str.shift();
			console.log(str)

			if (str.pop() == 'true') {
				this.eDateStatus = true;
			} else {
				this.eDateStatus = false;
			}
		}, 

		ADD_WORK_DAY: function() {
			var chkWorkDay = this.workDays.filter(workDay => workDay == this.newWorkDay);
			if (this.newWorkDay !== "" & chkWorkDay == '') {
				this.workDays.push(this.newWorkDay);
			}
			this.newWorkDay = '';
		},

		REMOVE_WORK_DAY: function(i) {
			this.workDays.splice(i, 1);
		},

		GET_COUNTRIES: function() {
			utility_services.GET_COUNTRIES()
			.then(result => { // TRY ALL RESOURCE NEEDED FOR REGISTRATION  
				if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1500); 
				!result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" }) : this.countries = DECRYPT(result.data.data);
			})
			.catch(err => {
				this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" })
				console.log(err.message)
			}) 
		},

		GET_STATES: function() {
			this.org_states  = null; 
			this.org_cities  = null;  
			utility_services.GET_STATES({
				'id': this.country
			})
			.then(result => { // TRY ALL RESOURCE NEEDED FOR REGISTRATION  
				if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1500); 
				!result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" }) : this.states = result.data.data; 
			})
			.catch(err => {
				this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" })
				console.log(err.message)
			})  
		},
	
		GET_EMPLOYEMENT_TYPES: function() {
			utility_services.GET_EMPLOYEEMENT_TYPES()
			.then(result => { // TRY ALL RESOURCE NEEDED FOR REGISTRATION 
			console.log(result.data)
				if (!result.data.success) { 
					this.$emit('notifyer', { 'success': 'error', 'msg': 'encountered an internal server error' })
				} else {
					this.loading = false;
					this.empTypes = result.data.data;
				}
			})
			.catch(err => {
				console.log(err.message)
			})
			// this.orgState = this.states.filter(stateData => stateData.countryId == this.country); // search and find message from message array using message id   
		},

		VALIDATE_EMAIL: function() { // VALIDATE ORG IF NAME EXIST IN THE DATABASE  
			if(!CHECK_EMAIL_PATTERN(this.email)) this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " this is an invalid email address" });
			else{
				utility_services.CONFIRM_USER_EMAIL({
					'email': this.email
				})
				.then(result => { // TRY ALL RESOURCE NEEDED FOR REGISTRATION 
					if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 3000);
					if(!result.data.success) this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg });  
				})
				.catch(err => {
					console.log(err.message)
				})
			}
		}, 

		addDept: function() {
			var chkDept = this.depts.filter(dep => dep == this.newDept);
			if (this.newDept !== "" & chkDept == '') {
				this.depts.push(this.newDept);
			}
			this.newDept = '';
		},

		removeDept: function(i) {
			this.depts.splice(i, 1);
		}, 

		addPosi: function() {
			var chkPosi = this.positions.filter(pos => pos == this.newPosi);
			if (this.newPosi !== "" & chkPosi == '') {
				this.positions.push(this.newPosi);
			}
			this.newPosi = '';
		},

		removePosi: function(j) {
			this.positions.splice(j, 1);
		}, 

		addLvl: function() {
			var chkLvl = this.levels.filter(lv => lv == this.newLvl);
			if (this.newLvl !== "" & chkLvl == '') {
				this.levels.push(this.newLvl);
			}
			this.newLvl = '';
		},

		removeLvl: function(n) {
			this.levels.splice(n, 1);
		},

		VALIDATE_FORM: function() { // VALIDATE FORM DATA  

			this.processing = true;
			this.$store.commit('PAGE_NOTIFICATION', {"success": null, "msg": null }); 

			if ( !CHECK_EMAIL_PATTERN(this.email) || IS_EMPTY(this.email) || IS_EMPTY(this.sName) || IS_EMPTY(this.fName) || IS_EMPTY(this.phone) || IS_EMPTY(this.address) || IS_EMPTY(this.dob) || IS_EMPTY(this.office) || IS_EMPTY(this.department) || IS_EMPTY(this.empType) || IS_EMPTY(this.salary) || IS_EMPTY(this.noOfWorkDays)) {
				this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " some required fields are empty" });
				this.processing = false;
			} else {
				this.DO_FORM_SUBMISSION() // else process form
                // this.UPLOAD_USER_PHOTO()
			}



		},  

		DO_FORM_SUBMISSION: function() { 
 
            let payload = ENCRYPT({  
			'id': DECRYPT(this.ref),
			'sobs': this.subsidiary.onboarding,
			'hSource': this.hSource,
			'sName': this.sName,
			'fName': this.fName,
			'oName': this.oName,
			'phone': this.phone,
			'email': this.email,
			'address': this.address,
			'gender': this.gender,
			'dob': this.dob,
			'country': this.country,
			'oState': this.oState,
			'hometown': this.hometown,
			'office': this.office,
			'level': this.level,
			'unit': this.unit,
			'team': this.team,
			'role': this.role,
			'position': this.position,
			'department': this.department,
			'salary': this.salary,
			'noOfWorkDays': this.noOfWorkDays,
			'workDays': this.workDays,
			'empType': this.empType,
			'rDate': this.rDate,
			'eDate': this.eDate
            })
 
            EMPLOYEE_SERVICE.SAVE_EMPLOYEE({payload})
			.then(result => {  
				if(result.data.success && IS_EMPTY(this.photo)){
					this.processing = false; 
					this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }); 
					this.$emit('action', { 'reload': true, 'close': 'add'}) //CLOSE ADD PANE AND RELOAD RECORDS
				}
				if(result.data.success && !IS_EMPTY(this.photo)){
					this.UPLOAD_USER_PHOTO(DECRYPT(result.data.data)) //DO PHOTO UPLOAD IF USER IMAGE WAS ADDED
				}
				if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 3000); 
			})
			.catch(err => { 
				this.processing = false;
				console.log('Error:' + err.message) 
				this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": "encountered a fatal server error" })
			})
		},
		
		UPLOAD_USER_PHOTO: function(id) { 
			
			let formData = new FormData();
			formData.append("photo", this.photo)  
 
            let payload = ENCRYPT({id});

            USER_SERVICES.UPLOAD_USER_PHOTO(payload, formData)
            .then(result => { 
				this.processing = false; 
                this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) 
                this.$emit('action', { 'reload': true, 'close': 'add'}); 
                if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1500);
            })
            .catch(err => {
                this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured processing request, try again!" });
                console.log(err.message) 
            }) 
        },
	},
	beforeMount() {},
	mounted() {
		this.GET_COUNTRIES()  
        this.GET_EMPLOYEMENT_TYPES()  
	}, 
};
</script>