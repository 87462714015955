<template>
  <div> 
    <form id="surityForm" class="login-form">
        <h5>Edit surity</h5>
      <div class="row"> 

            <div class="col-md-4 mb-3">
                <label class="form-label">Surity type <span class="text-danger">*</span></label> 
                <select class="form-select form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="editable_surity.surity_type_id">
                    <option v-for="(st, i) in surity_types" :key="i" :value="st.id">{{CAPITALIZE_STR(st.name)}}</option>
                </select>
            </div>

            <div class="col-md-8 mb-3">
                    <label class="form-label">Name <span class="text-danger">*</span></label> 
                    <input v-model="editable_surity.name" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" required="">
            </div>

            <div class="col-md-5 mb-3">
                <label class="form-label">Email <span class="text-danger">*</span></label> 
                <input v-model="editable_surity.email" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" required="">
            </div> 
            <div class="col-md-3 mb-3">
                <label class="form-label">Phone <span class="text-danger">*</span></label> 
                <input v-model="editable_surity.phone" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" required="">
            </div> 
            <div class="col-md-4 mb-3">
                <label class="form-label">Relationship <span class="text-danger">*</span></label> 
                <input v-model="editable_surity.relationship" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" required="">
            </div> 
            <div class="col-md-4 mb-3">
                <label class="form-label">Ocuppation <span class="text-danger">*</span></label> 
                <input v-model="editable_surity.occupation" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" required="">
            </div> 

            <div class="col-md-3 mb-3">
                <label class="form-label">Status <span class="text-danger">*</span></label> 
                <select class="form-select form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="editable_surity.status_id"> 
                    <option :value="STATUS_CODE.VERIFIED" :selected="editable_surity.status_id == STATUS_CODE.VERIFIED">verified</option>
                    <option :value="STATUS_CODE.UNVERIFIED" :selected="editable_surity.status_id == STATUS_CODE.VERIFIED">not verified</option>
                </select>
            </div> 
            
            <div class="col-md-5 mb-3">
                <label class="form-label">Address <span class="text-danger">*</span></label> 
                <input v-model="editable_surity.address" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" required="">
            </div> 

            
            <div class="col-md-4 mb-3"> 
                    <label class="form-label">Country <span class="text-danger">*</span></label> 
                    <div class="form-icon position-relative"> 
                    <select v-model="editable_surity.country_id" @change="GET_STATES()" class="form-select form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="country"> 
                        <option v-bind:value="country.id" v-for="(country, coi) in countries" :key="coi"> {{CAPITALIZE_STR(country.name)}}</option> 
                    </select>
                    </div> 
            </div><!--end col-->

            <div class="col-md-4 mb-3"> 
                    <label class="form-label">State <span class="text-danger">*</span></label>
                    <input v-if="IS_EMPTY(states)" :value="CAPITALIZE_STR(surity.state.name)" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" disabled="" required="">
                   
                    <div class="form-icon position-relative" v-if="cities !== null"> 
                    <select v-model="editable_surity.state_id" @change="GET_CITIES()" class="form-select form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="state" :disabled="states == null"> 
                        <option v-if="IS_EMPTY(states)"> {{CAPITALIZE_STR(surity.state.name)}}</option> 
                        <option v-bind:value="st.id" v-for="(st, si) in states" :key="si"> {{CAPITALIZE_STR(st.name)}}</option> 
                    </select>
                    </div>  
            </div><!--end col-->

            <div class="col-md-4 mb-3">
                    <label class="form-label">City <span class="text-danger">*</span></label>
                    
                    <input v-if="IS_EMPTY(cities)" :value="CAPITALIZE_STR(surity.city.name)" type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" disabled="" required="">

                    <div class="form-icon position-relative" v-if="cities !== null"> 
                        <select v-model="editable_surity.city_id" class="form-select form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="orgCity" :disabled="cities == null">   
                            <option v-bind:value="city.id" v-for="(city, ci) in cities" :key="ci"> {{CAPITALIZE_STR(city.name)}}</option> 
                            <option v-bind:value="0">others</option> 
                        </select>
                    </div>  
 
            </div><!--end col--> 
        </div> 

            <div class="d-flex justify-content-end mt-10">
                    <button type="button" @click="$emit('action', {'close': 'edit'})" class="btn btn-light-danger btn-flat me-3">Close form</button> 
                    <button type="button" @click="VALIDATE_UPDATE_SURITY_FORM()" id="surity_edit_btn" class="btn btn-light-primary btn-flat ml-2">Update <i class="mdi mdi-upload"></i> </button> 
            </div> 
    </form> 

  </div>
</template>


<script>
// @ is an alias to /src  
import utility_services from "@/services/utility_services.js"; 
import EMPLOYEE_SERVICE  from "@/services/employee.js"; 
import {MILLISECONDS_TO_DAYS, CAPITALIZE_STR, REMOVE_CHARC, IS_EMPTY , RESPONSE_STATUS, STATUS_CODE} from "@/utils/"
import { ENCRYPT, DECRYPT } from "@/utils/security/"; 

export default {
name: "edit-employee-surity",
props: ["employee", "surity"], 
data() {
    return{ 
        // Theme: this.$store.state.currentTheme,   
        editable_surity: this.surity, 
        MILLISECONDS_TO_DAYS, CAPITALIZE_STR, REMOVE_CHARC, ENCRYPT, RESPONSE_STATUS, STATUS_CODE, IS_EMPTY, 
        surity_types: '',
        countries: null,
        states: null, 
        cities: null, 
    }
},
components: {  }, 
methods: {

    CLOSE_NEW_SURITY_FORM: function() { // reload content  
        this.$emit('notifyer', {'success': null}) // close form
        this.$emit('notifyer', {'paneStatus': 'surity'}) 
    },
  
    GET_COUNTRIES: function() {
        utility_services.GET_COUNTRIES()
        .then(result => { // TRY ALL RESOURCE NEEDED FOR REGISTRATION 

        console.log(result.data)   
            if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1500); 
            !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" }) : this.countries = DECRYPT(result.data.data);
        })
        .catch(err => {
            this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" })
            console.log(err.message)
        }) 
    },

    GET_STATES: function() {
        this.states  = null; 
        this.cities  = null;  
        utility_services.GET_STATES({
            'id': this.country
        })
        .then(result => { // TRY ALL RESOURCE NEEDED FOR REGISTRATION  
            if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1500); 
            !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" }) : this.states = result.data.data; 
        })
        .catch(err => {
            this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" })
            console.log(err.message)
        })  
    },

    GET_CITIES: function(){
        this.cities  = null; 
        utility_services.GET_CITIES({'id':this.state})
        .then(result =>{ // TRY ALL RESOURCE NEEDED FOR REGISTRATION 
            if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 1500); 
            !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" }) : this.cities  = result.data.data;   
        })
        .catch(err => {
            this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" })
            console.log(err.message)    
        })
    },
    
    LOAD_SURITY_TYPES: function() { // FETCH ALL MESSAGES FROM DATABASE 
        this.retry = ''
        EMPLOYEE_SERVICE.LOAD_SUTIRY_TYPES({})
        .then(result => {  
            !result.data.success ? this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }) : this.surity_types = DECRYPT(result.data.data);  
        })
        .catch(err => {
            console.log('Error:' + err.message)
            this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " error occured loading contents, reload page" })  
        })
    },

    
    VALIDATE_UPDATE_SURITY_FORM: function() { // FETCH ALL MESSAGES FROM DATABASE    
        document.getElementById('surity_edit_btn').innerHTML = 'Submitting <i class="mdi mdi-loading mdi-spin"></i>';
        document.getElementById('surity_edit_btn').setAttribute(`disabled`, true); 
        if( IS_EMPTY(this.editable_surity.surity_type_id)  || IS_EMPTY(this.editable_surity.name)  || IS_EMPTY(this.editable_surity.email)  || IS_EMPTY(this.editable_surity.phone)){
            this.$store.commit('PAGE_NOTIFICATION', {"success": false, "msg": " ensure no required filed is empty."}); 
            document.getElementById('surity_edit_btn').innerHTML = 'Submit <i class="mdi mdi-upload"></i>';
            document.getElementById('surity_edit_btn').removeAttribute(`disabled`);
        }
        else{
            this.UPDATE_SURITY_FORM() 
        }
    },

    UPDATE_SURITY_FORM: function() { // FETCH ALL MESSAGES FROM DATABASE   
        let payload = ENCRYPT(this.editable_surity)
        EMPLOYEE_SERVICE.UPDATE_SURITY_FORM({ payload })
        .then(result => {  
            this.$store.commit('PAGE_NOTIFICATION', {"success": result.data.success, "msg": result.data.msg }); 
            // if (result.data.success && !IS_EMPTY(this.file)) this.UPLOAD_EDUCATION_FILE(DECRYPT(result.data.data)) // UPLOAD FILE IF THERE IS ANY ATTACHED
            if(result.data.success) this.$emit('action', { 'reload': true, 'close': 'edit'}) //CLOSE ADD PANE AND RELOAD RECORDS 
            document.getElementById('surity_edit_btn').removeAttribute(`disabled`);
            document.getElementById('surity_edit_btn').innerHTML = 'Update';
            if(result.data.code === RESPONSE_STATUS.UNAUTHORIZED.code) setTimeout(() => this.$store.commit('AUTH_LOGOUT', { 'path': this.$route.path } ), 3000);          
        })
        .catch(err => {
            console.log('Error:' + err.message)
            document.getElementById('surity_edit_btn').removeAttribute(`disabled`);
            document.getElementById('surity_edit_btn').innerHTML = 'Update';
            this.$emit('notifyer', { 'success': 'error', 'msg': 'error occured loading form contents' })
        })
    },

},
beforeMount() {},
mounted() {
    this.LOAD_SURITY_TYPES()
    this.GET_COUNTRIES() 
},

};
</script>